import {
  SAVE_SIGN_UP_SESSION,
  CLEAR_SIGN_UP_SESSION,
  MEMBER_LOGIN_SUCCEEDED,
  GET_MEMBER_DATA_SUCCEEDED,
  GET_MEMBER_DATA_FAILED,
  MEMBER_LOGOUT_SUCCEEDED,
  SEND_MEMBER_RESET_EMAIL_START,
  SEND_MEMBER_RESET_EMAIL_SUCCEEDED,
  SEND_MEMBER_RESET_EMAIL_FAILED,
  GET_MEMBER_LOCATION_INFO_START,
  GET_MEMBER_LOCATION_INFO_SUCCEEDED,
  GET_MEMBER_LOCATION_INFO_FAILED,
  MEMBERS_LOADING,
  MEMBERS_LOAD_SUCCESS,
  MEMBER_LOGIN_FAILED,
  SIGNUP_SUCCESS,
  VALID_INVITE,
  MEMBER_SAVE_CREDENTIALS,
  FETCH_REQUEST_START,
  FETCH_REQUEST_SUCCESS,
  FETCH_REQUEST_FAILED,
  UPDATE_REQUEST_START,
  UPDATE_REQUEST_SUCCESS,
  UPDATE_REQUEST_FAILED,
  // Registration
  SEND_REGISTER_START,
  SEND_REGISTER_SUCCEEDED,
  SEND_REGISTER_FAILED,
  // Job title list
  GET_TITLE_LIST_START,
  GET_TITLE_LIST_SUCCEEDED,
  GET_TITLE_LIST_FAILED,
  GET_DEPS_LIST_START,
  GET_DEPS_LIST_SUCCEEDED,
  GET_DEPS_LIST_FAILED,
  // POTP
  CELL_VERIFICATION_INIT,
  CELL_VERIFICATION_ATTEMPT,
  CELL_VERIFICATION_RESPONSE,
  CELL_VERIFICATION_ERROR,
  // Terms and conditions
  GET_TERMS_AND_CONDITIONS_START,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  GET_TERMS_AND_CONDITIONS_FAILED,
  // Country list
  // GET_COUNTRY_LIST_START,
  GET_COUNTRY_LIST_SUCCESS,
  // GET_COUNTRY_LIST_FAILED,

  // tz list
  // GET_TZLIST_START,
  GET_TZLIST_SUCCESS,
  // GET_TZLIST_FAILED,

  // Member information
  // GET_MEMBER_INFO_START,
  GET_MEMBER_INFO_SUCCESS,
  // GET_MEMBER_INFO_FAILED,
  PUT_MEMBER_INFO_START,
  PUT_MEMBER_INFO_SUCCESS,
  // PUT_MEMBER_INFO_FAILED,

  // Avatar update
  // PUT_AVATAR_UPDATE_START,
  GET_MEMBER_SETTING_SUCCESS,
  PUT_MEMBER_SETTING_SUCCESS,
  PUT_AVATAR_UPDATE_SUCCESS,
  // PUT_AVATAR_UPDATE_FAILED,
  FETCH_ROLES_START,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILED,
  SET_MEMBER_ALERT,

  //Notifications setting
  FETCH_NOTIFICATIONS_SETTING_START,
  FETCH_NOTIFICATIONS_SETTING_SUCCESS,
  FETCH_NOTIFICATIONS_SETTING_FAILED,
  PUT_NOTIFICATIONS_SETTING_START,
  PUT_NOTIFICATIONS_SETTING_SUCCESS,
  PUT_NOTIFICATIONS_SETTING_FAILED,
  SHOW_COUNTDOWN,
  SET_COUNTDOWN_TIMER,
  AUTO_LOGOUT,
  CLEAR_AUTO_LOGOUT,
  GET_ALL_MEMBERS,
} from '../actionTypes/member.js';

export const initialState = {
  // member: { member_id: 1 },
  member: null,
  memberInfo: {},
  session_id: '',
  signUpSession: '',
  locationInfo: {},
  isFetching: false,
  isLoading: false,
  isSuccess: false,
  isUpdated: false,
  isSubmittingRegisterForm: false,
  isLoadingVerification: false,
  error: '',
  members: [],
  loginErr: false,
  loginAttempt: false,
  loadingMembers: false,
  invite: {},
  credentials: null,
  jobTitleList: [],
  departmentsList: [],
  countryList: [],
  timezoneList: [],
  roles: [],
  termsConditions: {},
  notificationsSetting: {},
  memberAlert: {
    open: false,
    severity: 'success',
    message: '',
  },
  showCountdown: false,
  countdownTimer: null,
  autoLogout: false,
  memberSetting: {},
  ameraMembers: [],
};

const member = (state = initialState, action) => {
  if (!state.memberInfo) {
    state.memberInfo = {};
  }
  switch (action.type) {
    case GET_MEMBER_DATA_SUCCEEDED:
      return {
        ...state,
        member: action.payload || {},
        session_id: action.payload.session_id,
        autoLogout: false,
      };
    case GET_MEMBER_DATA_FAILED:
      return {
        ...state,
        member: {},
        session_id: '',
      };
    case SAVE_SIGN_UP_SESSION:
      return {
        ...state,
        signUpSession: action.payload,
      };
    case CLEAR_SIGN_UP_SESSION:
      return {
        ...state,
        signUpSession: '',
      };
    case MEMBER_LOGIN_SUCCEEDED:
      return {
        ...state,
        session_id: action.payload.session_id,
      };
    case MEMBER_LOGOUT_SUCCEEDED:
      return {
        ...state,
        member: {},
        memberInfo: {},
        session_id: '',
      };
    case FETCH_REQUEST_START:
      return {
        ...state,
        isFetching: true,
        error: null,
        isSuccess: false,
      };
    case FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        isSuccess: true,
      };
    case FETCH_REQUEST_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        isSuccess: false,
      };
    case PUT_MEMBER_INFO_START:
    case UPDATE_REQUEST_START:
      return {
        ...state,
        isUpdated: false,
        error: null,
        isLoading: true,
        isSuccess: false,
      };
    case UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        error: '',
        isLoading: false,
        isSuccess: false,
      };
    case UPDATE_REQUEST_FAILED:
      return {
        ...state,
        isUpdated: false,
        isLoading: false,
        error: action.payload,
      };
    case SEND_MEMBER_RESET_EMAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_MEMBER_RESET_EMAIL_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    case SEND_MEMBER_RESET_EMAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: action.payload,
      };
    case GET_MEMBER_LOCATION_INFO_START:
      return {
        ...state,
        locationInfo: action.payload,
        isFetching: true,
      };
    case GET_MEMBER_LOCATION_INFO_SUCCEEDED:
      return {
        ...state,
        locationInfo: action.payload,
        isFetching: false,
      };
    case GET_MEMBER_LOCATION_INFO_FAILED:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case MEMBERS_LOADING:
      return {
        ...state,
        loadingMembers: true,
      };
    case MEMBERS_LOAD_SUCCESS:
      return {
        ...state,
        loadingMembers: false,
        members: action.payload,
      };
    case MEMBER_LOGIN_FAILED:
      return {
        ...state,
        loginErr: action.payload.loginErr,
        loginAttempt: !state.loginAttempt,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        member: {},
        session_id: {},
      };
    case VALID_INVITE:
      return {
        ...state,
        invite: action.payload,
      };
    case MEMBER_SAVE_CREDENTIALS:
      return {
        ...state,
        credentials: action.payload,
      };

    case GET_DEPS_LIST_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_DEPS_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        departmentsList: [
          ...action.payload,
          {
            department_id: 'not_applicable',
            department_name: 'Not Applicable',
          },
        ],
      };

    case GET_DEPS_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_TITLE_LIST_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_TITLE_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        jobTitleList: [
          ...action.payload,
          { job_title_id: 'not_applicable', job_title: 'Not Applicable' },
        ],
      };
    case GET_TITLE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_TERMS_AND_CONDITIONS_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        termsConditions: action.payload,
      };
    case GET_TERMS_AND_CONDITIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    // Phone verification
    case CELL_VERIFICATION_INIT:
      return {
        ...state,
        isLoadingVerification: false,
      };
    case CELL_VERIFICATION_ATTEMPT:
      return {
        ...state,
        isLoadingVerification: true,
      };
    case CELL_VERIFICATION_RESPONSE:
      return {
        ...state,
        isLoadingVerification: false,
      };
    case CELL_VERIFICATION_ERROR:
      return {
        ...state,
        isLoadingVerification: false,
      };
    case SEND_REGISTER_START:
      return {
        ...state,
        isSubmittingRegisterForm: true,
      };
    case SEND_REGISTER_SUCCEEDED:
      return {
        ...state,
        isSubmittingRegisterForm: false,
        memberAlert: {
          open: true,
          severity: 'success',
          message:
            'Member registered successfully! Redirecting to Login page...',
        },
      };

    case SEND_REGISTER_FAILED:
      return {
        ...state,
        isSubmittingRegisterForm: false,
        memberAlert: {
          open: true,
          severity: 'error',
          message: action.payload,
        },
      };

    case GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: action.payload,
      };

    case GET_TZLIST_SUCCESS:
      return {
        ...state,
        timezoneList: action.payload,
      };

    case GET_MEMBER_INFO_SUCCESS:
      return {
        ...state,
        memberInfo: action.payload,
      };

    // After sumbitting the Profile Edit for we receive the new memberInfo and write
    case PUT_MEMBER_INFO_SUCCESS:
      return {
        ...state,
        memberInfo: action.payload,
      };

    case GET_MEMBER_SETTING_SUCCESS:
      return {
        ...state,
        memberSetting: action.payload,
      };

    case PUT_MEMBER_SETTING_SUCCESS:
      return {
        ...state,
        memberSetting: action.payload,
      };
    case PUT_AVATAR_UPDATE_SUCCESS:
      return {
        ...state,
        member: { ...state.member, amera_avatar_url: action.payload },
        memberInfo: { ...state.memberInfo, amera_avatar_url: action.payload },
      };
    case FETCH_ROLES_START:
    case FETCH_NOTIFICATIONS_SETTING_START:
    case PUT_NOTIFICATIONS_SETTING_START:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        isLoading: false,
      };
    case FETCH_ROLES_FAILED:
    case FETCH_NOTIFICATIONS_SETTING_FAILED:
    case PUT_NOTIFICATIONS_SETTING_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case FETCH_NOTIFICATIONS_SETTING_SUCCESS:
      return {
        ...state,
        notificationsSetting: action.payload,
        isLoading: false,
      };
    case PUT_NOTIFICATIONS_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case SET_MEMBER_ALERT:
      return {
        ...state,
        memberAlert: action.payload,
        isLoading: false,
      };

    case SHOW_COUNTDOWN:
      return {
        ...state,
        showCountdown: action.payload,
      };
    case SET_COUNTDOWN_TIMER:
      if (state.countdownTimer) {
        clearTimeout(state.countdownTimer);
      }
      return {
        ...state,
        countdownTimer: action.payload,
      };
    case AUTO_LOGOUT:
      return {
        ...state,
        autoLogout: true,
      };
    case CLEAR_AUTO_LOGOUT:
      return {
        ...state,
        autoLogout: false,
      };
    case GET_ALL_MEMBERS:
      return {
        ...state,
        ameraMembers: action.payload,
      };
    default:
      return state;
  }
};

export default member;
