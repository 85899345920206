/* global $ ,APP*/


/**
 *
 */
export default class SharedVideoThumb  {
    /**
     *
     * @param {*} participant
     * @param {*} videoType
     * @param {*} VideoLayout
     */
    constructor(participant) {
        this.id = participant.id;
        this.isLocal = false;
        this.url = participant.id;
        this.videoSpanId = 'sharedVideoContainer';
        this.container = this.createContainer(this.videoSpanId);
        this.$container = $(this.container);
    
    }

    /**
     *
     */
    initializeAvatar() {} // eslint-disable-line no-empty-function

    /**
     *
     * @param {*} spanId
     */
    createContainer(spanId) {
        const container = document.createElement('span');

        container.id = spanId;
        container.className = 'videocontainer';

        // add the avatar
        const avatar = document.createElement('img');

        avatar.className = 'sharedVideoAvatar';
        avatar.src = `https://img.youtube.com/vi/${this.url}/0.jpg`;
        container.appendChild(avatar);

        const displayNameContainer = document.createElement('div');
        displayNameContainer.id = `localDisplayName${this.id}`;
        displayNameContainer.className = 'displayNameContainer';
        displayNameContainer.innerHTML = 'YouTube';

        container.appendChild(displayNameContainer);

        const remoteVideosContainer = document.querySelector('#filmstripRemoteVideosContainer');
        remoteVideosContainer.prepend(container);
        APP.vacall.remoteVideos[this.id] = container;
          
    }

}
