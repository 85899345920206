import React, { useEffect, useState } from 'react';
import ContactModal from '../../components/Modal/ContactModal';
import { ApiEmailService } from '../../config/apiService';
import {
  getEmailQueryParams,
  getUnstyledHtmlBody,
  mailButtonGroup,
} from '../../utils/email';
import { EmailPageWrapper } from './SharedComponents/EmaliPageWrapper';
import { apiPaths } from '../../config/apiPaths';

const Inbox = (props) => {
  const [emails, setEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);

  const [forward, setForward] = useState({
    open: false,
    id: null,
    folder_id: null,
  });

  const [folders, setFolders] = useState([]);

  const [selectedFolder, setSelectedFolder] = useState(-1);

  const getFolders = async () => {
    const data = await ApiEmailService.getEmails('getFolders', []);
    if (data !== undefined) {
      setFolders([...data]);
    }
  };

  const getEmails = async (start_id) => {
    let params = getEmailQueryParams(
      start_id,
      selectSort,
      props.search,
      selectedFolder,
      props.contact_filter
    );

    let tmp_data = await ApiEmailService.getEmails('getInbox', params);
    let { data } = tmp_data !== undefined ? tmp_data : {};

    if (data !== undefined) {
      data.forEach((email) => {
        email.noStyleBody = getUnstyledHtmlBody(email.body);
      });

      setSelectedEmail(null);
      if (start_id) {
        setEmails([...emails, ...data]);
      } else {
        setEmails([...data]);
      }
    }
  };

  const getEmailDetails = async (id) => {
    let data = await ApiEmailService.getEmailDetails(
      'getInboxEmailDetails',
      id
    );
    let reply;
    if (data.reply && data.reply.id) {
      reply = await ApiEmailService.getEmailDetails(
        'getSendEmailDetails',
        data.reply.id
      );
      reply.id = data.reply.id;
      data.reply = reply;
    }
    setSelectedEmail({
      ...data,
      mail_id: id,
    });
  };

  const selectEmail = (id, xref_id, multi = false, is_detail_view = false) => {
    if (is_detail_view) {
      if (Array.isArray(id)) {
        getEmailDetails(id[id.length - 1]).then(() => {});
        const index = emails.findIndex((x) => x.mail_id === id[id.length - 1]);

        if (index !== -1 && emails[index].read === false) {
          const _emails = [...emails];
          _emails[index].read = true;
          setEmails(_emails);
        }
      } else {
        getEmailDetails(id).then(() => {});
        const index = emails.findIndex((x) => x.mail_id === id);

        if (index !== -1 && emails[index].read === false) {
          const _emails = [...emails];
          _emails[index].read = true;
          setEmails(_emails);
        }
      }
      return;
    }
    if (Array.isArray(id)) {
      let is_same =
        selectedEmails.length === id.length &&
        selectedEmails.every(function (element, index) {
          return element === id[index];
        });
      if (is_same) {
        setSelectedEmails([]);
      } else {
        setSelectedEmails(id);
        const index = emails.findIndex((x) => x.mail_id === id[id.length - 1]);

        if (index !== -1 && emails[index].read === false) {
          const _emails = [...emails];
          _emails[index].read = true;
          setEmails(_emails);
        }
        getEmailDetails(id[id.length - 1]).then(() => {});
      }
    } else {
      const index = emails.findIndex((x) => x.mail_id === id);

      if (index !== -1 && emails[index].read === false) {
        const _emails = [...emails];
        _emails[index].read = true;
        setEmails(_emails);
      }
      getEmailDetails(id).then(() => {});

      let findItem = selectedEmails.findIndex((el) => el === id);

      if (findItem === -1) {
        setSelectedEmails([...selectedEmails, id]);
      } else {
        let newSelected = [...selectedEmails];
        newSelected.splice(findItem, 1);
        setSelectedEmails([...newSelected]);
      }
    }
  };

  const topButtonsHandlers = {
    [mailButtonGroup.mailStarred.name]: async (event) => {
      if (selectedEmail) {
        let res;
        if (selectedEmails.length === 1) {
          res = await ApiEmailService.postEmail('postEmailStar', {
            mail_id: selectedEmail.mail_id,
            xref_id: selectedEmail.xref_id,
            rm: selectedEmail.is_stared,
          });
        } else {
          let xref_id = [];
          selectedEmails.forEach((el) => {
            emails.forEach((email) => {
              if (email.mail_id === el) {
                xref_id.push(email.xref_id);
              }
            });
          });
          res = await ApiEmailService.postEmail('postEmailStar', {
            mail_id: selectedEmails,
            xref_id: xref_id,
            rm: selectedEmail.is_stared,
          });
        }
        if (res.success) {
          await getEmails();
          setSelectedEmail({
            ...selectedEmail,
            is_stared: !selectedEmail.is_stared,
          });
          if (props.view === 'table') {
            setSelectedEmails([]);
            setSelectedEmail(null);
          }
        }
      }
    },
    [mailButtonGroup.mailTrash.name]: async (event) => {
      if (selectedEmail) {
        let res = null;
        if (selectedEmails.length === 1) {
          res = await ApiEmailService.postEmail('postEmailToTrash', {
            mail_id: selectedEmail.mail_id,
            xref_id: selectedEmail.xref_id,
          });
        } else {
          let xref_id = [];
          selectedEmails.forEach((el) => {
            emails.forEach((email) => {
              if (email.mail_id === el) {
                xref_id.push(email.xref_id);
              }
            });
          });
          res = await ApiEmailService.postEmail('postEmailToTrash', {
            mail_id: selectedEmails,
            xref_id: xref_id,
          });
        }
        if (res.success) getEmails();
        setSelectedEmails([]);
      }
    },
    [mailButtonGroup.mailArchive.name]: async (event) => {
      if (selectedEmail) {
        let res = null;
        if (selectedEmails.length === 1) {
          res = await ApiEmailService.postEmail('postEmailToArchive', {
            mail_id: selectedEmail.mail_id,
            xref_id: selectedEmail.xref_id,
          });
        } else {
          let xref_id = [];
          selectedEmails.forEach((el) => {
            emails.forEach((email) => {
              if (email.mail_id === el) {
                xref_id.push(email.xref_id);
              }
            });
          });
          res = await ApiEmailService.postEmail('postEmailToArchive', {
            mail_id: selectedEmails,
            xref_id: xref_id,
          });
        }
        if (res.success) getEmails();
        setSelectedEmails([]);
      }
    },
    [mailButtonGroup.mailFolder.name]: {
      folders: () => {
        return folders;
      },
      moveEmailToFolder: async (folder_id) => {
        let response = null;
        if (selectedEmails.length === 1) {
          response = await ApiEmailService.postEmail('moveEmailToFolder', {
            folder_id,
            mail_id: selectedEmail.mail_id,
            xref_id: selectedEmail.xref_id,
          });
        } else {
          let xref_id = [];
          selectedEmails.forEach((el) => {
            emails.forEach((email) => {
              if (email.mail_id === el) {
                xref_id.push(email.xref_id);
              }
            });
          });
          response = await ApiEmailService.postEmail('moveEmailToFolder', {
            folder_id,
            mail_id: selectedEmails,
            xref_id: xref_id,
          });
        }
        if (response.success) getEmails();
        setSelectedEmails([]);
      },
    },
  };

  const bottomButtonsHandlers = {
    [mailButtonGroup.mailForward.name]: async (event) => {
      if (selectedEmail) {
        setForward({
          open: true,
          id: selectedEmail.mail_id,
          folder_id: selectedEmail.folder_id,
        });
      }
    },
    [mailButtonGroup.mailReply.name]: async (email) => {
      if (email) {
        let tmp_receivers = email.receivers.amera;
        let tmp_member_details = email.member_details;
        if (tmp_receivers.indexOf(email.sender_member_id) === -1) {
          tmp_receivers.push(email.sender_member_id);
        }
        tmp_member_details[email.sender_member_id] = {
          amera_avatar_url: email.profile_url,
          email: email.sender_mail,
          first_name: email.first_name,
          last_name: email.last_name,
          member_id: email.sender_member_id,
        };
        props.setCompose({
          open: true,
          initialState: {
            folder_id: selectedEmail.folder_id,
            reply_id: selectedEmail.mail_id,
            subject: selectedEmail.subject,
            receivers: [
              ...tmp_receivers.map((el) => {
                return {
                  ...tmp_member_details[el],
                  contact_member_id: tmp_member_details[el].member_id,
                };
              }),
            ],
            cc: !email.cc
              ? []
              : [
                  ...email.cc.amera.map((el) => {
                    return {
                      ...email.member_details[el],
                      contact_member_id: email.member_details[el].member_id,
                    };
                  }),
                ],
          },
        });
      } else {
        props.setCompose({
          open: true,
          initialState: {
            folder_id: selectedEmail.folder_id,
            reply_id: selectedEmail.mail_id,
            subject: selectedEmail.subject,
            receivers: [
              {
                email: selectedEmail.sender_mail,
                contact_member_id: selectedEmail.sender_member_id,
                first_name: selectedEmail.first_name,
                last_name: selectedEmail.last_name,
              },
            ],
          },
        });
      }
    },
  };

  const [selectSort, setSelectSort] = React.useState(0);

  useEffect(() => {
    props.setMemberUrl(apiPaths.mail.getSelectableMembersInbox);
    // eslint-disable-next-line
  }, [props.setMemberUrl]);

  useEffect(() => {
    let cleanup = false;
    if (!cleanup) {
      getEmails();
      getFolders();
    }
    return () => (cleanup = true);
    // eslint-disable-next-line
  }, [selectSort, selectedFolder]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const mail_id = params.get('mail_id');
    if (mail_id) {
      selectEmail(mail_id);
    }
    // eslint-disable-next-line
  }, [props.location.search]);

  const handleChangeSort = (e) => {
    setSelectSort(e.target.value);
  };
  // "TODO: this block is not needed anymore, shall we keep this block or remove ?"
  // const [selectFilter, setSelectFilter] = React.useState(0);

  // const handleChangeFilter = (e) => {
  //   setSelectFilter(e.target.value);
  // };

  const lazyLoading = (value) => {};

  const handleEditFolder = async (folder_id, folder_name) => {
    const res = await ApiEmailService.postEmail('postFolder', {
      folder_id,
      folder_name,
    });
    if (res.success) getFolders();
  };

  const handleRemoveFolder = async (folder_id) => {
    const res = await ApiEmailService.deleteFolder('deleteFolder', folder_id);
    if (res.success) {
      let newFoldersData = [...folders];
      newFoldersData.splice(
        folders.findIndex((el) => el.id === res.id),
        1
      );
      setFolders([...newFoldersData]);
    }
  };

  return (
    <EmailPageWrapper
      section="Inbox"
      view={props.view}
      selectedEmail={selectedEmail}
      selectedEmails={selectedEmails}
      emails={emails}
      sortControls={{
        value: selectSort,
        changeValue: handleChangeSort,
      }}
      lazyLoading={lazyLoading}
      // "TODO: this block is not needed anymore, shall we keep this block or remove ?"
      // filterControls={{
      //   value: selectFilter,
      //   changeValue: handleChangeFilter,
      // }}
      topButtonsHandlers={topButtonsHandlers}
      bottomButtonsHandlers={bottomButtonsHandlers}
      selectEmail={selectEmail}
      showFolders={true}
      getFolders={getFolders}
      folders={folders}
      handleEditFolder={handleEditFolder}
      handleRemoveFolder={handleRemoveFolder}
      selectedFolder={selectedFolder}
      setSelectedFolder={setSelectedFolder}
    >
      {forward.open && (
        <ContactModal
          forward
          mail_id={forward.id}
          folder_id={forward.folder_id}
          open={true}
          onClose={() => {
            setForward({ open: false });
          }}
        />
      )}
    </EmailPageWrapper>
  );
};

export default Inbox;
