import { includes, isEqual, isArray } from 'lodash';
// import { addMinutes, differenceInMinutes, isBefore } from 'date-fns';

const countryList = [
  {
    name: 'Afghanistan',
    code: 'AF',
  },
  {
    name: 'Åland Islands',
    code: 'AX',
  },
  {
    name: 'Albania',
    code: 'AL',
  },
  {
    name: 'Algeria',
    code: 'DZ',
  },
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'AndorrA',
    code: 'AD',
  },
  {
    name: 'Angola',
    code: 'AO',
  },
  {
    name: 'Anguilla',
    code: 'AI',
  },
  {
    name: 'Antarctica',
    code: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
  },
  {
    name: 'Argentina',
    code: 'AR',
  },
  {
    name: 'Armenia',
    code: 'AM',
  },
  {
    name: 'Aruba',
    code: 'AW',
  },
  {
    name: 'Australia',
    code: 'AU',
  },
  {
    name: 'Austria',
    code: 'AT',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
  },
  {
    name: 'Bahamas',
    code: 'BS',
  },
  {
    name: 'Bahrain',
    code: 'BH',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
  },
  {
    name: 'Barbados',
    code: 'BB',
  },
  {
    name: 'Belarus',
    code: 'BY',
  },
  {
    name: 'Belgium',
    code: 'BE',
  },
  {
    name: 'Belize',
    code: 'BZ',
  },
  {
    name: 'Benin',
    code: 'BJ',
  },
  {
    name: 'Bermuda',
    code: 'BM',
  },
  {
    name: 'Bhutan',
    code: 'BT',
  },
  {
    name: 'Bolivia',
    code: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
  },
  {
    name: 'Botswana',
    code: 'BW',
  },
  {
    name: 'Bouvet Island',
    code: 'BV',
  },
  {
    name: 'Brazil',
    code: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
  },
  {
    name: 'Brunei Darussalam',
    code: 'BN',
  },
  {
    name: 'Bulgaria',
    code: 'BG',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
  },
  {
    name: 'Burundi',
    code: 'BI',
  },
  {
    name: 'Cambodia',
    code: 'KH',
  },
  {
    name: 'Cameroon',
    code: 'CM',
  },
  {
    name: 'Canada',
    code: 'CA',
  },
  {
    name: 'Cape Verde',
    code: 'CV',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
  },
  {
    name: 'Chad',
    code: 'TD',
  },
  {
    name: 'Chile',
    code: 'CL',
  },
  {
    name: 'China',
    code: 'CN',
  },
  {
    name: 'Christmas Island',
    code: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
  },
  {
    name: 'Colombia',
    code: 'CO',
  },
  {
    name: 'Comoros',
    code: 'KM',
  },
  {
    name: 'Congo',
    code: 'CG',
  },
  {
    name: 'Congo, The Democratic Republic of the',
    code: 'CD',
  },
  {
    name: 'Cook Islands',
    code: 'CK',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
  },
  {
    name: 'Cote D"Ivoire',
    code: 'CI',
  },
  {
    name: 'Croatia',
    code: 'HR',
  },
  {
    name: 'Cuba',
    code: 'CU',
  },
  {
    name: 'Cyprus',
    code: 'CY',
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
  },
  {
    name: 'Denmark',
    code: 'DK',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
  },
  {
    name: 'Dominica',
    code: 'DM',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
  },
  {
    name: 'Ecuador',
    code: 'EC',
  },
  {
    name: 'Egypt',
    code: 'EG',
  },
  {
    name: 'El Salvador',
    code: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
  },
  {
    name: 'Eritrea',
    code: 'ER',
  },
  {
    name: 'Estonia',
    code: 'EE',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
  },
  {
    name: 'Fiji',
    code: 'FJ',
  },
  {
    name: 'Finland',
    code: 'FI',
  },
  {
    name: 'France',
    code: 'FR',
  },
  {
    name: 'French Guiana',
    code: 'GF',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
  },
  {
    name: 'French Southern Territories',
    code: 'TF',
  },
  {
    name: 'Gabon',
    code: 'GA',
  },
  {
    name: 'Gambia',
    code: 'GM',
  },
  {
    name: 'Georgia',
    code: 'GE',
  },
  {
    name: 'Germany',
    code: 'DE',
  },
  {
    name: 'Ghana',
    code: 'GH',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
  },
  {
    name: 'Greece',
    code: 'GR',
  },
  {
    name: 'Greenland',
    code: 'GL',
  },
  {
    name: 'Grenada',
    code: 'GD',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Guatemala',
    code: 'GT',
  },
  {
    name: 'Guernsey',
    code: 'GG',
  },
  {
    name: 'Guinea',
    code: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
  },
  {
    name: 'Guyana',
    code: 'GY',
  },
  {
    name: 'Haiti',
    code: 'HT',
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    code: 'HM',
  },
  {
    name: 'Holy See (Vatican City State)',
    code: 'VA',
  },
  {
    name: 'Honduras',
    code: 'HN',
  },
  {
    name: 'Hong Kong',
    code: 'HK',
  },
  {
    name: 'Hungary',
    code: 'HU',
  },
  {
    name: 'Iceland',
    code: 'IS',
  },
  {
    name: 'India',
    code: 'IN',
  },
  {
    name: 'Indonesia',
    code: 'ID',
  },
  {
    name: 'Iran, Islamic Republic Of',
    code: 'IR',
  },
  {
    name: 'Iraq',
    code: 'IQ',
  },
  {
    name: 'Ireland',
    code: 'IE',
  },
  {
    name: 'Isle of Man',
    code: 'IM',
  },
  {
    name: 'Israel',
    code: 'IL',
  },
  {
    name: 'Italy',
    code: 'IT',
  },
  {
    name: 'Jamaica',
    code: 'JM',
  },
  {
    name: 'Japan',
    code: 'JP',
  },
  {
    name: 'Jersey',
    code: 'JE',
  },
  {
    name: 'Jordan',
    code: 'JO',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
  },
  {
    name: 'Kenya',
    code: 'KE',
  },
  {
    name: 'Kiribati',
    code: 'KI',
  },
  {
    name: "Korea, Democratic People's Republic of",
    code: 'KP',
  },
  {
    name: 'Korea, Republic of',
    code: 'KR',
  },
  {
    name: 'Kuwait',
    code: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
  },
  {
    name: 'Lao People"S Democratic Republic',
    code: 'LA',
  },
  {
    name: 'Latvia',
    code: 'LV',
  },
  {
    name: 'Lebanon',
    code: 'LB',
  },
  {
    name: 'Lesotho',
    code: 'LS',
  },
  {
    name: 'Liberia',
    code: 'LR',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    code: 'LY',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
  },
  {
    name: 'Lithuania',
    code: 'LT',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
  },
  {
    name: 'Macao',
    code: 'MO',
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
  },
  {
    name: 'Madagascar',
    code: 'MG',
  },
  {
    name: 'Malawi',
    code: 'MW',
  },
  {
    name: 'Malaysia',
    code: 'MY',
  },
  {
    name: 'Maldives',
    code: 'MV',
  },
  {
    name: 'Mali',
    code: 'ML',
  },
  {
    name: 'Malta',
    code: 'MT',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    name: 'Martinique',
    code: 'MQ',
  },
  {
    name: 'Mauritania',
    code: 'MR',
  },
  {
    name: 'Mauritius',
    code: 'MU',
  },
  {
    name: 'Mayotte',
    code: 'YT',
  },
  {
    name: 'Mexico',
    code: 'MX',
  },
  {
    name: 'Micronesia, Federated States of',
    code: 'FM',
  },
  {
    name: 'Moldova, Republic of',
    code: 'MD',
  },
  {
    name: 'Monaco',
    code: 'MC',
  },
  {
    name: 'Mongolia',
    code: 'MN',
  },
  {
    name: 'Montserrat',
    code: 'MS',
  },
  {
    name: 'Morocco',
    code: 'MA',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
  },
  {
    name: 'Myanmar',
    code: 'MM',
  },
  {
    name: 'Namibia',
    code: 'NA',
  },
  {
    name: 'Nauru',
    code: 'NR',
  },
  {
    name: 'Nepal',
    code: 'NP',
  },
  {
    name: 'Netherlands',
    code: 'NL',
  },
  {
    name: 'Netherlands Antilles',
    code: 'AN',
  },
  {
    name: 'New Caledonia',
    code: 'NC',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
  },
  {
    name: 'Niger',
    code: 'NE',
  },
  {
    name: 'Nigeria',
    code: 'NG',
  },
  {
    name: 'Niue',
    code: 'NU',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Norway',
    code: 'NO',
  },
  {
    name: 'Oman',
    code: 'OM',
  },
  {
    name: 'Pakistan',
    code: 'PK',
  },
  {
    name: 'Palau',
    code: 'PW',
  },
  {
    name: 'Palestinian Territory, Occupied',
    code: 'PS',
  },
  {
    name: 'Panama',
    code: 'PA',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
  },
  {
    name: 'Paraguay',
    code: 'PY',
  },
  {
    name: 'Peru',
    code: 'PE',
  },
  {
    name: 'Philippines',
    code: 'PH',
  },
  {
    name: 'Pitcairn',
    code: 'PN',
  },
  {
    name: 'Poland',
    code: 'PL',
  },
  {
    name: 'Portugal',
    code: 'PT',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Qatar',
    code: 'QA',
  },
  {
    name: 'Reunion',
    code: 'RE',
  },
  {
    name: 'Romania',
    code: 'RO',
  },
  {
    name: 'Russian Federation',
    code: 'RU',
  },
  {
    name: 'RWANDA',
    code: 'RW',
  },
  {
    name: 'Saint Helena',
    code: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
  },
  {
    name: 'Samoa',
    code: 'WS',
  },
  {
    name: 'San Marino',
    code: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    code: 'ST',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
  },
  {
    name: 'Senegal',
    code: 'SN',
  },
  {
    name: 'Serbia and Montenegro',
    code: 'CS',
  },
  {
    name: 'Seychelles',
    code: 'SC',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
  },
  {
    name: 'Singapore',
    code: 'SG',
  },
  {
    name: 'Slovakia',
    code: 'SK',
  },
  {
    name: 'Slovenia',
    code: 'SI',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
  },
  {
    name: 'Somalia',
    code: 'SO',
  },
  {
    name: 'South Africa',
    code: 'ZA',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
  },
  {
    name: 'Spain',
    code: 'ES',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
  },
  {
    name: 'Sudan',
    code: 'SD',
  },
  {
    name: 'Suriname',
    code: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
  },
  {
    name: 'Swaziland',
    code: 'SZ',
  },
  {
    name: 'Sweden',
    code: 'SE',
  },
  {
    name: 'Switzerland',
    code: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    code: 'SY',
  },
  {
    name: 'Taiwan, Province of China',
    code: 'TW',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of',
    code: 'TZ',
  },
  {
    name: 'Thailand',
    code: 'TH',
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
  },
  {
    name: 'Togo',
    code: 'TG',
  },
  {
    name: 'Tokelau',
    code: 'TK',
  },
  {
    name: 'Tonga',
    code: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
  },
  {
    name: 'Tunisia',
    code: 'TN',
  },
  {
    name: 'Turkey',
    code: 'TR',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
  },
  {
    name: 'Uganda',
    code: 'UG',
  },
  {
    name: 'Ukraine',
    code: 'UA',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
  },
  {
    name: 'United Kingdom',
    code: 'GB',
  },
  {
    name: 'United States',
    code: 'US',
  },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
  },
  {
    name: 'Uruguay',
    code: 'UY',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
  },
  {
    name: 'Venezuela',
    code: 'VE',
  },
  {
    name: 'Viet Nam',
    code: 'VN',
  },
  {
    name: 'Virgin Islands, British',
    code: 'VG',
  },
  {
    name: 'Virgin Islands, U.S.',
    code: 'VI',
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
  },
  {
    name: 'Western Sahara',
    code: 'EH',
  },
  {
    name: 'Yemen',
    code: 'YE',
  },
  {
    name: 'Zambia',
    code: 'ZM',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
  },
];

const allowedCountries = [
  'CA', // Canada
  'CN', // China
  'DE', // Germany
  'FR', // France
  'JP', // Japan
  'KR', // South Korea
  'RU', // Russia
  'TW', // Taiwan
  'US', // United States
  'UZ', // Uzbekistan
];

export const filterCountriesByCountryCode = (countries, allowedCountries) => {
  return countries.filter((country) => {
    return allowedCountries.indexOf(country.code) !== -1;
  });
};

export const Countries = filterCountriesByCountryCode(
  countryList,
  allowedCountries
);

export const geoApi = 'https://chromium-i18n.appspot.com/ssl-address/data';

export function extractAddress(address) {
  let city = '';
  let state = '';
  let street = '';
  let postal = '';
  let province = '';
  let country = '';
  let cityShort = '';
  let stateShort = '';
  let provinceShort = '';
  let countryShort = '';

  if (address.results.length > 0) {
    const arrComponents = address.results[0].address_components;
    const countryObj = arrComponents.find(
      (comp) => comp.types[0] === 'country'
    );
    country = countryObj.long_name.trim();
    countryShort = countryObj.short_name.trim();
    arrComponents.forEach((component) => {
      const types = component.types;
      if (
        includes(types, 'sublocality_level_1') ||
        includes(types, 'locality')
      ) {
        city = component.long_name.trim();
        cityShort = component.short_name.trim();
      }

      if (includes(types, 'sublocality_level_2') || includes(types, 'route')) {
        street = component.long_name.trim();
      }

      if (includes(types, 'administrative_area_level_1')) {
        if (includes(['US', 'MX', 'CA'], countryShort)) {
          state = component.long_name.trim();
          stateShort = component.short_name.trim();
          province = null;
          provinceShort = null;
        } else {
          state = null;
          stateShort = null;
          province = component.long_name.trim();
          provinceShort = component.short_name.trim();
        }
      }

      if (includes(types, 'postal_code')) {
        postal = component.long_name;
      }
    });

    // arrComponents.forEach((component) => {
    //   const type = component.types[0];
    //   if (
    //     city === '' &&
    //     (type === 'sublocality_level_1' ||
    //       type === 'locality' ||
    //       type === 'administrative_area_level_2')
    //   ) {
    //     city = component.long_name.trim();
    //     cityShort = component.short_name.trim();
    //   }

    //   if (
    //     street === '' &&
    //     (type === "sublocality_level_2"|| type === 'route')
    //   ) {
    //     street = component.long_name.trim();
    //   }

    //   if (
    //     province === '' &&
    //     state === '' &&
    //     type === 'administrative_area_level_1'
    //   ) {
    //     if (includes(['US', 'MX', 'CA'], countryShort)) {
    //       state = component.long_name.trim();
    //       stateShort = component.short_name.trim();
    //       province = null;
    //       provinceShort = null;
    //     } else {
    //       state = null;
    //       stateShort = null;
    //       province = component.long_name.trim();
    //       provinceShort = component.short_name.trim();
    //     }
    //   }

    //   if (type === 'postal_code') {
    //     postal = component.long_name;
    //   }

    //   if (city !== '' && state !== '' && country !== '' && postal !== '') {
    //     //we're done
    //     return;
    //   }
    // });

    return {
      city: {
        long: city,
        short: cityShort,
      },
      state: {
        long: state,
        short: stateShort,
      },
      province: {
        long: province,
        short: provinceShort,
      },
      country: {
        long: country,
        short: countryShort,
      },
      postal: postal,
      street: street,
      formatted_address: address.results[0].formatted_address,
      location: address.results[0].geometry.location,
    };
  }
}

export function getAddress(zip, key, callback) {
  if (!zip) {
    throw Error('Zip is required');
  }
  if (!key) {
    throw Error('Key is required');
  }
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&sensor=true&language=en&key=${key}`
  )
    .then(function (response) {
      if (response.status !== 200) {
        console.log(
          'Looks like there was a problem. Status Code: ' + response.status
        );
        return;
      }

      // Examine the text in the response
      response.json().then(function (data) {
        console.log('Gmaps', data);
        callback(null, extractAddress(data));
      });
    })
    .catch(function (err) {
      callback(err);
    });
}

export const locationObjectToString = (locationObject) => {
  const {
    address_1,
    address_2,
    city,
    country,
    postal,
    state,
    province,
    street,
  } = locationObject;
  return [
    address_2,
    address_1,
    street,
    city,
    province,
    state,
    postal,
    country,
  ].join(', ');
};

export const locationStringToGoogleQuery = (string) => {
  // https://developers.google.com/maps/documentation/urls/url-encoding#special-characters
  const prohibitedCharactes = [
    '!',
    '%',
    '(',
    ')',
    ';',
    ':',
    '@',
    '&',
    '=',
    '$',
    '/',
    '?',
    '#',
    '[',
    ']',
  ];

  const res = string
    .replaceAll(',', '+')
    .replaceAll(' ', '+')
    .replaceAll(/./gm, (m) => (includes(prohibitedCharactes, m) ? '' : m));

  // console.debug('in', string, 'out', res);
  return res;
};

export function getBoundsAtLatLngWithZoom(map, center, zoom) {
  var p = map.getProjection();
  if (!p) {
    return null;
  }
  var el = map;
  var zf = Math.pow(2, zoom) * 2;
  var dw = (el.clientWidth | 0) / zf;
  var dh = (el.clientHeight | 0) / zf;
  var cpx = p.fromLatLngToPoint(center);
  return new window.google.maps.LatLngBounds(
    p.fromPointToLatLng(new window.google.maps.Point(cpx.x - dw, cpx.y + dh)),
    p.fromPointToLatLng(new window.google.maps.Point(cpx.x + dw, cpx.y - dh))
  );
}

// Filters places to have
// business_status: "OPERATIONAL"
// open for the event duration

// FIXME: Unfortunately, won't work for most places since there's not utc-offset for most of them
// https://developers.google.com/maps/documentation/javascript/reference/3.42/places-service#PlaceOpeningHours.isOpen

// Will check if the location is open for every X mins from event start time to  event end time
// start and end are dates
// const MINUTES_RATE_TO_CHECK = 30;
// const isOpenDuringEvent = (place, start, end) => {
//   if (!!place) {
//     let datesToCheck = [start, end];
//     if (differenceInMinutes(end, start) >= MINUTES_RATE_TO_CHECK) {
//       let nextDate = addMinutes(start, MINUTES_RATE_TO_CHECK);
//       while (isBefore(nextDate, end)) {
//         datesToCheck.push(nextDate);
//         nextDate = addMinutes(nextDate, MINUTES_RATE_TO_CHECK);
//       }
//     }

//     const isOpen = datesToCheck.map((date) => place.opening_hours.isOpen(date));
//     // .some((isOpen) => isOpen === false);
//     console.log(
//       place.vicinity,
//       'Dates to check',
//       datesToCheck,
//       'isOpen',
//       isOpen
//     );
//   }
// };

export const filterOperationalOpenPlaces = (places, start, end) => {
  return places.filter(
    (place) => place.business_status === 'OPERATIONAL'
    // isOpenDuringEvent(place, start, end)
  );
};

export const isSamePlaces = (prev, curr) => {
  if (isArray(prev) && isArray(curr) && prev.length === curr.length) {
    return isEqual(
      prev.map((p) => p.place_id),
      curr.map((p) => p.place_id)
    );
  } else {
    return false;
  }
};

export const isPlaceInList = (places, place) =>
  !!place &&
  includes(
    places.map((p) => p.place_id),
    place.place_id
  );

export const placeToString = (place) =>
  `${place.name}, ${
    !!place.formatted_address ? place.formatted_address : place.vicinity
  }`;
