import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// import InputAdornment from '@material-ui/core/InputAdornment';
import NotApplicableCheck from './NotApplicableCheck';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& .MuiAutocomplete-noOptions': {
      display: 'none',
    },
  },
  adornment: {
    position: 'absolute',
    top: '7px',
    right: '22px',
  },
}));

const StyledAutocomplete = withStyles({
  noOptions: {
    display: 'none',
  },
})(Autocomplete);

const CompanyNameInput = ({
  companyId,
  errors,
  // onChange,
  handleChangeCompanyId,
  handleChangeCompany,
  onToggle,
  isApplicable,
}) => {
  const classes = useStyles();

  let companyList = useSelector((state) => state.company.companyList);
  companyList = companyList ? companyList : [];

  const options = useMemo(() => {
    return companyList.map((company) => ({
      id: company.id,
      name: company.name,
    }));
  }, [companyList]);
  return (
    <div className={classes.root}>
      <StyledAutocomplete
        id="company-name"
        debug
        disabled={!!onToggle && !isApplicable}
        getOptionSelected={(o, v) => {
          return o.value === v.value;
        }}
        // style={style}
        disableClearable
        options={options}
        name="company_name"
        getOptionLabel={(o) => o.name}
        // getOptionDisabled={(o) => o.value === 'custom'}
        renderOption={(o) => <Typography variant="body2">{o.name}</Typography>}
        onChange={(event, value) => {
          event.persist();
          handleChangeCompanyId(value.id);
        }}
        value={companyId}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Company"
            required
            placeholder="Company"
          />
        )}
        onInputChange={(event, value) => {
          handleChangeCompany(value);
        }}
        error={errors ? true : false}
        helperText={errors ? errors : ''}
      />
      <div className={classes.adornment}>
        {!!onToggle && (
          <NotApplicableCheck isChecked={!isApplicable} onChange={onToggle} />
        )}
      </div>
    </div>
  );
};

export default CompanyNameInput;
