import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Paper,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  // Checkbox,
} from '@material-ui/core';
// import PropTypes from 'prop-types';
import { format } from 'date-fns';

import DenseTabs from '../../components/DenseTabs/DenseTabs';
import DenseTab from '../../components/DenseTabs/DenseTab';
import UnregisteredCompanyTable from './UnregisteredCompanyTable';
// import EnhancedTableToolbar from './EnhancedTableToolbar';
import AmeraPagination from '../../components/AmeraPagination';
import CompanyDetailModal from '../../components/Company/CompanyDetailModal';
// import { loadContactMembers } from '../../redux/actions/contact';
import {
  loadCompanies,
  // deleteCompanies
} from '../../redux/actions/company';

import { getAllMembers } from '../../redux/actions/member';

const headCells = [
  {
    id: 'company_name',
    label: 'Company Name',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'address',
    label: 'Address',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'phone',
    label: 'Phone',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'create_date',
    label: 'Created Date',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = (props) => {
  // const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  createCompany: {
    borderRadius: '25px',
    margin: theme.spacing(2),
  },
  tabWrapper: {
    margin: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  actions: {
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(2),
    },
  },
  skinnyTable: {
    width: 450,
  },
}));

const GlobalCompanyAdmin = (props) => {
  const { isAdmin } = props;
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [tab, setTab] = useState('registered');
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.company.companyList);

  useEffect(() => {
    dispatch(loadCompanies());
    dispatch(getAllMembers());
  }, [dispatch]);

  const handleSelect = (event, id) => {
    const selectedIndex = selectedCompanies.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCompanies, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCompanies.slice(1));
    } else if (selectedIndex === selectedCompanies.length - 1) {
      newSelected = newSelected.concat(selectedCompanies.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCompanies.slice(0, selectedIndex),
        selectedCompanies.slice(selectedIndex + 1)
      );
    }

    setSelectedCompanies(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showCompanyDetail = (companyId) => {
    setShowCompanyModal(true);
    setIsEdit(false);
    setCompanyId(companyId);
  };

  const createCompany = () => {
    setShowCompanyModal(true);
    setCompanyId(null);
    setIsEdit(true);
  };

  const isSelected = (id) => selectedCompanies.indexOf(id) !== -1;

  return (
    <Paper className={classes.paper}>
      <Button
        color="primary"
        variant="contained"
        className={classes.createCompany}
        onClick={createCompany}
      >
        Create Company
      </Button>
      {/* <EnhancedTableToolbar
        title="Company"
        numSelected={selectedCompanies.length}
        deleteCompany={() => {
          const formData = new FormData();
          formData.set('companyIds', selectedCompanies)
          dispatch(deleteCompanies(formData));
        }}
      /> */}
      <div className={classes.tabWrapper}>
        <DenseTabs
          value={tab}
          onChange={(event, value) => {
            setTab(value);
          }}
          indicatorColor="primary"
        >
          <DenseTab value="registered" label="Registered" />
          <DenseTab value="unregistered" label="Unregistered" />
        </DenseTabs>
      </div>
      {tab === 'registered' ? (
        <>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="activityTable"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead />
              <TableBody>
                {/* {stableSort(groups, getComparator(order, orderBy)) */}
                {companies &&
                  companies
                    .slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
                    .map((company, index) => {
                      const isItemSelected = isSelected(company.id);
                      // const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleSelect(event, company.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={company.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell> */}
                          <TableCell align="left">{company.name}</TableCell>
                          <TableCell align="left">
                            {company.address_1}
                          </TableCell>
                          <TableCell align="left">
                            {company.main_phone}
                          </TableCell>
                          <TableCell align="left">
                            {company.create_date &&
                              format(new Date(company.create_date), 'P')}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(event) => {
                                event.stopPropagation();
                                showCompanyDetail(company.id);
                              }}
                            >
                              view company
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          {companies && (
            <AmeraPagination
              itemTypeLabel="Items"
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              totalCount={companies.length}
              page={page}
              handleChangePage={handleChangePage}
            />
          )}
        </>
      ) : (
        <UnregisteredCompanyTable classes={classes} />
      )}
      <CompanyDetailModal
        open={showCompanyModal}
        isEdit={isEdit}
        isAdmin={isAdmin}
        onClose={() => {
          setShowCompanyModal(false);
        }}
        companyId={companyId}
        switchMode={() => {
          setIsEdit(true);
        }}
      />
    </Paper>
  );
};

export default GlobalCompanyAdmin;
