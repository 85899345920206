import React from 'react';
import { isSameDay } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateInput from './DateInput';
import DropDownTImeInput from './DropDownTImeInput';
import DurationSelect from './DurationSelect';
import FormHelperText from '@material-ui/core/FormHelperText';

const DateTimeLine = (props) => {
  const {
    start,
    end,
    isFullDay,
    onDateChange,
    onDurationChange,
    error,
  } = props;

  const bothDatesFull = () => (
    <>
      <Grid item xs={6}>
        <DateInput
          value={start}
          label="Start Date"
          onChange={(v) => onDateChange(v, 'start')}
        />
      </Grid>
      <Grid item xs={6}>
        <DateInput
          value={end}
          label="End Date"
          onChange={(v) => onDateChange(v, 'end')}
        />
      </Grid>
    </>
  );

  const bothDatesTime = () => (
    <>
      <Grid item container xs={5} alignItems="center">
        <Grid item xs={7}>
          <DateInput
            value={start}
            label="From Date"
            onChange={(v) => onDateChange(v, 'start')}
          />
        </Grid>
        <Grid item xs={5}>
          <DropDownTImeInput
            date={start}
            id="start"
            label="From"
            onChange={(v) => onDateChange(v, 'start')}
          />
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <DurationSelect
          start={start}
          end={end}
          handleChangeDuration={onDurationChange}
        />
      </Grid>

      <Grid item container xs={5} alignItems="center">
        <Grid item xs={7}>
          <DateInput
            value={end}
            label="To Date"
            onChange={(v) => onDateChange(v, 'end')}
          />
        </Grid>
        <Grid item xs={5}>
          <DropDownTImeInput
            date={end}
            id="end"
            label="To"
            onChange={(v) => onDateChange(v, 'end')}
          />
        </Grid>
      </Grid>
    </>
  );

  const sameDayFull = () => (
    <>
      <Grid item xs={12}>
        <DateInput
          value={start}
          label="Event Date"
          onChange={(v) => onDateChange(v, 'start')}
        />
      </Grid>
    </>
  );

  const sameDayTime = () => (
    <>
      <Grid item xs={6}>
        <DateInput
          value={start}
          label="Event Date"
          onChange={(v) => onDateChange(v, 'start')}
        />
      </Grid>
      <Grid item xs={2}>
        <DropDownTImeInput
          date={start}
          label="From"
          onChange={(v) => onDateChange(v, 'start')}
        />
      </Grid>
      <Grid item xs={2}>
        <DurationSelect
          start={start}
          end={end}
          handleChangeDuration={onDurationChange}
        />
      </Grid>
      <Grid item xs={2}>
        <DropDownTImeInput
          date={end}
          label="To"
          onChange={(v) => onDateChange(v, 'end')}
        />
      </Grid>
    </>
  );

  const isOneDaySchedule = isSameDay(start, end);

  let content;

  if (isOneDaySchedule) {
    if (isFullDay) {
      content = sameDayFull();
    } else {
      content = sameDayTime();
    }
  } else {
    if (isFullDay) {
      content = bothDatesFull();
    } else {
      content = bothDatesTime();
    }
  }

  return (
    <Grid item container xs={12} spacing={1} alignItems="center">
      {content}
      {!!error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </Grid>
  );
};

export default DateTimeLine;
