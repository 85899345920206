import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AmeraPagination from '../../components/AmeraPagination';
import {
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TextField,
  Button,
  // Checkbox,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ConfirmDialog from '../../components/ConfirmDialog';
import Modal from '../../components/Modal/modal';
import {
  getUnregisteredCompanies,
  deleteUnregisteredCompany,
  updateUnregisteredCompany,
  createCompanyFromName,
} from '../../redux/actions/company';

const UnregisteredCompanyTable = (props) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmTextType, setConfirmTextType] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [newCompanyName, setNewCompanyName] = useState('');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { classes } = props;

  const dispatch = useDispatch();
  let companies = useSelector((state) => state.company.unregisteredCompanies);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    dispatch(getUnregisteredCompanies());
  }, [dispatch]);

  const ModalContent = () => (
    <div>
      <TextField
        variant="standard"
        value={newCompanyName}
        fullWidth
        onChange={(event) => {
          setNewCompanyName(event.target.value);
        }}
      />
    </div>
  );

  const ModalAction = () => (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={async () => {
          await dispatch(
            updateUnregisteredCompany(companyName, newCompanyName)
          );
          setShowUpdateModal(false);
        }}
      >
        Update
      </Button>

      <Button
        variant="secondary"
        color="primary"
        onClick={() => {
          setShowUpdateModal(false);
        }}
      >
        Cancel
      </Button>
    </>
  );

  return (
    <>
      <TableContainer className={classes.skinnyTable}>
        <Table
          stickyHeader
          aria-labelledby="activityTable"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center" padding="default">
                Company Name
              </TableCell>
              <TableCell align="center" padding="default">
                Total Members
              </TableCell>
              <TableCell align="center" padding="default">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies &&
              companies
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((company) => {
                  return (
                    <TableRow hover key={company.company_name}>
                      <TableCell align="left">{company.company_name}</TableCell>
                      <TableCell align="left">
                        {company.total_members}
                      </TableCell>
                      <TableCell align="center" className={classes.actions}>
                        <Tooltip title="Create a new company">
                          <AddCircleIcon
                            onClick={() => {
                              setShowConfirmDialog(true);
                              setConfirmTextType(0);
                              setCompanyName(company.company_name);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Edit">
                          <EditIcon
                            onClick={() => {
                              setCompanyName(company.company_name);
                              setNewCompanyName(company.company_name);
                              setShowUpdateModal(true);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Clear">
                          <DeleteIcon
                            onClick={() => {
                              setShowConfirmDialog(true);
                              setConfirmTextType(1);
                              setCompanyName(company.company_name);
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      {companies && (
        <AmeraPagination
          itemTypeLabel="Items"
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          totalCount={companies.length}
          page={page}
          handleChangePage={handleChangePage}
        />
      )}

      <ConfirmDialog
        open={showConfirmDialog}
        content={
          confirmTextType === 0
            ? 'Are you sure you want to create a new company?'
            : 'Are you sure you want to delete this'
        }
        onCancel={() => {
          setShowConfirmDialog(false);
        }}
        onOk={async () => {
          if (confirmTextType === 0) {
            await dispatch(createCompanyFromName(companyName));
            setShowConfirmDialog(false);
          } else {
            await dispatch(deleteUnregisteredCompany(companyName));
            setShowConfirmDialog(false);
          }
        }}
      />
      <Modal
        open={showUpdateModal}
        title="Update company name"
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      />
    </>
  );
};

export default UnregisteredCompanyTable;
