import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import PhoneField from './PhoneInput';
import PhoneTypeSelector from './PhoneTypeSelector';
import MethodTypeSelector from './MethodTypeSelector';
import ListDeleteButton from '../../ListDeleteButton';

const PhoneListItem = (props) => {
  const {
    errors,
    phoneData,
    userCountry,
    handleChange,
    handlePhoneInputChanges,
    handleDelete,
    // phoneCount,
    isPrimary,
  } = props;
  const {
    id,
    description,
    phoneType,
    phoneNumber,
    phoneCountry,
    isPhoneVerified,
    method,
  } = phoneData;

  const handlePhoneInput = (value, phoneCountry) => {
    handlePhoneInputChanges({
      id: id,
      phoneNumber: value,
      phoneCountry: phoneCountry,
    });
  };

  const handleDescriptionInput = (event) => {
    handleChange({ id: id, name: 'description', value: event.target.value });
  };

  const handlePhoneTypeSelect = (event) => {
    handleChange({ id: id, name: 'phoneType', value: event.target.value });
  };

  const handleMethodSelect = (event) => {
    handleChange({ id: id, name: 'method', value: event.target.value });
  };

  const handleVerificationSuccess = () => {
    handleChange({ id: id, name: 'isPhoneVerified', value: true });
  };

  let phoneError,
    descriptionError = false;
  if (errors) {
    phoneError = errors.phoneInput ? errors.phoneInput : null;
    descriptionError = errors.description ? errors.description : null;
  }

  return (
    <ListItem id={id} key={id} className="item">
      <div className="item-text">
        {/* Description */}
        <TextField
          // id="phone_description"
          // id={id}
          error={Boolean(descriptionError)}
          name="description"
          disabled={isPrimary}
          className="description"
          label="Description"
          value={description}
          onChange={handleDescriptionInput}
          helperText={descriptionError}
        />
        {/* Phone type */}
        <PhoneTypeSelector
          id={id}
          disabled={isPrimary}
          phoneType={phoneType}
          handleChange={handlePhoneTypeSelect}
        />
        {/* Phone input*/}
        <span className="phone-with-btns">
          <PhoneField
            error={phoneError}
            disabled={isPrimary}
            name="phoneNumber"
            phoneNumber={phoneNumber}
            userCountry={userCountry}
            phoneCountry={phoneCountry}
            phoneType={phoneType}
            handleVerificationSuccess={handleVerificationSuccess}
            isPhoneVerified={isPhoneVerified}
            handleChange={handlePhoneInput}
          />
        </span>

        {/* Method type */}
        <MethodTypeSelector
          id={id}
          disabled={isPrimary}
          deviceClass={phoneType}
          method={method}
          handleChange={handleMethodSelect}
        />
      </div>
      <ListItemSecondaryAction>
        <ListDeleteButton
          id={id}
          handleDelete={handleDelete}
          isDisabled={isPrimary}
          listContext="phone"
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default PhoneListItem;
