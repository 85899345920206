import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
// import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';

// Modals and Dialogs
import EventCreateModal from '../../components/Modal/EventModals/EventCreateModal';
import EventInfoModal from '../../components/Modal/EventModals/EventInfoModal/EventInfoModal';
import EventCancelModal from '../../components/Modal/EventModals/EventCancelModal/EventCancelModal';
import EventEditModal from '../../components/Modal/EventModals/EventEditModal';
import EventFilterDialog from '../../components/Modal/EventModals/EventFilterDialog/EventFilterDialog';
import ConfirmDialog from '../../components/ConfirmDialog';

import FunctionalToolbar from '../../components/FunctionalToolbar';
// import CustomEvent from './CustomEvent';
import EventTitle from './EventTitle';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import {
  getScheduleEvent,
  putModifyEvent,
} from '../../redux/actions/schedule_event';

import { loadContacts } from '../../redux/actions/contact';
import { getGroupList } from '../../redux/actions/group';
import {
  CALENDAR_VIEWS,
  EVENT_FILTER_TYPE,
  parseFromStr,
  getMonthLimits,
  formatInTimeZone,
  eventPropGetter,
  getParams,
  filterEvents,
  filterDescription,
  isEditableEvent,
} from '../../utils/calendar';

import usePrevious from '../../hooks/usePrevious';

import { includes } from 'lodash';
const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
const DnDCalendar = withDragAndDrop(Calendar);

const defaulCreateModalState = {
  isShow: false,
  startDateTime: null, // null or Date
  endDateTime: null, // null or Date
};

const defaultEventFilterState = {
  filterType: EVENT_FILTER_TYPE.all,
  contacts: [],
  group: {},
};

const useStyles = makeStyles((theme) => ({
  createNewEvent: {
    borderRadius: 25,
  },
}));

const NewCalendar = (props) => {
  const {
    events,
    colors,
    dispatch,
    group_id,
    member_ids,
    memberId,
    location,
    groupList,
    contactList,
  } = props;

  const classes = useStyles();
  const [createModalOptions, setCreateModalOptions] = useState(
    defaulCreateModalState
  );

  let previousSearch = usePrevious(location.search);

  // Filter for events related to contact or group
  const [eventsFilter, setEventsFilter] = useState(defaultEventFilterState);
  const [isShowFilterSelector, setShowFilterSelector] = useState(false);

  // Date change on drag
  const [dropped, setDropped] = useState(null);
  const [isShowDateChangeConfirm, setShowDateChangeConfirm] = useState(false);

  // Selected event for details, edit and cancel
  const [scoped, setScoped] = useState(null);
  const [isShowInfo, setShowInfo] = useState(false);
  const [isShowCancelModal, setShowCancelModal] = useState(false);
  const [isShowEditModal, setShowEditModal] = useState(false);

  // Not used to control the calendar, but to make the right API calls
  const [view, setView] = useState(CALENDAR_VIEWS.month);
  const [range, setRange] = useState(null);
  // This fixes an issue with the calendar component where
  // setRange gets called twice
  // https://github.com/jquense/react-big-calendar/issues/1275
  // To fix: View is not changing
  const handleDrillDownChange = function (date, view) {
    setRange([date]);
    setView(view);
  };
  const handleVisibleRangeChange = (range) => setRange(range);
  const handleViewChange = (view) => setView(view);

  useEffect(() => {
    dispatch(loadContacts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGroupList(true));
  }, [dispatch]);

  useEffect(() => {
    if (!!range) {
      const params = getParams(view, range);
      dispatch(getScheduleEvent(params));
    }
  }, [view, range, dispatch]);

  // Fetch on load
  useEffect(() => {
    const params = getParams(CALENDAR_VIEWS.month, getMonthLimits());
    dispatch(getScheduleEvent(params));
  }, [dispatch]);

  // If schedule is initated from Contacts window
  useEffect(() => {
    if (!!group_id) {
      const scopedGroup = groupList.find(
        (glo) => glo.group_id === parseInt(group_id)
      );

      // TODO: Commenting out until we figure out the proper UX
      // if (
      //   !!location &&
      //   !!location.search &&
      //   location.search !== previousSearch
      // ) {
      //   setCreateModalOptions({
      //     isShow: true,
      //     group: scopedGroup,
      //   });
      // }

      setEventsFilter((ps) => ({
        ...ps,
        filterType: EVENT_FILTER_TYPE.group,
        group: scopedGroup,
      }));
    } else if (!!member_ids) {
      const parsed_member_ids = member_ids.split(',').map((id) => parseInt(id));

      const scopedContacts = contactList.filter((clo) =>
        includes(parsed_member_ids, clo.contact_member_id)
      );

      // TODO: Commenting out until we figure out the proper UX
      // if (
      //   !!location &&
      //   !!location.search &&
      //   location.search !== previousSearch
      // ) {
      //   setCreateModalOptions({
      //     isShow: true,
      //     contacts: scopedContacts,
      //   });
      // }

      setEventsFilter((ps) => ({
        ...ps,
        filterType: EVENT_FILTER_TYPE.contacts,
        contacts: scopedContacts,
      }));
    }
  }, [contactList, groupList, group_id, location, member_ids, previousSearch]);

  // const params = useParams();

  const handleEventDblClick = () => console.log('dbl click');

  const handleSlotSelect = (obj) => {
    const { start, end } = obj;
    console.log('Events filter we get', eventsFilter);
    setCreateModalOptions((ps) => ({
      ...ps,
      isShow: true,
      startDateTime: start,
      endDateTime: end,
      contacts:
        eventsFilter.filterType === EVENT_FILTER_TYPE.contacts
          ? eventsFilter.contacts
          : [],
      group:
        eventsFilter.filterType === EVENT_FILTER_TYPE.group
          ? eventsFilter.group
          : null,
    }));
  };

  const handleSelectEvent = (event) => {
    setScoped(event);
    setShowInfo(true);
  };

  const handleEventDrop = (obj) => {
    const { start, end, event } = obj;
    const changes = {
      start: formatInTimeZone(start, 'UTC'),
      end: formatInTimeZone(end, 'UTC'),
      event_id: event.event_id,
    };
    setDropped(changes);
    setShowDateChangeConfirm(true);
    // dispatch(putModifyEvent(changes));
  };

  const handleDateChangeConfirm = () => {
    setShowDateChangeConfirm(false);
    if (!!dropped) {
      let formData = new FormData();
      // console.log('dropped', dropped);
      formData.set('event_data', JSON.stringify(dropped));
      formData.set('mode', 'date');

      dispatch(putModifyEvent(formData));
    }
    setDropped(null);
  };

  const handleDateChangeCancel = () => {
    setShowDateChangeConfirm(false);
    setDropped(null);
  };

  const handleEventResize = (obj) => console.log(obj);

  const handleCloseEventInfoClick = () => {
    setShowInfo(false);
    setScoped(null);
  };

  const handleEditClick = () => {
    console.log('Edit event clicked');
    setShowInfo(false);
    setShowEditModal(true);
    // setScoped(null);
  };

  const handleCancelClick = () => {
    setShowCancelModal(true);
    setShowInfo(false);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
    setScoped(null);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setScoped(null);
  };

  const handleApplyEventFilter = (filter) => {
    setEventsFilter(filter);
    // if (filter.filterType !== EVENT_FILTER_TYPE.all) {
    //   setCreateModalOptions((ps) => ({
    //     ...ps,
    //     contacts: filter.contacts,
    //     group: filter.group,
    //   }));
    // }
  };

  const handleCreateEventClick = () => {
    console.log('Events filter we get', eventsFilter);
    setCreateModalOptions({
      startDateTime: null,
      endDateTime: null,
      isShow: true,
      contacts:
        eventsFilter.filterType === EVENT_FILTER_TYPE.contacts
          ? eventsFilter.contacts
          : [],
      group:
        eventsFilter.filterType === EVENT_FILTER_TYPE.group
          ? eventsFilter.group
          : null,
    });
  };

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <>
      {createModalOptions.isShow && (
        <EventCreateModal
          show={createModalOptions.isShow}
          onClose={() =>
            setCreateModalOptions((ps) => ({ ...ps, isShow: false }))
          }
          inputs={createModalOptions}
          displayTz={tz}
        />
      )}

      {!!scoped && isShowInfo && (
        <EventInfoModal
          show={isShowInfo}
          onEditClick={handleEditClick}
          onCancelClick={handleCancelClick}
          onClose={handleCloseEventInfoClick}
          selectedEvent={scoped}
          isEditable={isEditableEvent(scoped, memberId)}
        />
      )}

      {!!scoped && isShowCancelModal && (
        <EventCancelModal
          show={isShowCancelModal}
          onClose={handleCloseCancelModal}
          selectedEvent={scoped}
        />
      )}

      {!!scoped && isShowEditModal && (
        <EventEditModal
          show={isShowEditModal}
          onClose={handleCloseEditModal}
          selectedEvent={scoped}
        />
      )}

      <ConfirmDialog
        title="Date change confirmation"
        open={isShowDateChangeConfirm}
        onCancel={handleDateChangeCancel}
        content="Are you sure you want to change dates for this event?"
        onOk={handleDateChangeConfirm}
      />

      {isShowFilterSelector && (
        <EventFilterDialog
          open={isShowFilterSelector}
          currentFilter={eventsFilter}
          onClose={() => setShowFilterSelector(false)}
          onApply={handleApplyEventFilter}
        />
      )}

      <Grid container className="h-100">
        {/* Disabled for now */}
        <Fab
          variant="extended"
          color="primary"
          size="medium"
          style={{
            position: 'fixed',
            zIndex: 1000,
            bottom: '2rem',
            right: '2rem',
            textTransform: 'none',
          }}
          // color="secondary"
          onClick={() => setShowFilterSelector(true)}
        >
          <FilterListIcon fontSize="small" />
          <Typography variant="body2">
            {filterDescription(eventsFilter)}
          </Typography>
        </Fab>

        <Button
          variant="contained"
          color="primary"
          className={classes.createNewEvent}
          onClick={handleCreateEventClick}
          style={{ position: 'absolute', top: '10rem', left: '11rem' }}
        >
          Create New Event
        </Button>

        <Grid item xs={12} className="h-100">
          <div className="big_calendar h-100">
            <DnDCalendar
              startAccessor={(e) => e && parseFromStr(e.start, tz)}
              endAccessor={(e) => e && parseFromStr(e.end, tz)}
              titleAccessor={(e) => (
                <EventTitle event={e} memberId={memberId} />
              )}
              draggableAccessor={(e) => isEditableEvent(e, memberId)}
              resizableAccessor={(e) => isEditableEvent(e, memberId)}
              allDayAccessor={(e) => e && e.is_full_day}
              defaultView={view}
              onRangeChange={handleVisibleRangeChange}
              onView={handleViewChange}
              // resources={EVENT_TYPES}
              // resourceAccessor={(e) => e.event_type}
              localizer={localizer}
              onDrillDown={handleDrillDownChange}
              popup={false}
              events={filterEvents(events, eventsFilter)}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSlotSelect}
              selectable
              eventPropGetter={(e, start, end, isSelected) =>
                eventPropGetter(e, start, end, isSelected, colors, view)
              }
              onEventDrop={handleEventDrop}
              onEventResize={handleEventResize}
              // resizable
              className="h-100"
              // step={30}
              components={{ toolbar: FunctionalToolbar }}
              // style={{ height: 800 }}
              onDoubleClickEvent={handleEventDblClick}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  events: state.scheduleEvents.data,
  colors: state.scheduleEvents.colors,
  groupList: state.group.groupList,
  contactList: state.contact.contacts,
  memberId: state.member.member.member_id,
});

NewCalendar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(NewCalendar);
