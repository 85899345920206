import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import Layout from '../Sections/Layout';
import InviteActivity from './InviteActivity';
import SessionActivity from './SessionActivity';
import GlobalUserBehaviour from './GlobalUserBehaviour';
import GlobalBugReports from './GlobalBugReports';
import GlobalCompanyAdmin from './GlobalCompanyAdmin';
import styles from '../../jss/views/activityStyle';
import { useSelector } from 'react-redux';
import useQuery from '../../hooks/useQuery';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(styles);

const Activity = (props) => {
  const query = useQuery();
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const isAdmin = useSelector(
    (state) => state.member.member.user_type === 'administrator'
  );
  const defaultTabIndex = query.get('tab_index');

  useEffect(() => {
    if (
      defaultTabIndex === '0' ||
      defaultTabIndex === '1' ||
      (isAdmin && defaultTabIndex === '4')
    ) {
      changeTab('', parseInt(defaultTabIndex));
    }
  }, [props.location.search, isAdmin, defaultTabIndex]);

  const changeTab = (_, newValue) => {
    setTabIndex(newValue);
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  return (
    <Layout {...props}>
      <div className={classes.activity}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabIndex}
            onChange={changeTab}
            indicatorColor="primary"
            textColor="primary"
            aria-label="activity tabs"
          >
            <Tab label="My Invites" {...a11yProps(0)} />
            <Tab label="My Session" {...a11yProps(1)} />
            {isAdmin && <Tab label="Global Invites" {...a11yProps(2)} />}
            {isAdmin && <Tab label="Global Session" {...a11yProps(3)} />}
            {isAdmin && <Tab label="Global User Behavior" {...a11yProps(4)} />}
            {isAdmin && <Tab label="Global Bug Reports" {...a11yProps(5)} />}
            {isAdmin && <Tab label="Global Company Admin" {...a11yProps(6)} />}
          </Tabs>
        </AppBar>
        <TabPanel className={classes.tabPanel} value={tabIndex} index={0}>
          <InviteActivity />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={tabIndex} index={1}>
          <SessionActivity />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={tabIndex} index={2}>
          <InviteActivity isCanSeeAllInvites />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={tabIndex} index={3}>
          <SessionActivity isCanSeeAllSessions />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={tabIndex} index={4}>
          <GlobalUserBehaviour isCanSeeAll={false} {...props} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={tabIndex} index={5}>
          <GlobalBugReports isCanSeeAll={isAdmin} {...props} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={tabIndex} index={6}>
          <GlobalCompanyAdmin isAdmin={isAdmin} />
        </TabPanel>
      </div>
    </Layout>
  );
};

export default Activity;
