import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { fade, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import TableChartIcon from '@material-ui/icons/TableChart';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import Alert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
// This is temporarily being commented out, the code/layout is important
// so it's being preserved
// [TODO]
// import NewContact from './NewContact';
import MemberInviteModal from '../../components/Modal/MemberInviteModal';
// import { fetchTodos } from '../../config/apiService/Fakeapi';
import {
  deleteContacts,
  loadContactCompanies,
  loadContactCountries,
  loadContactRoles,
  loadContacts,
  setReduxContactAlert,
} from '../../redux/actions/contact';
import { setContactListFilterSelectOptions } from '../../utils/general';
import Compose from '../Email/SharedComponents/Compose';
import Layout from '../Sections/Layout';
import CardContainer from './CardContainer';
import ListContainer from './ListContainer';
import ContactMembersTable from './MemberTable';

// const sortContact = (data, sortkey) => {
//   return data.sort((a, b) =>
//     a[sortkey].toLowerCase() > b[sortkey].toLowerCase()
//       ? 1
//       : b[sortkey].toLowerCase() > a[sortkey].toLowerCase()
//       ? -1
//       : 0
//   );
// };

const getDistinctArray = (array) => {
  return [...new Set(array)];
};

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    // marginTop: '20px',
    marginRight: theme.spacing(2),
    width: '100%',
    boxShadow:
      '0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 7px 0px rgba(0, 0, 0, 0.12)',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sortContact: {
    float: 'right',
    paddingRight: '20px',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    width: '92%',
  },
  searchToolsContainer: {
    padding: '20px 8px 0 8px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const Contact = (props) => {
  const classes = useStyles();
  const {
    totalCount,
    contacts,
    companies,
    countries,
    roles,
    contactAlert,
    loadContacts,
    loadContactCompanies,
    loadContactCountries,
    loadContactRoles,
    setReduxContactAlert,
    location,
  } = props;

  const [compose, setCompose] = useState({
    open: false,
    initialState: {},
  });

  const [contactData, setContactData] = useState([]);
  const [view, setView] = useState('tile');
  // This is temporarily being commented out, the code/layout is important
  // so it's being preserved
  // [TODO]
  // const [showNewContactModal, setShowNewContactModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showContactsSetting, setShowContactsSetting] = useState(false);

  // const [filterRoleValues, setFilterRoleValues] = useState([]);
  const [allFitlerOptions, setAllFitlerOptions] = useState([]);
  const history = useHistory();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState('first_name');
  const [order, setOrder] = useState('asc');
  const [filterBy, setFilterBy] = useState({});

  // const goToMyGroup = () => {
  //   history.push('groups/my-groups');
  // };

  const isContactExists = (contactMemberId) =>
    !!contacts.find((co) => co.contact_member_id === contactMemberId);

  const goToCreateGroup = (selectedContacts = []) => {
    if (selectedContacts.length > 0) {
      const selectedMembers = getDistinctArray(selectedContacts);
      history.push({
        pathname: 'groups/new-group',
        selectedMembers,
      });
    } else {
      history.push('groups/new-group');
    }
  };

  const goToScheduleEvent = (contacts) => {
    if (!!contacts && contacts.length > 0) {
      history.push({
        pathname: `${process.env.PUBLIC_URL}/calendar`,
        search: `?member_ids=${contacts.map((co) => co.contact_member_id)}`,
      });
    }
  };

  const deleteContactsFunc = (selectedContacts) => {
    let formData = new FormData();
    formData.set(
      'contactIds',
      selectedContacts.map(({ id }) => id)
    );
    props.deleteContacts(formData);
  };

  const search = (e) => {
    const value = e.target.value;
    loadSortedContacts(null, null, null, value);
    setSearchKey(value);
  };

  // const objVals = (data) => {
  //   let obj = {};
  //   Object.keys(data).forEach(function (key) {
  //     let val = data[key];
  //     obj[val] = val;
  //   });
  //   return JSON.stringify(obj);
  // };

  useEffect(() => {
    loadContacts({ searchKey, pageNumber: pageNumber - 1, pageSize });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadContacts]);

  const loadSortedContacts = (
    property,
    value,
    filterByItems,
    search_key,
    page_number,
    page_size
  ) => {
    let sort = '';
    if (!property) {
      property = orderBy;
    }
    if (!value) {
      value = order;
    }

    if (property === 'member_name') {
      sort =
        value === 'desc' ? `-first_name,-last_name` : `first_name,last_name`;
    } else {
      sort = value === 'desc' ? `-${property}` : `${property}`;
    }
    let params = {};
    filterByItems = filterByItems ? filterByItems : filterBy;
    if (Object.keys(filterByItems).length > 0) {
      //consider user recent filter options as well if exist
      params = { sort, filter: new URLSearchParams(filterByItems) };
    } else {
      params = { sort };
    }
    params.searchKey = search_key ? search_key : searchKey;
    params.pageNumber = page_number >= 0 ? page_number : pageNumber - 1;
    params.pageSize = page_size ? page_size : pageSize;

    loadContacts(params, true);
    setOrderBy(property);
    setFilterBy(filterByItems);
  };

  const loadFilteredContacts = ({ target: { value, name } }) => {
    // const { name, value } = e.target;
    let filterByItems;
    if (!value) {
      filterByItems = { ...filterBy };
      delete filterByItems[name];
    } else {
      filterByItems = {
        ...filterBy,
        [name]: value,
      };
    }
    loadSortedContacts(null, null, filterByItems);
  };

  const handleSnackbarClose = () => {
    if (contactAlert.show) {
      const alertOption = {
        show: false,
        variant: contactAlert.variant,
        message: '',
      };
      setReduxContactAlert(alertOption);
    }
  };

  const handleScheduleClick = (contactMembers) => {
    // console.log('schedule for', contactMembersIds);
    goToScheduleEvent(contactMembers);
  };
  useEffect(() => {
    if (contactAlert.show) {
      setShowContactsSetting(false);
      setTimeout(() => {
        const alertOption = {
          show: false,
          variant: contactAlert.variant ? contactAlert.variant : 'success',
          message: '',
        };
        setReduxContactAlert(alertOption);
      }, 5000);
    }
  }, [contactAlert, setReduxContactAlert]);

  const loadInitialFilterValues = useCallback(() => {
    loadContactCompanies();
    loadContactCountries();
    loadContactRoles();
  }, [loadContactCompanies, loadContactCountries, loadContactRoles]);

  useEffect(() => {
    loadInitialFilterValues();
  }, [loadInitialFilterValues]);
  // useEffect(() => {
  //   const url = 'filter-role';
  //   fetchTodos(url).then((res) => {
  //     setFilterRoleValues(res);
  //   });
  // });

  useEffect(() => {
    setAllFitlerOptions(setContactListFilterSelectOptions(contacts));
    setContactData(contacts);
  }, [contacts]);

  const toggleCard = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView);
      setContactData(contacts);
      setOrderBy('');
      setFilterBy({});
    }
  };

  const handleChangePage = (e, newPage) => {
    if (newPage !== pageNumber) {
      loadSortedContacts(null, null, null, null, newPage - 1);
      setPageNumber(newPage);
    }
  };

  const handleChangeRowsPerPage = (e) => {
    const pageSize = e.target.value;
    setPageSize(parseInt(pageSize, 10));
    setPageNumber(1);
    loadSortedContacts(null, null, null, null, 0, pageSize);
  };

  const filterSelectKeys = [
    {
      id: 'company',
      text: 'Company',
    },
    {
      id: 'country_code_id',
      text: 'Country',
      key: 'location_information',
    },
    {
      id: 'role',
      text: 'Role',
    },
  ];

  // const filterData = useCallback(() => {
  //   let tempCardContacts = contacts;
  //   Object.keys(filterBy).map((key) => {
  //     const value = filterBy[key];
  //     if (value)
  //       tempCardContacts = tempCardContacts.filter(
  //         (contact) => contact[key] === value
  //       );
  //     return tempCardContacts;
  //   });
  //   setContactData(tempCardContacts);
  // }, [filterBy, contacts]);

  // useEffect(() => {
  //   filterData();
  // }, [filterData]);

  // const handleChangeFilterOption = (event) => {
  //   event.persist();
  //   setFilterBy({
  //     ...filterBy,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const setSelectOptions = (key, data, sub_key) => {
    let optionValues = [];
    let foundFilterOptions = null;
    // if (key === 'role') {
    //   optionValues = roles;
    // } else {
    //   const selectOptions = data.find((d) => d.label === key);
    //   if (selectOptions) optionValues = selectOptions.values;
    // }

    switch (key) {
      case 'company':
        optionValues = companies.map((company) => {
          return {
            value: company.company_name,
            name: company.company_name,
          };
        });
        break;
      case 'country_code_id':
        // // This section will be throaway code once
        // // it is replaced with fetching these values from the API
        // // By default we must create the expected object
        // foundFilterOptions = data.find((d) => d.label === sub_key);
        // if (foundFilterOptions) {
        //   // Location information for each contact comes in an array of location objects
        //   // we must first flatten the array of arrays into a single array of objects
        //   // This tip was read from here:
        //   // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#Alternatives
        //   // > reduce and concat
        //   // > const arr = [1, 2, [3, 4]];
        //   // >
        //   // > // To flat single level array
        //   // > arr.flat();
        //   // > // is equivalent to
        //   // > arr.reduce((acc, val) => acc.concat(val), []);
        //   // > // [1, 2, 3, 4]
        //   // >
        //   // > // or with decomposition syntax
        //   // > const flattened = arr => [].concat(...arr);
        //   foundFilterOptions.values = foundFilterOptions.values.reduce(
        //     (acc, location) => acc.concat(location),
        //     []
        //   );
        //   // We must then make sure we only have unique values
        //   // doing this we create a map to keep track of the values we've added
        //   // we must then map the values to the expected filter object
        //   const map = new Map();
        //   optionValues = foundFilterOptions.values.reduce((acc, location) => {
        //     if (!map.has(location.country_code_id)) {
        //       map.set(location.country_code_id, true); // set any value to Map
        //       return acc.concat({
        //         value: location.country_code_id,
        //         name: location.country,
        //       });
        //     }
        //     return acc;
        //   }, []);
        //   optionValues.sort((location) => location.name);
        // }
        optionValues = countries.map((country) => {
          return {
            value: country.id,
            name: country.name,
          };
        });
        break;
      case 'role':
        // Roles come with Identifiers and name
        optionValues = roles.map((role) => {
          return {
            value: role.name,
            name: role.name,
          };
        });
        break;
      default:
        // By default we must create the expected object
        foundFilterOptions = data.find((d) => d.label === key);
        if (foundFilterOptions) {
          optionValues = foundFilterOptions.values.map((filter) => {
            return {
              value: filter,
              name: filter,
            };
          });
        }
        break;
    }
    return (
      <>
        <option aria-label="None" value="" />
        {optionValues.length > 0 &&
          optionValues.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
      </>
    );
  };
  return (
    <Layout {...props}>
      <div className="view-section contact-section">
        {/*
        // This is temporarily being commented out, the code/layout is important
        // so it's being preserved
        // [TODO]
        <NewContact
          open={showNewContactModal}
          onClose={() => setShowNewContactModal(false)}
        /> */}

        <MemberInviteModal
          open={showInviteModal}
          onClose={() => setShowInviteModal(false)}
        ></MemberInviteModal>

        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className={classes.searchToolsContainer}
        >
          {/* <Grid item xs={12} sm={6} className="create-new-button">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowInviteModal(true)}
              className="button-group"
            >
              Invite Contact
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={goToCreateGroup}
              className="button-group"
            >
              Create Group
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={goToMyGroup}
              className="button-group"
            >
              Show My Groups
            </Button>
          </Grid> */}
          <Grid item xs={12} sm={5} md={4}>
            {view !== 'master' && (
              <div className={classes.search}>
                <div aria-label="search-icon" className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  value={searchKey}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  onChange={search}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={7}
            md={5}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <ToggleButtonGroup value={view} exclusive onChange={toggleCard}>
                <ToggleButton value="tile" aria-label="module">
                  <Tooltip title="Contact Tile">
                    <ViewModuleIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="list" aria-label="list">
                  <Tooltip title="Contact List">
                    <ViewListIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="master" aria-label="list">
                  <Tooltip title="Amera Master List">
                    <TableChartIcon />
                  </Tooltip>
                  &nbsp;&nbsp;AmeraShare Users
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item className="contact-setting-button">
              <Tooltip title="Settings" arrow>
                <SettingsIcon onClick={() => setShowContactsSetting(true)} />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {view !== 'master' && (
          <React.Fragment>
            <div className="multi-filter-option">
              <Grid container spacing={2}>
                {filterSelectKeys.map((key) => (
                  <Grid item xs={4} key={key.id} className="filter-select-item">
                    <FormControl variant="filled">
                      <InputLabel htmlFor="filled-age-native-simple">
                        {key.text}
                      </InputLabel>
                      <Select
                        native
                        value={
                          filterBy && filterBy[key.id] ? filterBy[key.id] : ''
                        }
                        onChange={(e) => loadFilteredContacts(e)}
                        inputProps={{
                          name: key.id,
                          id: key.id,
                        }}
                      >
                        {setSelectOptions(key.id, allFitlerOptions, key.key)}
                      </Select>
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </div>
          </React.Fragment>
        )}
        {view === 'tile' && (
          <CardContainer
            totalCount={totalCount}
            contactData={contactData}
            showContactsSetting={showContactsSetting}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            pageSize={pageSize}
            pageNumber={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loadSortedContacts={loadSortedContacts}
            loadContactCompanies={loadContactCompanies}
            loadContactCountries={loadContactCountries}
            loadContactRoles={loadContactRoles}
            setShowContactsSetting={setShowContactsSetting}
            goToCreateGroup={goToCreateGroup}
            deleteContactsFunc={deleteContactsFunc}
            // This makes sure an empty modal is not opened if the person of interest is nt in you contacts list
            openDetailsFor={
              !!location.state && isContactExists(location.state.openDetailsFor)
                ? location.state.openDetailsFor
                : null
            }
            setCompose={setCompose}
            onSchedule={handleScheduleClick}
          />
        )}
        {view === 'list' && (
          <ListContainer
            totalCount={totalCount}
            contactData={contactData}
            order={order}
            setOrder={(o) => setOrder(o)}
            orderBy={orderBy}
            setOrderBy={(p) => setOrderBy(p)}
            pageSize={pageSize}
            pageNumber={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            showContactsSetting={showContactsSetting}
            setShowContactsSetting={setShowContactsSetting}
            goToCreateGroup={goToCreateGroup}
            loadSortedContacts={loadSortedContacts}
            deleteContactsFunc={deleteContactsFunc}
            // This makes sure an empty modal is not opened if the person of interest is nt in you contacts list
            openDetailsFor={
              !!location.state && isContactExists(location.state.openDetailsFor)
                ? location.state.openDetailsFor
                : null
            }
            setCompose={setCompose}
            onSchedule={handleScheduleClick}
          />
        )}
        {view === 'master' && (
          <ContactMembersTable
            showContactsSetting={showContactsSetting}
            setShowContactsSetting={setShowContactsSetting}
          />
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={contactAlert.show}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={contactAlert.variant}>{contactAlert.message}</Alert>
      </Snackbar>
      {!compose.open ? null : (
        <Compose
          open={true}
          initialComposeState={compose.initialState}
          fullWidth={false}
          onClose={() => {
            setCompose({ open: false, initialState: {} });
          }}
        />
      )}
    </Layout>
  );
};

Contact.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
  countries: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.object),
  contactAlert: PropTypes.object,
};

Contact.defaultProps = {
  companies: [],
  countries: [],
  roles: [],
  contactAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

const mapStateToProps = (state) => ({
  contacts: state.contact.contacts,
  totalCount: state.contact.totalCount,
  companies: state.contact.companies,
  countries: state.contact.countries,
  roles: state.contact.roles,
  contactAlert: state.contact.contactAlert,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        loadContacts,
        loadContactCompanies,
        loadContactCountries,
        loadContactRoles,
        deleteContacts,
        setReduxContactAlert,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
