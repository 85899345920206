import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import List from '@material-ui/core/List';

import Contact from './Contact';
import ContactSkeleton from './Skeleton';
import { loadContacts } from '../../../../../../redux/actions/contact';

function Contacts(props) {
  const { loading, contacts, loadContacts } = props;
  const [selectedContactId, setSelectedContactId] = React.useState('');
  React.useEffect(() => {
    loadContacts();
  }, [loadContacts]);

  let contactItems = null;

  if (loading) {
    contactItems = [...Array(10).keys()].map((num) => (
      <ContactSkeleton key={num} num={num} />
    ));
  } else {
    contactItems = contacts.map((contact) => (
      <Contact
        loading={true}
        contact={contact}
        selectedContactId={selectedContactId}
        setSelectedContactId={setSelectedContactId}
      />
    ));
  }
  return <List className="contacts-list">{contactItems}</List>;
}

const mapStateToProps = (state) => ({
  contacts: state.contact.contacts,
  loading: state.contact.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        loadContacts,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
