import React from 'react';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import { textCapitalized } from '../utils/general';

const MemberLocationPreview = (props) => {
  const {
    location_type,
    street,
    city,
    state,
    province,
    // country_code_id,
    postal,
    address_1,
    address_2,
  } = props.data;

  //   Check uf address 1 matches street
  const fields =
    address_1 !== street
      ? [address_2, address_1, street, city, province, state, postal]
      : [address_2, street, city, province, state, postal];

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
      }}
    >
      <span style={{ marginRight: '1rem' }}>
        {textCapitalized(location_type)}
      </span>
      {fields.map(
        (f, idx) =>
          !!f && (
            <span
              key={`${f}${idx}`}
              variant="body2"
              style={{ marginRight: '0.5rem' }}
            >
              {f}
            </span>
          )
      )}
    </div>
  );
};

export default MemberLocationPreview;
