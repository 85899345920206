import React, { useState, useEffect, useRef } from 'react';
import { IntlProvider } from 'react-intl';
import detectBrowserLanguage from 'detect-browser-language';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, CircularProgress, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import App from './App';
import theme from './jss/theme';

import { appStartAction } from './redux/actions/app';

const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'fixed',
    bottom: '50vh',
    left: '50vw',
  },
}));

const AppWrapper = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    browserLanguage: detectBrowserLanguage(),
    countryCode: '', // countryCode is not matched to a langauge of browser so there will be another approach
  });

  function loadScript(src, position, id) {
    if (!position) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  }

  const loaded = useRef(false);
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_API_KEY}&v=3.exp&language=en&libraries=geometry,drawing,places`,
        document.querySelector('head'),
        'google-maps'
      );
    }
  }

  const [isLoadedTranslations, setIsLoadedTranslations] = useState(false);

  const { appStart } = props;

  useEffect(() => {
    fetch('https://extreme-ip-lookup.com/json/')
      .then((res) => res.json())
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          countryCode: response.countryCode,
        }));
      })
      .catch(() => {
        console.log('Request failed');
      });
    appStart().then(() => {
      setIsLoadedTranslations(true);
    });
  }, [appStart]);

  const locale =
    props.global.locale || state.browserLanguage || state.countryCode;

  return (
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.app.appState === 'RUNNING' && isLoadedTranslations ? (
          <IntlProvider
            locale={locale}
            key={locale}
            messages={props.app.translations && props.app.translations[locale]}
          >
            <App />
          </IntlProvider>
        ) : (
          <CircularProgress
            variant="indeterminate"
            className={classes.loading}
            size={40}
            thickness={4}
            value={60}
          />
        )}
      </ThemeProvider>
    </DndProvider>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  global: state.global,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ appStart: appStartAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
