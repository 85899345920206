import React, { useState, useEffect } from 'react';
// import Sidebar, { messages as sidebarMessages } from '../Sidebar';
import SubMenu from '../Sidebar/SubMenu';
import navItems from '../Sidebar/navItems';
import Footer from '../Footer';
import ErrorBoundary from '../../../components/ErrorBoundary';

const Content = ({ location: { pathname }, children }) => {
  const [activeNav, setActiveNav] = useState(0);
  const [activeMenu, setActiveMenu] = useState(0);

  useEffect(() => {
    const navItemIndex = navItems.findIndex((item) =>
      pathname.includes(item.to)
    );
    if (navItemIndex >= 0) {
      setActiveNav(navItemIndex);
    } else {
      setActiveNav(0);
    }
  }, [pathname]);

  let sectionClass = 'content';
  if (pathname === '/dashboard') {
    sectionClass = 'content-dashboard';
  } else if (pathname.includes('/call/video')) {
    sectionClass = 'content video-call';
  }

  return (
    <section className="main-content">
      <div className="page_content">
        <SubMenu
          menuItems={navItems[activeNav].menuItems}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          id={navItems[activeNav].id}
          btnLabel={navItems[activeNav].btnLabel}
          secondBtnLabel={navItems[activeNav].secondBtnLabel}
          filterItems={navItems[activeNav].filterItems}
          hasCount={navItems[activeNav].hasCount ? true : false}
          path={pathname}
        />
        <ErrorBoundary>
          <section className={sectionClass}>{children}</section>
        </ErrorBoundary>
      </div>
      <Footer />
    </section>
  );
};

export default Content;
