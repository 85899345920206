import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '../Modal/modal';
import CompanyDetail from './CompanyDetail';
import CompanyMembers from './CompanyMembers';
import {
  createCompany,
  updateCompany,
  getCompanyDetail,
} from '../../redux/actions/company';

const useStyles = makeStyles((theme) => ({
  companyDetail: {
    marginBottom: theme.spacing(4),
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
}));

const CompanyDetailModal = (props) => {
  const { open, onClose, companyId, isEdit, isAdmin, switchMode } = props;
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company.companyData);
  const [state, setState] = useState({
    name: null,
    address_1: null,
    address_2: null,
    state: null,
    country_code_id: null,
    city: null,
    primary_url: null,
  });
  const [src, setSrc] = useState(null);

  const title = 'Company Detail';
  const classes = useStyles();

  const setValues = (data) => {
    setState((ps) => ({
      ...ps,
      ...data,
    }));
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetail(companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (company) {
      // delete company.members
      setState(company);
    }
  }, [company]);

  const handleCloseModal = () => {
    onClose();
  };

  const setFormData = (values) => {
    let formData = new FormData();
    let newValues = { ...values };
    delete newValues.members;
    Object.keys(newValues).map((key) => {
      return formData.append(key, newValues[key]);
    });
    return formData;
  };

  const ModalContent = () => (
    <div className={classes.company}>
      {companyId && isAdmin && (
        <Button onClick={switchMode} className="modal-header-button">
          Edit
        </Button>
      )}
      <div className={classes.companyDetail}>
        {((companyId && company) || companyId === null) && (
          <CompanyDetail
            company={companyId ? company : {}}
            state={state}
            companyId={companyId}
            isEdit={isEdit}
            src={src}
            setSrc={setSrc}
            onStateChange={setValues}
          />
        )}
      </div>
      <div>
        {companyId && company && (
          <CompanyMembers
            company={company}
            companyId={companyId}
            isEdit={isEdit}
          />
        )}
      </div>
    </div>
  );

  const ModalAction = () => (
    <Grid container justify="flex-end">
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseModal}
        className={classes.closeButton}
      >
        Close
      </Button>
      {isEdit && (
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            if (companyId) {
              await dispatch(updateCompany(companyId, setFormData(state)));
              handleCloseModal();
            } else {
              await dispatch(createCompany(setFormData(state)));
              handleCloseModal();
            }
          }}
          className="modal-action-btn"
        >
          {companyId ? 'Update Company' : 'Create Company'}
        </Button>
      )}
    </Grid>
  );
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
    />
  );
};

CompanyDetailModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

CompanyDetailModal.defaultProps = {
  open: false,
  onClose: null,
};

const mapStateToProps = (state) => ({
  memberState: state.member,
  groupMemberships: state.group.groupList,
  memberAlert: state.member.memberAlert,
});

export default connect(mapStateToProps)(CompanyDetailModal);
