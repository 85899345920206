import React from 'react';
import List from '@material-ui/core/List';

import Participant from './Participant';

function Participants(props) {
  let participantItems = null;
  participantItems = props.rosters.map((participant, index) => (
    <Participant key={index} participant={participant} />
  ));
  return <List className="contacts-list">{participantItems}</List>;
}

export default Participants;
