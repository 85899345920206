import React from 'react';
import TextField from '@material-ui/core/TextField';
const PasswordInput = (props) => {
  const { isConfirm, handlePasswordChange, password, errors } = props;
  return (
    <TextField
      id={isConfirm ? 'outlined-confirm-password' : 'outlined-password'}
      label={isConfirm ? 'Confirm Password' : 'Password'}
      type="password"
      required
      variant="outlined"
      name={isConfirm ? 'confirm_password' : 'password'}
      // Prevent user from pasting confirm email
      onPaste={isConfirm ? (e) => e.preventDefault() : null}
      placeholder={isConfirm ? 'Confirm Password' : 'Password'}
      className="register_form_input"
      onChange={handlePasswordChange}
      value={password || ''}
      error={errors ? true : false}
      helperText={errors ? errors : ''}
      inputProps={{
        autoComplete: 'new-password',
        form: {
          autoComplete: 'off',
        },
      }}
    />
  );
};

export default PasswordInput;
