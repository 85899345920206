import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { connect } from 'react-redux';
import {
  LOCATION_TYPES,
  EAT_GPLACES,
  ADDRESS_TYPES,
} from '../../../../utils/calendar';
import {
  locationObjectToString,
  locationStringToGoogleQuery,
} from '../../../../utils/country';
import MemberLocationPreview from '../../../../components/MemberLocationPreview';
import MemberLocationSelect from '../MemberLocationSelect';
import LocationMap from '../LocationMap';
import DrivingDirectionDetails from './DrivingDirectionDetails';
import { intersection } from 'lodash';

const EventLocation = ({ event, locationObjects, onPlaceData }) => {
  const { location_mode, location_id, location_address } = event;

  const [isShowDirections, setShowDirections] = useState(false);
  const [destination, setDestination] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [directions, setDirections] = useState(null);
  const [originLocation, setOriginLocation] = useState(
    // locationObjects.length > 0 ? locationObjects[0] : null
    null
  );
  const [error, setError] = useState(null);

  let locationLine, address;

  if (location_mode === LOCATION_TYPES.own && locationObjects.length > 0) {
    const locationObject = locationObjects.find(
      (lio) => lio.id === location_id
    );
    address = locationObjectToString(locationObject);
    locationLine = <MemberLocationPreview data={locationObject} />;
  } else if (
    location_mode === LOCATION_TYPES.lookup ||
    location_mode === LOCATION_TYPES.find
  ) {
    locationLine = <Typography>{location_address}</Typography>;
    address = location_address;
  }

  let map = new window.google.maps.Map(document.createElement('div'));
  let placesService = new window.google.maps.places.PlacesService(map);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const geocodeAddress = (address) =>
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${locationStringToGoogleQuery(
        address
      )}&sensor=true&language=en&key=${process.env.REACT_APP_GMAPS_API_KEY}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'OK') {
          return data.results[0];
        } else {
          setError('Could get no location for input');
        }
      })
      .catch((error) => {
        setError('Could get no location for input');
        console.error(error);
      });

  const getSinglePlaceDetails = useCallback(
    (placeId) =>
      placesService.getDetails(
        {
          placeId,
          fields: ['ALL'],
        },
        async (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            /*
              if the place if one from the list => Send its image up 
            */
            console.debug('Place', place);
            if (intersection(place.types, EAT_GPLACES).length > 0) {
              onPlaceData(place);
            }

            setDestination(place);
            return place;
          } else {
            setError(status);
            // console.error(status);
          }
        }
      ),
    [onPlaceData, placesService]
  );

  const addressToPlace = useCallback(
    async (address) => {
      try {
        const { place_id } = await geocodeAddress(address);
        getSinglePlaceDetails(place_id);
      } catch (error) {
        setError(error);
      }
    },
    [geocodeAddress, getSinglePlaceDetails]
  );

  useEffect(() => {
    if (!!address) {
      addressToPlace(address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (!!origin && !!destination) {
      const DirectionsService = new window.google.maps.DirectionsService();
      DirectionsService.route(
        {
          origin: origin.geometry.location,
          destination: destination.geometry.location,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            console.log(result);
            setDirections(result);
          } else {
            setError('Could not obtain directions');
          }
        }
      );
    }
  }, [origin, destination]);

  const handleOriginSelect = async (value) => {
    setError(null);
    const locationObject = locationObjects.find((lio) => lio.id === value);

    // geocode origin to an object containing geometry.location
    console.debug('fetch string', locationObjectToString(locationObject));

    const geoData = await geocodeAddress(
      locationObjectToString(locationObject)
    );
    setOrigin(geoData);
    setOriginLocation(locationObject);
  };

  return (
    <Grid
      item
      container
      alignItems="center"
      alignContent="center"
      justify="flex-start"
    >
      <Grid item container xs={2} justify="center" alignItems="center">
        <Grid item>
          <LocationOnIcon />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        {locationLine}
      </Grid>
      {locationObjects.length > 0 && (
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Switch
                checked={isShowDirections}
                onChange={() => setShowDirections((ps) => !ps)}
                name="directions-show"
                color="primary"
              />
            }
            label="Driving Directions"
          />
        </Grid>
      )}

      {/* Directions part */}
      {isShowDirections && (
        <>
          <Grid item container xs={2} justify="center" alignItems="center">
            <Grid item>
              <MyLocationIcon />
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <MemberLocationSelect
              value={!!originLocation ? originLocation.id : null}
              handleChange={handleOriginSelect}
            />
          </Grid>
          {!!error && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </>
      )}

      {!!directions && isShowDirections && (
        <>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <DrivingDirectionDetails directions={directions} />
          </Grid>
        </>
      )}

      {/* End directions part */}
      {/* Map */}
      {!!destination && (
        <Grid item xs={12} style={{ marginTop: '0.5rem' }}>
          <LocationMap
            places={[destination]}
            selectedPlace={destination}
            directions={!!directions && isShowDirections ? directions : null}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `15rem` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </Grid>
      )}
      {/* End Map */}
      {/* Directions details */}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  locationObjects: state.member.memberInfo.location_information.filter(
    (lo) => lo.location_type !== ADDRESS_TYPES.billing
  ),
});

export default connect(mapStateToProps)(EventLocation);
