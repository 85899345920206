import React from 'react';
import { Quill } from 'react-quill';
import '../../../../../styles/components/CustomComposeEditorstyles.css';
import 'react-quill/dist/quill.snow.css';

const CustomUndo = () => (
  <svg
    width="9"
    height="7"
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.547653 3.80801L5.58036 6.63901V0.976013L0.547653 3.80801ZM8.60615 3.31801H5.08017V4.29901H8.60615V3.31801Z"
      fill="#767676"
    />
  </svg>
);

const CustomRedo = () => (
  <svg
    width="9"
    height="7"
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.78164 3.80801L3.74893 6.63901V0.976013L8.78164 3.80801ZM0.723145 3.31701H4.24912V4.29801H0.723145V3.31701Z"
      fill="#767676"
    />
  </svg>
);

function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import('attributors/style/size');
Size.whitelist = [
  '8pt',
  '9pt',
  '10pt',
  '11pt',
  '12pt',
  '14pt',
  '16pt',
  '18pt',
  '20pt',
  '22pt',
  '24pt',
  '26pt',
  '28pt',
  '36pt',
  '48pt',
  '72pt',
];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('attributors/style/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'color',
  'indent',
  'clean',
];

// Quill Toolbar component
export const QuillToolbar = ({ className }) => (
  <div id="toolbar" className={className}>
    <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-undo">
        <CustomRedo />
      </button>
      <select className="ql-font" id="font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-header" id="header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
      <select className="ql-size" id="size" defaultValue="14pt">
        <option value="8pt">8pt</option>
        <option value="9pt">9pt</option>
        <option value="10pt">10pt</option>
        <option value="11pt">11pt</option>
        <option value="12pt">12pt</option>
        <option value="14pt">14pt</option>
        <option value="16pt">16pt</option>
        <option value="18pt">18pt</option>
        <option value="20pt">20pt</option>
        <option value="22pt">22pt</option>
        <option value="24pt">24pt</option>
        <option value="26pt">26pt</option>
        <option value="28pt">28pt</option>
        <option value="36pt">36pt</option>
        <option value="48pt">48pt</option>
        <option value="72pt">72pt</option>
      </select>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <select className="ql-color" />
      <select className="ql-align" />
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
      <button className="ql-blockquote" />
      <button className="ql-clean" />
      <button className="ql-strike" />
    </span>
  </div>
);

export default QuillToolbar;
