import {
  APP_START,
  APP_ERROR,
  APP_INITIALIZATION_END,
  CHANGE_LOCALE,
  LOAD_TRANSLATIONS,
  APP_MESSAGE_UPDATE,
} from '../actionTypes/app.js';

import * as api from '../../config/api';

const apiUrl = api.apiUrl;

export const changeLocale = (locale) => {
  return {
    type: CHANGE_LOCALE,
    payload: {
      locale,
    },
  };
};

export const appStartAction = () => async (dispatch) => {
  const url = `${apiUrl}/languages`;
  dispatch({ type: APP_START });
  try {
    const res = await api.GET(url);
    dispatch({ type: LOAD_TRANSLATIONS, payload: res });
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({
      type: APP_INITIALIZATION_END,
    });
  }
  return true;
};

export const updateMessage = (payload) => (dispatch) => {
  const url = `${apiUrl}/languages`;
  api
    .POST(url, JSON.stringify(payload), { 'Content-Type': 'application/json' })
    .then((res) => {
      dispatch({ type: APP_MESSAGE_UPDATE, payload });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const appError = (payload) => (dispatch) => {
  dispatch({ type: APP_ERROR, payload });
};
