import React, { useState, useMemo } from 'react';
import PhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';
import { store } from '../../../../../utils/store';
import TOTPModal from '../../../../../pages/Registration/TOTPModal';
import Button from '@material-ui/core/Button';
// import Chip from '@material-ui/core/Chip';
// import DoneIcon from '@material-ui/icons/Done';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import FormHelperText from '@material-ui/core/FormHelperText';

const PhoneField = (props) => {
  const {
    error,
    disabled,
    phoneNumber,
    phoneCountry,
    userCountry,
    handleChange,
    handleVerificationSuccess,
    isPhoneVerified,
    phoneType,
    countryList,
  } = props;

  const [isShouldVerify, setShouldVerify] = useState(false);
  const [isShowTOTP, setShowTOTP] = useState(false);

  const handlePhoneInput = (value, country) => {
    if (phoneType === 'cell' || (phoneType === 'TDD' && !isPhoneVerified)) {
      console.log(country);
      const alpha2Code = country.countryCode.toUpperCase();
      const cellRegExp = countryList.find((clo) => clo.alpha2 === alpha2Code)
        .cell_regexp;

      //  If no RegExp, then just start when at least 7 symbols present
      let condition;
      if (cellRegExp) {
        condition = RegExp(cellRegExp).test(value);
      } else {
        condition = value.length >= 10;
      }

      // We await for the number to match country specific Regexp before we allow to send confirmation sms
      if (condition) {
        setShouldVerify(true);
      } else {
        setShouldVerify(false);
      }
    }

    handleChange(value, country.countryCode);
  };

  const handleVerifyClick = () => {
    setShowTOTP(true);
  };

  const allowedCountries = useMemo(
    () => countryList.map((clo) => clo.alpha2.toLowerCase()),
    [countryList]
  );

  return (
    <>
      <TOTPModal
        show={isShowTOTP}
        close={() => setShowTOTP(false)}
        phoneNumber={phoneNumber}
        onVerificationSuccess={handleVerificationSuccess}
        dispatch={store.dispatch}
        // isLoading
      />
      <div>
        <PhoneInput
          error={Boolean(error)}
          country={phoneCountry ? phoneCountry : userCountry} // Use user's country as default
          value={phoneNumber}
          disabled={disabled}
          onlyCountries={allowedCountries}
          onChange={handlePhoneInput}
          inputClass="phone-input-field"
          containerClass="phone-input"
          disableDropdown={false}
          countryCodeEditable={true}
        />
        <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
      </div>

      {isShouldVerify ? (
        <Button onClick={handleVerifyClick}>Verify</Button>
      ) : null}
      {isPhoneVerified && phoneType !== 'landline' ? (
        <VerifiedUserIcon color="primary" />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  countryList: state.member.countryList,
});

export default connect(mapStateToProps)(PhoneField);
