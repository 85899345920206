import { chatDateFormat } from './dateFormat';

export const groupMessagesByDate = (messages) => {
  let group = messages.reduce((r, a) => {
    const dateTime = new Date(a.createdAt);
    //set time to 0 to get same day
    dateTime.setHours(0);
    dateTime.setMinutes(0);
    dateTime.setSeconds(0, 0);
    r[dateTime.toUTCString()] = [...(r[dateTime.toUTCString()] || []), a];
    return r;
  }, {});
  return group;
};

export const getMessagesDividerDate = (date) => {
  let a = new Date(date), // input date to process
    b = new Date(), // today date time
    c = new Date(Date.now() - 864e5); // Yesterday date time

  a = setTimeToZero(a);
  b = setTimeToZero(b);
  c = setTimeToZero(c);

  if (a.getTime() === b.getTime()) {
    return 'Today';
  } else if (a.getTime() === c.getTime()) {
    return 'Yesterday';
  } else {
    return chatDateFormat(date);
  }
  // else if ((new Date(2015, 8 - 1, 25 - 7)).getTime() < c.getTime())
  // return "Less than a week";
};

const setTimeToZero = (dateTime) => {
  dateTime.setHours(0);
  dateTime.setMinutes(0);
  dateTime.setSeconds(0, 0);
  return dateTime;
};

//temp solution should not depend on client end values
export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
