export const ADD_COMPANY_MEMBER = 'ADD_COMPANY_MEMBER';
export const REMOVE_COMPANY_MEMBER = 'REMOVE_COMPANY_MEMBER';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const LOAD_COMPANIES = 'LOAD_COMPANIES';
export const LOAD_COMPANY = 'LOAD_COMPANY';
export const LOAD_UNREGISTERED_COMPANIES = 'LOAD_UNREGISTERED_COMPANIES';
export const UPDATE_UNREGISTERED_COMPANY = 'UPDATE_UNREGISTERED_COMPANY';
export const DELETE_UNREGISTERED_COMPANY = 'DELETE_UNREGISTERED_COMPANY';
export const CREATE_COMPANY_FROM_NAME = 'CREATE_COMPANY_FROM_NAME';
