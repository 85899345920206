import React, { useEffect, useState } from 'react';
import { ApiEmailService } from '../../config/apiService';
import { getEmailQueryParams, getUnstyledHtmlBody } from '../../utils/email';
import { EmailPageWrapper } from './SharedComponents/EmaliPageWrapper';
import { apiPaths } from '../../config/apiPaths';

const Drafts = (props) => {
  const [emails, setEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  // "TODO: this block is not needed anymore, shall we keep this block or remove ?"
  // const [totalEmails, setTotalEmails] = useState(emailsCountByRequest + 1);

  const moveDraftToCompose = async (draftEmail) => {
    setSelectedEmails([]);
    let data = await ApiEmailService.getEmailDetails(
      'getDraftEmailDetails',
      draftEmail.mail_id
    );
    if (!draftEmail.attachments || draftEmail.attachments.length === 0) {
      props.setCompose({
        open: true,
        initialState: {
          folder_id: draftEmail.folder_id,
          mail_id: draftEmail.mail_id,
          body: draftEmail.body,
          subject: draftEmail.subject,
          receivers: [
            ...draftEmail.receivers?.amera.map((el) => {
              return {
                ...draftEmail.member_details[el],
                contact_member_id: draftEmail.member_details[el].member_id,
              };
            }),
          ],
          reply_id: data.reply ? data.reply.id : null,
          cc: !draftEmail.cc
            ? []
            : [
                ...draftEmail.cc?.amera.map((el) => {
                  return {
                    ...draftEmail.member_details[el],
                    contact_member_id: draftEmail.member_details[el].member_id,
                  };
                }),
              ],
          bcc: !draftEmail.bcc
            ? []
            : [
                ...draftEmail.bcc?.amera.map((el) => {
                  return {
                    ...draftEmail.member_details[el],
                    contact_member_id: draftEmail.member_details[el].member_id,
                  };
                }),
              ],
        },
      });
    } else {
      props.setCompose({
        open: true,
        initialState: {
          folder_id: draftEmail.folder_id,
          mail_id: draftEmail.mail_id,
          body: draftEmail.body,
          subject: draftEmail.subject,
          receivers: [
            ...draftEmail.receivers.amera.map((el) => {
              return {
                ...draftEmail.member_details[el],
                contact_member_id: draftEmail.member_details[el].member_id,
              };
            }),
          ],
          reply_id: data.reply ? data.reply.id : null,
          cc: !draftEmail.cc
            ? []
            : [
                ...draftEmail.cc.amera.map((el) => {
                  return {
                    ...draftEmail.member_details[el],
                    contact_member_id: draftEmail.member_details[el].member_id,
                  };
                }),
              ],
          bcc: !draftEmail.bcc
            ? []
            : [
                ...draftEmail.bcc.amera.map((el) => {
                  return {
                    ...draftEmail.member_details[el],
                    contact_member_id: draftEmail.member_details[el].member_id,
                  };
                }),
              ],
          attachments: draftEmail.attachments.map((el) => {
            return {
              0: el,
              file_id: el.file_id,
            };
          }),
        },
      });
    }
  };

  const getEmails = async (start_id) => {
    let params = getEmailQueryParams(
      start_id,
      0,
      props.search,
      null,
      props.contact_filter
    );

    let { data } = await ApiEmailService.getEmails('getDraftEmails', params);

    data.forEach((email) => {
      email.noStyleBody = getUnstyledHtmlBody(email.body);
    });

    if (start_id) {
      setEmails([...emails, ...data]);
    } else {
      setEmails([...data]);
    }

    return data.length;
  };

  useEffect(() => {
    props.setMemberUrl(apiPaths.mail.getSelectableMembersDraft);
    // eslint-disable-next-line
  }, [props.setMemberUrl]);

  useEffect(() => {
    async function getMails() {
      await getEmails();
    }
    getMails();
    // eslint-disable-next-line
  }, []);

  const mailPreviewHandlers = {
    moveToTrash: async (id) => {
      try {
        await ApiEmailService.deleteEmail('deleteDraftedEmail', id);
        getEmails();
      } catch {}
    },
  };

  const lazyLoading = () => {
    const mail_ids = emails.map((item) => item.mail_id);
    const continue_id = Math.min(...mail_ids);
    getEmails(continue_id);
  };

  return (
    <EmailPageWrapper
      section="Draft"
      view={props.view}
      emails={emails}
      selectedEmails={selectedEmails}
      selectEmail={moveDraftToCompose}
      lazyLoading={lazyLoading}
      mailPreviewHandlers={mailPreviewHandlers}
    />
  );
};

export default Drafts;
