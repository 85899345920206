/* global APP  */
// import { vacall, VI, config, initOptions, rePopo } from './global';
import { vacall, VI, config, initOptions } from './global';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import {
  IconMicDisabled,
  IconCameraDisabled,
  // IconMicrophone,
  // IconCamera
} from './icons/svg';
import Panelbuttons from './buttons';
import AmeraWebrtcClient from '../../utils/webcam/client';
import { getGroupSecurity } from '../../redux/actions/group';
import EncryptionController from './encryption';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../components/Modal/modal.js';
import { setGroupCallReplies } from '../../redux/actions/event';
import * as api from '../../config/api';
import SettingDialog from './settingdialog';
import VideoQuality from './Dialog/VideoQuality/VideoQuality';
import UIEvents from './service/UI/UIEvents';
const JitsiMeetJS = window.JitsiMeetJS;
const $ = window.$;

type Props = {
  /**
   * Whether the local participant is recording the conference.
   */
  _iAmRecorder: boolean,

  /**
   * The CSS class to apply to the root of {@link Conference} to modify the
   * application layout.
   */
  _layoutClassName: string,

  /**
   * Conference room name.
   */
  _room: string,

  /**
   * Whether or not the current UI layout should be in tile view.
   */
  _shouldDisplayTileView: boolean,

  dispatch: Function,
  t: Function,
};

//let connection = null;
//   let isJoined = false;
//   let room = null;

//   let localTracks = [];
//   const remoteTracks = {};
//   const initOptions = {
//     disableAudioLevels: true,
//   };

//const VI = new VideoConference();

const styles = (theme) => ({
  modalContentWrapper: {
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '1.2em',
    textAlign: 'center',
  },
});

let sc;
class Conference extends Component<Props> {
  constructor(props) {
    super(props);
    console.log(props);
    console.log('sssssss');
    this.state = {
      tog: vacall.ToggleView,
      viewType: 'tile',
      viewtileType: false,
      userName: '',
      lastCallDeclinedMemberInfo: null,
      lastCallDeclinedMemberId: null,
      e2E: true,
      isDialogOpen: false,
      userCount: 1,
      youtube: false,
      isVideoDialogOpen: false,
      mic: false,
      video: false,
      securityModalOpen: false,
    };
  }

  toggleViewType = () => {
    this.setState({
      viewType: !this.state.viewType,
    });
  };

  enableTileView = () => {
    this.setState({
      viewType: 'tile',
    });

    let getAllParticipants = vacall.room.participants;
    var pIds = [];
    //var localParticipant = vacall.room.myUserId();
    //pIds.push(localParticipant);
    if (getAllParticipants) {
      $.each(getAllParticipants, function (i, val) {
        pIds.push(i);
      });
    }

    vacall.room.selectParticipants(pIds);
  };

  enableVerticalView = () => {
    this.setState({
      viewType: 'vertical',
    });
  };

  enableHorizontalView = () => {
    this.setState({
      viewType: 'horizontal',
    });
  };

  toggleScreenshare = () => {
    if (vacall.screenshareEnabled === false) {
      VI.switchVideo();
    }
  };
  toggleEncy = () => {
    this.setState({
      e2E: !this.state.e2E,
    });
    console.log(this.state.e2E);
    vacall.room.toggleE2EE(
      this.state.e2E,
      window.crypto.getRandomValues(new Uint8Array(32))
    );
  };
  toggleTileView = () => {
    var element = document.getElementById('filmstripRemoteVideosContainer');
    // var element2 = document.getElementById('filmstrip');
    this.setState({
      viewType: false,
      viewtileType: !this.state.viewtileType,
    });
    if (vacall.ToggleView === true) {
      vacall.ToggleView = false;
      document.getElementsByClassName('main-video-source')[0].style.display =
        'block';
      document.getElementById('filmstripLocalVideoThumbnail').style.display =
        'block';
      document.getElementById('localVideoContainer2').style.display = 'none';
      //element2.classList.remove('grid-view');
      element.classList.remove('wrapperV');
    } else {
      vacall.ToggleView = true;
      document.getElementsByClassName('main-video-source')[0].style.display =
        'none';
      document.getElementById('filmstripLocalVideoThumbnail').style.display =
        'none';
      document.getElementById('localVideoContainer2').style.display = 'block';
      //element2.classList.add('grid-view');
      element.classList.add('wrapperV');
    }
  };
  toggleSetting = () => {
    this.setState({
      isDialogOpen: !this.state.isDialogOpen,
      selectedAudio: vacall.room.getLocalTracks()[0].deviceId,
      selectedVideo: vacall.room.getLocalTracks()[1].deviceId,
    });
  };
  toggleVideoQuality = () => {
    this.setState({
      isVideoDialogOpen: !this.state.isVideoDialogOpen,
    });
  };
  handleClose(data) {
    if (data === 'isVideoDialogOpen') {
      this.setState({ isVideoDialogOpen: !this.state.isVideoDialogOpen });
    } else {
      this.setState({ isDialogOpen: !this.state.isDialogOpen });
    }
  }
  toggleYoutube = () => {
    VI.toggleYoutube();
  };
  toggleShortcut = () => {
    APP.keyboardshortcut.openDialog();
  };

  /**
   * Start the connection and get the UI ready for the conference.
   *
   * @inheritdoc
   */
  upDateUserCount(count) {
    this.setState({
      userCount: count,
    });
  }
  upDateMicIndicator() {
    this.setState({
      mic: APP.vacall.isMicMuted(),
    });
  }

  upDateVideoIndicator() {
    this.setState({
      video: !APP.vacall.isVideoMuted(),
    });
  }
  componentDidMount() {
    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
    JitsiMeetJS.init(initOptions);
    const { memberState } = this.props;
    let { memberInfo } = memberState;

    if (memberInfo === null) {
      memberInfo = {};
    }

    const {
      first_name,
      last_name,
      amera_avatar_url,
      member_id,
      // job_title_id,
      // job_title,
      // location_information,
    } = memberInfo;
    vacall.localParticipant._member_id = member_id;
    vacall.localParticipant._avatar_url = amera_avatar_url;
    this.setState({ userName: first_name + ' ' + last_name });
    vacall.connection = new JitsiMeetJS.JitsiConnection(null, null, config);
    console.log('amit');
    console.log(vacall.connection);

    if (memberInfo.email) {
      sc = new AmeraWebrtcClient('group');
      sc.connectWebsocket({
        username: memberInfo.email,
        member_id: memberInfo.member_id,
        first_name: memberInfo.first_name,
        last_name: memberInfo.last_name,
      });
    }

    if (this.props.call_group_id) {
      this.props.getGroupSecurity(this.props.call_group_id);
    }

    vacall.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      () => {
        VI.onConnectionSuccess(first_name + ' ' + last_name, amera_avatar_url);
      }
    );
    vacall.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      VI.onConnectionFailed
    );
    vacall.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      VI.disconnect
    );

    JitsiMeetJS.mediaDevices.addEventListener(
      JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
      VI.onDeviceListChanged
    );

    vacall.connection.connect();

    JitsiMeetJS.createLocalTracks({ devices: ['audio', 'video'] })
      .then(VI.onLocalTracks)
      .catch((error) => {
        throw error;
      });

    if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output')) {
      JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
        const audioOutputDevices = devices.filter(
          (d) => d.kind === 'audiooutput'
        );

        if (audioOutputDevices.length > 1) {
          $('#audioOutputSelect').html(
            audioOutputDevices
              .map((d) => `<option value="${d.deviceId}">${d.label}</option>`)
              .join('\n')
          );

          $('#audioOutputSelectWrapper').show();
        }
      });
    }
    vacall.popup = true;
    APP.UI.start();
    APP.keyboardshortcut.init();
    APP.UI.registerListeners();
    APP.UI.bindEvents();
    APP.UI.addListener(UIEvents.USER_COUNT, (count) => {
      this.upDateUserCount(count);
    });
    APP.UI.addListener(UIEvents.MIC_INDICATOR, () => {
      this.upDateMicIndicator();
    });
    APP.UI.addListener(UIEvents.VIDEO_INDICATOR, () => {
      this.upDateVideoIndicator();
    });
    APP.UI.addListener(UIEvents.VERTICAL_VIEW, () => {
      this.enableVerticalView();
    });
  }

  /**
   * Calls into legacy UI to update the application layout, if necessary.
   *
   * @inheritdoc
   * returns {void}
   */
  async componentDidUpdate(prevProps, prevState) {
    console.log(APP.vacall.isVideoMuted());
    if (prevState.userCount !== vacall.userCount) {
      this.setState((ps) => ({
        ...ps,
        userCount: vacall.userCount,
      }));
    }
    const { groupCallReplies } = this.props;
    let flg = false;
    for (let i = groupCallReplies.length - 1; i >= 0; i++) {
      if (groupCallReplies[i].reply_type === 'decline') {
        if (
          prevState.lastCallDeclinedMemberId !== groupCallReplies[i].callee_id
        ) {
          flg = true;
          this.setState((ps) => ({
            ...ps,
            lastCallDeclinedMemberId: groupCallReplies[i].callee_id,
          }));
          api
            .GET(`${api.apiUrl}/member/info/${groupCallReplies[i].callee_id}`)
            .then((res) => {
              this.setState((ps) => ({
                ...ps,
                lastCallDeclinedMemberInfo: res.data,
              }));
            });
        }
        return;
      }
    }
    if (!flg && prevState.lastCallDeclinedMemberId) {
      this.setState((ps) => ({
        ...ps,
        lastCallDeclinedMemberId: null,
        lastCallDeclinedMemberInfo: null,
      }));
    }
  }

  /**
   * Disconnect from the conference when component will be
   * unmounted.
   *
   * @inheritdoc
   */
  componentWillUnmount() {
    if (sc && sc.ws) {
      sc.ws.close();
      sc = null;
    }
    VI.unload();
  }

  handleDeclineModal = () => {
    const { lastCallDeclinedMemberId } = this.state;
    const { groupCallReplies } = this.props;
    const newGroupCallReplies = groupCallReplies.filter(
      (x) => x.callee_id !== lastCallDeclinedMemberId
    );
    this.props.setGroupCallReplies(newGroupCallReplies);
  };

  CallModalContent = () => {
    const { classes } = this.props;
    const { lastCallDeclinedMemberInfo } = this.state;
    let displayStr = 'Call Declined';
    if (lastCallDeclinedMemberInfo) {
      displayStr = lastCallDeclinedMemberInfo
        ? `${lastCallDeclinedMemberInfo.first_name} ${lastCallDeclinedMemberInfo.last_name} is unavailable`
        : '';
    }

    return <div className={classes.modalContentWrapper}>{displayStr}</div>;
  };

  CallModalAction = () => (
    <Button
      variant="contained"
      color="secondary"
      onClick={this.handleDeclineModal}
    >
      Close
    </Button>
  );

  handleSecurity = () => {
    this.setState((ps) => ({
      ...ps,
      securityModalOpen: !ps.securityModalOpen,
    }));
  };

  ModalContent = () => {
    const { groupList, call_group_id } = this.props;
    let isMyGroup = false;
    if (groupList && call_group_id) {
      for (let i = 0; i < groupList.length; i++) {
        if (groupList[i].group_id === call_group_id) {
          isMyGroup = true;
          break;
        }
      }
    }

    return (
      <EncryptionController
        signalingClient={sc}
        toggleEncy={this.toggleEncy}
        e2E={!this.state.e2E}
        isMyGroup={isMyGroup}
      />
    );
  };

  ModalAction = () => (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        this.setState((ps) => ({ ...ps, securityModalOpen: false }));
      }}
    >
      Close
    </Button>
  );

  render() {
    const {
      lastCallDeclinedMemberInfo,
      viewType,
      userCount,
      youtube,
    } = this.state;
    let viewClass;

    if (viewType === 'vertical') {
      viewClass = 'vertical-filmstrip';
    } else if (viewType === 'horizontal') {
      viewClass = 'horizontal-filmstrip';
    } else if (viewType === 'tile') {
      viewClass = 'tile-view';
    }

    return (
      // <div
      //   className={`${this.props._layoutClassName} ${
      //     this.state.viewType
      //       ? 'vertical-filmstrip'
      //       : this.state.viewtileType
      //       ? 'tileView'
      //       : 'horizontal-filmstrip'
      //   }`}
      //   id="videoconference_page"
      // >
      <div
        className={viewClass}
        id="videoconference_page"
        data-paticipants={userCount}
      >
        <Modal
          open={lastCallDeclinedMemberInfo ? true : false}
          onClose={this.handleDeclineModal}
          title="Amerashare group video call"
          ModalContent={this.CallModalContent}
          ModalAction={this.CallModalAction}
          disableBackdropClick={true}
        ></Modal>
        <Modal
          open={this.state.securityModalOpen}
          onClose={this.handleSecurity}
          title="Amerashare group video call"
          ModalContent={this.ModalContent}
          ModalAction={this.ModalAction}
          disableBackdropClick={true}
        ></Modal>

        <SettingDialog
          selectedAudio={this.state.selectedAudio}
          selectedVideo={this.state.selectedVideo}
          selectedSpeaker={this.state.selectedSpeaker}
          isDialogOpen={this.state.isDialogOpen}
          handleClose={() => {
            this.handleClose('isDialogOpen');
          }}
        ></SettingDialog>
        <VideoQuality
          openVideodialog={this.state.isVideoDialogOpen}
          handleClose={() => {
            this.handleClose('isVideoDialogOpen');
          }}
        ></VideoQuality>
        <div id="videospace">
          <div className="largeVvideo" data-stage="">
            <div className="main-center">
              {/*dominantSpeaker, make the style visibility: visible */}
              <div id="dominantSpeaker">
                <div className="dynamic-shadow" />
                <div id="dominantSpeakerAvatarContainer">
                  <div className="avatar" id="dominantSpeakerAvatar"></div>
                </div>
              </div>

              <div className="alone-status"></div>
              <div className="invite-people"></div>
              <div id="sharedVideo" style={{ display: 'none' }}>
                <div id="sharedVideoIFrame" />
              </div>
              <div className="main-video-source">
                <video id="large_videowrapper" autoPlay={true}></video>
                <span
                  style={{ display: 'none' }}
                  className="videowithscreenshare"
                  id="localVideoContainer_screensahre"
                >
                  <video autoPlay={true} id="localVideo3"></video>
                </span>
              </div>
            </div>
          </div>

          <Panelbuttons
            toggleYoutube={this.toggleYoutube}
            toggleSetting={this.toggleSetting}
            toggleEncy={this.toggleEncy}
            toggleTileView={this.toggleTileView}
            toggleScreenshare={this.toggleScreenshare}
            enableTileView={this.enableTileView}
            enableVerticalView={this.enableVerticalView}
            enableHorizontalView={this.enableHorizontalView}
            youtubeStatus={youtube}
            handleSecurity={this.handleSecurity}
            toggleVideoQuality={this.toggleVideoQuality}
            toggleShortcut={this.toggleShortcut}
            toggleFullScreen={this.toggleFullScreen}
          />

          <div id="filmstrip" className="filmstrip reduce-height">
            <div className="filmstrip__videos" id="filmstripLocalVideo">
              <div id="filmstripLocalVideoThumbnail">
                <span
                  className="videocontainer display-avatar-with-name"
                  id="localVideoContainer"
                >
                  <div className="videocontainer__background"></div>
                  <span id="localVideoWrapper"></span>
                  <div className="videocontainer__toolbar">
                    {/* audio indicator*/}
                    <div className="indicator-container">
                      <OverlayTrigger
                        placement={'left'}
                        overlay={
                          <Tooltip id={`mic-disabled`}>
                            Participant is muted
                          </Tooltip>
                        }
                      >
                        <span
                          className="indicator-icon-container audioMuted toolbar-icon"
                          id=""
                        >
                          <div className="amera-icon ">
                            {this.state.mic && <IconMicDisabled />}
                          </div>
                        </span>
                      </OverlayTrigger>
                    </div>
                    {/* video indicator*/}
                    <div className="indicator-container">
                      <OverlayTrigger
                        placement={'left'}
                        overlay={
                          <Tooltip id={`video-disabled`}>
                            Participant has stopped the camera
                          </Tooltip>
                        }
                      >
                        <span
                          className="indicator-icon-container videoMuted toolbar-icon"
                          id=""
                        >
                          <div className="amera-icon ">
                            {this.state.video && <IconCameraDisabled />}
                          </div>
                        </span>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="videocontainer__toptoolbar">
                    <OverlayTrigger
                      trigger="click"
                      key={1}
                      rootClose={true}
                      placement={'left'}
                      overlay={
                        <Popover id={`popover-positioned`}>
                          <Popover.Content>
                            <strong>Holy guacamole!</strong> Check this info.
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      {/* <div className="connection-indicator indicator status-high">
                        <div className="connection indicatoricon">
                          <span className="connection_empty">
                            <div className="amera-icon icon-gsm-bars">
                              <IconConnectionActive fill="white" />
                            </div>
                          </span>
                        </div>
                      </div> */}
                      <div></div>
                    </OverlayTrigger>
                  </div>
                  <div className="videocontainer__hoverOverlay"></div>
                  <div className="displayNameContainer">
                    <div className="displayname" id="localDisplayName">
                      {this.state.userName} (ME)
                    </div>
                  </div>
                  <div className="avatar-container">
                    <div className="avatar userAvatar ">
                      <div className="avatar-pic"></div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div className="filmstrip__videos" id="filmstripRemoteVideos">
              <div
                className="remote-videos-container"
                id="filmstripRemoteVideosContainer"
              >
                <span
                  className="videocontainer grid-item"
                  id="localVideoContainer2"
                >
                  <div className="videocontainer__background"></div>
                  <span id="localVideoWrapper2"></span>
                  <div className="videocontainer__toolbar">
                    {/* audio indicator*/}
                    <div className="indicator-container">
                      <OverlayTrigger
                        placement={'left'}
                        overlay={
                          <Tooltip id={`mic-disabled`}>
                            Participant is muted
                          </Tooltip>
                        }
                      >
                        <span
                          className="indicator-icon-container audioMuted toolbar-icon"
                          id=""
                        >
                          <div className="amera-icon ">
                            {this.state.mic && <IconMicDisabled />}
                          </div>
                        </span>
                      </OverlayTrigger>
                    </div>
                    {/* video indicator*/}
                    <div className="indicator-container">
                      <OverlayTrigger
                        placement={'left'}
                        overlay={
                          <Tooltip id={`video-disabled`}>
                            Participant has stopped the camera
                          </Tooltip>
                        }
                      >
                        <span
                          className="indicator-icon-container videoMuted toolbar-icon"
                          id=""
                        >
                          <div className="amera-icon ">
                            {this.state.video && <IconCameraDisabled />}
                          </div>
                        </span>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="videocontainer__toptoolbar">
                    <OverlayTrigger
                      trigger="click"
                      key={1}
                      rootClose={true}
                      placement={'left'}
                      overlay={
                        <Popover id={`popover-positioned`}>
                          <Popover.Content>
                            <strong>Holy guacamole!</strong> Check this info.
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      {/* <div className="connection-indicator indicator status-low">
                        <div className="connection indicatoricon">
                          <span className="connection_empty">
                            <div className="amera-icon icon-gsm-bars">
                              <IconConnectionActive fill="white" />
                            </div>
                          </span>
                        </div>
                      </div> */}
                      <div></div>
                    </OverlayTrigger>
                  </div>
                  <div className="videocontainer__hoverOverlay"></div>
                  <div className="displayNameContainer">
                    <span className="displayname" id="localDisplayName2">
                      {this.state.userName} (ME)
                    </span>
                  </div>
                  <div className="avatar-container">
                    <div className="avatar  userAvatar ">
                      <div className="avatar-pic"></div>
                    </div>
                  </div>
                </span>
                <div className="audio-elements"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { setGroupCallReplies, getGroupSecurity },
    dispatch
  );
};
const mapStateToProps = (state) => ({
  memberState: state.member,
  jobTitleList: state.member.jobTitleList,
  groupCallReplies: state.event.groupCallReplies,
  call_group_id: state.event.call_group_id,
  groupList: state.group.groupList,
  security: state.group.security,
  currentKey: state.VideoCallRedux.currentKey,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Conference));
