import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Card, CardContent, Grid, Tooltip } from '@material-ui/core';
import adapter from 'webrtc-adapter';

import { AmeraAES } from '../../utils/ameraWebCrypto';

import { getMemberSecurity } from '../../redux/actions/contact';
import { apiUrl } from '../../config/api';

const EncryptionController = (props) => {
  const [picture, setPicture] = useState(null);
  const [pin, setPin] = useState('08221991');
  const [pictureStyle, setPictureStyle] = useState({});
  let pictureRef = useRef();
  const {
    classes,
    partner,
    memberInfo,
    ameraWebrtcClient,
    currentKey,
    encryptEnabled,
    isInModal,
    encryptionAvailable,
    localStream,
    member_id,
    security,
    // dispatch,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (member_id) {
      dispatch(getMemberSecurity(member_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member_id]);

  useEffect(() => {
    if (security) {
      let sp = pin;
      if (security.pin) sp = security.pin;
      if (security.security_picture) {
        let url = `${apiUrl}${security.security_picture}`;
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'arraybuffer';
        xhr.onload = function (e) {
          var arr = new Uint8Array(this.response);
          var raw = String.fromCharCode.apply(null, arr);
          var b64 = btoa(raw);
          var dataURL = 'data:image/png;base64,' + b64;
          let size = parseInt(xhr.getResponseHeader('Content-Length'));
          let chunksize = 50000;
          let nextslice, gotmore;
          let realjuju = dataURL.split(',')[1];
          for (let i = 0; i < realjuju.length; i += chunksize) {
            // continue;
            nextslice = realjuju.slice(i, i + chunksize);
            gotmore = nextslice.length === chunksize;
            if (gotmore) {
              const msg = {
                type: 'getkey',
                length: size,
                index: i,
                more: true,
                rawbytes: nextslice,
              };
              ameraWebrtcClient.sendMessage(msg);
            } else {
              const msg = {
                type: 'getkey',
                length: size,
                pin: sp,
                twofa: '05181955', // twofactor.value,
                keysize: 256,
                index: i,
                more: false,
                verbose: 1,
                rawbytes: nextslice,
              };
              ameraWebrtcClient.sendMessage(msg);
            }
          }
        };
        xhr.send();
      }
      setPin(sp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [security]);

  const handlePicPinFiles = (files) => {
    if (!ameraWebrtcClient) return;
    files = files.target.files;

    for (let j = 0; j < files.length; j++) {
      const file = files[j];

      if (!file.type.startsWith('image/')) {
        continue;
      }
      let size = file.size;
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setPicture(reader.result);
      };
      reader.onload = function () {
        let chunksize = 50000;
        let nextslice, gotmore;
        let realjuju = reader.result.split(',')[1];
        //twofactor.value = "05181955"; // online for now
        for (let i = 0; i < realjuju.length; i += chunksize) {
          // continue;
          nextslice = realjuju.slice(i, i + chunksize);
          gotmore = nextslice.length === chunksize;
          if (gotmore) {
            const msg = {
              type: 'getkey',
              length: size,
              index: i,
              more: true,
              rawbytes: nextslice,
            };

            ameraWebrtcClient.sendMessage(msg);
          } else {
            const msg = {
              type: 'getkey',
              length: size,
              pin,
              twofa: '05181955', // twofactor.value,
              keysize: 256,
              index: i,
              more: false,
              verbose: 1,
              rawbytes: nextslice,
            };

            ameraWebrtcClient.sendMessage(msg);
          }
        }
      };
      reader.onerror = function () {
        console.log(reader.error);
      };
    }
  };

  const handleEncrypt = async () => {
    if (!ameraWebrtcClient) return;
    const useCryptoOffset = true;
    try {
      await dispatch({
        type: 'SET_ENCRYPT_ENABLED',
        payload: { encryptEnabled: !encryptEnabled },
      });
    } catch (e) {
      console.log(e);
    }

    try {
      if (!encryptEnabled) {
        const currentCryptoKey = {
          keysize: 256,
          taglength: 128,
          fromkeyfile: false,
          key: currentKey,
          verbose: false,
        };
        currentCryptoKey.verbose = true;

        // this is the signaling server encryption object
        ameraWebrtcClient.ameraAes = new AmeraAES(currentCryptoKey);
        ameraWebrtcClient.encryptEnabled = true;
        currentCryptoKey.verbose = false;
        currentCryptoKey.refreshCount = 1000;

        // hack for now.  make non-webcam start different
        if (memberInfo.email !== partner) {
          currentCryptoKey.send_counter = '000000000099900000000001';
        }

        // this is for the  worker encryption object, creates its own encryption objects
        ameraWebrtcClient.worker.postMessage({
          operation: 'setCryptoKey',
          assets_path: `${process.env.PUBLIC_URL}/`,
          currentCryptoKey,
          useCryptoOffset,
        });
      } else {
        ameraWebrtcClient.ameraAes = null;
        ameraWebrtcClient.encryptEnabled = false;
        const currentCryptoKey = {
          keysize: 0,
        }; // size 0 means turn off!!
        ameraWebrtcClient.worker.postMessage({
          operation: 'setCryptoKey',
          assets_path: `${process.env.PUBLIC_URL}/`,
          currentCryptoKey,
          useCryptoOffset,
        });
      }
      ameraWebrtcClient.sendUserMessage({
        type: 'toggleencryption',
        toggle: {
          enabled: !encryptEnabled,
        },
      });
    } catch (e) {
      console.log('socket error ocrrued');
    }
  };

  useEffect(() => {
    if (pictureRef.current) {
      let ps =
        pictureRef.current.offsetHeight > pictureRef.offsetWidth
          ? { width: '100%', height: 'auto' }
          : { width: 'auto', height: '100%' };
      setPictureStyle(ps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureRef.current]);

  // const supportsInsertableStreamsLegacy = !!RTCRtpSender.prototype.createEncodedVideoStreams;
  // warn if E2EE not supported.  continue after acknowledged

  // const supportsInsertableStreamsLegacy = !!RTCRtpSender.prototype.createEncodedVideoStreams;
  const supportsInsertableStreams = !!RTCRtpSender.prototype
    .createEncodedStreams;
  let supportsTransferableStreams = false;
  try {
    const stream = new ReadableStream();
    window.postMessage(stream, '*', [stream]);
    supportsTransferableStreams = true;
  } catch (e) {
    console.error('Transferable streams are not supported.');
  }
  // if (!((supportsInsertableStreams || supportsInsertableStreamsLegacy) && supportsTransferableStreams)) {

  let scary = '';
  if (!(supportsInsertableStreams && supportsTransferableStreams)) {
    scary = 'Your browser does not support Insertable Streams. ';

    if (adapter.browserDetails.browser === 'chrome') {
      scary +=
        '\n Try with Enable experimental "Web Platform" features enabled from chrome://flags.';
      scary += '\n applies to Brave, Vivaldi and other Chromium based browsers';
    }
  }

  let disable = false;
  if (
    !(
      supportsInsertableStreams &&
      supportsTransferableStreams &&
      localStream
    ) ||
    !encryptionAvailable
  )
    disable = true;

  return (
    <Grid item md={isInModal ? 12 : 4} sm={isInModal ? 12 : 4}>
      <Card className={classes.encryptionCard} variant="outlined">
        <CardContent className={classes.encryptionWrapper}>
          <div className={classes.encryptionImage}>
            {picture && (
              <img
                ref={pictureRef}
                src={picture}
                style={pictureStyle}
                alt="Preview"
              />
            )}
            {!picture && security && security.security_picture && (
              <img
                ref={pictureRef}
                src={`${apiUrl}${security.security_picture}`}
                style={pictureStyle}
                alt="Preview"
              />
            )}
          </div>
          <div className={classes.encryptionControlWrapper}>
            <div className={classes.encryptionImageKey}>
              <label htmlFor="aeskey">Key</label>
              <br />
              <input
                type="text"
                className="form-control form-control-sm"
                id="aeskey"
                value={currentKey}
                placeholder="current key"
                disabled
              />
              <hr />
            </div>
            <h5>Encryption Controls</h5>
            <div className={classes.controlGroup}>
              <div className={classes.fileInputWrapper}>
                <label>Picture</label>
                <input
                  className={classes.fileInput}
                  id="picfile"
                  type="file"
                  accept=".png, .jpg, .ppm"
                  onChange={handlePicPinFiles}
                />
                <label htmlFor="picfile">
                  <Button
                    variant="contained"
                    size="small"
                    component="span"
                    disabled={encryptEnabled}
                  >
                    Choose Picture
                  </Button>
                </label>
              </div>
              <div className={classes.userPinWrapper}>
                <label htmlFor="userpin">PIN</label>
                <input
                  type="text"
                  placeholder="enter pin"
                  value={pin}
                  onChange={(e) => {
                    setPin(e.target.value);
                  }}
                />
              </div>
            </div>
            <Tooltip title={scary}>
              <span>
                <Button
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  onClick={handleEncrypt}
                  disabled={disable}
                >
                  {!encryptEnabled
                    ? 'Create key and encrypt'
                    : 'Remove encryption'}
                </Button>
              </span>
            </Tooltip>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  partner: state.one2onevcall.partner,
  remoteStream: state.one2onevcall.remoteStream,
  callStarted: state.one2onevcall.callStarted,
  currentKey: state.one2onevcall.currentKey,
  encryptEnabled: state.one2onevcall.encryptEnabled,
  memberInfo: state.member.memberInfo,
  encryptionAvailable: state.one2onevcall.encryptionAvailable,
  localStream: state.one2onevcall.localStream,
  member_id: state.one2onevcall.member_id,
  security: state.contact.security,
});

export default connect(mapStateToProps)(EncryptionController);
