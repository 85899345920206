import parsePhoneNumber from 'libphonenumber-js';
import { capitalize } from 'lodash';

export const getContactDevice = (contact, device_type) => {
  if (!contact.contact_information) {
    return;
  }

  const device = contact.contact_information
    .filter(Boolean)
    .find((info) => info.description === device_type);

  if (!device) {
    return;
  }

  const country = contact.country_code
    .filter(Boolean)
    .find((info) => info.id === device.device_country);
  const phoneNumber = `+${country.phone}${device.device}`;
  const nationalPhoneNumber = parsePhoneNumber(phoneNumber).formatNational();
  return `+${country.phone} ${nationalPhoneNumber}`;
};

export const getContactLocation = (contact, location_type) => {
  if (!contact.location_information) {
    return;
  }
  return contact.location_information
    .filter(Boolean)
    .find((info) => info.location_type === location_type);
};

export const displayableFullName = (c) => {
  const middleNamePortion = !!c.middle_name
    ? ` ${capitalize(c.middle_name)}`
    : '';
  return `${capitalize(c.first_name)}${middleNamePortion} ${capitalize(
    c.last_name
  )}`;
};
