import React from 'react';
import TextField from '@material-ui/core/TextField';

const EventDeclineComments = ({ value, onInput }) => {
  return (
    <TextField
      id="event-decline-comments"
      label="Comments"
      fullWidth
      autoFocus
      multiline
      variant="outlined"
      placeholder="Sorry, I can't attend this meeting because.."
      rows={3}
      rowsMax={6}
      value={value}
      onChange={(e) => onInput(e.target.value)}
    />
  );
};

export default EventDeclineComments;
