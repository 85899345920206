// @flow

import * as bodyPix from '@tensorflow-models/body-pix';
import * as tfjs from '@tensorflow/tfjs';
import StreamBlurEffect from './StreamBlurEffect';

/**
 * Creates a new instance of . This loads the bodyPix model that is used to
 * extract person segmentation.
 *
 * @returns {Promise<StreamBlurEffect>}
 */
 export default async function createBlurEffect() {
    console.log(tfjs);
    if (!MediaStreamTrack.prototype.getSettings && !MediaStreamTrack.prototype.getConstraints) {
        throw new Error('JitsiStreamBlurEffect not supported!');
    }

    // An output stride of 16 and a multiplier of 0.5 are used for improved
    // performance on a larger range of CPUs.
    const bpModel = await bodyPix.load({
        architecture: 'MobileNetV1',
        outputStride: 16,
        multiplier: 0.50,
        quantBytes: 2
    });

    return new StreamBlurEffect(bpModel);
}
