import React from 'react';
import Grid from '@material-ui/core/Grid';
import ContactPreview from './ContactPreview';
import InviteStatusChip from './InviteStatusChip';

const InviteLine = (props) => {
  const { contactData, inviteData } = props;
  return (
    <Grid container>
      <Grid item xs={8}>
        <ContactPreview
          contactData={{
            ...contactData,
            member_id: contactData.invite_member_id,
          }}
          isShowAvatar
        />
      </Grid>
      <Grid item xs={4}>
        <InviteStatusChip inviteData={inviteData} />
      </Grid>
    </Grid>
  );
};

export default InviteLine;
