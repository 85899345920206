import React from 'react';
import GooglePlaceTypeAutocomplete from './GooglePlaceTypeAutocomplete';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import StreetAutocomplete from '../../StreetAutocomplete';
// import { GPLACES_TYPES } from '../../../utils/calendar';

const FindInMap = (props) => {
  const { street, handleChange, onPlaceTypeChange, placeType } = props;

  return (
    <Grid container item xs={12}>
      <Grid item xs={3}>
        <GooglePlaceTypeAutocomplete
          placeType={placeType}
          onSelectPlaceType={onPlaceTypeChange}
        />
      </Grid>
      <Grid item xs={9}>
        <StreetAutocomplete
          label="Location/Postal code"
          street={street}
          types={['geocode', 'establishment']}
          handleChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  country_code: state.member.memberInfo.country_code,
});

export default connect(mapStateToProps)(FindInMap);
