import React, { useState } from 'react';
import FullScreenMailPreview from './FullScreenMailPreview';
import MailPreview from './MailPreview';
import { useMailTableStyles } from './MailTableStyles';

import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';

export const MailTable = ({
  list,
  selectEmail,
  section,
  selectedEmail,
  selectedEmails,
  handlers,
  member,
}) => {
  const classes = useMailTableStyles();

  const defaultPerPageCount = 5;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPerPageCount);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getItems = () => {
    return list
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((email, index) => {
        const isOwnEmail =
          section === 'Sent' ? true : email.sender_mail === member.email;

        return section === 'Draft' ? (
          <FullScreenMailPreview
            key={`${index}-draft`}
            index={index}
            email={email}
            handlers={handlers}
            section={section}
            selectEmail={selectEmail}
            selectedEmail={selectedEmail}
            selectedEmails={selectedEmails}
            isOwnEmail={isOwnEmail}
          />
        ) : (
          <MailPreview
            email={email}
            section={section}
            selectEmail={selectEmail}
            selectedEmail={selectedEmail}
            selectedEmails={selectedEmails}
            isOwnEmail={isOwnEmail}
          />
        );
      });
  };

  // const getOffset =
  //   section === 'Draft' ? 'calc(100vh - 300px)' : 'calc(100vh - 340px)';
  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                // indeterminate={numSelected > 0 && numSelected < rowCount}
                // checked={rowCount > 0 && numSelected === rowCount}
                // onChange={onSelectAllClick}
                // inputProps={{ 'aria-label': 'select all desserts' }}
                />
              </TableCell>
              <TableCell>To/From</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Message blurb</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>{list && getItems()}</TableBody>
        </Table>
      </TableContainer>

      {list.length > defaultPerPageCount && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};
