import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import ConfirmDialog from '../../components/ConfirmDialog';
import MaterialAvatar from '../../components/MaterialAvatar';
import EventDeclineComments from './EventDeclineComments';
import { setEventInviteStatus } from '../../redux/actions/event';
import { getScheduleEvent } from '../../redux/actions/schedule_event';
import { setSnackbarData } from '../../redux/actions/snackbar';
import {
  calcEventDuration,
  eventDisplayName,
  getMonthLimits,
  EVENT_INVITE_STATUS,
  CALENDAR_VIEWS,
  parseFromStr,
  getParams,
} from '../../utils/calendar';
import { displayableFullName } from '../../utils/contact';

const EventCard = ({ data, type, showDetail }) => {
  const dispatch = useDispatch();
  const memberId = useSelector((state) => state.member.member.member_id);

  const [open, setOpen] = useState(false);
  const [declineComments, setDeclineComments] = useState('');

  console.debug(data, 'data from invitations card', type);
  const title = 'Event Invite';

  const handleClose = () => {
    setOpen(false);
    setDeclineComments('');
  };

  const submit = async (reaction) => {
    let status, comment;

    if (reaction === 'accept') {
      status = EVENT_INVITE_STATUS.accepted;
      comment = null;
    } else if (reaction === 'decline') {
      status = EVENT_INVITE_STATUS.declined;
      comment = declineComments;
    }

    const result = dispatch(setEventInviteStatus(data.id, status, comment));

    if (result) {
      dispatch(
        setSnackbarData({
          open: true,
          message: `Successfully ${status.toLocaleLowerCase()} event invitation`,
          type: 'success',
        })
      );

      // Fetch event for current month
      const params = getParams(
        CALENDAR_VIEWS.month,
        getMonthLimits(parseFromStr(data.start))
      );
      dispatch(getScheduleEvent(params));
    } else {
      dispatch(
        setSnackbarData({
          open: true,
          message: `Failed to ${reaction} event invitation`,
          type: 'error',
        })
      );
    }
    setOpen(false);
  };

  const name = !!data.first_name ? `${displayableFullName(data)}` : null;

  let event_name = eventDisplayName(data, memberId);

  event_name = `${name} is inviting you to ${eventDisplayName(data, memberId)}`;

  const duration = calcEventDuration(parseISO(data.start), parseISO(data.end));

  console.debug(`Duration: ${duration}`);

  return (
    <Card className={'invitation-card'}>
      <CardHeader
        className="card-title"
        title={title}
        action={
          <span>{`${format(new Date(data.start), 'p')} ${format(
            new Date(data.start),
            'PPP'
          )} `}</span>
        }
      />
      <CardHeader
        className="card-profile-header"
        avatar={
          <MaterialAvatar
            firstName={data.first_name}
            lastName={data.last_name}
            src={data.amera_avatar_url}
            isBasicAvatar={false}
          />
        }
        title={
          <span>
            <b>{'From:'}</b> {name}
          </span>
        }
        subheader={event_name}
      />
      <CardContent>
        <List dense={true}></List>
      </CardContent>
      <CardActions disableSpacing>
        <div className="btn-container">
          <Button
            variant="contained"
            color="secondary"
            aria-label="Cancel"
            className="card_btn cancel_btn"
            startIcon={<CancelIcon />}
            onClick={() => setOpen(true)}
          >
            Decline
          </Button>
        </div>
        <div className="btn-container">
          <Button
            variant="contained"
            color="secondary"
            aria-label="Confirm"
            className="card_btn confirm_btn"
            startIcon={<CheckBoxIcon />}
            onClick={() => submit('accept')}
          >
            Accept
          </Button>
        </div>
        <ConfirmDialog
          open={open}
          title="Confirm"
          onOk={() => submit('decline')}
          content="Are you sure you want to decline this event invitation?"
          onCancel={handleClose}
        >
          <EventDeclineComments
            value={declineComments}
            onInput={(v) => setDeclineComments(v)}
          />
        </ConfirmDialog>
      </CardActions>
    </Card>
  );
};

export default EventCard;
