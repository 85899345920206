import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import TimerIcon from '@material-ui/icons/Timer';
import { format } from 'date-fns';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';

import { getUnstyledHtmlBody } from '../../utils/email';
import { capitalize } from 'lodash';

const Notifications = (props) => {
  let history = useHistory();
  const { notifications } = props;

  const handleClick = (type, item) => {
    if (type === 'mails') {
      const mail_id =
        (item && item.request_data && item.request_data.mail_id) || null;
      if (mail_id) {
        history.push({
          pathname: 'mail/inbox',
          search: `?mail_id=${mail_id}`,
        });
      }
    } else if (type === 'sessions' || type === 'alerts') {
      const tabIndex = 1;
      history.push({
        pathname: '/statistic',
        search: `?tab_index=${tabIndex}`,
      });
    }
  };

  const NotificationCard = ({ notification }) => {
    const noOfItems = (notification.data && notification.data.length) || 0;
    return (
      <div className={`notification-wrapper ${notification.type}`}>
        <Card>
          <CardHeader
            title={
              notification.type === 'alerts'
                ? 'Security Alerts'
                : notification.type === 'invitations'
                ? 'Invitations'
                : notification.type === 'mails'
                ? 'Messages'
                : notification.type === 'sessions'
                ? 'Your Activity'
                : 'Group Activity'
            }
            subheader={
              notification.type === 'alerts'
                ? noOfItems < 1
                  ? 'No Alerts'
                  : `You have ${noOfItems} alert${
                      noOfItems > 1 ? 's' : ''
                    } that require action`
                : notification.type === 'invitations'
                ? noOfItems < 1
                  ? 'No Invitations'
                  : `You have ${noOfItems} invitation${
                      noOfItems > 1 ? 's' : ''
                    }`
                : notification.type === 'mails'
                ? noOfItems < 1
                  ? 'No Messages'
                  : `You have ${noOfItems} new message${
                      noOfItems > 1 ? 's' : ''
                    }`
                : notification.type === 'sessions'
                ? noOfItems < 1
                  ? 'No Activity'
                  : `You have ${noOfItems} ${
                      noOfItems > 1 ? 'activities' : 'activity'
                    }`
                : noOfItems < 1
                ? 'No Activities'
                : `You have ${noOfItems} new group notification${
                    noOfItems > 1 ? 's' : ''
                  }`
            }
          />
          {noOfItems > 0 ? (
            <CardContent>
              <List dense={true}>
                {notification.data.map((item, index) => {
                  let message = item.message;
                  let message2 = null;
                  let name = item.first_name
                    ? `${capitalize(item.first_name)} ${capitalize(
                        item.last_name
                      )}`
                    : null;
                  if (notification.type === 'mails') {
                    message = `${name}: ${getUnstyledHtmlBody(
                      item.request_data.subject
                    )}`;
                    message2 = `.`;
                  } else if (notification.type === 'invitations') {
                    if (item.invitation_type === 'add-contact') {
                      message = `${name} added you to their contact list.`;
                    } else if (item.invitation_type === 'create-group') {
                      message = `${name} added you to a new group ${item.request_params.name}.`;
                    } else if (item.invitation_type === 'add-group-member') {
                      message = `${name} added you to the ${item.request_params.name} group.`;
                    }
                  } else if (
                    notification.type === 'sessions' ||
                    notification.type === 'alerts'
                  ) {
                    message = `Login from ${item.city ? item.city : ''}${
                      item.region ? ', ' + item.region : ''
                    }${item.country_code ? ' ' + item.country_code : ''}`;
                    message2 = `${item.device ? item.device : ''}${
                      item.organization_name
                        ? ', ' + item.organization_name
                        : ''
                    }`;
                  }
                  return (
                    <div
                      className={`notification-item notification-type-${notification.type}`}
                      key={index}
                    >
                      <ListItem>
                        <ListItemIcon>
                          <div className="shape-circle" />
                        </ListItemIcon>
                        <ListItemText
                          className={`message`}
                          primary={message}
                          secondary={message2 ? message2 : null}
                        />
                        <div className="time">
                          <ListItemIcon>
                            <TimerIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={format(
                              new Date(item.create_date),
                              'Pp'
                            ).replace(',', '')}
                          />
                        </div>
                        <ListItemSecondaryAction className="notification-action">
                          <Tooltip title="action" placement="left" arrow>
                            <IconButton
                              edge="end"
                              aria-label="action"
                              onClick={() =>
                                handleClick(notification.type, item)
                              }
                            >
                              <PlayCircleFilledRoundedIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </div>
                  );
                })}
              </List>
            </CardContent>
          ) : (
            <div className="empty-icon-container">
              <DraftsOutlinedIcon className="empty-icon" />
            </div>
          )}
        </Card>
      </div>
    );
  };
  return notifications.map((notification) => {
    return (
      <NotificationCard key={notification.id} notification={notification} />
    );
  });
};

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
};

Notifications.defaultProps = {
  notifications: [],
};

const mapStateToProps = (state) => ({
  notifications: state.event.notifications,
});

export default connect(mapStateToProps)(Notifications);
