import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
// import FilterListIcon from '@material-ui/icons/FilterList';
import MaterialAvatar from '../../components/MaterialAvatar';

import SortBySettingsModal from '../../components/Modal/SortBySettingsModal';

import {
  loadContactMembers,
  addMemberToContact,
} from '../../redux/actions/contact';
import { displayableFullName } from '../../utils/contact';

// const descendingComparator = (a, b, orderBy) => {
//   if (b[orderBy] && a[orderBy]) {
//     if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
//       return -1;
//     }
//     if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
//       return 1;
//     }
//   }
//   return 0;
// };

// const getComparator = (order, orderBy) => {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// };

// const tableSort = (array, comparator) => {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// };

const headCells = [
  {
    id: 'first_name',
    label: 'Name',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'company',
    label: 'Company',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = (props) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '8px 16px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  sortTool: {
    marginBottom: '16px',
    minWidth: '120px',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { dispatch, numSelected } = props;

  // const handleSortMember = (event) => {
  //   const sortKey = event.target.value;
  //   setSort(sortKey);
  // const data = members.slice(0);
  // const newMembers = sortContact(data, sortKey);
  // dispatch(resetContactMembers(newMembers));
  // };

  // const sortContact = (data, sortkey) => {
  //   return data.sort((a, b) =>
  //     a[sortkey].toLowerCase() > b[sortkey].toLowerCase()
  //       ? 1
  //       : b[sortkey].toLowerCase() > a[sortkey].toLowerCase()
  //       ? -1
  //       : 0
  //   );
  // };

  const handleAddMemberToContact = () => {
    console.log(
      'Selected items upon click on Add to contact list',
      numSelected
    );
    const formData = new FormData();
    formData.set('member_id_list', numSelected);
    formData.set('type', 'add-contact');
    dispatch(addMemberToContact(formData));
  };
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected.length > 0,
      })}
    >
      {numSelected.length > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected.length} {numSelected.length > 1 ? 'members' : 'member'}{' '}
          selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          Please select member(s) to request to be added to your contacts
        </Typography>
      )}

      {numSelected.length > 0 ? (
        <Tooltip title="Add to my contacts">
          <IconButton
            aria-label="Add to my contacts"
            onClick={handleAddMemberToContact}
          >
            Add to my contacts
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          {/* <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  dispatch: PropTypes.func,
  numSelected: PropTypes.arrayOf(PropTypes.number),
  members: PropTypes.arrayOf(PropTypes.object),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ContactMembersTable = (props) => {
  const {
    loading,
    dispatch,
    contactMembers,
    showContactsSetting,
    setShowContactsSetting,
  } = props;

  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('first_name');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    dispatch(loadContactMembers());
  }, [dispatch]);

  useEffect(() => {
    setSelected([]);
  }, [contactMembers]);

  const loadSortedContactMembers = (property, order) => {
    let sortItems = '';
    if (property === 'member_name') {
      sortItems =
        order === 'desc' ? `-first_name,-last_name` : `first_name,last_name`;
    } else {
      sortItems = order === 'desc' ? `-${property}` : `${property}`;
    }

    let params = { type: 'sort', sortItems },
      showAlert = true;
    dispatch(loadContactMembers(params, showAlert));
    setOrderBy(property);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedContactMembers(property, newOrder);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contactMembers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className="contact-table">
      <SortBySettingsModal
        type="contacts"
        loading={loading}
        open={showContactsSetting}
        orderBy={orderBy}
        onClose={() => setShowContactsSetting(false)}
        setOrderBy={setOrderBy}
        onClick={loadSortedContactMembers}
      />
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected}
          dispatch={dispatch}
          orderBy={orderBy}
          setOrder={(order) => setOrder(order)}
        />
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={contactMembers.length}
            />
            <TableBody>
              {/* {tableSort(contactMembers, getComparator(order, orderBy)) */}
              {contactMembers
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="contact-member-name"
                      >
                        <div>
                          <MaterialAvatar
                            member_id={row.id}
                            src={row.amera_avatar_url}
                            firstName={row.first_name}
                            lastName={row.last_name}
                            classes="contact"
                          />
                        </div>
                        <p>{displayableFullName(row)}</p>
                      </TableCell>
                      <TableCell align="left">{row.company}</TableCell>
                      <TableCell align="left">{row.title}</TableCell>
                      <TableCell align="left">{row.country}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {contactMembers.length > 25 && (
          <div className="card-pagination contact-cards-pagination">
            <div className="item-per-page">
              <FormControl>
                <Select
                  labelId="item-count-per-page-select-label"
                  id="item-count-per-page-select"
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={75}>75</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
              <p className="item-count-text">Cards Per Page</p>
            </div>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={parseInt(Math.ceil(contactMembers.length / rowsPerPage))}
              page={page}
              onChange={handleChangePage}
              defaultPage={1}
              color="primary"
              showFirstButton
              showLastButton
            />
          </div>
        )}
      </Paper>
    </div>
  );
};

ContactMembersTable.propTypes = {
  contactMembers: PropTypes.arrayOf(PropTypes.object),
};

ContactMembersTable.defaultProps = {
  contactMembers: [],
};

const mapStateToProps = (state) => ({
  loading: state.contact.loading,
  contactMembers: state.contact.contactMembers,
});

export default connect(mapStateToProps)(ContactMembersTable);
