import {
  SET_CALL_PARTNER,
  SET_LOCAL_DEVICES,
  SET_LOCAL_STREAM,
  SET_REMOTE_DEVICES,
  SET_REMOTE_STREAM,
  SET_ENCRYPT_ENABLED,
  SET_CURRENT_KEY,
  SET_LOCAL_SELECTED,
  SET_CALL_STARTED,
  SET_HAS_ECHO_CANCELLATION,
  SET_LOCAL_AUDIO_ENABLED,
  SET_LOCAL_VIDEO_ENABLED,
  SET_REMOTE_ENCRYPT_ENABLED,
  SET_USER_LIST,
  SIGNALING_SERVER_LOGIN,
  SET_CALL_MEMBER_ID,
  SET_MEMBER_INFO,
  CLEAR_CALL,
  SET_CALL_ENDED_BY_REMOTE,
  SET_ENCRYPTION_AVAILABLE,
} from '../actionTypes/one2onevcall';

import { LOCATION_CHANGE } from 'connected-react-router';

import { str2ab } from '../../utils/webcam/webrtc';

import { AmeraKey } from '../../utils/ameraWebCrypto';

// The initial state of the App
export const initialState = {
  partner: '',
  ameraWebrtcClient: null,
  remoteStream: null,
  localStream: null,
  localDevices: [],
  remoteDevices: [],
  localSelected: null,
  encryptEnabled: false,
  currentKey: AmeraKey,
  localAudioEnabled: true,
  localVideoEnabled: true,
  hasEchoCancellation: true,
  remoteEncryptEnabled: false,
  binaryString: '',
  userList: [],
  partnerInfo: null,
  member_id: null,
  memberInfo: null,
  login: false,
  callEndedByRemote: false,
  encryptionAvailable: true,
};

const one2onevcall = (state = initialState, action) => {
  switch (action.type) {
    case SET_CALL_PARTNER:
      return {
        ...state,
        partner: action.payload.partner.email,
        partnerInfo: action.payload.partner,
      };
    case SET_LOCAL_STREAM:
      if (state.localStream) {
        state.localStream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      return {
        ...state,
        localStream: action.payload.localStream,
      };
    case SET_LOCAL_DEVICES:
      return {
        ...state,
        localDevices: action.payload.localDevices,
      };
    case SET_REMOTE_STREAM:
      return {
        ...state,
        remoteStream: action.payload.remoteStream,
      };
    case SET_REMOTE_DEVICES:
      return {
        ...state,
        remoteDevices: action.payload.remoteDevices,
      };
    case SET_ENCRYPT_ENABLED:
      return {
        ...state,
        encryptEnabled: action.payload.encryptEnabled,
      };
    case SET_CURRENT_KEY:
      let byteArray = new Uint8Array(str2ab(action.payload.currentKey));
      let binaryString = '';
      let binaryTemplate = '00000000';
      let binaryTemp = '';

      for (let x = 0; x < byteArray.length; x++) {
        binaryTemp = byteArray[x].toString(2);
        binaryString += binaryTemplate.substr(binaryTemp.length) + binaryTemp;
      }

      return {
        ...state,
        currentKey: action.payload.currentKey,
        binaryString,
      };
    case SET_LOCAL_SELECTED:
      return {
        ...state,
        localSelected: action.payload.localSelected,
      };
    case SET_CALL_STARTED:
      return {
        ...state,
        callStarted: action.payload.callStarted,
      };
    case SET_HAS_ECHO_CANCELLATION:
      return {
        ...state,
        hasEchoCancellation: action.payload.hasEchoCancellation,
      };
    case SET_LOCAL_AUDIO_ENABLED:
      return {
        ...state,
        localAudioEnabled: action.payload.localAudioEnabled,
      };
    case SET_LOCAL_VIDEO_ENABLED:
      return {
        ...state,
        localVideoEnabled: action.payload.localVideoEnabled,
      };
    case SET_REMOTE_ENCRYPT_ENABLED:
      return {
        ...state,
        remoteEncryptEnabled: action.payload.remoteEncryptEnabled,
      };
    case SET_USER_LIST:
      return {
        ...state,
        userList: action.payload.userList,
      };
    case SIGNALING_SERVER_LOGIN:
      return {
        ...state,
        login: action.payload.login,
      };
    case SET_CALL_MEMBER_ID:
      if (action.payload.member_id) {
        return {
          ...state,
          member_id: action.payload.member_id,
        };
      } else {
        return {
          ...state,
          member_id: action.payload.member_id,
          memberInfo: null,
        };
      }
    case SET_MEMBER_INFO:
      return {
        ...state,
        memberInfo: action.payload,
      };
    case LOCATION_CHANGE:
      return state;
    case CLEAR_CALL:
      if (state.ameraWebrtcClient) {
        if (state.ameraWebrtcClient.connection) {
          state.ameraWebrtcClient.handleClose(false);
        }
        if (state.ameraWebrtcClient.ws) {
          state.ameraWebrtcClient.ws.close();
          state.ameraWebrtcClient.ws = null;
        }
      }
      if (state.localStream) {
        console.log('track stop');
        state.localStream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      return {
        ...state,
        localStream: null,
        remoteStream: null,
        login: false,
        ameraWebrtcClient: null,
      };
    case SET_CALL_ENDED_BY_REMOTE:
      return {
        ...state,
        callEndedByRemote: action.payload.callEndedByRemote,
      };
    case SET_ENCRYPTION_AVAILABLE:
      return {
        ...state,
        encryptionAvailable: action.payload.encryptionAvailable,
      };
    default:
      return state;
  }
};

export default one2onevcall;
