import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { getTimeLimits } from '../../../utils/calendar';
import { isEqual, roundToNearestMinutes, format } from 'date-fns';

const DropDownTImeInput = (props) => {
  const { date, onChange, label, stepMinutes = 15 } = props;

  const options = getTimeLimits(date, stepMinutes);

  return (
    <Autocomplete
      getOptionSelected={(o, v) => {
        return isEqual(
          roundToNearestMinutes(o.value),
          roundToNearestMinutes(v.value)
        );
      }}
      id={label}
      fullWidth
      disableClearable
      options={options}
      getOptionLabel={(o) => {
        return o.str;
      }}
      renderOption={(o) => <Typography variant="body2">{o.str}</Typography>}
      onChange={(e, o) => onChange(o.value)}
      value={{ value: date, str: format(date, 'p') }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default DropDownTImeInput;
