import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// Pages
import SignUp from './pages/SignUp';
import Registration from './pages/Registration';
import Login from './pages/Login';
import ForgotPassword from './pages/PasswordUpdate/ForgotPassword';
import ChangePassword from './pages/PasswordUpdate/ChangePassword';
import Dashboard from './pages/Dashboard';
import FileShare from './pages/FileShare';
import Contact from './pages/Contact';
import Group from './pages/Group';
import Chat from './pages/Chat';
import Activity from './pages/Activity';
import Home from './pages/Home';
import Email from './pages/Email';
import One2OneVCall from './pages/One2OneVCall';
import VideoCall from './pages/VideoCall';
import Layout from './pages/Sections/Layout';
import {
  getMember,
  getTitleList,
  getDepartmentList,
  getCountryList,
  getTZList,
  getRoleList,
  // getTermsAndConditions,
} from './redux/actions/member';

import Calendar from './pages/Calendar';
import { nonBrowserFeatureState } from './utils/non-browser-state';
import ResetPassword from './pages/PasswordUpdate/ResetPassword';
import useQuery from './hooks/useQuery';
import Countdown from './components/Countdown';

const MainRouter = (props) => {
  const dispatch = props.dispatch;
  const history = useHistory();

  useEffect(() =>
    // Load the job title list to from back-end
    {
      dispatch(getTitleList());
    }, [dispatch]);

  useEffect(() =>
    // Load the departments list
    {
      dispatch(getDepartmentList());
    }, [dispatch]);

  useEffect(() =>
    // Load the active country list
    {
      dispatch(getCountryList());
    }, [dispatch]);

  useEffect(() => {
    // Load timezones data
    dispatch(getTZList());
  }, [dispatch]);

  useEffect(() => {
    // Load roles list
    dispatch(getRoleList());
  }, [dispatch]);

  // useEffect(() =>
  //   // Load the terms and conditions
  //   {
  //     dispatch(getTermsAndConditions());
  //   }, [dispatch]);

  useEffect(() => {
    dispatch(getMember());
  }, [dispatch]);

  const redirectTo = nonBrowserFeatureState
    ? `${process.env.PUBLIC_URL}/login`
    : `${process.env.PUBLIC_URL}/home`;

  let query = useQuery();

  let matchNonAuthRoutes = [
    '/login',
    '/home',
    '/signup',
    '/registration',
    '/settings',
  ].filter((route) => history.location.pathname.startsWith(route));
  let showInPage = matchNonAuthRoutes.length === 0;

  const { member, showCountdown } = props;
  let end = new Date();
  if (member && member.expiration_date !== undefined) {
    end = new Date(member.expiration_date);
  } else {
    showInPage = false;
  }

  return (
    <>
      <Switch>
        {/* PUBLIC PAGES */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          component={Login}
        />
        <Route exact path={`${process.env.PUBLIC_URL}/home`} component={Home} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/signup`}
          component={SignUp}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/registration/:inviteKey?`}
          component={Registration}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login/forgot`}
          component={ForgotPassword}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login/reset/:forgot_key`}
          component={ResetPassword}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/settings/password`}
          component={ChangePassword}
        />
        <Redirect from={`${process.env.PUBLIC_URL}/`} to={redirectTo} exact />
        {/* AUTH PAGES */}
        <Route
          path={`${process.env.PUBLIC_URL}/dashboard`}
          component={Dashboard}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/fileshare`}
          component={FileShare}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/contacts/call/video/:contact_id`}
          component={One2OneVCall}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/groups/call/video/:room`}
          component={VideoCall}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/contacts`}
          component={Contact}
        />
        <Route path={`${process.env.PUBLIC_URL}/groups`} component={Group} />
        <Route path={`${process.env.PUBLIC_URL}/chat`} component={Chat} />

        <Route
          path={`${process.env.PUBLIC_URL}/calendar`}
          component={() => (
            <Calendar
              {...props}
              group_id={query.get('group_id')}
              member_ids={query.get('member_ids')}
            />
          )}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/statistic`}
          component={Activity}
        />
        <Route path={`${process.env.PUBLIC_URL}/mail`} component={Email} />
        <Route path="/" component={Layout} />
      </Switch>
      {showInPage && <Countdown end={end} show={showCountdown} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  member: state.member.member,
  showCountdown: state.member.showCountdown,
});

export default connect(mapStateToProps)(MainRouter);
