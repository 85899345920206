import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { EVENT_TYPES } from '../../../utils/calendar';

const EventURLInput = (props) => {
  const { meetingType, locationData, onInputChange, error } = props;
  return (
    <TextField
      label={
        meetingType === EVENT_TYPES.video
          ? 'AMERA Conference URL'
          : 'AMERA Webinar URL'
      }
      type="url"
      error={!!error}
      helperText={!!error ? error : ''}
      fullWidth
      required
      value={locationData}
      onChange={(e) => onInputChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment postion="end">
            <IconButton onClick={() => onInputChange('')}>
              <ClearRoundedIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default EventURLInput;
