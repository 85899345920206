import React from 'react';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

import MaterialAvatar from '../../../../../../components/MaterialAvatar';
import { textCapitalized } from '../../../../../../utils/general';

function Conversation({ conversation, selectedConversationId, getMessages }) {
  return (
    <ListItem
      key={conversation.id}
      button
      disableRipple
      alignItems="flex-start"
      className="conversation-item"
      selected={conversation.id === selectedConversationId}
      onClick={() => getMessages(conversation.id)}
    >
      <ListItemAvatar>
        <MaterialAvatar
          badgeType="online"
          badgeVariant="dot"
          classes="conversation-profile-avatar"
          src={conversation.amera_avatar_url}
          firstName={conversation.first_name}
          lastName={conversation.last_name}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          textCapitalized(conversation.first_name) +
          ' ' +
          textCapitalized(conversation.last_name)
        }
        secondary={conversation.latestMessageText}
      />
      <div className="conversation-time">
        <div className="conversation-time-icon">
          <WatchLaterIcon />
        </div>
        <div className="conversation-time-text">
          {moment(new Date()).format('hh:mm A')}
        </div>
      </div>
    </ListItem>
  );
}

export default Conversation;
