import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import MethodTypeSelector from '../../PhoneList/PhoneListItem/MethodTypeSelector';
import ListDeleteButton from '../../ListDeleteButton';
const EmailListItem = (props) => {
  const {
    errors,
    isPrimary,
    emailData,
    // emailCount,
    handleChange,
    handleDelete,
  } = props;

  // const handlePrimaryCheck = (event) => {
  //   handleChange({
  //     id: id,
  //     name: 'primaryEmailId',
  //     value: event.target.checked,
  //   });
  // };

  const handleDescriptionInput = (event) => {
    handleChange({ id: id, name: 'description', value: event.target.value });
  };

  const handleEmailInput = (event) => {
    handleChange({ id: id, name: 'email', value: event.target.value });
  };
  const handleMethodSelect = (event) => {
    handleChange({ id: id, name: 'method', value: event.target.value });
  };

  let emailError,
    descriptionError = false;
  if (errors) {
    emailError = errors.email ? errors.email : null;
    descriptionError = errors.description ? errors.description : null;
  }

  const { id, description, method, email } = emailData;
  return (
    <ListItem id={id} key={id} className="item">
      <div className="item-text email">
        <TextField
          // id="phone_description"
          // id={id}
          disabled={isPrimary}
          error={Boolean(descriptionError)}
          name="description"
          className="description"
          label="Description"
          value={description}
          onChange={handleDescriptionInput}
          helperText={descriptionError}
        />
        {/* Email imput */}
        <TextField
          error={Boolean(emailError)}
          disabled={isPrimary}
          type="email"
          name="email"
          label="E-mail"
          placeholder="example@example.com"
          onChange={handleEmailInput}
          value={email}
          helperText={emailError}
        />
        {/* Method type */}
        <MethodTypeSelector
          disabled={isPrimary}
          id={id}
          deviceClass="email"
          method={method}
          handleChange={handleMethodSelect}
        />
      </div>
      <ListItemSecondaryAction>
        <ListDeleteButton
          id={id}
          handleDelete={handleDelete}
          isDisabled={isPrimary}
          listContext="email"
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default EmailListItem;
