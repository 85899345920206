import React from 'react';
import List from '@material-ui/core/List';
import EmailListItem from './EmailListItem/EmailListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';

const EmailList = (props) => {
  const {
    errors,
    primaryEmailId,
    emailItems,
    handleChange,
    handleDelete,
    handleAdd,
  } = props;
  return (
    <div className="phone-list-container">
      <List
        className="phone-list"
        subheader={
          <ListSubheader disableSticky className="list-header">
            Email list
          </ListSubheader>
        }
      >
        {emailItems && emailItems.length > 0 ? (
          emailItems.map((ei) => (
            <EmailListItem
              errors={errors ? errors[ei.id] : null}
              key={ei.id}
              isPrimary={ei.id === primaryEmailId}
              emailCount={emailItems.length}
              emailData={ei}
              handleChange={handleChange}
              handleDelete={handleDelete}
            />
          ))
        ) : (
          // When no record - show fallback message
          <Typography variant="body2">Please create a record</Typography>
        )}
      </List>
      <Button
        startIcon={<AddCircleIcon />}
        onClick={handleAdd}
        // disabled={true}
        disabled={
          emailItems &&
          emailItems.length === parseInt(process.env.REACT_APP_MAX_EMAILS)
        }
      >
        Add
      </Button>
    </div>
  );
};

export default EmailList;
