import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '../../components/Modal/modal';
import PicturePin from '../../components/Forms/PicturePinForm/PicturePin';
import { updateSecurity, getGroupSecurity } from '../../redux/actions/group';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: 130,
  },
}));

const SecurityDetailModal = (props) => {
  const {
    open,
    loading,
    onClose,
    group_id,
    security,
    disabled,
    group_leader_id,
    member_id,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [securityData, setSecurityData] = React.useState();
  const handleChange = (data) => {
    console.log('P&P input changed', data);
    setSecurityData(data);
  };

  useEffect(() => {
    console.log('group_leader_id haahaha', group_leader_id);
  }, [group_leader_id]);

  useEffect(() => {
    console.log('group_id', group_id);
    if (group_id && open) {
      dispatch(getGroupSecurity(group_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, group_id]);

  const handleSubmit = async () => {
    let formData = new FormData();
    if (securityData.picture) formData.append('picture', securityData.picture);
    formData.append('pin', securityData.pin);
    formData.append('exchange_option', securityData.exchangeOption);
    await dispatch(updateSecurity(formData, group_id));
    onClose();
  };

  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.actionBtn}
          onClick={onClose}
        >
          {disabled ? 'Close' : 'Cancel'}
        </Button>
        {loading ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            disabled
          >
            ...
          </Button>
        ) : (
          !disabled && (
            <Button
              variant="contained"
              color="primary"
              className={classes.actionBtn}
              disableRipple
              disabled={
                disabled
                  ? true
                  : securityData
                  ? !securityData.isValidated
                  : true
              }
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )
        )}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Group Security Details"
      ModalContent={() => (
        <PicturePin
          isExchange
          onChange={handleChange}
          security={security}
          disable={
            group_leader_id && group_leader_id !== member_id ? true : false
          }
          disabled={disabled}
          hidePinPicture={
            group_leader_id &&
            group_leader_id !== member_id &&
            security &&
            (security.exchange_option === 'MOST_SECURE' ||
              security.exchange_option === 'VERY_SECURE' ||
              security.exchange_option === 'SECURE')
              ? true
              : false
          }
        />
      )}
      ModalAction={ModalAction}
      className="modal-contact-security-details"
    />
  );
};

SecurityDetailModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  group_id: PropTypes.number,
  group_leader_id: PropTypes.number,
  member_id: PropTypes.number,
};

SecurityDetailModal.defaultProps = {
  open: false,
  loading: false,
};

const mapStateToProps = (state) => ({
  loading: state.group.getGroupListLoading,
  security: state.group.security,
  member_id: state.member.member.member_id,
});

export default connect(mapStateToProps)(SecurityDetailModal);
