import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Message from './Message';
import MessageDivider from './MessageDivider';
import ConversationEmpty from './ConversationEmpty';
import {
  groupMessagesByDate,
  getMessagesDividerDate,
} from '../../../../utils/chat';

import MessageSkeleton from './MessageSkeleton';

function ChatMessages(props) {
  const {
    isGroupChat,
    emptyMessage,
    memberInfo,
    messages,
    conversations,
    selectedConversationId,
    messagesEnfRef,
    loadingMessages,
  } = props;
  const { first_name, last_name, amera_avatar_url } = memberInfo;
  const [messagesGroupedByDate, setMessagesGroupedByDate] = useState([]);

  let messageItems = null;

  useEffect(() => {
    if (messages && messages.length > 0) {
      setMessagesGroupedByDate(groupMessagesByDate(messages));
    } else {
      setMessagesGroupedByDate({});
    }
  }, [messages]);

  if (loadingMessages) {
    messageItems = [...Array(10).keys()].map((num) => (
      <MessageSkeleton key={num} num={num} />
    ));
  } else if (
    (messages && messages.length > 0) ||
    (messagesGroupedByDate && Object.keys(messagesGroupedByDate).length > 0)
  ) {
    messageItems = Object.keys(messagesGroupedByDate).map((date) => {
      return (
        <React.Fragment key={date}>
          {messagesGroupedByDate[date].map((message) => {
            const { isMyMessage } = message;
            return (
              <Message
                key={message.id}
                isMyMessage={isMyMessage}
                member_id={message.member_id}
                firstName={isMyMessage ? first_name : message.first_name}
                lastName={isMyMessage ? last_name : message.last_name}
                createdAt={message.createdAt}
                avatarUrl={
                  isMyMessage ? amera_avatar_url : message.amera_avatar_url
                }
                imageAlt={message.imageAlt}
                messageText={message.messageText}
              />
            );
          })}
          <ListItem key={date}>
            <MessageDivider key={date}>
              {getMessagesDividerDate(date)}
            </MessageDivider>
          </ListItem>
        </React.Fragment>
      );
    });
  } else {
    const conversation = conversations.filter(
      (c) => c.id === selectedConversationId
    );
    let contactFirstName = '',
      contactLastName = '',
      contactAvatarURL = '';
    if (conversation && conversation[0]) {
      contactFirstName = conversation[0].first_name;
      contactLastName = conversation[0].last_name;
      contactAvatarURL = conversation[0].amera_avatar_url;
    }
    messageItems = (
      <ConversationEmpty
        isGroupChat={isGroupChat}
        emptyMessage={emptyMessage}
        contactFirstName={contactFirstName}
        contactLastName={contactLastName}
        contactAvatarURL={contactAvatarURL}
        memberFirstName={first_name}
        memberLastName={last_name}
        memberAvatarURL={amera_avatar_url}
      />
    );
  }

  return (
    <List
      className="chat-messages"
      style={{ overflowY: loadingMessages ? 'hidden' : 'auto' }}
    >
      <div ref={messagesEnfRef} />
      {messageItems}
    </List>
  );
}

ChatMessages.defaultProps = {
  isGroupChat: false,
  emptyMessage: `This is the very beginning of your direct message history. 
                        Only the two of you are in this conversation, 
                            and no one else can join it.`,
};

const mapStateToProps = (state) => ({
  memberInfo: state.member.memberInfo,
  messages: state.chat.messages,
  conversations: state.chat.conversations,
  selectedConversationId: state.chat.selectedConversationId,
  loadingMessages: state.chat.loadingMessages,
});

export default connect(mapStateToProps)(ChatMessages);
