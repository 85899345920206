import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import SlideshowIcon from '@material-ui/icons/Slideshow';

const EmptyCard = ({ type, message }) => {
  return (
    <Card className="empty-card">
      {type === 'mail' && <DraftsOutlinedIcon className="empty-card-icon" />}
      {type === 'invitation' && (
        <InsertInvitationIcon className="empty-card-icon" />
      )}
      {type === 'market' && <SlideshowIcon className="empty-card-icon" />}
      {message && <CardHeader className="card-message" title={message} />}
    </Card>
  );
};

export default EmptyCard;
