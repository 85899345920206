import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import LocationModeSelector from './LocationModeSelector';
import MemberLocationSelect from './MemberLocationSelect';
import StreetAutocomplete from '../../StreetAutocomplete';
import LocationMap from './LocationMap';
import FindInMap from './FindInMap';
import FormHelperText from '@material-ui/core/FormHelperText';
import usePrevious from '../../../hooks/usePrevious';

import { LOCATION_TYPES, GPLACES_TYPES } from '../../../utils/calendar';
import { isPlaceInList, placeToString } from '../../../utils/country';
import {
  locationObjectToString,
  filterOperationalOpenPlaces,
  locationStringToGoogleQuery,
} from '../../../utils/country';
import { connect } from 'react-redux';

const LocationLine = (props) => {
  const {
    start,
    end,
    locationMode,
    locationData,
    onModeChange,
    onInputChange,
    memberInfo,
  } = props;

  // This is a dummy map https://stackoverflow.com/questions/23460435/get-google-place-details-without-map
  let map = new window.google.maps.Map(document.createElement('div'));
  let placesService = new window.google.maps.places.PlacesService(map);

  const [places, setPlaces] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [error, setError] = useState(null);
  const [googlePlaceType, setGooglePlaceType] = useState(
    GPLACES_TYPES.restaraunt
  );
  const prevGooglePlaceType = usePrevious(googlePlaceType);

  // reset error on mode change
  useEffect(() => {
    setError(null);
  }, [locationMode]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const geocodeAddress = (address) =>
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${locationStringToGoogleQuery(
        address
      )}&sensor=true&language=en&key=${process.env.REACT_APP_GMAPS_API_KEY}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'OK') {
          setError(null);
          return data.results[0];
        } else {
          setError('Could get no location for input');
        }
      })
      .catch((error) => {
        setError('Could get no location for input');
        console.error(error);
      });

  const getSinglePlaceDetails = useCallback(
    (placeId) =>
      placesService.getDetails(
        {
          placeId,
          fields: ['ALL'],
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            console.log('Place', place);
            setPlaces([place]);
            // We set the only place as selected
            setSelectedPlace(place);
          } else {
            console.error(status);
          }
        }
      ),
    [placesService]
  );

  const locationToPlaces = useCallback(
    (location, type = GPLACES_TYPES.restaraunt, RADIUS_METERS = 16000) =>
      placesService.nearbySearch(
        {
          location,
          radius: String(RADIUS_METERS),
          type,
        },
        (places, status) => {
          console.log('places results', places, status);
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const filteredPlaces = filterOperationalOpenPlaces(
              places,
              start,
              end
            );
            setPlaces(filteredPlaces);
            //If the previous selected place is still in the places list, we may want to keep it selected
            if (!isPlaceInList(filteredPlaces, selectedPlace)) {
              setSelectedPlace(null);
            }

            setSelectedPlace(null);
          } else {
            setPlaces(null);
            setSelectedPlace(null);
            setError('No places matching request');
          }
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [end, start]
  );

  const addressToPlace = useCallback(
    async (address) => {
      try {
        const { place_id } = await geocodeAddress(address);
        if (!!place_id) {
          getSinglePlaceDetails(place_id);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [geocodeAddress, getSinglePlaceDetails]
  );

  const boundsToNearby = async (bounds, type) => {
    try {
      if (!!bounds) {
        placesService.nearbySearch(
          {
            bounds,
            type,
          },
          (places, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              console.log('Settings place from bounds', places);
              const filteredPlaces = filterOperationalOpenPlaces(
                places,
                start,
                end
              );
              setPlaces(filteredPlaces);
              //If the previous selected place is still in the places list, we may want to keep it selected
              if (!isPlaceInList(filteredPlaces, selectedPlace)) {
                setSelectedPlace(null);
              }
            } else {
              setPlaces(null);
              setSelectedPlace(null);
              setError('No places matching request');
            }
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addressToNearby = useCallback(
    async (address) => {
      try {
        const location = (await geocodeAddress(address))?.geometry?.location;
        if (!!location) {
          locationToPlaces(location, googlePlaceType);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [googlePlaceType, locationToPlaces]
  );

  useEffect(() => {
    let address = null;

    if (!!locationData) {
      if (
        locationMode === LOCATION_TYPES.lookup ||
        (locationMode === LOCATION_TYPES.find &&
          typeof locationData === 'string')
      ) {
        address = locationData;
      } else if (
        locationMode === LOCATION_TYPES.own &&
        typeof locationData === 'number'
      ) {
        const locationObject = memberInfo.location_information.find(
          (lio) => lio.id === locationData
        );
        address = locationObjectToString(locationObject);
      }
    }

    if (!!address) {
      console.log('will fire for', address);
      if (
        locationMode === LOCATION_TYPES.lookup ||
        locationMode === LOCATION_TYPES.own
      ) {
        addressToPlace(address);
      } else if (locationMode === LOCATION_TYPES.find) {
        if (
          selectedPlace !== null &&
          address === placeToString(selectedPlace) &&
          googlePlaceType === prevGooglePlaceType
        ) {
          console.log('Same selected place');
        } else {
          addressToNearby(address, googlePlaceType);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googlePlaceType, locationData, locationMode]);

  let addressInput;

  const handleAddressInput = (suggestion) => {
    if (!!suggestion) {
      onInputChange(suggestion.description);
    }
  };

  const handleSelectClick = (place) => {
    onInputChange(placeToString(place));
    setSelectedPlace(place);
  };

  if (locationMode === LOCATION_TYPES.own) {
    addressInput = (
      <MemberLocationSelect value={locationData} handleChange={onInputChange} />
    );
  } else if (locationMode === LOCATION_TYPES.lookup) {
    addressInput = (
      <StreetAutocomplete
        street={locationData}
        types={['geocode', 'establishment']}
        handleChange={handleAddressInput}
      />
    );
  } else if (locationMode === LOCATION_TYPES.find) {
    addressInput = (
      <FindInMap
        street={locationData}
        handleChange={handleAddressInput}
        onPlaceTypeChange={(e, o) => setGooglePlaceType(o)}
        placeType={googlePlaceType}
      />
    );
  }

  // In find in map mode, when bounds idled => search nearby by bounds
  const handleBoundsReady = async (bounds) => {
    if (locationMode === LOCATION_TYPES.find && !!bounds) {
      await boundsToNearby(bounds, googlePlaceType);
    }
  };

  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
      direction="row"
      // wrap="nowrap"
      alignItems="flex-end"
    >
      <Grid container item xs={12} alignItems="flex-end">
        <Grid item xs={3}>
          <LocationModeSelector
            value={locationMode}
            handleChange={onModeChange}
          />
        </Grid>
        <Grid item xs={9}>
          {addressInput}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormHelperText error={!!error}>{!!error ? error : ''}</FormHelperText>
      </Grid>
      {!!places && places.length > 0 && (
        <Grid item xs={12}>
          <LocationMap
            places={places}
            selectedPlace={selectedPlace}
            onSelectClick={
              locationMode === LOCATION_TYPES.find ? handleSelectClick : null
            }
            onBoundsReady={handleBoundsReady}
            error={error}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `25rem` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  memberInfo: state.member.memberInfo,
});

export default connect(mapStateToProps)(LocationLine);
