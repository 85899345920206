import { useState, useEffect, useRef } from 'react';
import { Strophe, $msg, $pres } from 'strophe.js';
import 'strophejs-plugin-muc'; //here list of all other plugins https://github.com/strophe/strophejs-plugins/

const server_url = window.apiUrl || `${window.location.origin}`;
const BOSH_SERVICE = server_url + '/http-bind';

const connection = new Strophe.Connection(BOSH_SERVICE);

function useStrophe(props) {
  const {
    ROOM,
    ROOM_SERVER,
    JID,
    PASSWORD,
    nickName,
    debuggingMode,
    scrollToBottom,
  } = props;
  // A list of group chat users
  const isMountedRef = useRef(false);
  const [rosters, setRosters] = useState([]);
  const rostersRef = useRef(); //to access rosters inside callbacks which runs only once
  rostersRef.current = rosters;

  const onConnect = (status) => {
    log('status', status);
    if (status === Strophe.Status.CONNECTING) {
      log('Strophe is connecting.');
      setAlert('Strophe is connecting.');
    } else if (status === Strophe.Status.CONNFAIL) {
      log('Strophe failed to connect.');
      setAlert('Strophe failed to connect.', 'error');
    } else if (status === Strophe.Status.DISCONNECTING) {
      log('Strophe is disconnecting.', isMountedRef.current);
      isMountedRef.current && setAlert('Strophe is disconnecting.', 'warning');
    } else if (status === Strophe.Status.DISCONNECTED) {
      log('Strophe is disconnected.');
      isMountedRef.current && setAlert('Strophe is disconnected.', 'warning');
    } else if (status === Strophe.Status.CONNECTED) {
      log('Strophe connected successfully.');
      setAlert('Strophe connected successfully.', 'success');
      sendPresence();
      connection.addHandler(onMessage, null, 'message', null, null, null);
      mucJoin();
      mucListRooms();
    }
  };

  const mucJoin = () => {
    //https://github.com/strophe/strophejs-plugin-muc/blob/b5ebd8f48a66abe5860d867e1a5c8f721997198b/lib/strophe.muc.js#L56
    connection.muc.join(
      ROOM,
      nickName,
      null,
      (pres) => roomPresenceHandler(pres),
      rosterReceived,
      PASSWORD
    );
  };
  const mucLeave = () => {
    const exitMessage = nickName + ' leaving';
    log('exit message ==> ' + exitMessage);
    connection.muc.leave(ROOM, nickName, presenceReceived, exitMessage);
  };

  const cleanup = () => {
    mucLeave();
    disconnect();
  };

  useEffect(() => {
    isMountedRef.current = true;

    const onUnload = (e) => {
      // the method that will be used for both add and remove event
      cleanup();
      e.preventDefault();
      e.returnValue = '';
    };
    if (!connection.connected) {
      connection.connect(JID, PASSWORD, (status) => onConnect(status));
      //cleanup resources before page leave
      window.addEventListener('beforeunload', onUnload);
      //user should must interact with page before leaving otherwise strophe connection will close after 2-3mits automatically then user can re-login
    }
    return () => {
      isMountedRef.current = false;
      window.removeEventListener('beforeunload', onUnload);
      cleanup();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [stropheAlert, setStropheAlert] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const setAlert = (message, severity = 'info', show = true) => {
    setStropheAlert({
      show,
      severity,
      message,
    });
  };
  const hideAlert = () => {
    setStropheAlert({ ...stropheAlert, show: false });
  };
  const disconnect = () => {
    connection.flush();
    connection.disconnect();
  };
  function onMessage(msg) {
    const id = msg.getAttribute('id');
    let from = msg.getAttribute('from');
    let fromName = from.substring(from.lastIndexOf('/') + 1);
    let type = msg.getAttribute('type');
    let createdAt = '';
    if (
      msg.getElementsByTagName('delay') &&
      msg.getElementsByTagName('delay')[0]
    ) {
      createdAt = msg.getElementsByTagName('delay')[0].getAttribute('stamp');
    }
    let elems = msg.getElementsByTagName('body');

    if (type === 'groupchat' && elems.length > 0) {
      const msgBody = elems[0];
      let messageObj = {};
      if (fromName === nickName) {
        messageObj = {
          id,
          imageAlt: null,
          messageText: msgBody.innerHTML,
          createdAt,
          isMyMessage: true,
        };
      } else {
        messageObj = {
          id,
          amera_avatar_url: '',
          imageAlt: fromName,
          first_name: fromName, //need to research about it b/c not receiving first name and last name
          last_name: '',
          messageText: msgBody.innerHTML,
          createdAt,
          isMyMessage: false,
        };
      }
      props.addMessage(messageObj);
      setTimeout(() => scrollToBottom(), 100);
    }
    // // we must return true to keep the handler alive.
    // // returning false would remove it after it finishes.
    return true;
  }
  const sendMessage = (messgeTo, message, type) => {
    if (connection && connection.connected) {
      let messagetype = type ? type : 'groupchat',
        reply;
      if (messagetype === 'groupchat') {
        reply = $msg({
          to: messgeTo,
          from: connection.jid,
          type: messagetype,
          id: connection.getUniqueId(),
        })
          .c('body')
          .t(message);
      }
      reply.up().c('delay', {
        xmlns: 'urn:xmpp:delay',
        stamp: new Date().toISOString(),
        from: ROOM_SERVER,
      });
      connection.send(reply.tree());
      log('message sent to ' + messgeTo + ': ' + message, reply.tree());
    } else {
      alert('sorry server not connected!');
    }
  };
  const mucListRooms = () => {
    connection.muc.listRooms(
      Strophe.getDomainFromJid(connection.jid),
      (status) => log('List of Chat Rooms', status),
      (status) => log('Error getting Chat Rooms', status)
    );
  };
  const presenceReceived = (presence) => {
    var from = presence.getAttribute('from');
    let fromName = from.substring(from.lastIndexOf('/') + 1);

    // if roster not present then add into rosters list
    const rstr = rostersRef.current.filter((r) => r.jid === fromName);
    if (!rstr[0]) {
      rostersRef.current.push({
        id: fromName, //considering it as user name
        jid: null, //currently showing empty
        name: fromName,
        status: 'online', //setting online as default
      });
    }
    if (isMountedRef.current) {
      setRosters([
        ...new Map(
          rostersRef.current.map((item) => [item['name'], item])
        ).values(),
      ]);
    }
    return true;
  };

  const roomPresenceHandler = (presence) => {
    var presence_type = presence.getAttribute('type'); // unavailable, subscribed, etc...
    var from = presence.getAttribute('from'); // the jabber_id of the contact...
    let fromName = from.substring(from.lastIndexOf('/') + 1);

    // if roster not present then add into rosters list
    const rstr = rostersRef.current.filter((r) => r.id === fromName);
    if (!rstr[0]) {
      rostersRef.current.push({
        id: fromName,
        jid: null, //currently showing empty
        name: fromName,
        status: 'online',
      });
    }
    console.log('presence type==> ', presence_type);
    if (presence_type === 'unavailable') {
      rostersRef.current = rostersRef.current.filter((r) => r.id !== fromName); //remove unavailable user
    }
    setRosters([
      ...new Map(
        rostersRef.current.map((item) => [item['name'], item])
      ).values(),
    ]);
    return true;
  };

  const rosterReceived = (iq) => {
    let newRosters = [];
    setTimeout(() => {
      Object.keys(iq).forEach((key) => {
        let roster = iq[key];
        newRosters.push({
          id: key,
          jid: roster['jid'], //currently showing empty
          name: roster['nick'],
          status: 'online',
        });
      });

      setRosters(newRosters);
    }, 100); //need to resolve it properly currently not sure why iq object mutating from another place
    connection.addHandler(
      (pres) => {
        presenceReceived(pres);
      },
      null,
      'presence'
    );
  };
  const sendPresence = () => {
    connection.send($pres({ type: 'Available' }));
  };
  const log = (logData) => {
    if (!connection) {
      console.log(
        'Error, not connected`, please enter credentials:\n ' +
          "this.connect('jid','password')"
      );
    }
    if (debuggingMode) {
      console.log(logData);
    }
  };

  return {
    stropheAlert,
    hideAlert,
    authenticated: connection.authenticated,
    rosters,
    sendMessage,
  };
}

export default useStrophe;
