import React, { useEffect, useState } from 'react';
import { makeStyles, IconButton, Tooltip, Button } from '@material-ui/core';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MovieCreationIcon from '@material-ui/icons/MovieCreation';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import GetAppIcon from '@material-ui/icons/GetApp';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import SettingsIcon from '@material-ui/icons/Settings';
import MicIcon from '@material-ui/icons/Mic';

import { startDisplay } from '../../utils/webcam/webrtc';

import EncryptionController from './EncryptionController';
import Modal from '../../components/Modal/modal.js';

const styles = (theme) => ({
  toolBar: {
    backgroundColor: '#333333',
    height: '64px',
    width: '100%',
    borderTopLeftRadius: theme.spacing(2),
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  viewModeWrapper: {
    marginLeft: theme.spacing(2),
    backgroundColor: 'black',
    color: 'white',
    '& .MuiToggleButton-root': {
      color: 'white',
    },
  },
  features: {
    marginLeft: theme.spacing(2),
    '& .MuiIconButton-root': {
      color: 'white',
      backgroundColor: 'black',
      marginLeft: theme.spacing(1),
    },
    display: 'flex',
  },
  stopButtonWrapper: {
    '& .MuiIconButton-root': {
      backgroundColor: '#c82333',
      color: 'white',
    },
  },
  fileInput: {
    display: 'none',
  },
  encryptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  encryptionControlWrapper: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  encryptionCard: {
    height: '100%',
    '& .MuiCardContent-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  encryptionImage: {
    minWidth: '100%',
    maxWidth: '100%',
    width: '100%',
    height: '270px',
    minHeight: '270px',
    maxHeight: '270px',
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  encryptionImageKey: {
    '& label': {
      fontWeight: 'bold',
    },
  },
  controlGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& label': {
      fontWeight: 'bold',
    },
    paddingBottom: theme.spacing(1),
  },
  fileInputWrapper: {
    '& label': {
      display: 'block',
    },
  },
  userPinWrapper: {
    '& input': {
      height: '2.2em',
      width: '100%',
      border: '1px solid #ced4da',
      borderRadius: '0.23em',
      background: theme.palette.common.white,
      paddingLeft: theme.spacing(1),
    },
    marginLeft: theme.spacing(2),
  },
  selectWrapper: {
    marginTop: theme.spacing(2),
    '& label': {
      fontWeight: 'bold',
    },
  },
  select: {
    display: 'block',
    width: '100%',
    height: '2.3em',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    backgroundColor: theme.palette.common.white,
  },
  connectionType: {
    color: 'white',
    marginRight: theme.spacing(3),
  },
  modalContentWrapper: {
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '1.2em',
    textAlign: 'center',
  },
});

const useStyles = makeStyles(styles);

let captureStream, mediaRecorder, recordedBlobs, displayStream;

const Toolbar = (props) => {
  const { viewMode, handleViewMode } = props;
  const [record, setRecord] = useState(false);
  const [play, setPlay] = useState(false);
  const [playAvailable, setPlayAvailable] = useState(false);
  const [downloadAvailable, setDownloadAvailable] = useState(false);
  const [shareDisplay, setShareDisplay] = useState(false);
  const [showEncryptionModal, setShowEncryptionModal] = useState(false);
  const {
    callStarted,
    ameraWebrtcClient,
    localVideoRef,
    localStream,
    remoteVideoRef,
    remoteStream,
    handleCloseCall,
    audioOutput,
    changeAudioDestination,
    localDevices,
    localSelected,
    handleLocalVideoChange,
    handleLocalAudioChange,
    handleLocalAudio,
    localAudioEnabled,
    localVideoEnabled,
    handleLocalVideo,
    showCallStatusModal,
    callEndedByRemote,
    handleCloseCallFromModal,
    partnerInfo,
    connectionType,
  } = props;

  const classes = useStyles(styles);

  const handleDownload = () => {
    const blob = new Blob(recordedBlobs, { type: 'video/webm' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // later - max, get input file type
    let nowtime = new Date().toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    nowtime = nowtime.replace(/\//g, '');

    a.download = `Amera-webcam-${nowtime}.webm`;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  };

  const startRecording = () => {
    recordedBlobs = [];
    let options = { mimeType: 'video/webm;codecs=vp9,opus' };
    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      console.error(`${options.mimeType} is not supported`);
      options = { mimeType: 'video/webm;codecs=vp8,opus' };
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.error(`${options.mimeType} is not supported`);
        options = { mimeType: 'video/webm' };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.error(`${options.mimeType} is not supported`);
          options = { mimeType: '' };
        }
      }
    }
    try {
      mediaRecorder = new MediaRecorder(captureStream, options);
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e);
      return;
    }

    mediaRecorder.onstop = (event) => {
      console.log('Recorder stopped: ', event);
      console.log('Recorded Blobs: ', recordedBlobs);
    };
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start();
    setDownloadAvailable(false);
    setPlayAvailable(false);
  };

  const stopRecording = () => {
    if (!mediaRecorder) return;
    mediaRecorder.stop();
    const promptRecordingSource = false; //document.querySelector('#promptRecordingSource').checked;
    if (promptRecordingSource) {
      let videoTrack = captureStream.getVideoTracks()[0];
      videoTrack.stop();
    }
    captureStream = null;
    remoteVideoRef.current.srcObject = remoteStream;
    setDownloadAvailable(true);
    setPlayAvailable(true);
  };

  const handleDataAvailable = (event) => {
    if (event.data && event.data.size > 0) {
      recordedBlobs.push(event.data);
    }
  };

  useEffect(() => {
    const handleRecord = async () => {
      if (record) {
        captureStream = remoteStream;

        // see if we want to capture display instead of remote...
        const promptRecordingSource = false; // document.querySelector('#promptRecordingSource').checked;
        if (promptRecordingSource) {
          let displayMediaOptions = {
            video: { cursor: 'always' },
            audio: true,
          };
          // could add constraints here...
          //let supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
          captureStream = await startDisplay(displayMediaOptions);

          remoteVideoRef.current.srcObject = captureStream; // so we can see what is being captured
        }
        // later - max, do we need to mix audo so its all captured??
        startRecording();
      } else {
        stopRecording();
      }
    };
    handleRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    if (play) {
      const superBuffer = new Blob(recordedBlobs, { type: 'video/webm' });
      if (remoteVideoRef && remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = null;
        remoteVideoRef.current.src = window.URL.createObjectURL(superBuffer);
        remoteVideoRef.current.controls = true;
        remoteVideoRef.current.play();
      }
    } else {
      if (remoteVideoRef && remoteVideoRef.current)
        remoteVideoRef.current.srcObject = remoteStream;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [play]);

  /**
   *  handler to send files.  break into slices to stay below websocket max length of abou 70k
   *  all files are URI base64 encoded, so we don't actually care if binary of text
   * @param {*} files
   */
  const sendXfiles = async (event) => {
    const files = event.target.files;

    for (let j = 0; j < files.length; ++j) {
      const file = files[j];
      let size = file.size;

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        let chunksize = 50000;
        let nextslice, gotmore;
        let realjuju = reader.result.split(',')[1];

        for (let i = 0; i < realjuju.length; i += chunksize) {
          nextslice = realjuju.slice(i, i + chunksize);
          gotmore = nextslice.length === chunksize;
          let filedata = {
            name: file.name,
            type: file.type,
            length: size,
            index: i,
            more: gotmore,
            verbose: !gotmore,
            display: !gotmore, // don't log each packet
            rawbytes: nextslice,
          };
          ameraWebrtcClient.sendUserMessage({ type: 'xferfile', filedata });
        }
      };
      reader.onerror = function () {
        console.log(reader.error);
      };
    }
  };

  const shareDisplayStop = () => {
    if (!displayStream) return;
    let videoTrack = displayStream.getVideoTracks()[0];
    videoTrack.stop();
    localVideoRef.current.srcObj = localStream;
    videoTrack = localStream.getVideoTracks()[0];
    let sender = ameraWebrtcClient.connection.getSenders().find(function (s) {
      return s.track.kind === videoTrack.kind;
    });
    sender.replaceTrack(videoTrack);
  };

  useEffect(() => {
    const handleShareDisplay = async () => {
      if (shareDisplay === true) {
        displayStream = await startDisplay({
          video: { cursor: 'always' },
          audio: true,
        });

        if (displayStream !== null) {
          displayStream.addEventListener('inactive', shareDisplayStop, false);
          displayStream.addEventListener(
            'removetrack',
            shareDisplayStop,
            false
          );

          // for the webrtc connection, we are going to replace video track, but leave audio as selected (display just has video)
          localVideoRef.current.srcObject = displayStream;
          let videoTrack = displayStream.getVideoTracks()[0];
          let sender = ameraWebrtcClient.connection
            .getSenders()
            .find(function (s) {
              return s.track.kind === videoTrack.kind;
            });
          sender.replaceTrack(videoTrack);
          // from https://developer.mozilla.org/en-US/docs/Web/API/RTCRtpSender/replaceTrack
        }
      } else {
        shareDisplayStop();
      }
    };
    handleShareDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareDisplay]);

  useEffect(() => {
    recordedBlobs = [];
  }, []);

  const CallModalContent = () => {
    let displayStr = 'Call Ended!';
    if (showCallStatusModal) {
      displayStr = partnerInfo
        ? `${partnerInfo.first_name} ${partnerInfo.last_name} is unavailable`
        : '';
    }

    return <div className={classes.modalContentWrapper}>{displayStr}</div>;
  };

  const CallModalAction = () => (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleCloseCallFromModal}
    >
      Close
    </Button>
  );

  const ModalContent = () => (
    <EncryptionController
      classes={classes}
      ameraWebrtcClient={ameraWebrtcClient}
    />
  );

  const ModalAction = () => (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        setShowEncryptionModal(false);
      }}
    >
      Close
    </Button>
  );

  const [showDeviceController, setShowDeviceController] = useState(false);

  const DeviceModalContent = () => (
    <>
      <div className={classes.selectWrapper}>
        <label htmlFor="audioSource">
          Local microphone (audio input source):{' '}
        </label>
        <select
          className={classes.select}
          id="audioSource"
          value={
            localSelected
              ? localSelected.audioinput
                ? localSelected.audioinput
                : ''
              : ''
          }
          onChange={handleLocalAudioChange}
        >
          {localDevices
            .filter((device) => device.kind === 'audioinput')
            .map((device, index) => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label ? device.label : `microphone ${index + 1}`}
              </option>
            ))}
        </select>
      </div>
      <div className={classes.selectWrapper}>
        <label htmlFor="audioOutput">
          Local speaker (audio output destination):{' '}
        </label>
        <select
          className={classes.select}
          id="audioOutput"
          value={audioOutput}
          onChange={changeAudioDestination}
        >
          {localDevices
            .filter((device) => device.kind === 'audiooutput')
            .map((device, index) => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label ? device.label : `speaker ${index + 1}`}
              </option>
            ))}
        </select>
      </div>
      <div className={classes.selectWrapper}>
        <label htmlFor="videoSource">Local camera (Video source): </label>
        <select
          className={classes.select}
          id="videoSource"
          value={
            localSelected
              ? localSelected.videoinput
                ? localSelected.videoinput
                : ''
              : ''
          }
          onChange={handleLocalVideoChange}
        >
          {localDevices
            .filter((device) => device.kind === 'videoinput')
            .map((device, index) => (
              <option value={device.deviceId} key={device.deviceId}>
                {device.label ? device.label : `camera ${index + 1}`}
              </option>
            ))}
        </select>
      </div>
    </>
  );

  const DeviceModalAction = () => (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        setShowDeviceController(false);
      }}
    >
      Close
    </Button>
  );

  return (
    <div className={classes.toolBar}>
      <div className={classes.viewModeWrapper}>
        <ToggleButtonGroup
          value={viewMode}
          size="small"
          exclusive
          onChange={handleViewMode}
          aria-label="text alignment"
        >
          <ToggleButton value="second_mode" aria-label="centered">
            <FeaturedVideoIcon />
          </ToggleButton>
          <ToggleButton value="third_mode" aria-label="right aligned">
            <PauseIcon />
          </ToggleButton>
          <ToggleButton value="first_mode" aria-label="left aligned">
            <ViewColumnIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className={classes.features}>
        <Tooltip title={record ? 'Stop Recording' : 'Start Recording'}>
          <span>
            <IconButton
              aria-label="record"
              disabled={!callStarted}
              onClick={() => {
                setRecord((prev) => !prev);
              }}
            >
              {record ? <MovieCreationIcon /> : <MovieCreationIcon />}
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={play ? 'Monitor Webcam' : 'Play'}>
          <span>
            <IconButton
              aria-label="play"
              disabled={!playAvailable}
              onClick={() => {
                setPlay((prev) => !prev);
              }}
            >
              <LiveTvIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Download">
          <span>
            <IconButton
              aria-label="download"
              disabled={!downloadAvailable}
              onClick={handleDownload}
            >
              <GetAppIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <div className={classes.features}>
        <Tooltip title={shareDisplay ? 'Stop Sharing' : 'Share Display'}>
          <span>
            <IconButton
              aria-label="share"
              disabled={!callStarted}
              onClick={() => {
                setShareDisplay((prev) => !prev);
              }}
            >
              <ScreenShareIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="File send">
          <div className={classes.fileInputWrapper}>
            <input
              className={classes.fileInput}
              id="sendX-file"
              multiple
              onChange={sendXfiles}
              type="file"
              disabled={!callStarted}
            />
            <label htmlFor="sendX-file">
              <IconButton
                component="span"
                aria-label="file send"
                disabled={!callStarted}
              >
                <AttachFileIcon />
              </IconButton>
            </label>
          </div>
        </Tooltip>
      </div>
      <div className={classes.features}>
        <Tooltip title="Encryption">
          <IconButton
            aria-label="encryption"
            onClick={() => {
              setShowEncryptionModal(true);
            }}
          >
            <EnhancedEncryptionIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.features}>
        <div className={classes.stopButtonWrapper}>
          <Tooltip title="Stop">
            <IconButton aria-label="stop" onClick={handleCloseCall}>
              <StopIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Tooltip title={localAudioEnabled ? 'mute' : 'unmute'}>
          <IconButton aria-label="mute" onClick={handleLocalAudio}>
            {localAudioEnabled ? <MicIcon /> : <MicOffIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title={localVideoEnabled ? 'Turn off video' : 'Turn on video'}>
          <IconButton aria-label="turnonoffvideo" onClick={handleLocalVideo}>
            {localVideoEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Setting">
          <IconButton
            aria-label="setting"
            onClick={() => {
              setShowDeviceController(true);
            }}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </div>
      {connectionType && (
        <div className={classes.connectionType}>
          Connection Type: {connectionType}
        </div>
      )}
      <Modal
        open={showEncryptionModal}
        onClose={() => {
          setShowEncryptionModal(false);
        }}
        title="Encryption"
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        disableBackdropClick={true}
      ></Modal>
      <Modal
        open={showDeviceController}
        onClose={() => {
          setShowDeviceController(false);
        }}
        title="Settings"
        ModalContent={DeviceModalContent}
        ModalAction={DeviceModalAction}
        disableBackdropClick={true}
      ></Modal>
      <Modal
        open={showCallStatusModal || callEndedByRemote}
        onClose={handleCloseCallFromModal}
        title="Amerashare video call"
        ModalContent={CallModalContent}
        ModalAction={CallModalAction}
        disableBackdropClick={true}
      ></Modal>
    </div>
  );
};

export default Toolbar;
