import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Layout from '../Sections/Layout';
import One2OneChat from './One2OneChat';
import GroupChat from './GroupChat';

const Chat = (props) => {
  return (
    <Layout {...props}>
      <Switch>
        <Redirect
          from={`${process.env.PUBLIC_URL}/chat`}
          to={`${process.env.PUBLIC_URL}/chat/one-to-one`}
          exact
        />
        <Route
          path={`${process.env.PUBLIC_URL}/chat/one-to-one`}
          component={One2OneChat}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/chat/group`}
          component={GroupChat}
        />
      </Switch>
    </Layout>
  );
};

export default Chat;
