import React, { useEffect, useState, createRef } from 'react';
import usePrevious from '../../../hooks/usePrevious';
import { getBoundsAtLatLngWithZoom } from '../../../utils/country';
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} from 'react-google-maps';
import LocationMapMarker from './LocationMapMarker';
// import { isSamePlaces } from '../../../utils/country';

const LocationMap = withGoogleMap((props) => {
  const {
    places,
    directions = null,
    onDblClick,
    selectedPlace,
    onSelectClick,
    onBoundsReady,
  } = props;
  const [openedPin, setOpenedPin] = useState(null);
  const [bounds, setBounds] = useState(null);
  const mapRef = createRef();

  const prevBounds = usePrevious(bounds);

  const DEFAULT_ZOOM = 17;

  // THis would center the map to contatain all places
  useEffect(() => {
    // Will only fit to new bounds if they don't intersect with current

    if (!!places && places.length > 0) {
      let newBounds = new window.google.maps.LatLngBounds();
      if (places.length > 1) {
        places.forEach((p) => {
          newBounds.extend(p.geometry.location);
        });
      } else if (places.length === 1) {
        newBounds = getBoundsAtLatLngWithZoom(
          mapRef.current,
          places[0].geometry.location,
          DEFAULT_ZOOM
        );
      }

      if (!!prevBounds && !newBounds.intersects(prevBounds)) {
        console.log('Fitting to new bounds');
        mapRef.current.fitBounds(newBounds);
      }
    }
  }, [mapRef, places, prevBounds]);

  const handleIdle = () => {
    if (openedPin === null) {
      !!onBoundsReady && onBoundsReady(bounds);
    }
  };

  const handleBoundsChange = () => {
    console.debug('Bounds changed');
    setBounds(mapRef.current.getBounds());
  };

  return (
    <GoogleMap
      ref={mapRef}
      defaultZoom={DEFAULT_ZOOM}
      onDblClick={onDblClick}
      // center={places.length === 1 ? places[0].geometry.location : null}
      // center={!!bounds ? bounds.getCenter() : places[0].geometry.location}
      // center={{ lat: lat, lng: lng }}
      defaultCenter={places[0].geometry.location}
      onBoundsChanged={handleBoundsChange}
      onIdle={handleIdle}
      className="gmap"
    >
      {!!directions && <DirectionsRenderer directions={directions} />}
      {!!places &&
        places.length > 0 &&
        places.map((place) => (
          <LocationMapMarker
            key={place.place_id}
            place={place}
            onMarkerClick={() => setOpenedPin(place.place_id)}
            onInfoClose={() => setOpenedPin(null)}
            isInfoOpen={place.place_id === openedPin}
            isSelected={
              !!selectedPlace && place.place_id === selectedPlace.place_id
            }
            onSelectClick={onSelectClick}
          />
        ))}
    </GoogleMap>
  );
});

export default LocationMap;
