import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  ButtonGroup,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';

import CallAction from './CallAction';
import MainFeature from './MainFeature';
import AdditionalFeature from './AdditionalFeature';
import EncryptionController from './EncryptionController';
import { videoResolutionConstraints } from '../../utils/webcam/webrtc';

import styles from '../../jss/views/firstScreenModeStyles';
const useStyles = makeStyles(styles);

const FirstScreenMode = (props) => {
  const classes = useStyles();

  const {
    localVideoEnabled,
    localAudioEnabled,
    localSelected,
    localDevices,
    audioOutput,
    hasEchoCancellation,
    handleLocalVideo,
    handleLocalAudio,
    handleEchoCancellation,
    handleLocalAudioChange,
    changeAudioDestination,
    handleLocalVideoChange,
    handleRemoteVideo,
    remoteVideoEnabled,
    handleRemoteAudio,
    remoteAudioEnabled,
    handleRemoteVideoChange,
    handleRemoteAudioChange,
    remoteDevices,
    viewMode,
    handleViewMode,
    handleRemoteResolutionChange,
    binaryString,
    showCallStatusModal,
    setShowCallStatusModal,
    handleCloseCall,
    makeCall,
    handleCloseCallFromModal,
    ameraWebrtcClient,
    remoteVideoRef,
    localVideoRef,
    connectionType,
  } = props;

  const localWebcamRender = () => (
    <Grid item md={4} sm={12}>
      <Card className={classes.localWebcam} variant="outlined">
        <CardContent>
          <video
            autoPlay
            playsInline
            ref={localVideoRef}
            className={classes.videoPlayer}
            me="true"
            muted="muted"
          />
        </CardContent>
        <CardContent className={classes.playerControlWrapper}>
          <ButtonGroup
            variant="contained"
            size="small"
            aria-label="contained primary button group"
            className={classes.playerControlGroup}
          >
            <Button
              className={classes.localPlayerControl}
              onClick={handleLocalVideo}
            >
              Local Video{' '}
              {localVideoEnabled ? (
                <PauseIcon fontSize="small" />
              ) : (
                <PlayArrowIcon fontSize="small" />
              )}
            </Button>
            <Button
              className={classes.localPlayerControl}
              onClick={handleLocalAudio}
            >
              Local Audio{' '}
              {localAudioEnabled ? (
                <PauseIcon fontSize="small" />
              ) : (
                <PlayArrowIcon fontSize="small" />
              )}
            </Button>
            <Button
              className={classes.localPlayerControl}
              onClick={handleEchoCancellation}
            >
              Echo{' '}
              {hasEchoCancellation ? (
                <PauseIcon fontSize="small" />
              ) : (
                <PlayArrowIcon fontSize="small" />
              )}
            </Button>
          </ButtonGroup>
          <div className={classes.selectWrapper}>
            <label htmlFor="audioSource">
              Local microphone (audio input source):{' '}
            </label>
            <select
              className={classes.select}
              id="audioSource"
              value={
                localSelected
                  ? localSelected.audioinput
                    ? localSelected.audioinput
                    : ''
                  : ''
              }
              onChange={handleLocalAudioChange}
            >
              {localDevices
                .filter((device) => device.kind === 'audioinput')
                .map((device, index) => (
                  <option value={device.deviceId} key={device.deviceId}>
                    {device.label ? device.label : `microphone ${index + 1}`}
                  </option>
                ))}
            </select>
          </div>
          <div className={classes.selectWrapper}>
            <label htmlFor="audioOutput">
              Local speaker (audio output destination):{' '}
            </label>
            <select
              className={classes.select}
              id="audioOutput"
              value={audioOutput}
              onChange={changeAudioDestination}
            >
              {localDevices
                .filter((device) => device.kind === 'audiooutput')
                .map((device, index) => (
                  <option value={device.deviceId} key={device.deviceId}>
                    {device.label ? device.label : `speaker ${index + 1}`}
                  </option>
                ))}
            </select>
          </div>
          <div className={classes.selectWrapper}>
            <label htmlFor="videoSource">Local camera (Video source): </label>
            <select
              className={classes.select}
              id="videoSource"
              value={
                localSelected
                  ? localSelected.videoinput
                    ? localSelected.videoinput
                    : ''
                  : ''
              }
              onChange={handleLocalVideoChange}
            >
              {localDevices
                .filter((device) => device.kind === 'videoinput')
                .map((device, index) => (
                  <option value={device.deviceId} key={device.deviceId}>
                    {device.label ? device.label : `camera ${index + 1}`}
                  </option>
                ))}
            </select>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );

  const remoteWebcamRender = () => (
    <Grid item md={4} sm={12}>
      <Card className={classes.remoteWebcam} variant="outlined">
        <CardContent>
          <video
            autoPlay
            playsInline
            ref={remoteVideoRef}
            className={classes.videoPlayer}
            me="true"
          />
        </CardContent>
        {connectionType ? (
          <CardContent>Connection Type: {connectionType}</CardContent>
        ) : null}
        <CardContent className={classes.playerControlWrapper}>
          <ButtonGroup
            variant="contained"
            size="small"
            aria-label="contained primary button group"
            className={classes.playerControlGroup}
          >
            <Button
              className={classes.remotePlayerControl}
              onClick={handleRemoteVideo}
            >
              Remote Video{' '}
              {remoteVideoEnabled ? (
                <PauseIcon fontSize="small" />
              ) : (
                <PlayArrowIcon fontSize="small" />
              )}
            </Button>
            <Button
              className={classes.remotePlayerControl}
              onClick={handleRemoteAudio}
            >
              Remote Audio{' '}
              {remoteAudioEnabled ? (
                <PauseIcon fontSize="small" />
              ) : (
                <PlayArrowIcon fontSize="small" />
              )}
            </Button>
          </ButtonGroup>
          <div className={classes.selectWrapper}>
            <label htmlFor="audioSourceRemote">
              Remote microphone (audio input source):{' '}
            </label>
            <select
              className={classes.select}
              id="audioSourceRemote"
              onChange={handleRemoteAudioChange}
            >
              {remoteDevices
                .filter((device) => device.kind === 'audioinput')
                .map((device, index) => (
                  <option value={device.deviceId} key={device.deviceId}>
                    {device.label ? device.label : `microphone ${index + 1}`}
                  </option>
                ))}
            </select>
          </div>
          <div className={classes.selectWrapper}>
            <label htmlFor="videoSourceRemote">
              Remote camera (video source):{' '}
            </label>
            <select
              className={classes.select}
              id="videoRourceRemote"
              onChange={handleRemoteVideoChange}
            >
              {remoteDevices
                .filter((device) => device.kind === 'videoinput')
                .map((device, index) => (
                  <option value={device.deviceId} key={device.deviceId}>
                    {device.label ? device.label : `camera ${index + 1}`}
                  </option>
                ))}
            </select>
          </div>
          <div className={classes.selectWrapper}>
            <label htmlFor="videoResRemote">
              Remote camera video resolution:{' '}
            </label>
            <select
              className={classes.select}
              id="videoResRemote"
              onChange={handleRemoteResolutionChange}
            >
              {Object.keys(videoResolutionConstraints).map((constraint) => (
                <option value={constraint} key={constraint}>
                  {constraint}
                </option>
              ))}
            </select>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.viewModeWrapper}>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleViewMode}
              aria-label="text alignment"
            >
              <ToggleButton value="second_mode" aria-label="centered">
                <FeaturedVideoIcon />
              </ToggleButton>
              <ToggleButton value="thrid_mode" aria-label="right aligned">
                <PauseIcon />
              </ToggleButton>
              <ToggleButton value="first_mode" aria-label="left aligned">
                <ViewColumnIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>
        <CallAction
          classes={classes}
          showCallStatusModal={showCallStatusModal}
          setShowCallStatusModal={setShowCallStatusModal}
          handleCloseCall={handleCloseCall}
          makeCall={makeCall}
          handleCloseCallFromModal={handleCloseCallFromModal}
        />
        <MainFeature
          classes={classes}
          ref={localVideoRef}
          ameraWebrtcClient={ameraWebrtcClient}
        />
        <AdditionalFeature classes={classes} remoteVideoRef={remoteVideoRef} />
        {localWebcamRender()}
        <EncryptionController
          classes={classes}
          isInModal={false}
          ameraWebrtcClient={ameraWebrtcClient}
        />
        {remoteWebcamRender()}
        <Grid item xs={12}>
          {binaryString !== '' ? (
            <div className={classes.binaryEncryptionKey}>
              <label htmlFor="binary-encryption-key">
                Binary encryption key
              </label>
              <textarea
                id="binary-encryption-key"
                disabled={true}
                value={binaryString}
              ></textarea>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default FirstScreenMode;
