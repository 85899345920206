import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Button, Grid, Select, MenuItem } from '@material-ui/core';

import MemberAutocomplete from '../MemberAutocomplete';
import Modal from '../Modal/modal';

import { addCompanyMember } from '../../redux/actions/company';

const roleTypes = ['standard', 'administrator', 'owner'];

const AddMemberModal = (props) => {
  const { title, open, onClose, company } = props;
  const [addMember, setAddMember] = useState({});
  const [roleType, setRoleType] = useState('standard');
  const dispatch = useDispatch();

  const disableSubmit = addMember ? !addMember.email : true;

  const closeModal = () => {
    onClose();
  };

  const addedMembers = useMemo(() => {
    if (company && company.members && company.members.length) {
      return company.members.map((member) => {
        return member.id;
      });
    } else {
      return [];
    }
  }, [company]);

  const isAlreadyAddedMembers = useMemo(() => {
    return (option) => addedMembers.includes(option.member_id);
  }, [addedMembers]);

  const handleSubmit = () => {
    let formData = new FormData();

    formData.set('company_id', company.id);
    formData.set('company_role', roleType);
    formData.set('member_id', addMember.member_id);

    dispatch(addCompanyMember(formData));
    closeModal();
  };

  const handleMembersSelect = (e, v) => {
    console.log();
    setAddMember(v);
  };

  const handleRoleChange = (event) => {
    event.persist();
    setRoleType(event.target.value);
  };

  const ModalContent = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1">
          Please select a member and role to add to your group
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MemberAutocomplete
          handleChange={handleMembersSelect}
          disablerFunction={isAlreadyAddedMembers}
          label="Add Member"
          allMembers={true}
          isAddMember={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          label="Role"
          labelWidth={10}
          name="role type"
          className="mt-2"
          value={roleType}
          onChange={handleRoleChange}
        >
          {roleTypes.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );

  const ModalAction = () => {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableRipple
          disabled={disableSubmit}
          onClick={handleSubmit}
        >
          Add Member
        </Button>
      </>
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="add-member-modal"
    ></Modal>
  );
};

AddMemberModal.defaultProps = {
  open: false,
  onClose: null,
};

export default AddMemberModal;
