/* global APP */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { vacall, VI } from './global';

import { IconMenuThumb } from './icons';
import ProfileItem from './ToolboxMore/ProfileItem';
import ManageQuality from './ToolboxMore/ManageQuality';
import FullScreen from './ToolboxMore/FullScreen';
// import Recording from './ToolboxMore/Recording';
// import LiveStream from './ToolboxMore/LiveStream';
// import YoutubeVideo from './ToolboxMore/YoutubeVideo';
import BlurBackground from './ToolboxMore/BlurBackground';
import Settings from './ToolboxMore/Settings';
import MuteEveryOne from './ToolboxMore/MuteEveryOne';
// import SpeakerStats from './ToolboxMore/SpeakerStats';
// import EmbedMeeting from './ToolboxMore/EmbedMeeting';
// import LiveFeedback from './ToolboxMore/LiveFeedback';
import ViewShortcuts from './ToolboxMore/ViewShortcuts';
import Help from './ToolboxMore/Help';
import UIEvents from './service/UI/UIEvents';
import { injectIntl } from 'react-intl';
// const $ = window.$;

class buttons extends Component<Props> {
  constructor(props: Props) {
    super(props);

    // Bind event handlers so they are only bound once per instance.
    this._onMicKeyboardShortcut = this._onMicKeyboardShortcut.bind(this);
  }
  state = {
    mic: 'on',
    video: 'on',
    FullScreen:false
  };
  componentDidMount() {
    const {intl} = this.props;
    const messages = {
      audiomuteunmute: {
        id: 'app.home.audiomuteunmute',
        defaultMessage: 'Mute/Unmute',
      },
      videomuteunmute: {
        id: 'app.home.videomuteunmute',
        defaultMessage: 'Video Mute/Unmute',
      },
      callquality: {
        id: 'app.home.callquality',
        defaultMessage: 'Manage video quality',
      }, 
      vefullscr: {
        id: 'app.home.vefullscr',
        defaultMessage: 'View or exit full screen',
      }
    };
    typeof APP === 'undefined' ||
      APP.keyboardshortcut.registerShortcut(
        'M',
        null,
        this._onMicKeyboardShortcut,
        intl.formatMessage(messages.audiomuteunmute)
      );

    typeof APP === 'undefined' ||
      APP.keyboardshortcut.registerShortcut(
        'V',
        null,
        this._onVideoKeyboardShortcut,
        intl.formatMessage(messages.videomuteunmute)
      );

    typeof APP === 'undefined' ||
      APP.keyboardshortcut.registerShortcut(
        'A',
        null,
        this.props.toggleVideoQuality,
        intl.formatMessage(messages.callquality)
      );
    typeof APP === 'undefined' ||
      APP.keyboardshortcut.registerShortcut(
        'S',
        null,
        this._onfullScreen,
         intl.formatMessage(messages.vefullscr)
      );

    APP.UI.addListener(UIEvents.AUDIO_ONLY, (action) => {
      if (action === true) {
        this.MuteCam();
      } else {
        this.unMuteCam();
      }
    });
    APP.UI.addListener(UIEvents.MUTE_ALL, (value, id) => {
      if (value === 'unmute') {
        this.unMuteMic();
      } else {
        this.MuteMic();
      }
    });
    APP.UI.addListener(UIEvents.MUTE_AUDIO, (action) => {
      if (action === true) {
        this.MuteMic();
      } else {
        this.unMuteMic();
      }
    });
  }
  _onMicKeyboardShortcut: () => void;

  /**
   * Creates an analytics keyboard shortcut event and dispatches an action to
   * toggle the audio muting.
   *
   * @private
   * @returns {void}
   */
  _onMicKeyboardShortcut = () => {
    if (!APP.vacall.isMicMuted()) {
      this.MuteMic();
    } else {
      this.unMuteMic();
    }
  };

  _onVideoKeyboardShortcut: () => void;

  /**
   * Creates an analytics keyboard shortcut event and dispatches an action to
   * toggle the audio muting.
   *
   * @private
   * @returns {void}
   */
  _onVideoKeyboardShortcut = () => {
    if (!APP.vacall.isVideoMuted()) {
      this.MuteCam();
    } else {
      this.unMuteCam();
    }
  };
  _onfullScreen: () => void;
  _onfullScreen = () => {
    APP.UI.emitEvent(UIEvents.FULL_SCREEN);
  };
  
  _toggleBlurEffect = () => {
   // alert();
    APP.vacall.VI._toggleBlurEffect();
  }
  _setFullScreen = () => {
    var full = this.state.FullScreen;
    if (typeof APP === 'object') {
      if (!full) {
        const documentElement = document.documentElement || {};

        if (typeof documentElement.requestFullscreen === 'function') {
          documentElement.requestFullscreen();
        } else if (typeof documentElement.mozRequestFullScreen === 'function') {
          documentElement.mozRequestFullScreen();
        } else if (
          typeof documentElement.webkitRequestFullscreen === 'function'
        ) {
          documentElement.webkitRequestFullscreen();
        }
        this.setState({ FullScreen: true });
      } else {
        /* eslint-disable no-lonely-if */

        // $FlowFixMe
        if (typeof document.exitFullscreen === 'function') {
          document.exitFullscreen();

          // $FlowFixMe
        } else if (typeof document.mozCancelFullScreen === 'function') {
          document.mozCancelFullScreen();

          // $FlowFixMe
        } else if (typeof document.webkitExitFullscreen === 'function') {
          document.webkitExitFullscreen();
        }
        this.setState({ FullScreen: false });
      }
    }
  };

  // mute mic triggered
  MuteMic() {
    if (this.state.mic === 'off') {
      return false;
    }
    const checkMuted = new Promise((resolve, reject) => {
      if (vacall.localTracks[0].isMuted() === false) {
        vacall.localTracks[0].mute();
        return resolve('passed');
      } else {
        return reject('Err : Something went wrong!!');
      }
    });
    checkMuted
      .then((data) => {
        this.setState({
          mic: 'off',
        });
        APP.UI.emitEvent(UIEvents.MIC_INDICATOR);
      })
      .catch((data) => {
        alert(data);
      });
  }
  // unmute mic triggered
  unMuteMic() {
    if (this.state.mic === 'on') {
      return false;
    }
    const unmutedMic = new Promise((resolve, reject) => {
      if (vacall.localTracks[0].isMuted() === true) {
        vacall.localTracks[0].unmute();
        return resolve('passed');
      } else {
        return reject('Err : Something went wrong!!');
      }
    });
    unmutedMic
      .then((data) => {
        this.setState({
          mic: 'on',
        });
        APP.UI.emitEvent(UIEvents.MIC_INDICATOR);
      })
      .catch((data) => {
        alert(data);
      });
  }

  // mute cam triggered
  MuteCam() {
    if (this.state.video === 'off') {
      return false;
    }
    const CamMute = new Promise((resolve, reject) => {
      if (vacall.screenshareEnabled === true) {
        VI.switchSV(false);
        this.setState({
          video: 'off',
        });
        return false;
      } else {
        if (vacall.localTracks[1].isMuted() === false) {
          vacall.localTracks[1].mute();

          return resolve('passed');
        } else {
          return reject('Err : Something went wrong!!');
        }
      }
    });
    CamMute.then((data) => {
      this.setState({
        video: 'off',
      });
      let pCount = vacall.room.getParticipants().length;
      APP.UI.emitEvent(UIEvents.VIDEO_INDICATOR);
      if (pCount === 0) {
        document.getElementById('large_videowrapper').style.opacity = '0.1';
      }
    }).catch((data) => {
      alert(data);
    });
  }

  // mute cam triggered
  unMuteCam() {
    if (this.state.video === 'on') {
      return false;
    }
    const CamMute = new Promise((resolve, reject) => {
      if (vacall.localTracks[1].isMuted() === true) {
        vacall.localTracks[1].unmute();
        return resolve('passed');
      } else {
        if (vacall.screenshareEnabled === true) {
          VI.switchSV(true);
          return resolve('passed');
        } else {
          return reject('Err : Something went wrong!!');
        }
      }
    });
    CamMute.then((data) => {
      this.setState({
        video: 'on',
      });
      APP.UI.emitEvent(UIEvents.VIDEO_INDICATOR);
      document.getElementById('large_videowrapper').style.opacity = '';
    }).catch((data) => {
      alert(data);
    });
  }

  // trigger end call
  EndCall() {
    vacall.popup = false;
    const { history } = this.props;
    for (let i = 0; i < vacall.localTracks.length; i++) {
      vacall.localTracks[i].dispose();
    }

    try {
      vacall.room
        .leave()
        .then(() => {})
        .catch((e) => {
          console.log('call end issue', e);
        });
      vacall.connection.disconnect();
      vacall.room = null;
      vacall.connection = {};
      vacall.isJoined = false;
      vacall.room = null;
      vacall.localTracks = [];
      vacall.remoteTracks = {};
      vacall.isVideo = null;
      vacall.remoteVideos = {};
      let goTo = `${process.env.PUBLIC_URL}/groups`;
      history.push(goTo);
    } catch (e) {
      console.log('end call issue', e);
    }
  }

  render() {
    const isMic = this.state.mic;
    const isCam = this.state.video;
    return (
      <div className="new-toolbox" id="new-toolbox">
        {/* <div className="toolbox-background"></div> */}
        <div className="toolbox-content">
          <div className="button-group-left">
            {/* <div className="toolbar-button-with-badge">
              <div
                aria-label="Toggle chat window"
                aria-pressed="false"
                className="toolbox-button"
                role="button"
                tabIndex="0"
              >
                <div>
                  <div className="toolbox-icon ">
                    <div className="amera-icon ">
                      <svg height="24" width="24" viewBox="0 0 32 32">
                        <path d="M26.688 21.313v-16H5.313v18.688l2.688-2.688h18.688zm0-18.625a2.64 2.64 0 012.625 2.625v16c0 1.438-1.188 2.688-2.625 2.688H8l-5.313 5.313v-24a2.64 2.64 0 012.625-2.625h21.375z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <span className="badge-round">
                <span></span>
              </span>
            </div> */}
            <div
              onClick={this.props.toggleScreenshare}
              aria-label="Toggle screenshare"
              className="toolbox-button"
              role="button"
              tabIndex="0"
              aria-pressed="false"
            >
              <div>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-screenshare`}>Screen Share</Tooltip>
                  }
                >
                  <div className="toolbox-icon ">
                    <div className="amera-icon ">
                      <svg height="24" width="24" viewBox="0 0 32 32">
                        <path d="M28 22.688v-16H4v16h24zM28 4c1.438 0 2.688 1.25 2.688 2.688l-.063 16A2.64 2.64 0 0128 25.313h-6.688v2.688H10.687v-2.688H3.999c-1.438 0-2.688-1.188-2.688-2.625v-16C1.311 5.25 2.561 4 3.999 4h24z"></path>
                      </svg>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </div>
            <div
              onClick={this._setFullScreen}
              aria-label="Toggle full screen"
              className="toolbox-button"
              role="button"
              tabIndex="0"
              aria-pressed="false"
            >
              <div>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-fullscreen`}> <span>
                    {this.state.FullScreen ? 'Exit full screen' : 'View full screen'}
                  </span></Tooltip>
                  }
                >
                  <div className="toolbox-icon ">
                    <div className="amera-icon ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M18.688 6.688h6.625v6.625h-2.625v-4h-4V6.688zm4 16v-4h2.625v6.625h-6.625v-2.625h4zm-16-9.375V6.688h6.625v2.625h-4v4H6.688zm2.625 5.375v4h4v2.625H6.688v-6.625h2.625z"></path></svg>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </div>
           
            <Dropdown drop="up" className="toolbox-button">
              <Dropdown.Toggle id="view-menu">
                <div>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-filmstrip-button`}>
                        Change layout
                      </Tooltip>
                    }
                  >
                    <div className="toolbox-icon ">
                      <div className="amera-icon ">
                        <svg height="24" width="24" viewBox="0 0 24 24">
                          <path d="M2.667 0H8a2.667 2.667 0 012.667 2.667V8A2.667 2.667 0 018 10.667H2.667A2.667 2.667 0 010 8V2.667A2.667 2.667 0 012.667 0zM4 2.667c-.736 0-1.333.597-1.333 1.333v2.667C2.667 7.403 3.264 8 4 8h2.667C7.403 8 8 7.403 8 6.667V4c0-.736-.597-1.333-1.333-1.333H4zM2.667 13.333H8A2.667 2.667 0 0110.667 16v5.333A2.667 2.667 0 018 24H2.667A2.667 2.667 0 010 21.333V16a2.667 2.667 0 012.667-2.667zM4 16c-.736 0-1.333.597-1.333 1.333V20c0 .736.597 1.333 1.333 1.333h2.667C7.403 21.333 8 20.736 8 20v-2.667C8 16.597 7.403 16 6.667 16H4zM16 0h5.333A2.667 2.667 0 0124 2.667V8a2.667 2.667 0 01-2.667 2.667H16A2.667 2.667 0 0113.333 8V2.667A2.667 2.667 0 0116 0zm1.333 2.667C16.597 2.667 16 3.264 16 4v2.667C16 7.403 16.597 8 17.333 8H20c.736 0 1.333-.597 1.333-1.333V4c0-.736-.597-1.333-1.333-1.333h-2.667zM16 13.333h5.333A2.667 2.667 0 0124 16v5.333A2.667 2.667 0 0121.333 24H16a2.667 2.667 0 01-2.667-2.667V16A2.667 2.667 0 0116 13.333zM17.333 16c-.736 0-1.333.597-1.333 1.333V20c0 .736.597 1.333 1.333 1.333H20c.736 0 1.333-.597 1.333-1.333v-2.667c0-.736-.597-1.333-1.333-1.333h-2.667z"></path>
                        </svg>
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="view-menu">
                <Dropdown.Item eventKey="1" onClick={this.props.enableTileView}>
                  Tile View
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onClick={this.props.enableVerticalView}
                >
                  Vertical View
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onClick={this.props.enableHorizontalView}
                >
                  Horizotal View
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/*  <div
              aria-label="Toggle view"
              onClick={this.props.toggleViewType}
              className="toolbox-button"
              role="button"
              tabIndex="0"
              aria-pressed="false"
            >
              <div>
                <div className="toolbox-icon ">
                  <div className="amera-icon ">
                    <svg height="24" width="24" viewBox="0 0 24 24">
                      <path d="M2.667 0H8a2.667 2.667 0 012.667 2.667V8A2.667 2.667 0 018 10.667H2.667A2.667 2.667 0 010 8V2.667A2.667 2.667 0 012.667 0zM4 2.667c-.736 0-1.333.597-1.333 1.333v2.667C2.667 7.403 3.264 8 4 8h2.667C7.403 8 8 7.403 8 6.667V4c0-.736-.597-1.333-1.333-1.333H4zM2.667 13.333H8A2.667 2.667 0 0110.667 16v5.333A2.667 2.667 0 018 24H2.667A2.667 2.667 0 010 21.333V16a2.667 2.667 0 012.667-2.667zM4 16c-.736 0-1.333.597-1.333 1.333V20c0 .736.597 1.333 1.333 1.333h2.667C7.403 21.333 8 20.736 8 20v-2.667C8 16.597 7.403 16 6.667 16H4zM16 0h5.333A2.667 2.667 0 0124 2.667V8a2.667 2.667 0 01-2.667 2.667H16A2.667 2.667 0 0113.333 8V2.667A2.667 2.667 0 0116 0zm1.333 2.667C16.597 2.667 16 3.264 16 4v2.667C16 7.403 16.597 8 17.333 8H20c.736 0 1.333-.597 1.333-1.333V4c0-.736-.597-1.333-1.333-1.333h-2.667zM16 13.333h5.333A2.667 2.667 0 0124 16v5.333A2.667 2.667 0 0121.333 24H16a2.667 2.667 0 01-2.667-2.667V16A2.667 2.667 0 0116 13.333zM17.333 16c-.736 0-1.333.597-1.333 1.333V20c0 .736.597 1.333 1.333 1.333H20c.736 0 1.333-.597 1.333-1.333v-2.667c0-.736-.597-1.333-1.333-1.333h-2.667z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div
              aria-label="Toggle Tile view"
              onClick={this.props.toggleTileView}
              className="toolbox-button"
              role="button"
              tabIndex="0"
              aria-pressed="false"
            >
              <div>
                <div className="toolbox-icon ">
                  <div className="amera-icon ">
                    <svg height="24" width="24" viewBox="0 0 24 24">
                      <path d="M2.667 0H8a2.667 2.667 0 012.667 2.667V8A2.667 2.667 0 018 10.667H2.667A2.667 2.667 0 010 8V2.667A2.667 2.667 0 012.667 0zM4 2.667c-.736 0-1.333.597-1.333 1.333v2.667C2.667 7.403 3.264 8 4 8h2.667C7.403 8 8 7.403 8 6.667V4c0-.736-.597-1.333-1.333-1.333H4zM2.667 13.333H8A2.667 2.667 0 0110.667 16v5.333A2.667 2.667 0 018 24H2.667A2.667 2.667 0 010 21.333V16a2.667 2.667 0 012.667-2.667zM4 16c-.736 0-1.333.597-1.333 1.333V20c0 .736.597 1.333 1.333 1.333h2.667C7.403 21.333 8 20.736 8 20v-2.667C8 16.597 7.403 16 6.667 16H4zM16 0h5.333A2.667 2.667 0 0124 2.667V8a2.667 2.667 0 01-2.667 2.667H16A2.667 2.667 0 0113.333 8V2.667A2.667 2.667 0 0116 0zm1.333 2.667C16.597 2.667 16 3.264 16 4v2.667C16 7.403 16.597 8 17.333 8H20c.736 0 1.333-.597 1.333-1.333V4c0-.736-.597-1.333-1.333-1.333h-2.667zM16 13.333h5.333A2.667 2.667 0 0124 16v5.333A2.667 2.667 0 0121.333 24H16a2.667 2.667 0 01-2.667-2.667V16A2.667 2.667 0 0116 13.333zM17.333 16c-.736 0-1.333.597-1.333 1.333V20c0 .736.597 1.333 1.333 1.333H20c.736 0 1.333-.597 1.333-1.333v-2.667c0-.736-.597-1.333-1.333-1.333h-2.667z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div
              aria-label="Toggle raise hand"
              className="toolbox-button"
              role="button"
              tabIndex="0"
            >
              <div>
                <div className="toolbox-icon ">
                  <div className="amera-icon ">
                    <svg height="24" width="24" viewBox="0 0 32 32">
                      <path d="M30.688 7.313v19.375c0 2.938-2.438 5.313-5.375 5.313h-9.688a5.391 5.391 0 01-3.813-1.563L1.312 19.75S3 18.125 3.062 18.125a1.7 1.7 0 011.063-.375c.313 0 .563.063.813.188.063 0 5.75 3.25 5.75 3.25V5.313c0-1.125.875-2 2-2s2 .875 2 2v9.375h1.313V2c0-1.125.875-2 2-2s2 .875 2 2v12.688h1.313V3.313c0-1.125.875-2 2-2s2 .875 2 2v11.375h1.375V7.313c0-1.125.875-2 2-2s2 .875 2 2z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="button-group-center">
            <div className="audio-preview">
              <div>
                <div>
                  <div>
                    <div className="settings-button-container">
                      {isMic === 'on' ? (
                        <div
                          aria-pressed="false"
                          aria-disabled="false"
                          aria-label="Toggle mute audio"
                          className="toolbox-button"
                          tabIndex="0"
                          role="button"
                          onClick={() => this.MuteMic()}
                        >
                          <div>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-mic`}>
                                  Microphone mute
                                </Tooltip>
                              }
                            >
                              <div className="toolbox-icon">
                                <div className="amera-icon ">
                                  <svg
                                    height="24"
                                    width="24"
                                    viewBox="0 0 32 32"
                                  >
                                    <path d="M23.063 14.688h2.25c0 4.563-3.625 8.313-8 8.938v4.375h-2.625v-4.375c-4.375-.625-8-4.375-8-8.938h2.25c0 4 3.375 6.75 7.063 6.75s7.063-2.75 7.063-6.75zm-7.063 4c-2.188 0-4-1.813-4-4v-8c0-2.188 1.813-4 4-4s4 1.813 4 4v8c0 2.188-1.813 4-4 4z"></path>
                                  </svg>
                                </div>
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      ) : (
                        <div
                          aria-pressed="true"
                          aria-disabled="false"
                          aria-label="Toggle mute audio"
                          className="toolbox-button"
                          tabIndex="0"
                          role="button"
                          onClick={() => this.unMuteMic()}
                        >
                          <div>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-mic`}>
                                  Microphone unmute
                                </Tooltip>
                              }
                            >
                              <div className="toolbox-icon toggled  ">
                                <div className="amera-icon ">
                                  <svg
                                    height="24"
                                    width="24"
                                    viewBox="0 0 32 32"
                                  >
                                    <path d="M5.688 4l22.313 22.313-1.688 1.688-5.563-5.563c-1 .625-2.25 1-3.438 1.188v4.375h-2.625v-4.375c-4.375-.625-8-4.375-8-8.938h2.25c0 4 3.375 6.75 7.063 6.75 1.063 0 2.125-.25 3.063-.688l-2.188-2.188c-.25.063-.563.125-.875.125-2.188 0-4-1.813-4-4v-1l-8-8zM20 14.875l-8-7.938v-.25c0-2.188 1.813-4 4-4s4 1.813 4 4v8.188zm5.313-.187a8.824 8.824 0 01-1.188 4.375L22.5 17.375c.375-.813.563-1.688.563-2.688h2.25z"></path>
                                  </svg>
                                </div>
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      )}

                      <div>
                        <div className="amera-icon settings-button-small-icon">
                          <svg
                            fill="none"
                            height="9"
                            width="9"
                            viewBox="0 0 10 6"
                          >
                            <path
                              clipRule="evenodd"
                              d="M8.07.248a.75.75 0 111.115 1.004L5.656 5.193a.75.75 0 01-1.115 0L1.068 1.252A.75.75 0 012.182.248L5.1 3.571 8.07.248z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sc-kgoBCf iroQPA">
                  <div></div>
                </div>
              </div>
            </div>
            <div
              aria-disabled="false"
              aria-label="Leave the call"
              className="toolbox-button"
              tabIndex="0"
              role="button"
              onClick={() => this.EndCall()}
            >
              <div>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-hang`}>Hang up</Tooltip>}
                >
                  <div className="toolbox-icon   ">
                    <div className="amera-icon ">
                      <svg height="24" width="24" viewBox="0 0 32 32">
                        <path d="M16 12c-2.125 0-4.188.313-6.125.938v4.125c0 .5-.313 1.063-.75 1.25a13.87 13.87 0 00-3.563 2.438c-.25.25-.563.375-.938.375s-.688-.125-.938-.375L.373 17.438c-.25-.25-.375-.563-.375-.938s.125-.688.375-.938c4.063-3.875 9.563-6.25 15.625-6.25s11.563 2.375 15.625 6.25c.25.25.375.563.375.938s-.125.688-.375.938l-3.313 3.313c-.25.25-.563.375-.938.375s-.688-.125-.938-.375a13.87 13.87 0 00-3.563-2.438c-.438-.188-.75-.625-.75-1.188V13c-1.938-.625-4-1-6.125-1z"></path>
                      </svg>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </div>
            <div className="video-preview">
              <div>
                <div>
                  <div>
                    <div className="settings-button-container">
                      {isCam === 'on' ? (
                        <div
                          aria-pressed="false"
                          aria-disabled="false"
                          aria-label="Toggle mute video"
                          className="toolbox-button"
                          tabIndex="0"
                          role="button"
                          onClick={() => this.MuteCam()}
                        >
                          <div>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`video-off`}>Stop Camera</Tooltip>
                              }
                            >
                              <div className="toolbox-icon   ">
                                <div className="amera-icon ">
                                  <svg
                                    height="24"
                                    width="24"
                                    viewBox="0 0 32 32"
                                  >
                                    <path d="M22.688 14l5.313-5.313v14.625l-5.313-5.313v4.688c0 .75-.625 1.313-1.375 1.313h-16C4.563 24 4 23.437 4 22.687V9.312c0-.75.563-1.313 1.313-1.313h16c.75 0 1.375.563 1.375 1.313V14z"></path>
                                  </svg>
                                </div>
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      ) : (
                        <div
                          aria-pressed="true"
                          aria-disabled="false"
                          aria-label="Toggle mute video"
                          className="toolbox-button"
                          tabIndex="0"
                          role="button"
                          onClick={() => this.unMuteCam()}
                        >
                          <div>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`video-on`}>Start Camera</Tooltip>
                              }
                            >
                              <div className="toolbox-icon toggled  ">
                                <div className="amera-icon ">
                                  <svg
                                    height="24"
                                    width="24"
                                    viewBox="0 0 32 32"
                                  >
                                    <path d="M4.375 2.688L28 26.313l-1.688 1.688-4.25-4.25c-.188.125-.5.25-.75.25h-16c-.75 0-1.313-.563-1.313-1.313V9.313c0-.75.563-1.313 1.313-1.313h1L2.687 4.375zm23.625 6v14.25L13.062 8h8.25c.75 0 1.375.563 1.375 1.313v4.688z"></path>
                                  </svg>
                                </div>
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      )}

                      <div>
                        <div className="amera-icon settings-button-small-icon">
                          <svg
                            fill="none"
                            height="9"
                            width="9"
                            viewBox="0 0 10 6"
                          >
                            <path
                              clipRule="evenodd"
                              d="M8.07.248a.75.75 0 111.115 1.004L5.656 5.193a.75.75 0 01-1.115 0L1.068 1.252A.75.75 0 012.182.248L5.1 3.571 8.07.248z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sc-kgoBCf iroQPA">
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-group-right">
            {/* <div
              onClick={() => this.props.toggleYoutube()}
              aria-label="Invite people"
              className="toolbox-button"
              role="button"
              tabIndex="0"
            >
              <div>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-invite-people`}>
                      Invite People
                    </Tooltip>
                  }
                >
                  <div className="toolbox-icon ">
                    <div className="amera-icon ">
                      <svg
                        fill="none"
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14 7a2 2 0 11-4 0 2 2 0 014 0zM8 7a4 4 0 108 0 4 4 0 00-8 0zm4 5c-6.014 0-8 2.25-8 6.75 0 1.5.667 2.25 2 2.25h8.758a4.5 4.5 0 103.812-7c-1.216-1.333-3.296-2-6.57-2zm4.164 2.653C15.236 14.191 13.894 14 12 14c-4.698 0-6 1.174-6 4.75 0 .11.004.191.007.25h8.02a4.498 4.498 0 012.136-4.347zM19 18h2v1h-2v2h-1v-2h-2v-1h2v-2h1v2z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </div> */}
            <div
              aria-pressed="false"
              aria-disabled="false"
              aria-label="Security options"
              className="toolbox-button"
              tabIndex="0"
              role="button"
              // onClick={() => this.props.toggleEncy()}}
              onClick={() => {
                console.log('clicked');
                this.props.handleSecurity();
              }}
            >
              <div>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-security-options`}>
                      Security Options
                    </Tooltip>
                  }
                >
                  <div className="toolbox-icon   security-toolbar-button">
                    <div className="amera-icon ">
                      <svg
                        fill="none"
                        height="24"
                        width="24"
                        viewBox="0 0 24 25"
                      >
                        <path
                          d="M3.876 3.008L3 3.118v10.557a7 7 0 003.29 5.935l5.18 3.238.53.331.53-.331 5.18-3.238A7 7 0 0021 13.675V3.117l-.876-.11-8-1L12 1.993l-.124.016-8 1z"
                          fill="url(#security-off_svg__paint0_linear)"
                          stroke="#fff"
                          strokeWidth="2"
                        ></path>
                        <path
                          d="M20 4v9.675a6 6 0 01-2.82 5.087L12 22V3l8 1z"
                          fill="url(#security-off_svg__paint1_linear)"
                        ></path>
                        <defs>
                          <linearGradient
                            id="security-off_svg__paint0_linear"
                            x1="11.814"
                            y1="4.142"
                            x2="11.814"
                            y2="23"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FFB800"></stop>
                            <stop offset="1" stopColor="#CF4B00"></stop>
                          </linearGradient>
                          <linearGradient
                            id="security-off_svg__paint1_linear"
                            x1="11.814"
                            y1="4.142"
                            x2="11.814"
                            y2="23"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FF8A00"></stop>
                            <stop offset="1" stopColor="#A03B03"></stop>
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </div>

            <Dropdown drop="up">
              <Dropdown.Toggle
                id="ToolboxMoreMenu"
                className="toolbox-icon"
                variant="text"
              >
                <IconMenuThumb color="#ffffff" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ProfileItem  image={'/api/'+ APP.vacall.localParticipant._avatar_url } eventKey="1" />
                <Settings eventKey="8" trigger={this.props.toggleSetting} />
                <ManageQuality
                  eventKey="2"
                  trigger={this.props.toggleVideoQuality}
                />
                <FullScreen
                  eventKey="3"
                  trigger={this.props.toggleFullScreen}
                />
                {/* <LiveStream eventKey="4" /> */}
                {/* <Recording eventKey="5" /> */}
                {/* <YoutubeVideo
                  eventKey="6"
                  shareOn={this.props.youtubeStatus}
                  trigger={this.props.toggleYoutube}
                /> */}
                <MuteEveryOne eventKey="9" />
                <BlurBackground eventKey="7" trigger={this._toggleBlurEffect}/>
                 {/* <SpeakerStats eventKey="9" /> */}
                {/* <EmbedMeeting eventKey="10" /> */}
                {/* <LiveFeedback eventKey="11" /> */}
                <ViewShortcuts
                  eventKey="12"
                  trigger={this.props.toggleShortcut}
                />
                <Help eventKey="13" />
              </Dropdown.Menu>
            </Dropdown>
            {/*
            <div
              aria-pressed="false"
              aria-disabled="false"
              aria-label="Security options"
              className="toolbox-button"
              tabIndex="0"
              role="button"
              onClick={this.props.toggleSetting}
            >
              <div>
                <div>
                  <div>
                    <div
                      aria-label="Toggle more actions menu"
                      aria-pressed="false"
                      className="toolbox-button"
                      role="button"
                      tabIndex="0"
                    >
                      <div>
                        <div className="toolbox-icon ">
                          <div className="amera-icon ">
                            <svg height="24" width="24" viewBox="0 0 24 24">
                              <path d="M12 15.984c1.078 0 2.016.938 2.016 2.016s-.938 2.016-2.016 2.016S9.984 19.078 9.984 18s.938-2.016 2.016-2.016zm0-6c1.078 0 2.016.938 2.016 2.016s-.938 2.016-2.016 2.016S9.984 13.078 9.984 12 10.922 9.984 12 9.984zm0-1.968c-1.078 0-2.016-.938-2.016-2.016S10.922 3.984 12 3.984s2.016.938 2.016 2.016S13.078 8.016 12 8.016z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sc-kgoBCf iroQPA">
                  <div></div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(injectIntl(buttons));
