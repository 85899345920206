export default {
  NICKNAME_CHANGED: 'UI.nickname_changed',

  /**
   * Notifies that local user changed email.
   */
  EMAIL_CHANGED: 'UI.email_changed',

  /**
   * Notifies that "start muted" settings changed.
   */
  AUDIO_MUTED: 'UI.audio_muted',
  VIDEO_MUTED: 'UI.video_muted',
  ETHERPAD_CLICKED: 'UI.etherpad_clicked',
  SHARED_VIDEO_CLICKED: 'UI.start_shared_video',

  /**
   * Updates shared video with params: url, state, time(optional)
   * Where url is the video link, state is stop/start/pause and time is the
   * current video playing time.
   */
  UPDATE_SHARED_VIDEO: 'UI.update_shared_video',
  TOGGLE_FULLSCREEN: 'UI.toogle_fullscreen',
  FULLSCREEN_TOGGLED: 'UI.fullscreen_toggled',
  AUTH_CLICKED: 'UI.auth_clicked',

  /**
   * Notifies that the audio only mode was toggled.
   */
  TOGGLE_AUDIO_ONLY: 'UI.toggle_audioonly',

  /**
   * Notifies that a command to toggle the filmstrip has been issued. The
   * event may optionally specify a {Boolean} (primitive) value to assign to
   * the visibility of the filmstrip (i.e. the event may act as a setter).
   * The very toggling of the filmstrip may or may not occurred at the time
   * of the receipt of the event depending on the position of the receiving
   * event listener in relation to the event listener which carries out the
   * command to toggle the filmstrip.
   *
   * @see {TOGGLED_FILMSTRIP}
   */
  TOGGLE_FILMSTRIP: 'UI.toggle_filmstrip',

  TOGGLE_SCREENSHARING: 'UI.toggle_screensharing',
  HANGUP: 'UI.hangup',
  LOGOUT: 'UI.logout',
  VIDEO_DEVICE_CHANGED: 'UI.video_device_changed',
  AUDIO_DEVICE_CHANGED: 'UI.audio_device_changed',

  /**
   * Notifies that flipX property of the local video is changed.
   */
  LOCAL_FLIPX_CHANGED: 'UI.local_flipx_changed',

  /**
   * Notifies that the side toolbar container has been toggled. The actual
   * event must contain the identifier of the container that has been toggled
   * and information about toggle on or off.
   */
  SIDE_TOOLBAR_CONTAINER_TOGGLED: 'UI.side_container_toggled',

  /**
   * Notifies that the raise hand has been changed.
   */
  LOCAL_RAISE_HAND_CHANGED: 'UI.local_raise_hand_changed',

  /**
   * Notifies that the avatar is displayed or not on the largeVideo.
   */
  LARGE_VIDEO_AVATAR_VISIBLE: 'UI.large_video_avatar_visible',

  /**
   * Notifies that the displayed particpant id on the largeVideo is changed.
   */
  LARGE_VIDEO_ID_CHANGED: 'UI.large_video_id_changed',
  USER_COUNT: 'UI.user_count',
  AUDIO_ONLY: 'UI.audio_only',
  MUTE_ALL: 'UI.mute_all',
  MUTE_AUDIO: 'UI.mute_audio',
  OPEN_SHORTCUT: 'UI.open_shortcut',
  MIC_INDICATOR:'UI.mic_indicator',
  VIDEO_INDICATOR:'UI.video_indicator',
  VERTICAL_VIEW:'UI.vertical_view',
  FULL_SCREEN:'UI.full_screen',
  SWITCHCAM:'UI.switchcam',
  BLUR:'UI.blur'
};
