import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';
import Modal from '../../components/Modal/modal';
import Select from 'react-select';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import usePrevious from '../../hooks/usePrevious';

import {
  createExpandedGroup,
  setReduxGroupAlert,
} from '../../redux/actions/group';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  input: {
    display: 'none',
  },
  imageUpload: {
    cursor: 'pointer',
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  imageWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
  },
  media: {},
  formControl: {
    minWidth: '100%',
  },
  submitBtn: {
    margin: theme.spacing(1),
    width: '100%',
    '&:hover .MuiCircularProgress-circle': {
      color: '#4caf50',
    },
    '& .MuiCircularProgress-circle': {
      color: '#1b5e20',
    },
  },
  addMemberWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  alert: {
    margin: theme.spacing(1),
    width: '100%',
  },
  groupAlert: {
    '& .MuiAlert-filledSuccess': {
      backgroundColor: '#1b5e20',
    },
  },
}));

const ExpandedCreateGroupModal = (props) => {
  const { members, loadingMembers, groupAlert, createGroupLoading } = props;
  const { show, close } = props;

  const [state, setState] = useState({
    name: '',
    picture: '',
    pin: '',
    members: [],
    exchangeOption: 'LEAST_SECURE',
    nameError: '',
    pictureError: '',
    pinError: '',
  });

  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [expanded, setExpanded] = useState({
    memberList: true,
    addMember: true,
    exchangeOption: true,
  });
  const [emailOptions, setEmailOptions] = useState([]);
  const [groupMemberEmail, setGroupMemberEmail] = useState(null);

  const nameRef = useRef(null);
  const pictureRef = useRef(null);
  const pinRef = useRef(null);

  const prevGroupLoading = usePrevious(createGroupLoading);

  const title = 'Create New Group';
  const classes = useStyles();

  const mutateState = (data) => {
    setState((state) => ({
      ...state,
      ...data,
    }));
  };

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  /**
   *  ========================================= EVENT HANDLERS START =============================================
   */

  const hideAlert = () => {
    props.setReduxGroupAlert({
      show: false,
      variant: groupAlert.variant,
      message: '',
    });
  };

  const handleChange = (value, prop) => {
    setState((state) => ({
      ...state,
      [prop]: value,
      [`${prop}Error`]: '',
    }));
  };

  const handleUploadClick = (event) => {
    event.persist();
    const file = event.target.files[0];

    if (!file) {
      setImagePreviewUrl('');
      mutateState({
        pictureError: 'File is not selected, please choose image',
      });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    const imageType = isFileImage(file);

    if (imageType) {
      reader.onloadend = function (e) {
        handleChange(file, 'picture');
        setImagePreviewUrl(reader.result);
      };
    } else {
      setImagePreviewUrl('');
      mutateState({
        pictureError: 'File is not selected, please choose image',
      });
      return false;
    }
  };

  const isFileImage = (file) => {
    return file && file['type'].split('/')[0] === 'image';
  };

  const handleCreateGroup = (e) => {
    e.preventDefault();
    if (state.name === '') {
      mutateState({ nameError: 'Group name is required' });
      scrollToRef(nameRef);
      return;
    }

    if (state.picture === '') {
      mutateState({
        pictureError: 'File is not selected, please choose image',
      });
      scrollToRef(pictureRef);
      return;
    }

    if (state.pin === '') {
      mutateState({ pinError: 'Pin is required' });
      scrollToRef(pinRef);
      return;
    }

    const formData = new FormData();
    const requestMembers = state.members.map((x) => x.member_id);
    formData.set('name', state.name);
    formData.set('picture', state.picture);
    formData.set('pin', state.pin);
    formData.set('members', JSON.stringify(requestMembers));
    formData.set('exchangeOption', state.exchangeOption);
    formData.set('type', 'create-group');
    props.createExpandedGroup(formData);
  };

  const handleExpanded = (section) => {
    return () => {
      setExpanded((state) => ({ ...state, [section]: !state[section] }));
    };
  };

  const handleMemberAdd = () => {
    if (groupMemberEmail) {
      const member = members.find((x) => x.email === groupMemberEmail.value);
      setState((state) => ({
        ...state,
        members: [...state.members, member],
      }));
      setEmailOptions((options) =>
        options.filter((x) => x.value !== groupMemberEmail.value)
      );
      setGroupMemberEmail(null);
    }
  };

  const handleMemberRemove = (email) => {
    setState((state) => {
      const newMembers = state.members.filter((x) => x.email !== email);

      return {
        ...state,
        members: newMembers,
      };
    });
    setEmailOptions((options) => [...options, { value: email, label: email }]);
  };

  useEffect(() => {
    let options = members.map((x) => ({
      value: x.email,
      label: x.email,
    }));
    options = options.filter((x) => {
      if (state.members.find((stateMember) => stateMember.email === x.value))
        return false;
      return true;
    });
    setEmailOptions(options);
  }, [state.members, members]);

  useEffect(() => {
    if (prevGroupLoading === true && createGroupLoading === false) close();
  }, [prevGroupLoading, createGroupLoading, close]);

  /**
   *  ========================================= RENDERERS START =============================================
   */

  const renderSection = (
    renderContent,
    className,
    title,
    expanded,
    expandController
  ) => (
    <Grid item xs={12} className={className}>
      <Accordion square expanded={expanded} onChange={expandController}>
        <AccordionSummary aria-controls="member-card" id="member-card-header">
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderContent()}</AccordionDetails>
      </Accordion>
    </Grid>
  );

  const renderMemberListContent = () => (
    <List dense={false}>
      {state.members &&
        state.members.map((member) => (
          <ListItem key={member.member_id}>
            <ListItemText
              primary={`${member.first_name} ${member.last_name}`}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  handleMemberRemove(member.email);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  );

  const renderAddMemberContent = () => (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        Email
      </Grid>
      <Grid item xs={8}>
        <Select
          options={emailOptions}
          placeholder="john@mail.com"
          isLoading={loadingMembers}
          onChange={(value) => {
            setGroupMemberEmail(value);
          }}
          isSearchable={true}
          value={groupMemberEmail || null}
        />
      </Grid>
      <Grid item xs={12} className={classes.addMemberWrapper}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleMemberAdd}
        >
          Add Member
        </Button>
      </Grid>
    </Grid>
  );

  const renderMemberList = () =>
    renderSection(
      renderMemberListContent,
      'group-member-card group-detail-card',
      'Members',
      expanded.memberList,
      handleExpanded('memberList')
    );

  const renderAddMember = () =>
    renderSection(
      renderAddMemberContent,
      'group-detail-card',
      'Add member',
      expanded.addMember,
      handleExpanded('addMember')
    );

  const renderExchangeOptionFormContent = () => (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="exchange"
        name="exchange"
        value={state.exchangeOption}
        onChange={(e) => {
          e.persist();
          handleChange(e.target.value, 'exchangeOption');
        }}
      >
        <FormControlLabel
          value="MOST_SECURE"
          control={<Radio />}
          label="Exchange in person - Most secure"
        />
        <FormControlLabel
          value="VERY_SECURE"
          control={<Radio />}
          label="Exchange Picture using system and PIN Verbally (Very Secure)"
        />
        <FormControlLabel
          value="SECURE"
          control={<Radio />}
          label="Exchange Picture using system and PIN SMS (Secure)"
        />
        <FormControlLabel
          value="LEAST_SECURE"
          control={<Radio />}
          label="Exchange Picture and PIN between group using system to distribute (Least Secure)"
        />
      </RadioGroup>
    </FormControl>
  );

  const renderExchangeOptionForm = () =>
    renderSection(
      renderExchangeOptionFormContent,
      'group-detail-card',
      'Group Picture and PIN Exchange Options',
      expanded.exchangeOption,
      handleExpanded('exchangeOption')
    );

  const ModalContent = () => (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            id="outlined-group_name"
            label="Group Name"
            placeholder="Amera lot"
            type="text"
            variant="outlined"
            name="group_name"
            required
            value={state.name || ''}
            onChange={(e) => {
              e.persist();
              handleChange(e.target.value, 'name');
            }}
            error={state.nameError === '' ? false : true}
            helperText={state.nameError === '' ? '' : state.nameError}
            className={classes.formControl}
            ref={nameRef}
          />
        </Grid>
        {imagePreviewUrl === '' && (
          <Grid item xs={12}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleUploadClick}
            />
            <label
              htmlFor="contained-button-file"
              className={classes.imageUpload}
              ref={pictureRef}
            >
              <span>Upload picture </span>
              <CloudUploadRoundedIcon color="primary" fontSize="large" />
            </label>
          </Grid>
        )}
        {imagePreviewUrl !== '' && (
          <Grid item xs={12}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleUploadClick}
            />
            <label htmlFor="contained-button-file">
              <div className={classes.imageWrapper}>
                <img width="100%" src={imagePreviewUrl} alt="preview" />
              </div>
            </label>
          </Grid>
        )}
        {state.pictureError !== '' && (
          <Grid item xs={12}>
            <Alert variant="filled" severity="warning">
              {state.pictureError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            id="outlined-pin"
            label="PIN, min: 6, max: 12"
            placeholder="123456789123"
            type="text"
            variant="outlined"
            name="pin_code"
            required
            value={state.pin || ''}
            onChange={(e) => {
              e.persist();
              handleChange(e.target.value, 'pin');
            }}
            error={state.pinError === '' ? false : true}
            helperText={state.pinError === '' ? '' : state.pinError}
            className={classes.formControl}
            ref={pinRef}
          />
        </Grid>
        {renderMemberList()}
        {renderAddMember()}
        {renderExchangeOptionForm()}
      </Grid>
    </div>
  );

  const ModalAction = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        disableRipple
        onClick={(e) => handleCreateGroup(e)}
      >
        {props.createGroupLoading ? (
          <CircularProgress size={30} thickness={3} />
        ) : (
          'Create'
        )}
      </Button>
    );
  };

  return (
    <>
      {groupAlert.show ? (
        <Snackbar
          open={groupAlert.show}
          autoHideDuration={5000}
          onClose={hideAlert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <div className={classes.groupAlert}>
            <Alert
              onClose={hideAlert}
              variant="filled"
              severity={groupAlert.variant}
            >
              {groupAlert.message}
            </Alert>
          </div>
        </Snackbar>
      ) : (
        <Modal
          open={show}
          onClose={close}
          title={title}
          ModalContent={ModalContent}
          ModalAction={ModalAction}
          className="create-group-modal"
        />
      )}
    </>
  );
};

ExpandedCreateGroupModal.propTypes = {
  dispatch: PropTypes.func,
  show: PropTypes.bool,
  close: PropTypes.func,
  member: PropTypes.object,
};

ExpandedCreateGroupModal.defaultProps = {
  dispatch: null,
  show: false,
  close: null,
  member: {},
};

const mapStateToProps = (state) => ({
  members: state.member.members,
  createGroupLoading: state.group.createGroupLoading,
  groupAlert: state.group.groupAlert,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      { createExpandedGroup, setReduxGroupAlert },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpandedCreateGroupModal);
