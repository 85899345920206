import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  BtnWithCircular: {
    margin: theme.spacing(1),
    '&:hover .MuiCircularProgress-circle': {
      color: '#ff00ff',
    },
    '& .MuiCircularProgress-circle': {
      color: '#ff0000',
    },
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
}));

export default useStyles;
