import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// import ProfileEdit from './ProfileEdit';
import MyProfile from './Profiles/MyProfile';

import {
  setReduxMemberAlert,
} from '../../redux/actions/member';

const Profile = (props) => {
  const { open, onClose, memberAlert } = props;

  const handleSnackbarClose = () => {
    if (memberAlert.show) {
      const alertOption = {
        show: false,
        variant: memberAlert.variant,
        message: '',
      };
      props.setReduxMemberAlert(alertOption);
    }
  };

  return (
    <>
      {/* <ProfileEdit open={open} onClose={onClose} /> */}
      <MyProfile open={open} onClose={onClose} handleSnackbarClose={handleSnackbarClose}/>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={memberAlert.show}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={memberAlert.variant}>{memberAlert.message}</Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  memberAlert: state.member.memberAlert
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setReduxMemberAlert,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
