import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const EventName = (props) => {
  const { value, onChange, error, defaultName } = props;
  return (
    <TextField
      label="Event Name"
      fullWidth
      value={value}
      placeholder={defaultName}
      autoFocus
      // variant="outlined"
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment postion="end">
            <IconButton onClick={() => onChange('')}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={!!error}
      helperText={!!error ? error : ''}
    />
  );
};

export default EventName;
