export const MEMBER = 'MEMBER';
export const MEMBER_LOGIN_SUCCEEDED = 'MEMBER_LOGIN_SUCCEEDED';
export const MEMBER_LOGIN_FAILED = 'MEMBER_LOGIN_FAILED';
export const GET_MEMBER_DATA_SUCCEEDED = 'GET_MEMBER_DATA_SUCCEEDED';
export const GET_MEMBER_DATA_FAILED = 'GET_MEMBER_DATA_FAILED';
export const MEMBER_LOGOUT_SUCCEEDED = 'MEMBER_LOGOUT_SUCCEEDED';
export const MEMBER_LOGOUT_FAILED = 'MEMBER_LOGOUT_FAILED';

export const SEND_MEMBER_RESET_EMAIL_START = 'SEND_MEMBER_RESET_EMAIL_START';
export const SEND_MEMBER_RESET_EMAIL_SUCCEEDED =
  'SEND_MEMBER_RESET_EMAIL_SUCCEEDED';
export const SEND_MEMBER_RESET_EMAIL_FAILED = 'SEND_MEMBER_RESET_EMAIL_FAILED';

export const SAVE_SIGN_UP_SESSION = 'SAVE_SIGN_UP_SESSION';
export const CLEAR_SIGN_UP_SESSION = 'CLEAR_SIGN_UP_SESSION';

export const GET_MEMBER_LOCATION_INFO_START = 'GET_MEMBER_LOCATION_INFO_START';
export const GET_MEMBER_LOCATION_INFO_SUCCEEDED =
  'GET_MEMBER_LOCATION_INFO_SUCCEEDED';
export const GET_MEMBER_LOCATION_INFO_FAILED =
  'GET_MEMBER_LOCATION_INFO_FAILED';
export const MEMBERS_LOADING = 'MEMBERS_LOADING';
export const MEMBERS_LOAD_SUCCESS = 'MEMBERS_LOAD_SUCCESS';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const VALID_INVITE = 'VALID_INVITE';
export const MEMBER_SAVE_CREDENTIALS = 'MEMBER_SAVE_CREDENTIALS';

export const FETCH_REQUEST_START = 'FETCH_REQUEST_START';
export const FETCH_REQUEST_SUCCESS = 'FETCH_REQUEST_SUCCESS';
export const FETCH_REQUEST_FAILED = 'FETCH_REQUEST_FAILED';

export const UPDATE_REQUEST_START = 'UPDATE_REQUEST_START';
export const UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS';
export const UPDATE_REQUEST_FAILED = 'UPDATE_REQUEST_FAILED';

// Registration form
export const SEND_REGISTER_START = 'SEND_REGISTER_START';
export const SEND_REGISTER_SUCCEEDED = 'SEND_REGISTER_SUCCEDED';
export const SEND_REGISTER_FAILED = 'SEND_REGISTER_FAILED';
// Job title
export const GET_TITLE_LIST_START = 'GET_TITLE_LIST_START';
export const GET_TITLE_LIST_SUCCEEDED = 'GET_TITLE_LIST_SUCCEEDED';
export const GET_TITLE_LIST_FAILED = 'GET_TITLE_LIST_FAILED';
// Department list
export const GET_DEPS_LIST_START = 'GET_DEPS_LIST_START';
export const GET_DEPS_LIST_SUCCEEDED = 'GET_DEPS_LIST_SUCCEEDED';
export const GET_DEPS_LIST_FAILED = 'GET_DEPS_LIST_FAILED';
// Cell verification
export const CELL_VERIFICATION_INIT = 'CELL_VERIFICATION_INIT';
export const CELL_VERIFICATION_ATTEMPT = 'CELL_VERIFICATION_START';
export const CELL_VERIFICATION_RESPONSE = 'CELL_VERIFICATION_RESPONSE';
export const CELL_VERIFICATION_ERROR = 'CELL_VERIFICATION_ERROR';

// Terms and conditions
export const GET_TERMS_AND_CONDITIONS_START = 'GET_TERMS_AND_CONDITIONS_START';
export const GET_TERMS_AND_CONDITIONS_SUCCESS =
  'GET_TERMS_AND_CONDITIONS_SUCCESS';
export const GET_TERMS_AND_CONDITIONS_FAILED =
  'GET_TERMS_AND_CONDITIONS_FAILED';

//  Country list
export const GET_COUNTRY_LIST_START = 'GET_COUNTRY_LIST_START';
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_FAILED = 'GET_COUNTRY_LIST_FAILED';

// Timezone list
export const GET_TZLIST_START = 'GET_TZLIST_START';
export const GET_TZLIST_SUCCESS = 'GET_TZLIST_SUCCESS';
export const GET_TZLIST_FAILED = 'GET_TZLIST_FAILED';

// Member info
export const GET_MEMBER_INFO_START = 'GET_MEMBER_INFO_START';
export const GET_MEMBER_INFO_SUCCESS = 'GET_MEMBER_INFO_SUCCESS';
export const GET_MEMBER_INFO_FAILED = 'GET_MEMBER_INFO_FAILED';
export const PUT_MEMBER_INFO_START = 'PUT_MEMBER_INFO_START';
export const PUT_MEMBER_INFO_SUCCESS = 'PUT_MEMBER_INFO_SUCCESS';
// export const PUT_MEMBER_INFO_FAILED = 'PUT_MEMBER_INFO_FAILED';

export const GET_MEMBER_SETTING_SUCCESS = 'GET_MEMBER_SETTING_SUCCESS';
export const PUT_MEMBER_SETTING_SUCCESS = 'PUT_MEMBER_SETTING_SUCCESS';

// Avatar update
export const PUT_AVATAR_UPDATE_START = 'PUT_AVATAR_UPDATE_START';
export const PUT_AVATAR_UPDATE_SUCCESS = 'PUT_AVATAR_UPDATE_SUCCESS';
export const PUT_AVATAR_UPDATE_FAILED = 'PUT_AVATAR_UPDATE_FAILED';

export const FETCH_ROLES_START = 'FETCH_ROLES_START';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED';

export const FETCH_NOTIFICATIONS_SETTING_START =
  'FETCH_NOTIFICATIONS_SETTING_START';
export const FETCH_NOTIFICATIONS_SETTING_SUCCESS =
  'FETCH_NOTIFICATIONS_SETTING_SUCCESS';
export const FETCH_NOTIFICATIONS_SETTING_FAILED =
  'FETCH_NOTIFICATIONS_SETTING_FAILED';
export const PUT_NOTIFICATIONS_SETTING_START =
  'PUT_NOTIFICATIONS_SETTING_START';
export const PUT_NOTIFICATIONS_SETTING_SUCCESS =
  'PUT_NOTIFICATIONS_SETTING_SUCCESS';
export const PUT_NOTIFICATIONS_SETTING_FAILED =
  'PUT_NOTIFICATIONS_SETTING_FAILED';

export const SET_MEMBER_ALERT = 'SET_MEMBER_ALERT';
export const SHOW_COUNTDOWN = 'SHOW_COUNTDOWN';
export const SET_COUNTDOWN_TIMER = 'SET_COUNTDOWN_TIMER';
export const CLEAR_COUNTDOWN_TIMER = 'CLEAR_COUNTDOWN_TIMER';
export const AUTO_LOGOUT = 'AUTO_LOGOUT';
export const CLEAR_AUTO_LOGOUT = 'CLEAR_AUTO_LOGOUT';

export const GET_ALL_MEMBERS = 'GET_ALL_MEMBERS';
