import fetch from 'isomorphic-unfetch';
import { logout } from '../redux/actions/member';
import { store } from '../utils/store';
import { has } from 'lodash';

export const apiUrl = window.apiUrl || `${window.location.origin}/api`;

export const storeing = store;
export const redirectToLogin = () => {
  if (window.location.pathname !== '/login') {
    const state = store.getState();
    const member = state.member.member;

    if (has(member, 'member_id')) {
      console.debug('Firing logout at redirect to Login', member);
      logout(member.member_id, member.session_id)(store.dispatch);
    }
  }
};

export const GET = (url, option = {}) => {
  return fetch(url, {
    method: 'GET',
    headers: option,
  }).then((res) => {
    if (res.ok) {
      try {
        if (res.headers.get('content-length') === '0') {
          return Promise.resolve();
        }
        return res.json();
      } catch (e) {
        return res;
      }
    }
    console.log('Failed.');
    console.error(res);
    // if the API returns a 401, redirect to login
    if (res.status === 401) {
      return redirectToLogin();
    }
    let error = new Error(res.statusText);
    error.res = res;
    return Promise.reject(error);
  });
};

export const POST = (url, data, option = {}) =>
  fetch(url, {
    method: 'POST',
    body: data,
    headers: option,
  }).then((res) => {
    if (res.ok) {
      try {
        if (res.headers.get('content-length') === '0') {
          return Promise.resolve();
        }
        return res.json();
      } catch (e) {
        return res;
      }
    }
    console.log('Failed.');
    // if the API returns a 401, redirect to login
    if (res.status === 401) {
      return redirectToLogin();
    }

    let error = new Error(res.statusText);
    error.res = res;
    try {
      error.res.data = res.json().catch((e) => {
        console.error(e);
      });
    } catch (e) {
      error.res.data = Promise.resolve({});
    }
    console.error(error);
    console.error(res);
    return Promise.reject(error);
  });

export const PUT = (url, data, option = {}) =>
  fetch(url, {
    method: 'PUT',
    body: data,
    headers: option,
  }).then((res) => {
    if (res.ok) {
      try {
        if (res.headers.get('content-length') === '0') {
          return Promise.resolve();
        }
        return res.json();
      } catch (e) {
        return res;
      }
    }
    console.log('Failed.');
    // if the API returns a 401, redirect to login
    if (res.status === 401) {
      return redirectToLogin();
    }

    let error = new Error(res.statusText);
    error.res = res;
    console.error(error);
    console.error(res);
    return Promise.reject(error);
  });

export const DELETE = (url, data, option = {}) =>
  fetch(url, {
    method: 'DELETE',
    body: data,
    headers: option,
  }).then((res) => {
    if (res.ok) {
      try {
        if (res.headers.get('content-length') === '0') {
          return Promise.resolve();
        }
        return res.json();
      } catch (e) {
        return res;
      }
    }

    console.log('Failed.');
    // if the API returns a 401, redirect to login
    if (res.status === 401) {
      return redirectToLogin();
    }

    let error = new Error(res.statusText);
    error.res = res;
    try {
      error.res.data = res.json().catch((e) => {
        console.error(e);
      });
    } catch (e) {
      error.res.data = {};
    }
    console.error(error);
    console.error(res);
    return Promise.reject(error);
  });
