import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import Pagination from '@material-ui/lab/Pagination';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SecurityIcon from '@material-ui/icons/Security';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import SVG from '../../components/Icons/SVG';
import GroupDetailModal from './GroupDetailModal';
import GroupDriveModal from './GroupDriveModal';
import MemberShipDetailModal from './MemberShipDetailModal';
import MemberShipDriveModal from './MemberShipDriveModal';
import SaveData from '../../components/Modal/SaveData';
import GroupActivityModal from './GroupActivityModal';
import {
  getGroupDetail,
  getGroupLeaderDetail,
  setShowGroupDetailModal,
  deleteGroups,
} from '../../redux/actions/group';

import { setCallGroupId } from '../../redux/actions/event';

import {
  textCapitalized,
  openGroupVideoConference,
  openGroupCallConference,
} from '../../utils/general';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import SecurityDetailModal from './SecurityDetailModal';
import AmeraPagination from '../../components/AmeraPagination';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 12,
    right: 40,
    width: 100,
    background: 'transparent',
    border: '1px solid #aaaaaa',
    height: 20,
    borderRadius: 8,
  },
  bar: {
    // borderRadius: 8,
    backgroundColor: '#94c03d',
    // margin: 1,
  },
}))(LinearProgress);

const CardContainer = (props) => {
  const {
    dispatch,
    groups,
    // ameraGroupSecurity,
    onScheduleClick = () => null,
    openDetailsFor,
    showGroupDetailModal,
    isDrive,
    type,
    member,
  } = props;
  let history = useHistory();

  const [isShowSecurityModal, setShowSecurityModal] = useState(false);

  const [membershipDetail, setMembershipDetail] = useState({});
  const [itemsPerPage, setItemPerPage] = useState(25);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [page, setPage] = useState(1);
  const [showSaveDataModal, setShowSaveDataModal] = useState(false);
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [group_id, setGroupId] = useState();
  const [group_leader_id, setGroupLeaderId] = useState();

  const showGroupDetail = useCallback(
    (group, isDrive = false) => {
      if (type === 'group') dispatch(getGroupDetail(group.group_id));
      if (type === 'membership') setMembershipDetail(group);
      dispatch(setShowGroupDetailModal(true, isDrive));

      // const isGroupSecurity = filterAmeraGroupSecurity(group.group_id);
      // if (!isGroupSecurity) {
      //   setShowSaveDataModal(true);
      // } else {
      //   dispatch(setShowGroupDetailModal(true));
      // }
    },
    [dispatch, type]
  );

  useEffect(() => {
    if (!!openDetailsFor) {
      showGroupDetail(groups.find((go) => go.group_id === openDetailsFor));
    }
  }, [openDetailsFor, groups, showGroupDetail]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // const filterAmeraGroupSecurity = useCallback(
  //   (groupId) => {
  //     for (var i = 0; i < ameraGroupSecurity.length; i++) {
  //       if (ameraGroupSecurity[i].groupId === groupId) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [ameraGroupSecurity]
  // );

  const handleGroupFilesClick = (group) => {
    // Now that the Group files live in that modal
    showGroupDetail(group, true);
  };

  const showGroupSecurity = async (group_id, group_leader_id) => {
    await setGroupId(group_id);
    await setGroupLeaderId(group_leader_id);
    setShowSecurityModal(true);
  };

  const handleSelect = (event, name) => {
    const selectedIndex = selectedGroups.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedGroups, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedGroups.slice(1));
    } else if (selectedIndex === selectedGroups.length - 1) {
      newSelected = newSelected.concat(selectedGroups.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedGroups.slice(0, selectedIndex),
        selectedGroups.slice(selectedIndex + 1)
      );
    }
    setSelectedGroups(newSelected);
  };

  const deleteGroupsFunc = (group_id) => {
    console.log('deleting group==>', group_id);
    let formData = new FormData();
    if (group_id) {
      let groupIds = [];
      groupIds.push(group_id);
      formData.set('groupIds', groupIds);
      props.deleteGroups(formData);
    } else {
      formData.set('groupIds', selectedGroups);
      props.deleteGroups(formData);
      setSelectedGroups([]);
    }
  };

  const openGroupCallAndNotify = async (group, member) => {
    await openGroupCallConference(group, member.member);
    await dispatch(setCallGroupId(group.group_id));
    // history.push(`${process.env.PUBLIC_URL}${conferenceUrl}`);
  };

  const openGroupVideoAndNotify = async (group, member) => {
    const conferenceUrl = await openGroupVideoConference(group, member.member);
    await dispatch(setCallGroupId(group.group_id));
    history.push(`${process.env.PUBLIC_URL}${conferenceUrl}`);
  };

  const openActivityModal = (group) => {
    setGroupId(group.group_id);
    dispatch(getGroupDetail(group.group_id));
    dispatch(getGroupLeaderDetail(group.group_leader_id));
    setShowActivityModal(true);
  };

  return (
    <React.Fragment>
      <Collapse in={selectedGroups.length > 0}>
        <EnhancedTableToolbar
          title=""
          numSelected={selectedGroups.length}
          deleteGroupsFunc={deleteGroupsFunc}
        />
      </Collapse>
      <Grid container className="group-container">
        {/* {groups.length > 0 && (
          <div className="card-pagination contact-cards-pagination">
            <div className="item-per-page">
              <FormControl>
                <Select
                  labelId="item-count-per-page-select-label"
                  id="item-count-per-page-select"
                  value={itemsPerPage}
                  onChange={(e) => setItemPerPage(e.target.value)}
                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={75}>75</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
              <p className="item-count-text">Cards Per Page</p>
            </div>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={noOfPages}
              page={page}
              onChange={handlePageChange}
              defaultPage={1}
              color="primary"
              showFirstButton
              showLastButton
            />
          </div>
        )} */}
        {groups
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .map((group, index) => (
            <Grid item md={6} sm={12} lg={4} key={index} className="group-card">
              <Card key={group.group_id}>
                <div className="custom-card_header">
                  <div className="primary-title">
                    <p className="title">{textCapitalized(group.group_name)}</p>
                  </div>
                  <p className="secondary-title">
                    Group Leader: {group.group_leader_name}
                  </p>
                  <p className="secondary-title">
                    Department: {group.department || ''}
                  </p>
                  <p className="secondary-title">
                    Group Members: {group.total_member + 1}
                  </p>
                  <p className="secondary-title">
                    Files: {group.total_files || 0}
                  </p>
                  <p className="secondary-title">
                    Date Created:{' '}
                    {group.group_create_date &&
                      format(new Date(group.group_create_date), 'P')}
                  </p>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      group?.group_exchange_option
                        ? group.group_exchange_option
                        : 0
                    }
                    onClick={() => {
                      showGroupSecurity(group.group_id, group.group_leader_id);
                    }}
                  />
                  <p className="bar-value">
                    {group?.group_exchange_option
                      ? group.group_exchange_option
                      : 0}
                    %
                  </p>
                  <p
                    className="bar-title"
                    onClick={() =>
                      showGroupSecurity(group.group_id, group.group_leader_id)
                    }
                  >
                    Security Level
                  </p>
                  <div className="select-checkbox">
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      onClick={(e) => handleSelect(e, group.group_id)}
                    />
                  </div>
                </div>
                <CardActions disableSpacing className="action-button-group">
                  <div
                    className="icon-button"
                    onClick={() => handleGroupFilesClick(group)}
                  >
                    <IconButton aria-label="shared files">
                      <SVG name="fileShareSmall" />
                    </IconButton>
                    <p className="icon-button-label">Shared Files</p>
                  </div>
                  <div className="icon-button">
                    <IconButton aria-label="videos">
                      <SVG name="fileShareSmall" />
                    </IconButton>
                    <p className="icon-button-label">Videos</p>
                  </div>
                  <div className="icon-button">
                    <IconButton aria-label="history">
                      <SVG name="fileShareSmall" />
                    </IconButton>
                    <p className="icon-button-label">History</p>
                  </div>
                </CardActions>
                <CardActions disableSpacing className="group-card-action">
                  <Tooltip title="Group Security Audit" placement="top" arrow>
                    <IconButton
                      aria-label="Group Security Audit"
                      className="group-card-action-icon"
                      onClick={() => {
                        openActivityModal(group);
                      }}
                    >
                      <SecurityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Chat" placement="top" arrow>
                    <IconButton
                      aria-label="Chat"
                      className="group-card-action-icon"
                    >
                      <CommentOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Send email" placement="top" arrow>
                    <IconButton
                      aria-label="Send email"
                      className="group-card-action-icon"
                    >
                      <EmailOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Video call" placement="top" arrow>
                    <IconButton
                      onClick={() => openGroupVideoAndNotify(group, member)}
                      aria-label="Video call"
                      className="group-card-action-icon"
                    >
                      <DesktopWindowsOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Call" placement="top" arrow>
                    <IconButton
                      onClick={() => openGroupCallAndNotify(group, member)}
                      aria-label="Call"
                      className="group-card-action-icon"
                    >
                      <PhoneOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Schedule" placement="top" arrow>
                    <IconButton
                      aria-label="Schedule"
                      className="group-card-action-icon"
                      onClick={() => onScheduleClick(group)}
                    >
                      <DateRangeIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
                <CardActions
                  disableSpacing
                  className="group-card-action-button"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => showGroupDetail(group, false)}
                  >
                    view group
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      <SaveData
        show={showSaveDataModal}
        close={() => setShowSaveDataModal(false)}
      ></SaveData>
      {type === 'group' && !isDrive && (
        <GroupDetailModal
          show={showGroupDetailModal}
          close={() => {
            dispatch(setShowGroupDetailModal(false));
            history.push(`${process.env.PUBLIC_URL}/groups/my-groups`);
          }}
        ></GroupDetailModal>
      )}
      {type === 'group' && isDrive && (
        <GroupDriveModal
          show={showGroupDetailModal}
          close={() => {
            dispatch(setShowGroupDetailModal(false));
            history.push(`${process.env.PUBLIC_URL}/groups/my-groups`);
          }}
        ></GroupDriveModal>
      )}

      {type === 'membership' && !isDrive && (
        <MemberShipDetailModal
          show={showGroupDetailModal}
          close={() => {
            dispatch(setShowGroupDetailModal(false));
            history.push(`${process.env.PUBLIC_URL}/groups/membership`);
          }}
          membershipDetail={membershipDetail}
        ></MemberShipDetailModal>
      )}
      {type === 'membership' && isDrive && (
        <MemberShipDriveModal
          show={showGroupDetailModal}
          close={() => {
            dispatch(setShowGroupDetailModal(false));
            history.push(`${process.env.PUBLIC_URL}/groups/membership`);
          }}
          membershipDetail={membershipDetail}
        ></MemberShipDriveModal>
      )}

      <SecurityDetailModal
        open={isShowSecurityModal}
        disabled={type === 'membership' ? true : false}
        onClose={() => setShowSecurityModal(false)}
        group_id={group_id}
        group_leader_id={group_leader_id}
      />
      <GroupActivityModal
        show={showActivityModal}
        group_id={group_id}
        onClose={() => setShowActivityModal(false)}
      />
      <AmeraPagination
        handleChangeRowsPerPage={(e) => setItemPerPage(e.target.value)}
        rowsPerPage={itemsPerPage}
        totalCount={groups.length}
        page={page}
        handleChangePage={handlePageChange}
      />
    </React.Fragment>
  );
};

CardContainer.propTypes = {
  dispatch: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.object),
  // ameraGroupSecurity: PropTypes.arrayOf(PropTypes.object),
  groupData: PropTypes.object,
  showGroupDetailModal: PropTypes.bool,
  isDrive: PropTypes.bool,
  type: PropTypes.string,
};

CardContainer.defaultProps = {
  dispatch: null,
  groupList: [],
  // ameraGroupSecurity: [],
  groupData: {},
  showGroupDetailModal: false,
  isDrive: false,
  type: '',
};

const mapStateToProps = (state) => ({
  // ameraGroupSecurity: state.group.ameraGroupSecurity,
  member: state.member,
  groupData: state.group.groupData,
  showGroupDetailModal: state.group.showGroupDetailModal,
  isDrive: state.group.isDrive,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        deleteGroups,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);
