import { sendNotification } from '../redux/actions/event';

export const textCapitalized = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const setContactListFilterSelectOptions = (selectListValues) => {
  let options = [];
  const keys = getFilterKeys(selectListValues);
  keys.map((key) => {
    let option = {};
    let optionValues = [];
    selectListValues.map((value) => {
      if (Array.isArray(value[key])) {
        value[key] = value[key].filter(Boolean);
        if (value[key].length === 0) {
          value[key] = undefined;
        }
      }
      if (!value || !value[key]) {
        return null;
      }

      return optionValues.push(value[key]);
    });
    option['label'] = key;
    option['values'] = [...new Set(optionValues)].filter(Boolean).sort();
    options.push(option);
    return option;
  });
  return options;
};

const getFilterKeys = (dataList) => {
  let keys = [];
  // This prevents an issue originating in: MemberTable.js:216
  // and actions/contact.js:49
  // when a member is added to contacts
  dataList = dataList || [];
  dataList = dataList.filter(Boolean);
  if (dataList.length > 0) {
    Object.keys(dataList[0]).map((key) => keys.push(key));
  }
  return keys;
};

export const openGroupCallConference = async (group, member) => {
  const conferenceBaseUrl = 'https://stream.amerashare.com';
  const conferenceId = `${group.group_leader_id}-${
    group.group_id
  }-${group.group_name.replace(/[\W_]+/g, '')}`;
  const cnid = conferenceId.toLowerCase();
  const conferenceUrl = `${conferenceBaseUrl}/#/${cnid}`;

  await sendNotification({
    call_id: conferenceId,
    call_url: conferenceUrl,
    caller_id: member.member_id,
    group_id: group.group_id,
  });

  openCallWindow(conferenceUrl);
};

export const openGroupVideoConference = async (group, member) => {
  const conferenceBaseUrl = '/groups/call/video';
  const conferenceId = `${group.group_leader_id}-${
    group.group_id
  }-${group.group_name.replace(/[\W_]+/g, '')}`;
  const cnid = conferenceId.toLowerCase();
  const conferenceUrl = `${conferenceBaseUrl}/${cnid}?room=${cnid}`;

  await sendNotification({
    call_id: cnid,
    call_url: conferenceUrl,
    caller_id: member.member_id,
    group_id: group.group_id,
  });
  // window.location.href = conferenceUrl;
  return conferenceUrl;
  //openCallWindow(conferenceUrl);
};
export const pad = (pad, str, padLeft) => {
  if (typeof str === 'undefined') return pad;
  if (padLeft) {
    return (pad + str).slice(-pad.length);
  } else {
    return (str + pad).substring(0, pad.length);
  }
};
export const createContactVideoCallIdentifier = (member) => {
  const initials = member[1]
    .map((i) => i.replace(/[\W_]+/g, '').toUpperCase()[0])
    .join('');
  const numeric = pad('00000000', member[0], true);
  return initials + numeric;
};

export const openContactVideoCall = async (member, contact) => {
  const callBaseUrl = 'https://conference.ameraiot.com/';

  const identifierItems = [
    [member.member_id, [member.first_name, member.last_name]],
    [contact.contact_member_id, [contact.first_name, contact.last_name]],
  ]
    .sort((item1, item2) => (item1[0] < item2[0] ? -1 : 1))
    .map(createContactVideoCallIdentifier);

  const callId = identifierItems.join('-');
  const callUrl = callBaseUrl + callId;

  await sendNotification({
    call_id: callId,
    call_url: callUrl,
    caller_id: member.member_id,
    callee_id: contact.contact_member_id,
  });

  // openCallWindow(callUrl);
};

export const sendCallSignal = async (member, contact) => {
  const identifierItems = [
    [member.member_id, [member.first_name, member.last_name]],
    [contact.contact_member_id, [contact.first_name, contact.last_name]],
  ]
    .sort((item1, item2) => (item1[0] < item2[0] ? -1 : 1))
    .map(createContactVideoCallIdentifier);

  const callId = identifierItems.join('-');
  const callUrl = `${process.env.PUBLIC_URL}/contacts/call/video/${contact.contact_member_id}`;

  await sendNotification({
    call_id: callId,
    call_url: callUrl,
    caller_id: member.member_id,
    callee_id: contact.contact_member_id,
  });
};

export const openCallWindow = (url) => {
  let win = window.open(url, '_blank');

  win.innerWidth = 800;
  win.innerHeight = 800;
  win.focus();
};

export const sortArray = (array, sortkey) => {
  return array.sort((a, b) =>
    a[sortkey].toLowerCase() > b[sortkey].toLowerCase()
      ? 1
      : b[sortkey].toLowerCase() > a[sortkey].toLowerCase()
      ? -1
      : 0
  );
};

export const getBrowserDetails = () => {
  let nAgt = navigator.userAgent,
    browserName = navigator.appName,
    fullVersion = '' + parseFloat(navigator.appVersion),
    majorVersion = parseInt(navigator.appVersion, 10),
    nameOffset,
    verOffset,
    ix;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browserName = 'Opera';
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer';
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(';')) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(' ')) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt('' + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  const OSName = detectOS;
  const connectionDetails = getNetworkDetails();

  return {
    browserName,
    OSName,
    fullVersion,
    majorVersion,
    navigatorAppName: navigator.appName,
    navigatorUserAgent: navigator.userAgent,
    appVersion: navigator.appVersion,
    deviceMemory: navigator.deviceMemory, //limited Chrome only
    hardwareConcurrency: navigator.hardwareConcurrency, //limited Chrome only
    browserLanguages: navigator.languages,
    connectionDetails,
  };
};

const detectOS = () => {
  var OSName = 'Unknown OS';
  if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
  if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
  if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
  if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';
  return OSName;
};

const getNetworkDetails = () => {
  let connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  return {
    effectiveType: connection.effectiveType,
    downlink: connection.downlink,
    rtt: connection.rtt,
  };
};
