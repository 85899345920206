/* global BigInt */

// UNIT TEST DATA (should be passed in by constructor)
//

export const AmeraKey = '4d8e1333019ddb330853917bd8e48997';
var AmeraSeed = '43bc1ffade22d6bb89cef4200b7e08a7';
var SendCounter = '000000000000000000000001';
var RecvCounter = '000000000000000000000001';
// END UNIT TEST DATA

/**
 *
 * These are Bruces helper functions.  Don't know how efficient,
 * but for now, here they are
 */
/*
const toHexString = bytes =>
	bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');
*/

// const fromHexString = (hexString) =>
//   new Uint8Array(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));

function toHexString(byteArray) {
  return Array.prototype.map
    .call(byteArray, function (byte) {
      return ('0' + (byte & 0xff).toString(16)).slice(-2);
    })
    .join('');
}

function toByteArray(hexString) {
  var result = [];
  for (var i = 0; i < hexString.length; i += 2) {
    result.push(parseInt(hexString.substr(i, 2), 16));
  }
  return new Uint8Array(result);
}
/*
function ab2str(buf) {
	return toHexString(String.fromCharCode.apply(null, new Uint8Array(buf)));
}

function stringToUint(string) {
	var string = btoa(unescape(encodeURIComponent(string))),
		charList = string.split(''),
		uintArray = [];
	for (var i = 0; i < charList.length; i++) {
		uintArray.push(charList[i].charCodeAt(0));
	}
	return new Uint8Array(uintArray);
}
function uintToString(uintArray) {
	var encodedString = String.fromCharCode.apply(null, uintArray),
		decodedString = decodeURIComponent(encodedString);
	return decodedString;
}
*/
function uintToHexString(d, padding) {
  var hex = Number(d).toString(16);
  padding =
    typeof padding === 'undefined' || padding === null
      ? (padding = 2)
      : padding;

  while (hex.length < padding) {
    hex = '0' + hex;
  }
  return hex;
}

// function combine(a, b) {
//   var c = new Uint8Array(a.length + b.length);
//   c.set(a);
//   c.set(b, a.length);

//   return c;
// }
/*
function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
/
/**
 * IV (Initial Vector or Value) for AESGCM - encapsulation class
 * as used here, it is a counter.  The counter is used so that
 * 1) the same key and IV are never reused (which can reveal the key)
 * 2) we have protrection against replay - the counter must always increase
 *   with the same salt
 */
export class IV {
  constructor(hexbytes) {
    this.ivArr = new Uint8Array(12);
    this.low = 0;
    this.high = 0;
    if (typeof hexbytes === 'number') hexbytes = uintToHexString(hexbytes, 24);

    if (typeof hexbytes === 'string') {
      if (hexbytes.length === 24) {
        // Note: the Node.js uses 16, the subtle uses 12.  The lower 4 is used internally
        //hexbytes = hexbytes + '00000000'
        this.ivArr = toByteArray(hexbytes);
        try {
          this.dv = new DataView(this.ivArr.buffer);
          this.low = this.dv.getBigUint64(4);
          this.high = this.dv.getBigUint64(0, 4);
          return;
        } catch(e) {
          console.log("error is here", e);
        }
      } else {
        throw new Error(
          'IV hexbytes string has invalid Length: ' + hexbytes.length
        );
      }
    } else {
      throw new Error('IV hexbytes must be a string');
    }
    // this.dv = new DataView(this.ivArr.buffer);
    // this.dv.setBigUint64(4, this.low);
    // this.dv.setBigUint64(0, this.high);
  }

  incr() {
    let retVal = this.getArr();
    // Note: the Node.js uses 16, the subtle uses 12.  The lower 4 is used internally
    if (this.low === 0xffffffffffffffff) {
      this.low = BigInt(0);
      this.high += BigInt(0x1);
    } else {
      this.low += BigInt(0x1);
    }
    this.dv = new DataView(this.ivArr.buffer);
    this.dv.setBigUint64(4, this.low);
    this.dv.setBigUint64(0, this.high);

    return retVal;
  }

  fromBytes(bArr) {
    this.ivArr = new Uint8Array(bArr);
    this.dv = new DataView(this.ivArr.buffer);
    this.dv.setBigUint64(4, this.low);
    this.dv.setBigUint64(0, this.high);
  }

  getArr() {
    return new Uint8Array(this.ivArr);
  }

  getStr() {
    return uintToHexString(this.high, 8) + uintToHexString(this.low, 16);
  }
  getLow() {
    return this.low;
  }
  getHigh() {
    return this.high;
  }
}

/**
 * AmeraAES (Amera AES encryption) for AESGCM - encapsulation class
 *
 */

export class AmeraAES {
  /**	Ctor or encryption class
   * -expected usage, pass in in a key at least, maybe a seed.  counters rarely
   * -usage for a worker, pass in worker instance as context.  workers do not have window, this is used instead
   *
   * @param {*} options
   */
  constructor(options = {}) {
    this.connection_name = null;
    this.myconnection = null;
    this.ameraSeed = options.seed ? options.seed : AmeraSeed;
    this.ameraKeyStr = options.key ? options.key : AmeraKey;
    this.sendIV = new IV(
      options.send_counter ? options.send_counter : SendCounter
    );
    this.recvIV = new IV(
      options.recv_counter ? options.recv_counter : RecvCounter
    );
    this.taglength = options.taglength ? options.taglength : 128;
    this.keysize = options.keysize ? options.keysize : 256;
    this.verbose = options.verbose ? options.verbose : false;
    this.context = options.context ? options.context : window;
    this.dumpmax = options.dumpmax ? options.dumpmax : 128;

    this.ameraKey = null;
    this.encrypt_salt = null;
    this.encrypt_key = null;
  }
  /**
   *
   * @param {*} plainkey - a hexstring, length is bit keylength/4, (ff => 8 bits)
   */
  async importkey(plainkey) {
    //console.log(`key ${plainkey}`);
    return this.context.crypto.subtle
      .importKey(
        'raw',
        toByteArray(plainkey),
        { name: 'HKDF' }, // says this CryptKey is for HKDF, not encryption
        false,
        ['deriveBits', 'deriveKey']
      )
      .then((key) => {
        return key;
      })
      .catch((err) => {
        console.error(err);
      });
  }
  // we derive the live key from the salt and master key
  // encrypt side drives when salt is changed, decrypt reacts
  // typically, we would change per file or per block or session on web
  async derive(masterkey, salt) {
    let algorithm = {
      name: 'HKDF',
      salt: salt,
      info: new TextEncoder().encode(''),
      hash: 'SHA-256',
    };
    return await this.context.crypto.subtle.deriveKey(
      algorithm,
      masterkey,
      {
        name: 'AES-GCM',
        length: this.keysize,
      },
      true,
      ['encrypt', 'decrypt']
    );
  }

  sha256(tohash) {
    return this.context.crypto.subtle
      .digest('SHA-256', new TextEncoder('utf-8').encode(tohash))
      .then(function (x) {
        return new Uint8Array(x);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**	The salt is used to derive the live key from the master (static) key.
   * 	-the salt must a) always be unique and b) can never be reused with the same IV
   * 	-some systems use osrandom() to get a salt.  we don't becasue this may be used
   * 	in an IoT device which will not have much entropy availble at start-up.  the safer
   * 	way for IoT is: salt derived from hash of seed, counter and local time
   */
  async createSalt() {
    let d = new Date();
    let tohash = this.ameraSeed + this.sendIV.getStr() + d.toLocaleTimeString();
    let hashed = await this.sha256(tohash);
    return hashed.slice(0, 16); // salt just 16 bytes
  }

  // first time and on request, we derive a key to decrypt with
  async checkEncrypt(refresh = false) {
    if (refresh || !this.encrypt_salt) {
      this.encrypt_salt = await this.createSalt();
      if (this.ameraKey == null)
        this.ameraKey = await this.importkey(this.ameraKeyStr);

      this.encrypt_key = await this.derive(this.ameraKey, this.encrypt_salt);
    }
  }
  // whenever sender changes salt, we have to re-derive the key
  async checkDecrypt(data, replay_check) {
    let next_salt = this.getSalt(data);

    if (replay_check) {
      let last_high = this.recvIV.getHigh();
      let last_low = this.recvIV.getLow();
      this.recvIV.fromBytes(this.getIV(data));

      if (last_low === 0 && last_high === 0) {
        if (this.recvIV.getLow() === 0 && this.recvIV.getHigh()) {
          throw new Error(`replay: IV cannot be zero`);
        }
      } else if (last_high === this.recvIV.getHigh()) {
        if (last_low >= this.recvIV.getLow()) {
          throw new Error(
            `replay: previous ${last_high}${last_low} decreased or stayed same ${this.recvIV.getHigh()}${this.recvIV.getLow()}`
          );
        }
      } else {
        if (last_high >= this.recvIV.getHigh()) {
          throw new Error(
            `replay: previous ${last_high}${last_low} decreased or stayed same ${this.recvIV.getHigh()}${this.recvIV.getLow()}`
          );
        }
      }
    } else {
      this.recvIV.fromBytes(this.getIV(data));
    }
    if (this.ameraKey === null)
      this.ameraKey = await this.importkey(this.ameraKeyStr);

    if (!this.decrypt_salt || this.decrypt_salt !== next_salt) {
      this.decrypt_salt = next_salt;
      this.decrypt_key = await this.derive(this.ameraKey, this.decrypt_salt);
    }
  }
  /**Helpers for slicing entire message into parts
   *
   * @param {*} data - the cipher text entire message
   */
  getTag = (data) => data.slice(data.byteLength - ((this.taglength + 7) >> 3));
  getSalt = (data) => data.slice(12, 28);
  getIV = (data) => data.slice(0, 12);
  getCipher = (data) => data.slice(28);

  /**	For verbose switch, these lets you look at the comnponents in assembled AES GCM packet
   *
   * @param {*} data
   */
  dumpEncrypt(data) {
    console.log(`e-len:  ${data.byteLength}`);
    console.log(`e-iv:   ${toHexString(this.getIV(data))}`);
    console.log(`e-salt: ${toHexString(this.getSalt(data))}`);
    console.log(`e-tag:  ${toHexString(this.getTag(data))}`);
    console.log(`e-len:  ${this.getCipher(data).byteLength}`);
    console.log(
      `e-msg:  ${toHexString(
        data.slice(0, Math.min(data.byteLength, this.dumpmax))
      )}`
    );
  }
  dumpDecrypt(data) {
    console.log(`d-len:  ${data.byteLength}`);
    console.log(`d-iv:   ${toHexString(this.getIV(data))}`);
    console.log(`d-salt: ${toHexString(this.getSalt(data))}`);
    console.log(`d-tag:  ${toHexString(this.getTag(data))}`);
    console.log(`d-len:  ${this.getCipher(data).byteLength}`);
    console.log(
      `d-msg:  ${toHexString(
        data.slice(0, Math.min(data.byteLength, this.dumpmax))
      )}`
    );
  }
  /**	AES GCM is assembled for transmission from parts.  order can vary, but iv, salt, tag, data seems most common
   *
   * @param {*} iv 				- initalization value, combined with derived key to keep data unique
   * @param {*} salt 			- the salt the decrypt will use to derive live key from shared key
   * @param {*} data 			- encrypted payload
   * @param {*} prepend 	- data that the caller will insert at front. common with video, clear frame ID bytes
   */
  assemble(iv, salt, data, prepend = 0) {
    let acc = new Uint8Array(iv.length + salt.length + data.length + prepend);
    let offset = prepend;
    acc.set(iv, offset);
    offset += iv.length;
    acc.set(salt, offset);
    offset += salt.length;
    acc.set(data, offset);
    return acc;
  }
  /**
   *
   * @param {*} data : default input is unicode string, supports options binary and JSON object
   * @param {*} options : cipher: default is base64 string, 'binary' -> raw ciphertext
   * 										: mode- 'binary' input is buffer, 'json', input is JSON object
   * 										: verbose- 'true' dumps, IV, salt, key, ...
   */
  async encrypt(data, options = {}) {
    // salt, key and so forth may need to be set or refreshed
    await this.checkEncrypt(options.refresh);

    // bump IV
    let iv = this.sendIV.incr();
    let algorithm = {
      name: 'AES-GCM',
      iv: iv,
      taglength: this.taglength,
    };

    // if we have a string or JSON, have to get into base64 first, bin is good to go
    if (!options.mode || options.mode === 'utf8' || options.mode === 'json') {
      if (options.mode === 'json') {
        data = JSON.stringify(
          data,
          (key, value) => (typeof value === 'bigint' ? value.toString() : value) // return everything else unchanged
        );
        console.log('data changed', data);
      }
      if (this.verbose) console.log(data);
      data = new TextEncoder().encode(data);
    }
    // the caller may want space at the front,
    let prepend = options.prepend ? options.prepend : 0;
    return await this.context.crypto.subtle
      .encrypt(algorithm, this.encrypt_key, data)
      .then((encrypted) => {
        let cipherText = this.assemble(
          iv,
          this.encrypt_salt,
          new Uint8Array(encrypted),
          prepend
        );

        if (this.verbose || options.verbose)
          this.dumpEncrypt(cipherText.slice(prepend));

        return cipherText;
      })
      .then((cipherText) => {
        // base64 text more natural for web traffic, binary for files
        if (options.cipher !== 'binary') {
          cipherText = this.bintobase64(cipherText);
        }
        return cipherText;
      })
      .catch((error) => {
        throw error; // let caller deal with the issue
      }); //end encrypt
  }

  /**
   *
   * @param {*} data    : data, default input is base64 cipher string, supports binary
   * @param {*} options : cipher: default is base64 string, 'binary' -> raw ciphertext
   * 										: mode- default output is unicode string, 'binary' is buffer, 'json', is JSON object
   * 										: verbose- 'true' dumps, IV, salt, key, ...
   */
  async decrypt(data, options = {}) {
    // handles base64, convert back to binary
    if (options.cipher !== 'binary') {
      data = this.base64tobin(data);
    }

    if (this.verbose) this.dumpDecrypt(data);

    // salt, key and so forth may need to updated if sender changed
    await this.checkDecrypt(data, options.replay);

    let algorithm = {
      name: 'AES-GCM',
      iv: this.getIV(data),
      taglength: this.taglength,
    };

    // decrypt the message
    let ciperData = this.getCipher(data);
    let cipherKey = this.decrypt_key;

    return await this.context.crypto.subtle
      .decrypt(algorithm, cipherKey, ciperData)
      .then((decrypted) => {
        if (options.mode !== 'binary') {
          decrypted = new TextDecoder().decode(decrypted);
          if (this.verbose || options.verbose) console.log(decrypted);

          if (options.mode === 'json') {
            decrypted = JSON.parse(decrypted);
            // if (this.verbose || options.verbose)
            //   console.log(JSON.stringify(decrypted, null, 2));
          }
        } else {
          decrypted = new Uint8Array(decrypted); // caller is expecting a Uint8Array, not an ArrayBuffer
        }
        return decrypted;
      })
      .catch((error) => {
        throw error; // let caller deal with the issue
      });
  } //end decrypt

  /**
   *  this takes a base64 string that represents binary data and converts to
   * 	a Uint8Array.
   * Note: atob() sort of creates a string, it is not yet binary.  we xlate by
   * character, create and array and then a typed array
   * @param {*} b64data  : base64 string that represents binary data
   *
   * Note: this has to be a class method because workers have do not have a window.atob
   */
  base64tobin = (b64data) => {
    //console.log('local base64tobin - decrypt');
    //console.log(`b64data : ${b64data}`);

    // convert to array
    const byteCharacters = this.context.atob(b64data);
    let bindata = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      bindata[i] = byteCharacters.charCodeAt(i);
    }
    //console.log(`bytex: ${toHexString(bindata)}`);

    return new Uint8Array(bindata);
  };

  // this just saves current value of counters in JSON
  // might be better in memcahched or redis
  persist() {}

  /**	This converts true binary data into base64 string.  Note, btoa()
   * 	does not work here, it is intended string-ish data, not full-blooded binary
   * @param {*} bindata  : Uint8Array that represents binary data
   *
   */
  bintobase64 = async (bindata) => {
    //console.log('bintobase64 - encrypt');
    let blob = new Blob([bindata]);
    let result = await readFileAsync(blob);
    let b64data = result.split(',')[1];
    //console.log(`base64: ${b64data}`);
    return b64data;
  };
  /**
   *  this takes a base64 string that represents binary data and converts to
   * 	a Uint8Array.
   * Note: atob() sort of creates a string, it is not yet binary.  we xlate by
   * character, create and array and then a typed array
   *
   * Note: this is standalone function for all that have window.atob, meaning not workers
   *
   */
  base64tobin = (b64data) => {
    //console.log('base64tobin - decrypt');
    //console.log(`b64data : ${b64data}`);

    // convert to array
    const byteCharacters = atob(b64data);
    let bindata = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      bindata[i] = byteCharacters.charCodeAt(i);
    }
    //console.log(`bytex: ${toHexString(bindata)}`);

    return new Uint8Array(bindata);
  };
} // end class AmeraAES

/**	this takes a an object, in our case a blob that contains binary data
 * 	nd converts to base64.  Note btoa() doesn't do this, it is meant for strings
 * 	this funtion is async, which allows you to await on the promise so, you can
 * process serially, in this case to return the base64
 *
 * @param {*} file
 */
function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

// async function myAesTest() {
//   let config_options = {
//     keysize: 256,
//     taglength: 128,
//     verbose: true,
//   };

//   let amera_aes = new AmeraAES({
//     keysize: 256,
//     taglength: 128,
//     verbose: true,
//   });

//   let mytext = 'for score here an seven beers ago, my forehead brought fourth';
//   let options = { mode: 'utf8', verbose: false, cipher: 'utf8' };
//   let ciphertext = await amera_aes.encrypt(mytext, options);
//   let plaintext = await amera_aes.decrypt(ciphertext, options);

//   ciphertext = await amera_aes.encrypt(mytext, options);
//   plaintext = await amera_aes.decrypt(ciphertext, options);
//   mytext =
//     "This does not work correctly. The variable length character logic is incorrect, there are no 8-bit characters in UTF-16. Despite the name, charCodeAt returns a 16-bit UTF-16 Code Unit, so you don't need any variable length logic. You can just call charCodeAt, split the result into two 8-bit bytes, and stuff them in the output array (lowest-order byte first since the question asks for UTF-16LE). ";
//   ciphertext = await amera_aes.encrypt(mytext, options);
//   plaintext = await amera_aes.decrypt(ciphertext, options);

//   //binary tests

//   let binoptions = { mode: 'binary', verbose: true, cipher: 'binary' };
//   mytext =
//     '09978021a5d07eda675f1b99a98ad65aa401c98b3d23e2da4a57c34a761d57faae9dc85a11de2037b4c5983c31f797df8fdb4878d5053991ecd117bdea0539eaec14607a9cf13f34cc87c845a35ef4c887f500d031a05aeded3c77103845cde78b2a5d0b';
//   let bintext = toByteArray(mytext);
//   ciphertext = await amera_aes.encrypt(bintext, binoptions);
//   console.log(`ciphertext: ${ciphertext}`);
//   plaintext = await amera_aes.decrypt(ciphertext, binoptions);
//   console.log(`plaintext: ${plaintext}`);
//   let newtext = toHexString(plaintext);
//   if (mytext != newtext) {
//     console.log(`binary transform failed\n${plaintext}\n${newtext}`);
//   }
//   mytext =
//     'Stack trace conveys some portion of the data whenever an exception is thrown. The stack trace is a collection of all the methods used in the program. I';
//   options.verbose = true;
//   for (let i = 0; i < 30; i++) {
//     if (i % 10 == 0) options.refresh = true;
//     else options.refresh = false;
//     ciphertext = await amera_aes.encrypt(mytext, options);
//     plaintext = await amera_aes.decrypt(ciphertext, options);
//   }
// }

//myAesTest();
