import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ListDeleteButton from '../../ListDeleteButton';
import LocationTypeSelector from './LocationTypeSelector';
import LocationCountrySelector from '../../../../../components/LocationCountrySelector';
import StreetAutocomplete from '../../../../../components/StreetAutocomplete';

import { capitalize } from 'lodash';
import { connect } from 'react-redux';
import { geoApi, extractAddress } from '../../../../../utils/country';

const useStyles = makeStyles((theme) => ({
  currencyWrapper: {
    marginTop: theme.spacing(1),
    '& span': {
      fontSize: '1.2em',
    },
  },
  description: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: theme.spacing(2),
  },
}));

const LocationListItem = (props) => {
  const {
    errors,
    locationData,
    handleChange,
    handleDelete,
    addressCount,
    handleAutocomplete,
    countryList,
    otherTypeOnly,
    billingOnly,
  } = props;
  const {
    id,
    address_1,
    address_2,
    location_type,
    postal,
    province,
    state,
    city,
    country,
    description,
  } = locationData;

  const [label, setLabel] = useState('Province');
  const classes = useStyles();
  const countryFullInfo = countryList.find((cl) => cl.id === country);

  const countryAlpha2 = countryFullInfo ? countryFullInfo.alpha2 : '';
  const currencyCode = countryFullInfo ? countryFullInfo.currency_code : '';
  const currencyName = countryFullInfo ? countryFullInfo.currency_name : '';

  // Label's based on geo data
  useEffect(() => {
    fetch(`${geoApi}/${countryAlpha2}`)
      .then((res) => res.json())
      .then((data) => {
        // console.log('geodata', data);
        setLabel(
          data.state_name_type ? capitalize(data.state_name_type) : 'Province'
        );
      });
  }, [countryAlpha2, country]);

  let fields = [
    // { fieldName: 'address_1', value: address_1, label: 'Street' },
    { fieldName: 'address_2', value: address_2, label: 'Suite/Floor/Bldg' },
    { fieldName: 'city', value: city, label: 'City' },
    country === 840
      ? { fieldName: 'state', value: state, label: label }
      : { fieldName: 'province', value: province, label: label },
    { fieldName: 'postal', value: postal, label: 'Postal Code' },
  ];

  const handleTextInput = (event) => {
    handleChange({
      id: id,
      name: event.target.name,
      value: event.target.value,
    });
  };

  const handleLocationTypeChange = (event) => {
    handleChange({ id: id, name: 'location_type', value: event.target.value });
  };

  const handleCountryChange = (event) => {
    handleChange({ id: id, name: 'country', value: event.target.value });
  };

  const handleStreetChange = (suggestion) => {
    if (suggestion) {
      // console.log('suggestion', suggestion);
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${suggestion.place_id}&sensor=true&language=en&key=${process.env.REACT_APP_GMAPS_API_KEY}`
      )
        .then((res) => res.json())
        .then((address) => {
          const { city, state, province, postal } = extractAddress(address);
          handleAutocomplete({
            id: id,
            city: city.long,
            province: province.long,
            state: state.long,
            street: suggestion.structured_formatting.main_text,
            postal: postal,
          });
        })
        .catch((e) => console.error(e));
    }
  };

  return (
    <ListItem id={id} key={id} className="item">
      <div className="item-header">
        {!otherTypeOnly && !billingOnly ? (
          <LocationTypeSelector
            id={id}
            location_type={location_type}
            handleChange={handleLocationTypeChange}
          />
        ) : (
          !billingOnly && (
            <TextField
              label="Name"
              value={description}
              name="description"
              onChange={handleTextInput}
            />
          )
        )}
        {!billingOnly && (
          <ListDeleteButton
            id={id}
            handleDelete={handleDelete}
            isDisabled={addressCount === 1 && !otherTypeOnly} //Don't let delete the last address
            listContext="location"
          />
        )}
      </div>

      <div className="item-text">
        <StreetAutocomplete
          street={address_1}
          handleChange={handleStreetChange}
          countryAlpha2={countryAlpha2}
        />
        {fields.map((f) => (
          <TextField
            error={Boolean(
              errors && errors[`${f.fieldName}`]
                ? errors[`${f.fieldName}`]
                : null
            )}
            key={f.fieldName}
            name={f.fieldName}
            label={f.label}
            value={f.value ? f.value : ''}
            onChange={handleTextInput}
            helperText={
              errors && errors[`${f.fieldName}`]
                ? errors[`${f.fieldName}`]
                : null
            }
          />
        ))}

        <LocationCountrySelector
          id={id}
          country={country}
          handleChange={handleCountryChange}
        />
        {billingOnly && (
          <div className={classes.currencyWrapper}>
            <Grid container>
              <Grid item xs={6}>
                <span className={classes.description}>Currency Code: </span>
                <span className={classes.currencyValue}>{currencyCode}</span>
              </Grid>
              <Grid item xs={6}>
                <span className={classes.description}>Currency Name: </span>
                <span className={classes.currencyValue}>{currencyName}</span>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </ListItem>
  );
};

const mapStateToProps = (state) => ({
  countryList: state.member.countryList,
});

export default connect(mapStateToProps)(LocationListItem);
