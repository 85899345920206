import React, { useMemo } from 'react';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import { durationOptions } from '../../../utils/calendar';
import TextField from '@material-ui/core/TextField';
import { differenceInMinutes, add } from 'date-fns';

const DurationSelect = ({ start, end, handleChangeDuration }) => {
  const handleChange = (e, o) => {
    const durationMins = o.value;
    handleChangeDuration(add(start, { minutes: durationMins }));
  };
  const value = useMemo(
    () => () => {
      // if it fits tha pattern, select one, else -> custon

      const diff = differenceInMinutes(end, start);

      // console.log('options', options, 'diff', diff);
      if (!durationOptions.find((o) => o.value === diff)) {
        console.log('custom duration', diff);
        return durationOptions.find((o) => o.value === 'custom');
      } else if (!!durationOptions.find((o) => o.value === diff)) {
        console.log('duration match', diff);
        return durationOptions.find((o) => o.value === diff);
      }
    },
    [end, start]
  );
  return (
    <Autocomplete
      getOptionSelected={(o, v) => {
        return o.value === v.value;
      }}
      // style={style}
      id="duration"
      disableClearable
      options={durationOptions}
      getOptionLabel={(o) => o.str}
      getOptionDisabled={(o) => o.value === 'custom'}
      renderOption={(o) => <Typography variant="body2">{o.str}</Typography>}
      onChange={handleChange}
      value={value()}
      // noOptionsText={
      //   <Typography variant="body2">
      //     Your Contact List is empty{' '}
      //     {/* <Button onClick={redirectToContacts}>Go to Contacts page</Button> */}
      //   </Typography>
      // }
      renderInput={(params) => (
        <TextField
          {...params}
          // variant={variant}
          label="Duration"
          placeholder="Duration"
        />
      )}
    />
  );
};

export default DurationSelect;
