/* global APP */
import React , {Component} from 'react';
import { Dropdown } from 'react-bootstrap';
import { IconBlurBackground } from '../icons';
import UIEvents from '../service/UI/UIEvents';
import { injectIntl } from 'react-intl';
//import { injectIntl } from 'react-intl';
// const Blur = (props) => {
//   const {
//     intl: { formatMessage },
//   } = props;
//   const messages = {
//     blurbackground: {
//       id: 'app.home.blurbackground',
//       defaultMessage: 'Blur my background',
//     },
//   };
//   return (
//     <Dropdown.Item eventKey={props.eveneKey}  onClick={props.trigger}>
//       <div className="amera-icon">
//         <IconBlurBackground />
//       </div>
//       <span>{formatMessage(messages.blurbackground)}</span>
//     </Dropdown.Item>
//   );
// };

export class Blur extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      blur: false,
    };
  }
  componentDidMount() {
    APP.UI.addListener(UIEvents.BLUR, (enable) => {
      this.setState({blur:enable});
    });
  }
  render() {
    console.log(APP);
    const {intl} = this.props;
    const messages = {
      blur: {
        id: 'app.home.blur',
        defaultMessage: 'Blur my background',
      },
      exitblur: {
        id: 'app.home.exitblur',
        defaultMessage: 'Exit Blur background',
      }      
    };
    return (
      <Dropdown.Item eventKey={this.props.eveneKey}  onClick={this.props.trigger}>
      <div className="amera-icon">
        <IconBlurBackground />
      </div>
      <span> {this.state.blur ? intl.formatMessage(messages.exitblur) : intl.formatMessage(messages.blur) }</span>
    </Dropdown.Item>
    );
  }

}

// export default Blur;
export default injectIntl(Blur);
