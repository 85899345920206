import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';

import Layout from '../Sections/Layout';
import CalendarWidget from './Calendar';
import UpcomingEvent from './UpcomingEvent.js';
import ScheduledMeetings from './ScheduledMeetings/index';
import Notifications from './Notification';
import withLanguageDrawer from '../Sections/LanguageDrawer/withLanguageDrawer';
import { messages } from '../Sections/Layout/index';
import {
  getEventInvitations,
  getNotifications,
  getSessions,
  getThreats,
  getUpcomingEvents,
} from '../../redux/actions/event';
import { isEditableEvent } from '../../utils/calendar';
import EventInfoModal from '../../components/Modal/EventModals/EventInfoModal/EventInfoModal';
import EventCancelModal from '../../components/Modal/EventModals/EventCancelModal/EventCancelModal';
import EventEditModal from '../../components/Modal/EventModals/EventEditModal';

const drawerMessages = { ...messages };

const Dashboard = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUpcomingEvents());
    dispatch(getEventInvitations());
    dispatch(getNotifications());
    dispatch(getSessions());
    dispatch(getThreats());
  }, [dispatch]);

  const [scoped, setScoped] = useState(null);
  const [isShowInfo, setShowInfo] = useState(false);
  const [isShowCancelModal, setShowCancelModal] = useState(false);
  const [isShowEditModal, setShowEditModal] = useState(false);

  const handleSelectEvent = (event) => {
    setScoped(event);
    setShowInfo(true);
  };

  const handleCloseEventInfoClick = () => {
    setShowInfo(false);
    setScoped(null);
  };

  const handleEditClick = () => {
    setShowInfo(false);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setScoped(null);
  };

  const handleCancelClick = () => {
    setShowCancelModal(true);
    setShowInfo(false);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
    setScoped(null);
  };

  return (
    <Layout {...props}>
      <Grid container className="dashboard-section">
        <Grid item sm={12} md={8} className="left-dashboard-section">
          <Grid container spacing={1} className="widgets">
            <Grid item xs={4} className="calendar-widget">
              <CalendarWidget
                handleSelectEvent={handleSelectEvent}
              ></CalendarWidget>
            </Grid>
            <Grid item xs={8} className="upcoming-event">
              <UpcomingEvent showDetail={handleSelectEvent} />
            </Grid>
          </Grid>
          <Grid item sm={12} className="scheduled-meetings">
            <ScheduledMeetings />
          </Grid>
        </Grid>
        <Grid item sm={12} md={4} className="notification-widget">
          <Notifications></Notifications>
        </Grid>
      </Grid>
      {!!scoped && isShowInfo && (
        <EventInfoModal
          show={isShowInfo}
          onEditClick={handleEditClick}
          onCancelClick={handleCancelClick}
          onClose={handleCloseEventInfoClick}
          selectedEvent={scoped}
          isEditable={isEditableEvent(scoped, props.memberId)}
        />
      )}
      {!!scoped && isShowCancelModal && (
        <EventCancelModal
          show={isShowCancelModal}
          onClose={handleCloseCancelModal}
          selectedEvent={scoped}
        />
      )}

      {!!scoped && isShowEditModal && (
        <EventEditModal
          show={isShowEditModal}
          onClose={handleCloseEditModal}
          selectedEvent={scoped}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  memberId: state.member.member.member_id,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLanguageDrawer(Dashboard, drawerMessages));
