import React from 'react';
// import { ForwardMail } from '../Forward';
import MailAttach from '../MailAttach';
import { ReplyMail } from '../Reply';
import { useEmailDetailsStyles } from './EmailDetailsStyles';
import MailBody from './MailBody';
import MailHeader from './MailHeader';

export const MailDetails = ({ email, member, isOwnEmail }) => {
  const rootClasses = useEmailDetailsStyles();

  return (
    <div className={rootClasses.detailsWrapper}>
      {email ? (
        <>
          <MailHeader
            email={email}
            member={member}
            isOwnEmail={isOwnEmail}
            senderFirstName={isOwnEmail ? member.first_name : email.first_name}
            senderLastName={isOwnEmail ? member.last_name : email.last_name}
            receiverName={email.receiverName}
            title={`${email.reply && email.reply.id ? 'RE : ' : ''}${
              email.subject
            }`}
            composeTime={email.time}
            senderAvatar={
              isOwnEmail ? member.amera_avatar_url : email.profile_url
            }
            username={
              isOwnEmail
                ? email.first_name
                : `${member.first_name} ${member.last_name}`
            }
          />
          <MailBody body={email.body} id={email.mail_id} />
          <MailAttach attachFiles={email.attachments} />
          {email.reply && email.reply.id && (
            <ReplyMail email={email.reply} member={member} />
          )}
          {/* {email.reply && email.reply.id && (
            <ForwardMail email={email.reply} member={member} />
          )} */}
        </>
      ) : null}
    </div>
  );
};
