import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ColorSelectSample from './ColorSelectSample';
// import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

const ColorSelect = (props) => {
  const { colors, colorId, onChange } = props;
  return (
    <FormControl>
      <InputLabel id="label-colorselect">Color</InputLabel>
      <Select
        labelId="label-colorselect"
        id="color-select"
        value={colorId}
        onChange={onChange}
        label="Color"
      >
        {colors.map((co) => (
          <MenuItem key={co.id} value={co.id}>
            <ColorSelectSample color={co.color} />
          </MenuItem>
        ))}
        {/* No color option */}
        <MenuItem key="no-color" value="no-color">
          <ColorSelectSample color="rgba(0,0,0,0)" border="2px solid gray" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  colors: state.scheduleEvents.colors,
});

export default connect(mapStateToProps)(ColorSelect);
