import { Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/newDesignModal';
import { ApiEmailService } from '../../config/apiService';
import {
  getEmailQueryParams,
  getUnstyledHtmlBody,
  mailButtonGroup,
} from '../../utils/email';
import { useEmailPageStyles } from './SharedComponents/EmaliPageWrapper/EmailPageStyles';
import { EmailPageWrapper } from './SharedComponents/EmaliPageWrapper';
import { apiPaths } from '../../config/apiPaths';

const Trash = (props) => {
  const classes = useEmailPageStyles();

  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedEmailXref, setSelectedEmailXref] = useState([]);
  const [emails, setEmails] = useState([]);
  const [openConfirm, setComfirmOpen] = useState(false);

  const deleteEmail = async (id, xref_id) => {
    try {
      if (selectedEmails.length === 1) {
        await ApiEmailService.deleteEmail('deleteEmail', id + '/' + xref_id);
      } else {
        let xref_id = [];
        let mails_id = [];
        let str_params = '';
        selectedEmails.forEach((el) => {
          emails.forEach((email) => {
            if (email.mail_id === el) {
              xref_id.push(email.xref_id);
              mails_id.push(email.mail_id);
            }
          });
        });
        str_params = mails_id.join(',') + '/' + xref_id.join(',');
        await ApiEmailService.deleteEmail('deleteEmail', str_params);
      }
      getEmails();
      setSelectedEmails([]);
      setSelectedEmailXref([]);
    } catch {}
    setComfirmOpen(false);
  };

  const selectEmail = (id, xref_id, multi = false, is_detail_view = false) => {
    if (is_detail_view) {
      if (Array.isArray(id)) {
        getEmailDetails(id[id.length - 1]).then(() => {});
      } else {
        getEmailDetails(id).then(() => {});
      }
      return;
    }
    if (Array.isArray(id)) {
      let is_same =
        selectedEmails.length === id.length &&
        selectedEmailXref.length === xref_id.length &&
        selectedEmails.every(function (element, index) {
          return element === id[index];
        }) &&
        selectedEmailXref.every(function (element, index) {
          return element === xref_id[index];
        });
      if (is_same) {
        setSelectedEmails([]);
        setSelectedEmailXref([]);
      } else {
        setSelectedEmails(id);
        setSelectedEmailXref(xref_id);
        const index = emails.findIndex((x) => x.mail_id === id[id.length - 1]);

        if (index !== -1 && emails[index].read === false) {
          const _emails = [...emails];
          _emails[index].read = true;
          setEmails(_emails);
        }
        getEmailDetails(id[id.length - 1]).then(() => {});
      }
    } else {
      const index = emails.findIndex((x) => x.mail_id === id);

      if (index !== -1 && emails[index].read === false) {
        const _emails = [...emails];
        _emails[index].read = true;
        setEmails(_emails);
      }
      getEmailDetails(id).then(() => {});

      let findItem = selectedEmails.findIndex((el) => el === id);
      let findItemXref = selectedEmailXref.findIndex((el) => el === xref_id);
      if (findItem === -1) {
        setSelectedEmails([...selectedEmails, id]);
        if (multi) {
          setSelectedEmailXref([...selectedEmailXref, xref_id]);
        } else {
          setSelectedEmailXref([xref_id]);
        }
      } else {
        if (findItemXref !== -1) {
          // Delete current mail!
          let xrefBackup = [...selectedEmailXref];
          xrefBackup.splice(findItemXref, 1);
          setSelectedEmailXref([...xrefBackup]);
          // -- END --
          // Search if any other emails with same header exists
          let tempEmails = emails
            .filter((el) => {
              return el.mail_id === id;
            })
            .map((el) => {
              return el.xref_id;
            });
          findItemXref = xrefBackup.findIndex((el) => {
            return tempEmails.indexOf(el) > -1;
          });
          if (findItemXref === -1) {
            let newSelected = [...selectedEmails];
            newSelected.splice(findItem, 1);
            setSelectedEmails([...newSelected]);
          }
          // -- END --
        } else {
          if (multi) {
            setSelectedEmailXref([...selectedEmailXref, xref_id]);
          } else {
            setSelectedEmailXref([xref_id]);
          }
        }
      }
    }
  };

  const topButtonsHandlers = {
    [mailButtonGroup.mailTrash.name]: async (event) => {
      if (selectedEmail) {
        setComfirmOpen(true);
      }
    },
  };

  const bottomButtonsHandlers = {
    [mailButtonGroup.mailMoveToAtchive.name]: async (event) => {
      if (selectedEmail) {
        const res = await ApiEmailService.postEmail(
          'postEmailFromTrashToArchive',
          {
            mail_id: selectedEmail.mail_id,
            xref_id: selectedEmail.xref_id,
          }
        );
        if (res.success) getEmails();
      }
    },
    [mailButtonGroup.mailMoveToOrigin.name]: async (event) => {
      if (selectedEmail) {
        const res = await ApiEmailService.postEmail('postMoveTrashToOrigin', {
          mail_id: selectedEmail.mail_id,
          xref_id: selectedEmail.xref_id,
        });
        if (res.success) getEmails();
      }
    },
  };

  const getEmails = async (start_id) => {
    let params = getEmailQueryParams(
      start_id,
      selectSort,
      props.search,
      null,
      props.contact_filter
    );

    let tmp_data = await ApiEmailService.getEmails('getTrash', params);
    let { data } = tmp_data !== undefined ? tmp_data : {};

    if (data !== undefined) {
      data.forEach((email) => {
        email.noStyleBody = getUnstyledHtmlBody(email.body);
      });

      setSelectedEmail(null);
      if (start_id) {
        setEmails([...emails, ...data]);
      } else {
        setEmails([...data]);
      }
    }
  };

  const getEmailDetails = async (id) => {
    let data = await ApiEmailService.getEmailDetails(
      'getTrashEmailDetails',
      id
    );
    setSelectedEmail({ ...data, mail_id: id });
  };

  useEffect(() => {
    props.setMemberUrl(apiPaths.mail.getSelectableMembersTrash);
    // eslint-disable-next-line
  }, [props.setMemberUrl]);

  useEffect(() => {
    async function getMails() {
      await getEmails();
    }
    getMails();
    // eslint-disable-next-line
  }, []);

  const [selectSort, setSelectSort] = React.useState(0);
  useEffect(() => {
    getEmails();
    // eslint-disable-next-line
  }, [selectSort]);

  const handleChangeSort = (e) => {
    setSelectSort(e.target.value);
  };
  // "TODO: this block is not needed anymore, shall we keep this block or remove ?"
  // const [selectFilter, setSelectFilter] = React.useState(0);
  // const handleChangeFilter = (e) => {
  //   setSelectFilter(e.target.value);
  // };

  return (
    <EmailPageWrapper
      section="Trash"
      view={props.view}
      selectedEmail={selectedEmail}
      selectedEmails={selectedEmails}
      selectedXref={selectedEmailXref}
      emails={emails}
      sortControls={{
        value: selectSort,
        changeValue: handleChangeSort,
      }}
      // "TODO: this block is not needed anymore, shall we keep this block or remove ?"
      // filterControls={{
      //   value: selectFilter,
      //   changeValue: handleChangeFilter,
      // }}
      topButtonsHandlers={topButtonsHandlers}
      bottomButtonsHandlers={bottomButtonsHandlers}
      selectEmail={selectEmail}
    >
      <Modal
        open={openConfirm}
        onClose={() => setComfirmOpen(false)}
        title="Confirm"
        maxWidth="sm"
        fullWidth={true}
        ModalContent={() => {
          let recipient = selectedEmail
            ? selectedEmail.first_name + ' ' + selectedEmail.last_name
            : '';
          return (
            <Typography variant="h6" align="center">
              Are you sure you want to delete email from
              <Typography variant="h5">{recipient} ?</Typography>
            </Typography>
          );
        }}
        ModalAction={() => {
          return (
            <>
              <Button
                variant="contained"
                className={classes.deleteButton}
                onClick={(e) =>
                  selectedEmail
                    ? deleteEmail(selectedEmail.mail_id, selectedEmail.xref_id)
                    : null
                }
              >
                DELETE
              </Button>
              <Button
                onClick={(e) => setComfirmOpen(false)}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </>
          );
        }}
      />
    </EmailPageWrapper>
  );
};

export default Trash;
