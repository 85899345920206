import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ContactPreview from './ContactPreview';
import { loadContacts } from '../redux/actions/contact';
import { displayableFullName } from '../utils/contact';
import { useSelector } from 'react-redux';

const MemberAutocomplete = (props) => {
  const {
    contactOptions,
    ameraMembers,
    handleChange,
    values,
    dispatch,
    disablerFunction,
    variant = 'standard',
    isShowAvatar = true,
    disabled = false,
    label,
    style,
    size,
    isAddMember = false,
    showLoggedMember = false,
    allMembers = false,
    memberGetUrl,
  } = props;

  const { memberState } = useSelector((state) => ({
    memberState: state.member,
  }));
  const { memberInfo } = memberState;

  const member_details = {
    id: 0,
    achievement_information: memberInfo.achievement_information,
    amera_avatar_url: memberInfo.amera_avatar_url,
    biography: memberInfo.biography,
    company: memberInfo.company_name,
    contact_information: memberInfo.contact_information,
    country_code: memberInfo.country_code,
    create_date: memberInfo.create_date,
    email: memberInfo.email,
    first_name: memberInfo.first_name,
    contact_member_id: memberInfo.member_id,
    last_name: memberInfo.last_name,
    location_information: memberInfo.location_information,
    middle_name: memberInfo.middle_name,
    title: memberInfo.job_title,
    update_date: memberInfo.update_date,
  };

  const contacts = allMembers
    ? ameraMembers
      ? [...ameraMembers]
      : []
    : contactOptions
    ? [...contactOptions]
    : [];

  if (showLoggedMember) contacts.push(member_details);

  useEffect(() => {
    dispatch(loadContacts({}, false, [], memberGetUrl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, memberGetUrl]);

  return (
    <Autocomplete
      getOptionSelected={(o, v) => {
        return allMembers
          ? o.member_id === v.member_id
          : o.contact_member_id === v.contact_member_id &&
              o.invite_member_id === v.invite_member_id;
      }}
      disableCloseOnSelect
      style={style}
      multiple={!isAddMember}
      id="members"
      options={contacts}
      disabled={disabled}
      getOptionLabel={(o) => displayableFullName(o)}
      getOptionDisabled={(o) => (!!disablerFunction ? disablerFunction(o) : o)}
      renderOption={(o) => (
        <ContactPreview
          contactData={{
            ...o,
            member_id: o.contact_member_id ? o.contact_member_id : o.member_id,
          }}
          isShowAvatar={isShowAvatar}
        />
      )}
      onChange={handleChange}
      value={values}
      noOptionsText={
        <Typography variant="body2">
          {allMembers ? '' : `Your Contact List is empty`}
        </Typography>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          multiline
          size={size}
          placeholder="AMERA members"
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  contactOptions: state.contact.contacts,
  ameraMembers: state.member.ameraMembers,
});

export default connect(mapStateToProps)(MemberAutocomplete);
