const fakeDatabase = {
  memberEvents: [
    {
      id: 1,
      first_name: 'Maximus',
      last_name: 'Capf',
      role: 'Team Lead',
      location: 'Main Medical Centre. 22 Satsen Pr, Paris',
      country: 'France',
      event_date: '2020-07-10T13:40:00.000Z',
      image: 'image',
    },
    {
      id: 2,
      first_name: 'Orlan',
      last_name: 'Luca',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-11T21:00:00.000Z',
      image: '',
    },
  ],
  meetingMembers: [
    {
      id: 1,
      first_name: 'Maximus',
      last_name: 'Capf',
      role: 'Team Lead',
      location: 'Main Medical Centre. 22 Satsen Pr, Paris',
      country: 'France',
      event_date: '2020-07-10T13:40:00.000Z',
      image: 'image',
      title: 'Marketing Meeting',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
    {
      id: 2,
      first_name: 'Orlan',
      last_name: 'Luca',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-11T21:00:00.000Z',
      image: 'image',
      title: 'Project Planning Session',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
    {
      id: 3,
      first_name: 'Martin',
      last_name: 'Martin',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-13T01:00:00.000Z',
      image: '',
      title: 'Lunch Meeting',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
    {
      id: 4,
      first_name: 'John',
      last_name: 'Doe',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-13T01:00:00.000Z',
      image: '',
      title: 'Lunch Meeting',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
    {
      id: 5,
      first_name: 'Jane',
      last_name: 'Doe',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-13T01:00:00.000Z',
      image: '',
      title: 'Board meetings',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
    {
      id: 6,
      first_name: 'Jane',
      last_name: 'Doe',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-13T01:00:00.000Z',
      image: '',
      title: 'Board Product launch',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
  ],
  roleFilterValues: [
    'Advisor',
    'Client',
    'Co-Worker',
    'Customer',
    'Friend',
    'Reseller',
    'Student',
    'Teacher',
    'Vendor',
    'Worker',
  ],
  conversations: [
    {
      id: '1',
      amera_avatar_url: '/member/file/keanu.jpg',
      imageAlt: 'Heidi Knight',
      title: 'Heidi Knight',
      first_name: 'Heidi',
      last_name: 'Knight',
      createdAt: 'Apr 16',
      latestMessageText: 'This is a message',
    },
    {
      id: '2',
      amera_avatar_url: '/member/file/ana.jpg',
      imageAlt: 'Benton Whitehead',
      title: 'Benton Whitehead',
      first_name: 'Benton',
      last_name: 'Whitehead',
      createdAt: 'Oct 20',
      latestMessageText: 'Ok fair enough. Well good talking to you.',
      messages: [],
    },
    {
      id: '3',
      amera_avatar_url: '/member/file/bill.jpg',
      imageAlt: 'John Anderson',
      title: 'John Anderson',
      first_name: 'John',
      last_name: 'Anderson',
      createdAt: '1 week ago',
      latestMessageText: 'Yes I love how Python does that',
      messages: [],
    },
    {
      id: '4',
      amera_avatar_url: '/member/file/keanu.jpg',
      imageAlt: 'Ben Smith',
      title: 'Ben Smith',
      first_name: 'Ben',
      last_name: 'Smith',
      createdAt: '2:49 PM',
      latestMessageText: 'Yeah Miami Heat are done',
      messages: [],
    },
    {
      id: '5',
      amera_avatar_url: '/member/file/ana.jpg',
      imageAlt: 'Douglas Johannasen',
      title: 'Douglas Johannasen',
      first_name: 'Douglas',
      last_name: 'Johannasen',
      createdAt: '6:14 PM',
      latestMessageText: 'No it does not',
      messages: [],
    },
    {
      id: '6',
      amera_avatar_url: '/member/file/bill.jpg',
      imageAlt: 'Jacob Manly',
      title: 'Jacob Manly',
      first_name: 'Jacob',
      last_name: 'Manly',
      createdAt: '3 secs ago',
      latestMessageText: 'Just be very careful doing that',
      messages: [],
    },
    {
      id: '7',
      amera_avatar_url: '/member/file/keanu.jpg',
      imageAlt: 'Stacey Wilson',
      title: 'Stacey Wilson',
      first_name: 'Stacey',
      last_name: 'Wilson',
      createdAt: '30 mins ago',
      latestMessageText: 'Awesome!!! Congratulations!!!!',
      messages: [],
    },
    {
      id: '8',
      amera_avatar_url: '/member/file/ana.jpg',
      imageAlt: 'Stan George',
      title: 'Stan George',
      first_name: 'Stan',
      last_name: 'George',
      createdAt: '1 week ago',
      latestMessageText: 'Good job',
      messages: [],
    },
    {
      id: '9',
      amera_avatar_url: '/member/file/bill.jpg',
      imageAlt: 'Sarah Momes',
      title: 'Sarah Momes',
      first_name: 'Sarah',
      last_name: 'Momes',
      createdAt: '1 year ago',
      latestMessageText: 'Thank you. I appreciate that.',
      messages: [],
    },
  ],
  messages: {
    1: [
      {
        id: 8,
        imageAlt: null,
        messageText: 'Got it. Thanks',
        createdAt: new Date().toUTCString(),
        isMyMessage: true,
      },
      {
        id: 7,
        amera_avatar_url: '/member/file/keanu.jpg',
        imageAlt: 'Daryl Duckmanton',
        first_name: 'Daryl',
        last_name: 'Duckmanton',
        messageText:
          'Great. Let me test it. Meanwhile, please continue to other tasks.',
        createdAt: '2020-11-07T12:10:58.629Z',
        isMyMessage: false,
      },
      {
        id: 6,
        imageAlt: null,
        messageText:
          "Hi, the task is complete. Let's me know if any issue. Thanks",
        createdAt: '2020-11-07T12:10:57.629Z',
        isMyMessage: true,
      },
      {
        id: 5,
        messageText: 'Sure, Will update soon. Thanks',
        createdAt: '2020-11-06T12:10:26.420Z',
        isMyMessage: true,
      },
      {
        id: 4,
        amera_avatar_url: '/member/file/keanu.jpg',
        imageAlt: 'Daryl Duckmanton',
        first_name: 'Daryl',
        last_name: 'Duckmanton',
        messageText:
          "Ok the meeting was great. Let's complete these mockups first.",
        createdAt: '2020-11-06T12:10:25.420Z',
        isMyMessage: false,
      },
      {
        id: 3,
        messageText: 'Sure, Thanks',
        createdAt: '2020-11-05T12:09:57.329Z',
        isMyMessage: true,
      },
      {
        id: 2,
        amera_avatar_url: '/member/file/keanu.jpg',
        imageAlt: 'Daryl Duckmanton',
        first_name: 'Daryl',
        last_name: 'Duckmanton',
        messageText: 'Great. let me create invite.',
        createdAt: '2020-11-05T12:09:56.099Z',
        isMyMessage: false,
      },
      {
        id: 1,
        messageText: 'Hi, I am available in next 1 hr.',
        createdAt: '2020-11-05T12:09:55.329Z',
        isMyMessage: true,
      },
      {
        id: 0,
        amera_avatar_url: '/member/file/keanu.jpg',
        imageAlt: 'Daryl Duckmanton',
        first_name: 'Daryl',
        last_name: 'Duckmanton',
        messageText:
          'Hi mate, please let me know availability I want to discuss the design of the chat panel',
        createdAt: '2020-11-05T12:09:54.099Z',
        isMyMessage: false,
      },
    ],
    2: [
      {
        id: 8,
        imageAlt: null,
        messageText: 'Bye! Have a good day!',
        createdAt: new Date().toUTCString(),
        isMyMessage: true,
      },
      {
        id: 9,
        amera_avatar_url: '/member/file/ana.jpg',
        imageAlt: 'Benton Whitehead',
        first_name: 'Benton',
        last_name: 'Whitehead',
        messageText:
          'Not yet but will check soon. I have to run—can we continue later?',
        createdAt: '2020-11-07T12:11:00.629Z',
        isMyMessage: false,
      },
      {
        id: 8,
        imageAlt: null,
        messageText: 'Have you seen my email?',
        createdAt: '2020-11-07T12:10:59.629Z',
        isMyMessage: true,
      },
      {
        id: 7,
        amera_avatar_url: '/member/file/ana.jpg',
        imageAlt: 'Benton Whitehead',
        first_name: 'Benton',
        last_name: 'Whitehead',
        messageText: 'It’s going well, thank you',
        createdAt: '2020-11-07T12:10:58.629Z',
        isMyMessage: false,
      },
      {
        id: 6,
        imageAlt: null,
        messageText: 'Fine, thanks. And yourself?',
        createdAt: '2020-11-07T12:10:57.629Z',
        isMyMessage: true,
      },
      {
        id: 5,
        messageText: 'How are you doing?',
        createdAt: '2020-11-06T12:10:26.420Z',
        isMyMessage: true,
      },
      {
        id: 4,
        amera_avatar_url: '/member/file/ana.jpg',
        imageAlt: 'Benton Whitehead',
        first_name: 'Benton',
        last_name: 'Whitehead',
        messageText: 'It’s a pleasure!',
        createdAt: '2020-11-06T12:10:25.420Z',
        isMyMessage: false,
      },
      {
        id: 3,
        messageText: 'This is Daniel.',
        createdAt: '2020-11-05T12:09:57.329Z',
        isMyMessage: true,
      },
      {
        id: 2,
        amera_avatar_url: '/member/file/ana.jpg',
        imageAlt: 'Benton Whitehead',
        first_name: 'Benton',
        last_name: 'Whitehead',
        messageText: 'My name is MAQ. What’s your name?',
        createdAt: '2020-11-05T12:09:56.099Z',
        isMyMessage: false,
      },
      {
        id: 1,
        messageText: 'Hey, Good Morning!',
        createdAt: '2020-11-05T12:09:55.329Z',
        isMyMessage: true,
      },
      {
        id: 0,
        amera_avatar_url: '/member/file/ana.jpg',
        imageAlt: 'Benton Whitehead',
        first_name: 'Benton',
        last_name: 'Whitehead',
        messageText: 'Hello! Good morning!',
        createdAt: '2020-11-05T12:09:54.099Z',
        isMyMessage: false,
      },
    ],
  },
  replyMessage: {
    id: null,
    amera_avatar_url: '/member/file/keanu.jpg',
    imageAlt: 'Daryl Duckmanton',
    first_name: 'Daryl',
    last_name: 'Duckmanton',
    messageText: 'Hello This is AmeraIOT bot. Nice to meet you!',
    createdAt: '',
    isMyMessage: false,
  },
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchTodos = (url, id) =>
  delay(500).then(() => {
    switch (url) {
      case 'member-event':
        return fakeDatabase.memberEvents;
      case 'notification':
        return fakeDatabase.notifications;
      case 'meeting-member':
        return fakeDatabase.meetingMembers;
      case 'filter-role':
        return fakeDatabase.roleFilterValues;
      case 'conversations':
        return fakeDatabase.conversations;
      case 'messages':
        return (fakeDatabase.messages[id] && fakeDatabase.messages[id]) || [];
      case 'message/send':
        return delay(500).then(() => fakeDatabase.replyMessage); //delay further
      default:
        throw new Error('Bad request');
    }
  });
