import { SET_SNACKBAR } from '../actionTypes/snackbar';

export const setSnackbarData = (options) => {
  return function (dispatch) {
    dispatch({
      type: SET_SNACKBAR,
      payload: options,
    });
  };
};
