import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '../../components/Modal/modal';
import PicturePin from '../../components/Forms/PicturePinForm/PicturePin';
import { updateSecurity, getMemberSecurity } from '../../redux/actions/contact';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: 130,
  },
}));

const SecurityDetailModal = (props) => {
  const { open, loading, onClose, contact, security } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [securityData, setSecurityData] = React.useState();
  const handleChange = (data) => {
    console.log('P&P input changed', data);
    setSecurityData(data);
  };

  useEffect(() => {
    if (contact.contact_member_id && open) {
      dispatch(getMemberSecurity(contact.contact_member_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, contact.contact_member_id]);

  const handleSubmit = async () => {
    let formData = new FormData();
    if (securityData.picture) formData.append('picture', securityData.picture);
    formData.append('pin', securityData.pin);
    formData.append('exchangeOption', securityData.exchangeOption);
    await dispatch(updateSecurity(formData, contact.contact_member_id));
    onClose();
  };

  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.actionBtn}
          onClick={onClose}
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            disabled
          >
            ...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            disabled={securityData ? !securityData.isValidated : true}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Contact Security Details"
      ModalContent={() => (
        <PicturePin isExchange onChange={handleChange} security={security} />
      )}
      ModalAction={ModalAction}
      className="modal-contact-security-details"
    />
  );
};

SecurityDetailModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.object,
};

SecurityDetailModal.defaultProps = {
  open: false,
  loading: false,
};

const mapStateToProps = (state) => ({
  loading: state.contact.loading,
  security: state.contact.security,
});

export default connect(mapStateToProps)(SecurityDetailModal);
