import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import List from '@material-ui/core/List';

import {
  loadConversations,
  loadMessages,
} from '../../../../../../redux/actions/chat';
import Conversation from './Conversation';
import ConversationSkeleton from './Skeleton';

function Conversations(props) {
  const {
    conversations,
    selectedConversationId,
    loadingConversations,
    loadConversations,
    loadMessages,
  } = props;
  let conversationItems = null;

  React.useEffect(() => {
    loadConversations();
    loadMessages('1'); //initially load recent conversation messages
  }, [loadConversations, loadMessages]);

  const getMessages = (conversationId) => {
    loadMessages(conversationId);
  };

  if (loadingConversations) {
    conversationItems = [...Array(10).keys()].map((num) => (
      <ConversationSkeleton key={num} num={num} />
    ));
  } else {
    conversationItems = conversations.map((conversation) => (
      <Conversation
        conversation={conversation}
        selectedConversationId={selectedConversationId}
        getMessages={getMessages}
      />
    ));
  }

  return <List className="conversation-list">{conversationItems}</List>;
}

const mapStateToProps = (state) => ({
  conversations: state.chat.conversations,
  selectedConversationId: state.chat.selectedConversationId,
  loadingConversations: state.chat.loadingConversations,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        loadConversations,
        loadMessages,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversations);
