import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Redirect, Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { isEmail } from '../../utils/validator';
import {
  login,
  saveCredentials,
  clearAutoLogout,
} from '../../redux/actions/member';
import logoLoginPng from '../../assets/img/logo-login.png';
import { nonBrowserFeatureState } from '../../utils/non-browser-state';

import styles from '../../jss/views/loginStyle';
import FormHeader from '../../components/AuthFormHeader';

const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = (props) => {
  const classes = useStyles();
  const { dispatch, member } = props;
  const loginAttempt = member.loginAttempt;
  const propsLoginErr = member.loginErr;
  const credentials = member.credentials;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [pwdErr, setPwdErr] = useState(false);
  const [loginErr, setLoginErr] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const emailRef = React.useRef();
  const passwordRef = React.useRef();

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
    setEmailErr(false);
    setLoginErr(false);
  };
  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
    setPwdErr(false);
    setLoginErr(false);
  };
  const handlePasswordKeyChange = (evt) => {
    if (evt.key === 'Enter') {
      onLogin();
    }
  };
  const handleEmailKeyChange = (evt) => {
    if (evt.key === 'Enter') {
      passwordRef.current.focus();
    }
  };
  const onLogin = async () => {
    const formData = { username: email, password };

    if (!isEmail(email)) {
      setEmailErr(true);
      return;
    }

    if (!password) {
      setPwdErr(true);
      return;
    }
    if (rememberMe) dispatch(saveCredentials({ email }));
    dispatch(login(JSON.stringify(formData)));
  };

  useEffect(() => {
    emailRef && emailRef.current && emailRef.current.focus();
  }, [emailRef]);

  useEffect(() => {
    setLoginErr(true);
  }, [propsLoginErr, loginAttempt]);

  useEffect(() => {
    if (credentials && credentials.email !== undefined)
      setEmail(credentials.email);
  }, [credentials]);

  // That will let the data to load properly before we display the app
  if (member.session_id && member.member && member.memberInfo) {
    console.log('Firing redirection after login()');
    return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />;
  }

  const handleClose = () => {
    dispatch(clearAutoLogout());
  };

  return (
    <div className={classes.loginPage}>
      <FormHeader />
      <div className={classes.formSection}>
        {!nonBrowserFeatureState && (
          <div className={classes.goHome}>
            <Link to={`${process.env.PUBLIC_URL}/home`}>&lt;- Home</Link>
          </div>
        )}
        <div className={classes.logoImg}>
          <img src={logoLoginPng} alt="logo" />
        </div>
        <div className="emailInput">
          <TextField
            error={emailErr}
            inputRef={emailRef}
            helperText={emailErr && 'Wrong Email Address!'}
            label="Email Address or User Name"
            fullWidth
            color="primary"
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleEmailKeyChange}
          />
        </div>
        <div className={classes.passwordInput}>
          <TextField
            inputRef={passwordRef}
            error={pwdErr}
            helperText={pwdErr && "Password can't be blank!"}
            label="Password"
            color="primary"
            type="password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handlePasswordKeyChange}
          />
        </div>
        <Grid container spacing={3}>
          <Grid container item xs={11} sm={6} md={6} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={() => {
                    setRememberMe((value) => !value);
                  }}
                  name="rememberMe"
                  color="primary"
                />
              }
              label="Remember me"
            />
          </Grid>
          <Grid
            container
            item
            justify="flex-end"
            xs={11}
            sm={6}
            md={6}
            lg={6}
            className={classes.forgotPasswordLabel}
          >
            <label>
              <Link to={`${process.env.PUBLIC_URL}/login/forgot`}>
                Forgot Password?
              </Link>
            </label>
          </Grid>
        </Grid>
        {loginErr && <div>{propsLoginErr}</div>}
        <div className={classes.signInWrapper}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={onLogin}
          >
            Sign In
          </Button>
          {/* <label>Don’t have an account? <Link to={`${process.env.PUBLIC_URL}/signup`}>Sign Up</Link></label> */}
        </div>
      </div>
      <Snackbar
        open={member.autoLogout}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} onClick={handleClose} severity="warning">
          Your session has timed out, please sign in again
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.member,
});

export default connect(mapStateToProps)(Login);
