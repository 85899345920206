import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';

function MessageSkeleton({ num }) {
  return (
    <ListItem
      key={num}
      className={`${
        num % 2 === 0 ? 'other-message' : 'my-message'
      } chat-message skeleton`}
    >
      <Skeleton
        variant="circle"
        animation={false}
        className="chat-message-avatar-div"
      >
        <Avatar />
      </Skeleton>
      <div className="message-content">
        <Skeleton variant="rect" animation="wave" className="message-time" />
        <Skeleton variant="rect" animation="wave" className="message-text" />
      </div>
    </ListItem>
  );
}

export default MessageSkeleton;
