import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from './modal';

const ImageCrop = (props) => {
  const { show, close, src, handleCropConfirm } = props;
  //   const [croppedImgSrc, setCroppedImageSrc] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);

  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    aspect: 1 / 1,
  });
  const imageRef = useRef(null);
  const title = 'Crop image';

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(
        imageRef.current,
        crop,
        'Avatar.jpeg'
      );
      setCroppedFile(croppedImage);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    });
  };

  const handleAcceptClick = () => {
    handleCropConfirm(new File([croppedFile], croppedFile.name));
    close();
  };

  const ModalContent = () => (
    <div className="react-crop-container">
      <ReactCrop
        src={src}
        className="react-crop"
        // circularCrop
        keepSelection
        crop={crop}
        onImageLoaded={(image) => (imageRef.current = image)}
        onComplete={(crop) => makeClientCrop(crop)}
        onChange={(crop) => setCrop(crop)}
      />
    </div>
  );
  const ModalAction = () => (
    <div className="actions-wrapper">
      <Button
        color="secondary"
        variant="contained"
        onClick={close}
        className="action-button"
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={handleAcceptClick}
        className="action-button"
      >
        Accept
      </Button>
    </div>
  );

  return (
    <Modal
      open={show}
      onClose={close}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="crop-modal"
    />
  );
};

export default ImageCrop;
