import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import FullScreenMailPreview from './FullScreenMailPreview';
import MailPreview from './MailPreview';
import { useMailListStyles } from './MailListStyles';

export const MailList = ({
  list,
  selectEmail,
  fetchData,
  section,
  selectedEmail,
  handlers,
  member,
}) => {
  const classes = useMailListStyles();
  const getItems = () => {
    return list.map((email, index) => {
      const isOwnEmail =
        section === 'Sent' ? true : email.sender_mail === member.email;

      return section === 'Draft' ? (
        <FullScreenMailPreview
          key={`${index}-draft`}
          index={index}
          email={email}
          handlers={handlers}
          selectEmail={selectEmail}
        />
      ) : (
        <div
          aria-label={`email-preview-${index}`}
          key={email.mail_id}
          onClick={() => {
            selectEmail(email.mail_id);
          }}
          style={{ padding: 5 }}
        >
          <MailPreview
            email={email}
            section={section}
            selectedEmail={selectedEmail}
            isOwnEmail={isOwnEmail}
          />
        </div>
      );
    });
  };

  // const getOffset =
  //   section === 'Draft' ? 'calc(100vh - 300px)' : 'calc(100vh - 340px)';
  return (
    <InfiniteScroll
      dataLength={getItems().length} //This is important field to render the next data
      //call lazy loading
      // next={fetchData}
      hasMore={true}
      className={classes.mailsContainerPanel}
      // height={getOffset}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
      // loader={<h4>Loading...</h4>}
      // below props only if you need pull down functionality
      // refreshFunction={refresh}
      // pullDownToRefresh
      // pullDownToRefreshThreshold={50}
      // pullDownToRefreshContent={
      //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
      // }
      // releaseToRefreshContent={
      //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
      // }
    >
      {list && getItems()}
    </InfiniteScroll>
  );
};
