/* global APP  */

import { vacall, conference } from './global';
// import { connect } from 'react-redux';
import UIEvents from './service/UI/UIEvents';
import  createBlurEffect  from './stream-effects/blur/index.js'

const JitsiMeetJS = window.JitsiMeetJS;
const $ = window.$;
const commands = {
  CUSTOM_ROLE: 'custom-role',
  ETHERPAD: 'etherpad',
  SHARED_VIDEO: 'shared-video',
  Mute_everyone: 'Mute_everyone',
};
const apiUrl =  window.apiUrl || `${window.location.origin}/api`;
class VideoConference {
  /**
   * Start the connection and get the UI ready for the conference.
   *
   * @inheritdoc
   */
  /**
   * That function is executed when the conference is joined
   */

  _onContainerClick(event) {
    let userid = event.target.offsetParent.dataset.id;
    //console.log(userid);
    if (vacall.room.getParticipantById(userid)) {
      //alert('s');
      var track1 = vacall.room
        .getParticipantById(userid)
        .getTracksByMediaType('video')[0];
      console.log(track1.getParticipantId());
      if (track1 !== undefined) {
        track1.attach($('#large_videowrapper')[0]);
        /* select endpoint receive higher video quality starts */ 
        var PId = track1.getParticipantId();
        vacall.room.selectParticipant(PId);
        /* select endpoint receive higher video quality ends */ 
        $('.largeVvideo').attr('data-stage',track1.getParticipantId())
          if(track1.isMuted()) {
            $('#large_videowrapper').hide()
          } else {
            $('#large_videowrapper').show()
          }
        let logo  = APP.vacall.VI.getLogoContainerRemote(track1.getParticipantId());
        $('#dominantSpeakerAvatar').html(logo);
        APP.UI.emitEvent(UIEvents.VERTICAL_VIEW);
      }
    }
      return false;
  }
  componentWillUnmount() {
    this.unload();
  }
  source(srcProp){
    if (srcProp) {
      const isDataURL = srcProp.startsWith('data:');
      // const isDataURL = RegExp(
      //   /^\s*data:([a-z]+\/[a-z0-9\-\+]+(;[a-z\-]+\=[a-z0-9\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i
      // ).test(srcProp);
      if (isDataURL) {
        return srcProp;
      } else {
        return `${apiUrl}${srcProp}`;
      }
    } else {
      return null;
    }
  };

  onConferenceJoined = (first_name,amera_avatar_url) => {
    console.log('conference joined!');
    vacall.room.setDisplayName(first_name);
    vacall.localParticipant._displayName = first_name;
    vacall.localParticipant._avatar_url = this.source(amera_avatar_url);
    vacall.isJoined = true;
    for (let i = 0; i < vacall.localTracks.length; i++) {
      vacall.room.addTrack(vacall.localTracks[i]);
    }
    
    
  };
  /**
   *
   * @param id
   */

  /**
   * That function is called when connection is established successfully
   */
  onConnectionSuccess = (first_name,amera_avatar_url) => {
    let params = new URL(document.location).searchParams;
    let roomName = params.get('room');
  
    vacall.room = vacall.connection.initJitsiConference(roomName, conference);
    vacall.room.on(
      JitsiMeetJS.events.conference.TRACK_ADDED,
      this.onRemoteTrack
    );
    vacall.room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, (track) => {
      console.log(`track removed!!!${track}`);
      this.onRemoteStreamRemoved(track);
    });
    vacall.room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
      $('.largeVvideo').attr('data-stage',APP.vacall.room.myUserId())
      APP.vacall.room.sendCommand('customUserAvatar', {
        attributes: { xmlns: 'https://jabber.org/protocol/customUserAvatar' },
        value: amera_avatar_url
      });
      this.onConferenceJoined(first_name,amera_avatar_url);
      let logo  = this.getLogoContainerlocal(APP.vacall.room.myUserId());
      $('#dominantSpeakerAvatar').html(logo);
      $('.avatar-pic').html(logo);
    });
    vacall.room.on(JitsiMeetJS.events.conference.USER_JOINED, (id) => {
      console.log('user join');
      vacall.userCount = vacall.room.getParticipantCount();
     
      APP.UI.emitEvent(UIEvents.USER_COUNT, APP.vacall.userCount);
      vacall.remoteTracks[id] = [];
      this.container = this.createContainer(`participant_${id}`, id);
      this.$container = $(this.container);
      this.container.onclick = this._onContainerClick;
      if(APP.vacall.userCount <=2) {
        APP.UI.emitEvent(UIEvents.SWITCHCAM);
      }
    });
    vacall.room.on(JitsiMeetJS.errors.track.TRACK_NO_STREAM_FOUND, (track) => {
      console.log(track);
     console.log('no media');
    });
    vacall.room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
    vacall.room.on(
      JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED,
      this.onDominant
    );
    vacall.room.on(
      JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
      (track) => {
        console.log('here success no track remote or local');
       // this.onLocalmuted(track);
        this.mutehappen(track);
        console.log(`${track.getType()} - ${track.isMuted()}`);
      }
    );
    vacall.room.on(
      JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
      (userID, displayName) => console.log(`${userID} - ${displayName}`)
    );
    vacall.room.on(
      JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
      (userID, audioLevel) => console.log(`${userID} - ${audioLevel}`)
    );
    vacall.room.on(JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED, () =>
      console.log(
        `${vacall.room.getPhoneNumber()} - ${vacall.room.getPhonePin()}`
      )
    );
    this._setupListeners();
    vacall.room.join();
    
  };
  _setupListeners() {
    vacall.isLocalId = (id) => {
      return APP.vacall.room.myUserId() === id;
    };
    vacall.isMicMuted = () => {
      if (APP.vacall.localTracks && APP.vacall.localTracks[0]) {
        return APP.vacall.localTracks[0].isMuted();
      }
    };
    vacall.isVideoMuted = () => {
      if (APP.vacall.localTracks && APP.vacall.localTracks[1])
        return APP.vacall.localTracks[1].isMuted();
    };
    vacall.isVideoMuted = () => {
      if(APP.vacall.localTracks[1]) {
        return APP.vacall.localTracks[1].isMuted();
      } else {
        return true;
      }
      
    };
    /* eslint-disable max-params */
    APP.UI.addListener(
      UIEvents.UPDATE_SHARED_VIDEO,
      (url, state, time, isMuted, volume) => {
        /* eslint-enable max-params */
        // send start and stop commands once, and remove any updates
        // that had left
        if (state === 'stop' || state === 'start' || state === 'playing') {
          const localParticipant = vacall.room.myUserId();
          vacall.room.removeCommand(commands.SHARED_VIDEO);
          vacall.room.sendCommandOnce(commands.SHARED_VIDEO, {
            value: url,
            attributes: {
              state,
              time,
              muted: isMuted,
              volume,
              from: localParticipant.id,
            },
          });
        } else {
          // in case of paused, in order to allow late users to join
          // paused
          vacall.room.removeCommand(commands.SHARED_VIDEO);
          vacall.room.sendCommand(commands.SHARED_VIDEO, {
            value: url,
            attributes: {
              state,
              time,
              muted: isMuted,
              volume,
            },
          });
        }

        //APP.store.dispatch(setSharedVideoStatus(state));
      }
    );
    vacall.room.addCommandListener(
      commands.SHARED_VIDEO,
      ({ value, attributes }, id) => {
        if (attributes.state === 'stop') {
          APP.UI.onSharedVideoStop(id, attributes);
        } else if (attributes.state === 'start') {
          // alert('start')
          APP.UI.onSharedVideoStart(id, value, attributes);
        } else if (
          attributes.state === 'playing' ||
          attributes.state === 'pause'
        ) {
          //  alert('pause')
          APP.UI.onSharedVideoUpdate(id, value, attributes);
        }
      }
    );
    vacall.room.addCommandListener(
      commands.Mute_everyone,
      ({ value, attributes }, id) => {
        if (!APP.vacall.isLocalId(id)) {
          APP.UI.emitEvent(UIEvents.MUTE_ALL, value, id);
        }
      }
    );
    APP.UI.addListener(UIEvents.SWITCHCAM, () => {
      setTimeout(()=>{
        if(APP.vacall.room.isP2PActive()){
          if(APP.vacall.room.p2pJingleSession.peerconnection.getLocalTracks().length === 0) {
            //alert();
            this.switchVideoCam();
          }
        }
        //this.switchVideoCam();
      },7000)
       
    });
  }

  /**
   * This function is called when the connection fail.
   */
  onConnectionFailed = () => {
    console.error('Connection Failed!');
  };

  /**
   * This function is called when the connection fail.
   */
  onDeviceListChanged = (devices) => {
    console.info('current devices', devices);
  };

  /**
   * This function is called when we disconnect.
   */
  disconnect = () => {
    console.log('disconnect!');
    vacall.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionSuccess
    );
    vacall.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      this.onConnectionFailed
    );
    vacall.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      this.disconnect
    );
  };

  /**
   *
   */

  /**
   *
   */
  switchVideo = () => {
    // eslint-disable-line no-unused-vars
    vacall.isVideo = !vacall.isVideo;
    if (vacall.localTracks[1]) {
      vacall.localTracks[1].dispose();
      vacall.localTracks.pop();
    }
    JitsiMeetJS.createLocalTracks({
      devices: ['desktop'],
    })
      .then((tracks) => {
        vacall.localTracks.push(tracks[0]);
        vacall.localTracks[1].addEventListener(
          JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
          () => console.log('local track muted')
        );
        vacall.localTracks[1].addEventListener(
          JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
          () => {
            this.switchVideoCam();
            this.switchSV(false);
            vacall.screenshareEnabled = false;
            console.log('local track stoped');
          }
        );
        this.switchSV(true);
        vacall.localTracks[1].attach($('#localVideo1')[0]);
        vacall.room.addTrack(vacall.localTracks[1]);
        vacall.screenshareEnabled = true;

        // $('.mutecam').click();
        // vacall.localTracks[1].mute();
      })
      .catch((error) => {
        this.switchVideoCam();
        vacall.screenshareEnabled = false;
        console.log(error);
      });
  };
  switchVideoCam = (trackID = null, audioMic = null, output) => {
    // eslint-disable-line no-unused-vars
    vacall.isVideo = !vacall.isVideo;
    if (vacall.localTracks[1]) {
      vacall.localTracks[1].dispose();
      vacall.localTracks.pop();
    }
    if (vacall.localTracks[0]) {
      vacall.localTracks[0].dispose();
      vacall.localTracks.pop();
    }
    if (output !== undefined) {
     // JitsiMeetJS.mediaDevices.setAudioOutputDevice(output);
    }
    JitsiMeetJS.createLocalTracks({
      devices: ['video', 'audio'],
      cameraDeviceId: trackID,
      micDeviceId: audioMic,
    })
      .then((tracks) => {
        console.log(tracks);
        vacall.localTracks.push(tracks[0]);
        vacall.localTracks.push(tracks[1]);
        // vacall.localTracks[0].addEventListener(
        //   JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
        //   () => {
        //     console.log('local track muted')
        //   }
        // );
        // vacall.localTracks[0].addEventListener(
        //   JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
        //   () => console.log('local track stoped')
        // );
        vacall.localTracks[0].attach($('#localAudio1')[0]);
        vacall.room.addTrack(vacall.localTracks[0]);
        //vacall.localTracks.push(tracks[0]);
        // vacall.localTracks[1].addEventListener(
        //   JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
        //   () => console.log('local track muted')
        // );
        // vacall.localTracks[1].addEventListener(
        //   JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
        //   () => console.log('local track stoped')
        // );
        vacall.localTracks[1].attach($('#localVideo1')[0]);
        vacall.localTracks[1].attach($('#localVideo2')[0]);
        vacall.room.addTrack(vacall.localTracks[1]);
        let onStage =  $('.largeVvideo').attr('data-stage');
        if(!APP.vacall.isLocalId(onStage)){
          var track1 = vacall.room
          .getParticipantById(onStage)
          .getTracksByMediaType('video')[0];
          if (track1 !== undefined) {
            track1.attach($('#large_videowrapper')[0]);
            /* select endpoint receive higher video quality starts */ 
            var PId = track1.getParticipantId();
            vacall.room.selectParticipant(PId);
            /* select endpoint receive higher video quality ends */ 
            $('.largeVvideo').attr('data-stage',track1.getParticipantId())
            let logo  = this.getLogoContainerRemote(track1.getParticipantId());
            $('#dominantSpeakerAvatar').html(logo);
         
              if(track1.isMuted()) {
                $('#large_videowrapper').hide()
              } else {
                $('#large_videowrapper').show()
              }
          }
        }
     
      })
      .catch((error) => console.log(error));
  };

  switchSV = (action = null, trackID = null, audioMic = null) => {
    // eslint-disable-line no-unused-vars
    JitsiMeetJS.createLocalTracks({
      devices: ['video'],
      cameraDeviceId: trackID,
      // micDeviceId:audioMic
    })
      .then((tracks) => {
        console.log(tracks[0]);
        console.log('me tracks');
        var element = document.getElementById(
          'localVideoContainer_screensahre'
        );
        if (action) {
          tracks[0].attach($('#localVideo3')[0]);
          element.style.display = 'block';
        } else {
          tracks[0].detach($('#localVideo3')[0]);
          element.style.display = 'none';
        }
      })
      .catch((error) => console.log(error));
  };
  toggleYoutube = (action = null, trackID = null, audioMic = null) => {
    APP.UI.emitEvent(UIEvents.SHARED_VIDEO_CLICKED);
  };

  /**
   *
   * @param selected
   */
  changeAudioOutput = (selected) => {
    // eslint-disable-line no-unused-vars
    JitsiMeetJS.mediaDevices.setAudioOutputDevice(selected.value);
  };

  /**
   * Handles remote tracks
   * @param track JitsiTrack object
   */

  onRemoteTrack = (track) => {
    console.log(track);
    if (track.isLocal()) {
       // alert(track.isLocal())
        //var l_tracks = APP.vacall.room.getLocalVideoTrack();
      //  l_tracks.attach($('#large_videowrapper')[0]);
    
        return false;
    }
    const participant = track.getParticipantId();

    if (!vacall.remoteTracks[participant]) {
      vacall.remoteTracks[participant] = [];
    }
    // const idx = vacall.remoteTracks[participant].push(track);

    track.addEventListener(
      JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
      (audioLevel) => console.log(`Audio Level remote: ${audioLevel}`)
    );
    track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, (track) => {
       // this.mutehappen(track);
       console.log('only emote track muted');
      }
    );
    track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () =>
      console.log('remote track stoped')
    );
    track.addEventListener(
      JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
      (deviceId) =>
        console.log(`track audio output device was changed to ${deviceId}`)
    );
    // const id = participant + track.getType() + idx;

    track.attach($('#large_videowrapper')[0]);
    /* select endpoint receive higher video quality starts */ 
    var PId = track.getParticipantId();
    vacall.room.selectParticipant(PId);
    /* select endpoint receive higher video quality ends */ 
    if(track.isMuted()) {
      $('#large_videowrapper').hide()
    } else {
      $('#large_videowrapper').show()
    }
    $('.largeVvideo').attr('data-stage',track.getParticipantId())
   
    console.log(track);
    this.onRemoteStreamAdded(track);
    console.log('vacall.remoteVideos');
    console.log(vacall.remoteVideos);
  };
  getLogo(track) {
    let id = track.getParticipantId();
    let name ='';
    if (track.isLocal()) {
      name = APP.vacall.localParticipant._displayName;
      if(name) {
        name = name.match(/\b(\w)/g);
        name = name.join('');
      }
    } else {
      name = vacall.room.getParticipantById(id)._displayName;
      if(name) {
        name = name.match(/\b(\w)/g);
        name = name.join('');
      }
    }
      let logo = `<svg
          className="avatar-svg"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <text
            dominant-baseline="central"
            fill="rgba(255,255,255,.6)"
            font-size="40pt"
            text-anchor="middle"
            x="50"
            y="50"
          >
            ${name}
          </text>
        </svg>`;
    return logo;
  }
  getLogoFromAPi = (url) => {
    fetch(url, {
      method: 'GET',
    }).then((res) => {
      console.log(res);
      if (res.ok) {
        return res.json();
      }
      let error = new Error(res.statusText);
      error.res = res;
      return Promise.reject(error);
    });

  }
  getLogoContainerlocal(id) {
    //const url = `${apiUrl}/member/${APP.vacall.localParticipant._member_id}/avatar`;
    //var logo_img = this.getLogoFromAPi(url);
    var logo_img = APP.vacall.localParticipant._avatar_url
    console.log(logo_img);
    if(logo_img) {
      return `<img src=${logo_img} style="width:100%;height:100%;border-radius:77px;"; class="av_image"/>`;
    }
    let name ='';
    if (APP.vacall.isLocalId(id)){
      name = APP.vacall.localParticipant._displayName;
      if(name) {
        name = name.match(/\b(\w)/g);
        name = name.join('');
      }
    }
      let logo = `<svg
          className="avatar-svg"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <text
            dominant-baseline="central"
            fill="rgba(255,255,255,.6)"
            font-size="40pt"
            text-anchor="middle"
            x="50"
            y="50"
          >
            ${name}
          </text>
        </svg>`;
    return logo;
  }

  getLogoContainerRemote(id) {
    //const url = `${apiUrl}/member/${APP.vacall.localParticipant._member_id}/avatar`;
    //var logo_img = this.getLogoFromAPi(url);
    
    let name ='';
    if (APP.vacall.isLocalId(id)){
      name = APP.vacall.localParticipant._displayName;
      if(name) {
        name = name.match(/\b(\w)/g);
        name = name.join('');
      }
    } else {

        var logo_img = vacall.room.getParticipantById(id)._customUserAvatar;
        console.log(logo_img);
        if(logo_img) {
          return `<img src=/api/${logo_img} style="width:100%;height:100%;border-radius:77px;"; class="av_image"/>`;
        }
      name = vacall.room.getParticipantById(id)._displayName;
      if(name) {
        name = name.match(/\b(\w)/g);
        name = name.join('');
      }
    }
      let logo = `<svg
          className="avatar-svg"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <text
            dominant-baseline="central"
            fill="rgba(255,255,255,.6)"
            font-size="40pt"
            text-anchor="middle"
            x="50"
            y="50"
          >
            ${name}
          </text>
        </svg>`;
    return logo;
  }
  mutehappen = (track) => {
    if(APP.vacall.isLocalId(track.getParticipantId())){
      if(track.getType() === 'video') {
        console.log('dddddd');
        if(track.isMuted()) {
          $('.avatar-container').show();
        } else {
          $('.avatar-container').hide();
        }
        let onStage =  $('.largeVvideo').attr('data-stage');
        if(!APP.vacall.isLocalId(onStage)){
          var track1 = vacall.room
          .getParticipantById(onStage)
          .getTracksByMediaType('video')[0];
          if (track1 !== undefined) {
            track1.attach($('#large_videowrapper')[0]);
            /* select endpoint receive higher video quality starts */ 
            var PId = track1.getParticipantId();
            vacall.room.selectParticipant(PId);
            /* select endpoint receive higher video quality ends */
            $('.largeVvideo').attr('data-stage',track1.getParticipantId())
            let logo  = this.getLogoContainerRemote(track1.getParticipantId());
            console.log(logo);
            console.log('logo here');
            $('#dominantSpeakerAvatar').html(logo);  
            console.log('participant_'+track1.getParticipantId()+' > video');
              if(track1.isMuted()) {
                $('#large_videowrapper').hide()
              } else {
                $('#large_videowrapper').show()
              }
          }
        }
      }
      return false;
    }
    let onStage =  $('.largeVvideo').attr('data-stage');
    if(onStage === track.getParticipantId()){
      if(track.getType() === 'video') {
        if(track.isMuted()) {
          $('#large_videowrapper').hide()
          $('#participant_'+track.getParticipantId() + ' > video').hide();
          
        } else {
          $('#large_videowrapper').show()
          $('#participant_'+track.getParticipantId() + ' > video').show();
          
        }
      }
      
    } 
    if(track.isMuted()) {
      $('#participant_'+track.getParticipantId() + ' > video').hide();
      $('#remoteAvatar'+track.getParticipantId()).show();
    } else {
      $('#participant_'+track.getParticipantId() + ' > video').show();
      $('#remoteAvatar'+track.getParticipantId()).hide();
    }
    console.log('participant_'+track.getParticipantId()+' > video');
    console.log(track);
    console.log(`${track.getType()} - ${track.isMuted()} - ${track.getParticipantId()}`);
  }
  onLocalmuted = (track) => {
   
    if(track.getType() === 'video') {
      if(track.isMuted()) {
        $('.avatar-container').show();
      } else {
        $('.avatar-container').hide();
      }
    }
    // console.log(track);
    // console.log(`${track.getType()} - ${track.isMuted()} - ${track.getParticipantId()}`);
  }

  createContainer = (spanId, id) => {
    const container = document.createElement('span');
    container.id = spanId;
    container.className = 'videocontainer grid-item';
    container.setAttribute('data-id',id);
    let userName = vacall.room.getParticipantById(id)._displayName;
    console.log(vacall.room.getParticipantById(id));
    console.log(userName);
    console.log('userName');
   
    container.innerHTML = `
                <div class = 'videocontainer__background'></div>
                <div class = 'videocontainer__toptoolbar'></div>
                <div class = 'videocontainer__hoverOverlay'></div>

                <div class = 'displayNameContainer'><div class="displayname" id="localDisplayName${id}"></div></div>
                <div id="remoteAvatar${id}" class = 'remote-avatar-container'></div>
                <div class ='presence-label-container' style='display:none;'></div>
                <span class = 'remotevideomenu'></span>`;

    const remoteVideosContainer = document.querySelector(
      '#filmstripRemoteVideosContainer'
    );
    // const localVideoContainer = document.querySelector(
    //   '#localVideoTileViewContainer'
    // );
    setTimeout(() => {
      if (vacall.room) {
        var dd = vacall.room.getParticipantById(id)._displayName;
        $('#localDisplayName' + id).html(dd);
        let logoo  = this.getLogoContainerRemote(id);
        $('#remoteAvatar' + id).html(logoo);
        $('#dominantSpeakerAvatar').html(logoo);
      }
    }, 2000);
    remoteVideosContainer.prepend(container);
    vacall.remoteVideos[id] = container;

    return container;
  };
  onRemoteStreamAdded = (stream) => {
    const id = stream.getParticipantId();
    const remoteVideo = vacall.remoteVideos[id];

    console.log(`Received a new ${stream.getType()} stream for ${id}`);

    if (!remoteVideo) {
      console.log('No remote video element to add stream');

      return;
    }

    this.addRemoteStreamElement(stream);

    // Make sure track's muted state is reflected
  };

  addRemoteStreamElement = (stream) => {
    const pid = stream.getParticipantId();
    const streamElement = this.createStreamElement(stream);
    var container = document.querySelector('#participant_' + pid);
    // Put new stream element always in front
    this.prependChild(container, streamElement);

    $(streamElement).hide();

    this.waitForPlayback(streamElement, stream);
    stream.attach(streamElement);

    if (!vacall.isVideo) {
    }
  };
  prependChild = (container, newChild) => {
    const firstChild = container.childNodes[0];

    if (firstChild) {
      container.insertBefore(newChild, firstChild);
    } else {
      container.appendChild(newChild);
    }
  };
  createStreamElement = (stream) => {
    vacall.isVideo = stream.isVideoTrack();
    const element = vacall.isVideo
      ? document.createElement('video')
      : document.createElement('audio');

    if (vacall.isVideo) {
      element.setAttribute('muted', 'true');
    }
    // else if (config.startSilent) {
    //     element.muted = true;
    // }

    //element.autoplay = !config.testing?.noAutoPlayVideo;
    element.autoplay = true;
    element.id = this.getStreamElementID(stream);

    return element;
  };

  getStreamElementID = (stream) => {
    return (
      (stream.isVideoTrack() ? 'remoteVideo_' : 'remoteAudio_') + stream.getId()
    );
  };

  waitForPlayback = (streamElement, stream) => {
    const webRtcStream = stream.getOriginalStream();
    vacall.isVideo = stream.isVideoTrack();

    if (!vacall.isVideo || webRtcStream.id === 'mixedmslabel') {
      return;
    }

    streamElement.onplaying = () => {
      //this.wasVideoPlayed = true;
      // remoteVideoActive(streamElement, this.id);
      streamElement.onplaying = null;
      $(streamElement).show();

      // Refresh to show the video
      //this.updateView();
    };
  };

  remoteVideoActive = (videoElement, resourceJid) => {
    console.log(`${resourceJid} video is now active`, videoElement);
    if (videoElement) {
      $(videoElement).show();
    }
    //this._updateLargeVideoIfDisplayed(resourceJid, true);
  };
  onRemoteStreamRemoved = (stream) => {
    const id = stream.getParticipantId();
    const remoteVideo = vacall.remoteVideos[id];

    // Remote stream may be removed after participant left the conference.

    if (remoteVideo) {
      this.removeRemoteStreamElement(stream);
    }
  };
  removeRemoteStreamElement = (stream) => {
    var pid = stream.getParticipantId();
    var container = document.querySelector('#participant_' + pid);
    if (!container) {
      return false;
    }

    vacall.isVideo = stream.isVideoTrack();
    const elementID = this.getStreamElementID(stream);
    const select = $(`#${elementID}`);

    select.remove();

    console.log(`${vacall.isVideo ? 'Video' : 'Audio'} removed ${pid}`, select);
    // }

    // this.updateView();
  };

  onUserLeft = (id) => {
    console.log('user left');
    if (vacall.room == null) {
      return false;
    }
    $('#participant_' + id).remove();
    if (!vacall.remoteTracks[id]) {
      return;
    }
    // const tracks = vacall.remoteTracks[id];
    var pcount = vacall.room.getParticipants().length;
    if (vacall.room !== null) {
      if (pcount === 0) {
        var l_tracks = vacall.room.getLocalVideoTrack();
        //l_tracks.attach($('#large_videowrapper')[0]);
        $('.largeVvideo').attr('data-stage',l_tracks.getParticipantId())
        if (l_tracks !== undefined) {
          l_tracks.attach($('#large_videowrapper')[0]);
          /* select endpoint receive higher video quality starts */ 
          var PId = l_tracks.getParticipantId();
          vacall.room.selectParticipant(PId);
          /* select endpoint receive higher video quality ends */ 
          let logo  = this.getLogoContainerlocal(l_tracks.getParticipantId());
          $('#dominantSpeakerAvatar').html(logo);  
          console.log('participant_'+l_tracks.getParticipantId()+' > video');
            if(l_tracks.isMuted()) {
              $('#large_videowrapper').hide()
            } else {
              $('#large_videowrapper').show()
            }
        }
      }
    }
    vacall.userCount = vacall.room.getParticipantCount();
    APP.UI.emitEvent(UIEvents.USER_COUNT, APP.vacall.userCount);
    if (vacall.youTubeJOined.from === id) {
      APP.UI.emitEvent(UIEvents.UPDATE_SHARED_VIDEO, null, 'stop');
    }
    // for (let i = 0; i < tracks.length; i++) {
    //     tracks[i].detach($(`#${id}${tracks[i].getType()}`));
    // }
  };

  onDominant = (id) => {
    if (vacall.room.getParticipantById(id)) {
      //alert('s');
      var track1 = vacall.room
        .getParticipantById(id)
        .getTracksByMediaType('video')[0];
      if (track1 !== undefined) {
        track1.attach($('#large_videowrapper')[0]);

        /* select endpoint receive higher video quality starts */ 
        var PId = track1.getParticipantId();
        vacall.room.selectParticipant(PId);
        /* select endpoint receive higher video quality ends */ 

        $('.largeVvideo').attr('data-stage',track1.getParticipantId())
        let logo  = this.getLogoContainerRemote(track1.getParticipantId());
        $('#dominantSpeakerAvatar').html(logo);
     
          if(track1.isMuted()) {
            $('#large_videowrapper').hide()
          } else {
            $('#large_videowrapper').show()
          }
        
      }
    }

    // for (let i = 0; i < tracks.length; i++) {
    //     tracks[i].detach($(`#${id}${tracks[i].getType()}`));
    // }
  };
  /**
   * Handles local tracks.
   * @param tracks Array with JitsiTrack objects
   */
  onLocalTracks = (tracks) => {
    vacall.localTracks = tracks;
    for (let i = 0; i < vacall.localTracks.length; i++) {
      vacall.localTracks[i].addEventListener(
        JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        (audioLevel) => console.log(`Audio Level local: ${audioLevel}`)
      );
      vacall.localTracks[i].addEventListener(
        JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
        (track) => {
            console.log('local track muted')
           // this.onLocalmuted(track);
           //$('.largeVvideo').attr('data-stage',track.getParticipantId())
          }
      );
      vacall.localTracks[i].addEventListener(
        JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
        () => console.log('local track stoped')
      );
      vacall.localTracks[i].addEventListener(
        JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
        (deviceId) =>
          console.log(`track audio output device was changed to ${deviceId}`)
      );
      if (vacall.localTracks[i].getType() === 'video') {
        $('#localVideoWrapper').append(
          `<video autoPlay='1'  id='localVideo${i}' />`
        );
        $('#localVideoContainer2').append(
          `<video autoPlay='1'  id='localVideo2' />`
        );
        vacall.localTracks[i].attach($(`#localVideo${i}`)[0]);
        vacall.localTracks[i].attach($(`#localVideo2`)[0]);
        vacall.localTracks[i].attach($('#large_videowrapper')[0]);
      } else {
        $('.audio-elements').append(
          `<audio autoPlay='1' muted='true' id='localAudio${i}' />`
        );
        vacall.localTracks[i].attach($(`#localAudio${i}`)[0]);
      }
      if (vacall.isJoined) {
        vacall.room.addTrack(vacall.localTracks[i]);
       
      }
    }
  };
  unload = () => {
    if (vacall.room != null) {
      for (let i = 0; i < vacall.localTracks.length; i++) {
        vacall.localTracks[i].dispose();
      }

      try {
        vacall.room
          .leave()
          .then(() => {})
          .catch((e) => {
            console.log('call end issue', e);
          });
        vacall.connection.disconnect();
        vacall.room = null;
        vacall.connection = {};
        vacall.isJoined = false;
        vacall.room = null;
        vacall.localTracks = [];
        vacall.remoteTracks = {};
        vacall.isVideo = null;
        vacall.remoteVideos = {};
      } catch (e) {
        console.log('group call issue', e);
      }
    }
  };
  _toggleBlurEffect() {
    //alert('enable');
     //createBlurEffect();
     let enabled  =  APP.vacall.blurBackground ? false : true;
     return createBlurEffect()
     .then(blurEffectInstance =>
      APP.vacall.room.getLocalVideoTrack().setEffect(enabled ? blurEffectInstance : undefined)
             .then((blurEffectInstance) => {
                 APP.vacall.blurBackground=true;
                 APP.UI.emitEvent(UIEvents.BLUR,true);
                console.log(blurEffectInstance)
             })
             .catch(error => {
                APP.vacall.blurBackground=false;
                APP.UI.emitEvent(UIEvents.BLUR,false);
                // enabled ? dispatch(blurDisabled()) : dispatch(blurEnabled());
                 console.log('setEffect failed with error:', error);
             })
     )
     .catch(error => {
        // dispatch(blurDisabled());
         console.log('getBlurEffect failed with error:', error);
     });
    
  }

}

$(window).bind('beforeunload', unload);
$(window).bind('unload', unload);
function unload() {
  if (vacall.room != null) {
    for (let i = 0; i < vacall.localTracks.length; i++) {
      vacall.localTracks[i].dispose();
    }
    try {
      vacall.room
        .leave()
        .then(() => {})
        .catch((e) => {
          console.log('call end issue', e);
        });
      vacall.connection.disconnect();
      vacall.room = null;
      vacall.connection = {};
      vacall.isJoined = false;
      vacall.room = null;
      vacall.localTracks = [];
      vacall.remoteTracks = {};
      vacall.isVideo = null;
      vacall.remoteVideos = {};
    } catch (e) {
      console.log('group call issue', e);
    }
  }
}
setTimeout(()=>{
  if(APP.vacall.userCount <=2) {
    //APP.vacall.VI.switchVideoCam(vacall.room.getLocalTracks()[1].deviceId,vacall.room.getLocalTracks()[0].deviceId);
  }
},20000)
// const mapStateToProps = (state) => ({
//   memberState: state.member,
//   jobTitleList: state.member.jobTitleList,
// });

export default VideoConference;
