import React, { useState, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Typography, Button, Grid } from '@material-ui/core';

import MemberAutocomplete from '../MemberAutocomplete';
import Modal from '../Modal/modal';

import { addGroupMember } from '../../redux/actions/group';

const AddMemberModal = (props) => {
  const {
    title,
    open,
    onClose,
    groupId,
    groupData,
    isLoadingMemberInvite,
  } = props;
  const [addMember, setAddMember] = useState({});
  const dispatch = useDispatch();

  const disableSubmit = !addMember.email;

  const closeModal = () => {
    onClose();
  };

  const addedMembers = useMemo(() => {
    if (groupData.members && groupData.members.length) {
      return groupData.members.map((member) => {
        return member.member_id;
      });
    } else {
      return [];
    }
  }, [groupData]);

  const isAlreadyAddedMembers = useMemo(() => {
    return (option) => addedMembers.includes(option.contact_member_id);
  }, [addedMembers]);

  const handleSubmit = () => {
    let formData = new FormData();
    formData.set('groupId', groupId);
    formData.set('name', groupData.group_name);
    formData.set('type', 'add-group-member');
    formData.set('groupMemberEmail', addMember.email);
    formData.set('memberId', addMember.member_id);

    dispatch(addGroupMember(formData));
    closeModal();
  };

  const handleMembersSelect = (e, v) => {
    setAddMember(v);
  };

  const ModalContent = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1">
          Please select a member to add to your group
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MemberAutocomplete
          handleChange={handleMembersSelect}
          disablerFunction={isAlreadyAddedMembers}
          label="Add Member"
          isAddMember={true}
        />
      </Grid>
    </Grid>
  );

  const ModalAction = () => {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => closeModal()}
          disabled={isLoadingMemberInvite}
        >
          Cancel
        </Button>
        {isLoadingMemberInvite ? (
          <Button
            variant="contained"
            color="primary"
            disableRipple
            disabled={disableSubmit}
          >
            Adding...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disableRipple
            disabled={disableSubmit}
            onClick={handleSubmit}
          >
            Add Member
          </Button>
        )}
      </>
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      disabled={isLoadingMemberInvite}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="add-member-modal"
    ></Modal>
  );
};

AddMemberModal.defaultProps = {
  dispatch: null,
  open: false,
  onClose: null,
  group_id: null,
  isLoadingMemberInvite: false,
  groupAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

const mapStateToProps = (state) => ({
  groupData: state.group.groupData,
});

export default connect(mapStateToProps)(AddMemberModal);
