export const IS_LOADING_CONTACT_LIST = 'LOADING_CONTACT_LIST';
export const IS_FINISHED_CONTACT_LIST_LOADING =
  'IS_FINISHED_CONTACT_LIST_LOADING';

export const GET_CONTACT_MEMBERS = 'GET_CONTACT_MEMBERS';
export const UPDATE_CONTACT_MEMBERS = 'UPDATE_CONTACT_MEMBERS';
export const REMOVE_CONTACT_MEMBER = 'REMOVE_CONTACT_MEMBER';
export const REMOVE_CONTACT_MEMBERS = 'REMOVE_CONTACT_MEMBERS';
export const NEW_CONTACT = 'NEW_CONTACT';
export const NEW_CONTACTS = 'NEW_CONTACTS';

export const DELETE_CONTACT_START = 'DELETE_CONTACT_START';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILED = 'DELETE_CONTACT_FAILED';
export const UPDATE_CONTACT_REQUEST_START = 'UPDATE_CONTACT_REQUEST_START';
export const UPDATE_CONTACT_REQUEST_SUCCESS = 'UPDATE_CONTACT_REQUEST_SUCCESS';
export const UPDATE_CONTACT_REQUEST_FAILED = 'UPDATE_CONTACT_REQUEST_FAILED';

export const FETCH_CONTACT_COMPANIES_START = 'FETCH_CONTACT_COMPANIES_START';
export const FETCH_CONTACT_COMPANIES_SUCCESS =
  'FETCH_CONTACT_COMPANIES_SUCCESS';
export const FETCH_CONTACT_COMPANIES_FAILED = 'FETCH_CONTACT_COMPANIES_FAILED';
export const FETCH_CONTACT_COUNTRIES_START = 'FETCH_CONTACT_COUNTRIES_START';
export const FETCH_CONTACT_COUNTRIES_SUCCESS =
  'FETCH_CONTACT_COUNTRIES_SUCCESS';
export const FETCH_CONTACT_COUNTRIES_FAILED = 'FETCH_CONTACT_COUNTRIES_FAILED';
export const FETCH_CONTACT_ROLES_START = 'FETCH_CONTACT_ROLES_START';
export const FETCH_CONTACT_ROLES_SUCCESS = 'FETCH_CONTACT_ROLES_SUCCESS';
export const FETCH_CONTACT_ROLES_FAILED = 'FETCH_CONTACT_ROLES_FAILED';

export const SET_CONTACT_ALERT = 'SET_CONTACT_ALERT';
export const CONTACTS_RECIVERS = 'CONTACTS_RECIVERS';

export const SET_SECURITY = 'SET_SECURITY';
