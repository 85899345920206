import * as api from '../../config/api';
import { setContactAlert } from '../../utils/alert';

import {
  IS_LOADING_CONTACT_LIST,
  IS_FINISHED_CONTACT_LIST_LOADING,
  NEW_CONTACT,
  NEW_CONTACTS,
  SET_CONTACT_ALERT,
  GET_CONTACT_MEMBERS,
  UPDATE_CONTACT_MEMBERS,
  REMOVE_CONTACT_MEMBER,
  REMOVE_CONTACT_MEMBERS,
  DELETE_CONTACT_START,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILED,
  UPDATE_CONTACT_REQUEST_START,
  UPDATE_CONTACT_REQUEST_SUCCESS,
  UPDATE_CONTACT_REQUEST_FAILED,
  FETCH_CONTACT_COMPANIES_START,
  FETCH_CONTACT_COMPANIES_SUCCESS,
  FETCH_CONTACT_COMPANIES_FAILED,
  FETCH_CONTACT_COUNTRIES_START,
  FETCH_CONTACT_COUNTRIES_SUCCESS,
  FETCH_CONTACT_COUNTRIES_FAILED,
  FETCH_CONTACT_ROLES_START,
  FETCH_CONTACT_ROLES_SUCCESS,
  FETCH_CONTACT_ROLES_FAILED,
  CONTACTS_RECIVERS,
} from '../actionTypes/contact';

const apiUrl = api.apiUrl;

export const loadContactMembers = (params = {}, showAlert = false) => (
  dispatch
) => {
  let uri = '';
  if (params && params.type === 'sort') {
    uri = '?sort=' + params.sortItems;
  }

  console.log('fetch ran');
  const url = `${apiUrl}/member-contacts${uri}`;
  dispatch({ type: IS_LOADING_CONTACT_LIST });
  api
    .GET(url)
    .then((res) => {
      dispatch(loadMemberContactsSuccess(res.members));
      if (showAlert) {
        const message = 'Members Loaded Successfully';
        const payload = setContactAlert(res, true, message);
        dispatch(setReduxContactAlert(payload));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(loadMemberContactsSuccess([]));
      if (showAlert) {
        const payload = setContactAlert(error, false);
        dispatch(setReduxContactAlert(payload));
      }
    });
};

export const addMemberToContact = (data) => (dispatch) => {
  const url = `${apiUrl}/member-contacts`;
  console.log('Sending to add contacts', data);
  dispatch({ type: IS_LOADING_CONTACT_LIST });
  api
    .POST(url, data, {})
    .then((res) => {
      console.log('Server response upon add members to contact', res.contacts);
      dispatch(addNewContacts(res.contacts));
      dispatch(removeContactMembers(res.contacts));

      // update contact filter options
      dispatch(loadContactCompanies());
      dispatch(loadContactCountries());
      dispatch(loadContactRoles());
    })
    .catch((error) => {
      console.log(error);
    });
};

export const loadContacts = (
  params = {},
  showAlert = false,
  contacts = [],
  get_url = null
) => (dispatch) => {
  get_url = get_url ? get_url : '/member/contact';
  const url = `${apiUrl}${get_url}?${new URLSearchParams(params).toString()}`;
  dispatch({ type: IS_LOADING_CONTACT_LIST });
  api
    .GET(url)
    .then((res) => {
      const count = contacts.length + res.count;
      contacts.push(...res.contacts);
      dispatch(loadContactsSuccess({ contacts, count }));
      if (showAlert) {
        const message = 'Contacts Loaded Successfully';
        const payload = setContactAlert(res, true, message);
        dispatch(setReduxContactAlert(payload));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(loadContactsSuccess([]));
      if (showAlert) {
        const payload = setContactAlert(error, false);
        dispatch(setReduxContactAlert(payload));
      }
    });
};

export const loadContactCompanies = () => (dispatch) => {
  const url = `${apiUrl}/member/contacts/companies`;
  dispatch(fetchCompaniesRequestStart());
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.companies) {
        dispatch(fetchCompaniesRequestSuccess(res.companies));
      } else {
        dispatch(fetchCompaniesRequestFailed(res.message));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(fetchCompaniesRequestFailed(error));
    });
};
export const loadContactCountries = () => (dispatch) => {
  const url = `${apiUrl}/member/contacts/countries`;
  dispatch(fetchCountriesRequestStart());
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.countries) {
        dispatch(fetchCountriesRequestSuccess(res.countries));
      } else {
        dispatch(fetchCountriesRequestFailed(res.message));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(fetchCompaniesRequestFailed(error));
    });
};
export const loadContactRoles = () => (dispatch) => {
  const url = `${apiUrl}/member/contacts/roles`;
  dispatch(fetchRolesRequestStart());
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.roles) {
        dispatch(fetchRolesRequestSuccess(res.roles));
      } else {
        dispatch(fetchRolesRequestFailed(res.message));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(fetchRolesRequestFailed(error));
    });
};

export const createNewContact = (data) => (dispatch) => {
  const url = `${apiUrl}/member/contact`;
  dispatch({ type: IS_LOADING_CONTACT_LIST });
  api
    .POST(url, data, {})
    .then((res) => {
      dispatch(addNewContact(res.contact));
      dispatch(removeContactMember(res.contact.contact_member_id));
      const payload = setContactAlert(res, true);
      dispatch(setReduxContactAlert(payload));
    })
    .catch((error) => {
      console.log(error);
      const payload = setContactAlert(error, false);
      dispatch(setReduxContactAlert(payload));
    });
};

export const deleteContacts = (data) => (dispatch) => {
  const url = `${apiUrl}/member-contacts`;
  dispatch({ type: DELETE_CONTACT_START });
  api
    .DELETE(url, data, {})
    .then((res) => {
      dispatch(removeContacts(res.data));
      const payload = setContactAlert(res, true);
      dispatch(setReduxContactAlert(payload));
    })
    .catch((error) => {
      console.log(error);
      const payload = setContactAlert(error, false);
      dispatch(setReduxContactAlert(payload));
      dispatch({ type: DELETE_CONTACT_FAILED });
    });
};

export const updateContact = (data) => (dispatch) => {
  const url = `${apiUrl}/member/contact`;

  const dataString = JSON.stringify(data);
  dispatch(updateContactRequestStart());
  api
    .PUT(url, dataString, { 'Content-Type': 'application/json' })
    .then((res) => {
      if (res && res.success) {
        dispatch(updateContactRequestSuccess(data));
        const payload = setContactAlert(res, true, res.description);
        dispatch(setReduxContactAlert(payload));
      } else {
        const payload = setContactAlert(res, true, res.description);
        dispatch(setReduxContactAlert(payload));
      }
    })
    .catch((error) => {
      const payload = setContactAlert(error, false);
      dispatch(setReduxContactAlert(payload));
      dispatch(updateContactRequestFailed(error));
    });
};

export const loadMemberContactsSuccess = (payload) => ({
  type: GET_CONTACT_MEMBERS,
  payload,
});

export const loadContactsSuccess = (payload) => ({
  type: IS_FINISHED_CONTACT_LIST_LOADING,
  payload,
});

export const addNewContacts = (payload) => ({
  type: NEW_CONTACTS,
  payload,
});

export const addNewContact = (payload) => ({
  type: NEW_CONTACT,
  payload,
});

export const setReduxContactAlert = (payload) => ({
  type: SET_CONTACT_ALERT,
  payload,
});

export const resetContactMembers = (payload) => ({
  type: UPDATE_CONTACT_MEMBERS,
  payload,
});

export const removeContactMember = (payload) => ({
  type: REMOVE_CONTACT_MEMBER,
  payload,
});

export const removeContactMembers = (payload) => ({
  type: REMOVE_CONTACT_MEMBERS,
  payload,
});

export const updateContactRequestStart = () => ({
  type: UPDATE_CONTACT_REQUEST_START,
});
export const updateContactRequestSuccess = (payload) => ({
  type: UPDATE_CONTACT_REQUEST_SUCCESS,
  payload,
});
export const updateContactRequestFailed = (payload) => ({
  type: UPDATE_CONTACT_REQUEST_FAILED,
  payload,
});

export const fetchCompaniesRequestStart = () => ({
  type: FETCH_CONTACT_COMPANIES_START,
});
export const fetchCompaniesRequestSuccess = (payload) => ({
  type: FETCH_CONTACT_COMPANIES_SUCCESS,
  payload,
});

export const fetchCompaniesRequestFailed = (payload) => ({
  type: FETCH_CONTACT_COMPANIES_FAILED,
  payload,
});

export const fetchCountriesRequestStart = () => ({
  type: FETCH_CONTACT_COUNTRIES_START,
});
export const fetchCountriesRequestSuccess = (payload) => ({
  type: FETCH_CONTACT_COUNTRIES_SUCCESS,
  payload,
});
export const fetchCountriesRequestFailed = (payload) => ({
  type: FETCH_CONTACT_COUNTRIES_FAILED,
  payload,
});

export const fetchRolesRequestStart = () => ({
  type: FETCH_CONTACT_ROLES_START,
});
export const fetchRolesRequestSuccess = (payload) => ({
  type: FETCH_CONTACT_ROLES_SUCCESS,
  payload,
});

export const fetchRolesRequestFailed = (payload) => ({
  type: FETCH_CONTACT_ROLES_FAILED,
  payload,
});

const removeContacts = (payload) => ({
  type: DELETE_CONTACT_SUCCESS,
  payload,
});

export const setNewRecivers = (reciver) => ({
  type: CONTACTS_RECIVERS,
  payload: reciver,
});

export const updateSecurity = (formData, contact_member_id) => {
  const url = `${apiUrl}/member/contact/security/${contact_member_id}`;
  return function (dispatch) {
    api
      .POST(url, formData, {})
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {});
  };
};

export const getMemberSecurity = (contact_member_id) => {
  const url = `${apiUrl}/member/contact/security/${contact_member_id}`;
  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        dispatch({ type: 'SET_SECURITY', payload: res.data });
      })
      .catch((error) => {});
  };
};
