import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Sections/Layout/GuestLayout';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import logoHomePng from '../../assets/img/logo-home.png';
import styles from '../../jss/views/homeStyle';
import { injectIntl } from 'react-intl';
import withLanguageDrawer from '../Sections/LanguageDrawer/withLanguageDrawer';
import { messages as layoutMessages } from '../Sections/Layout/GuestLayout';
import LogoAmeraShare from '../../components/Icons/LogoAmeraShare';

const useStyles = makeStyles(styles);

const messages = {
  introducing: {
    id: 'app.home.introducing',
    defaultMessage: 'Introducing',
  },
  remoteWork: {
    id: 'app.home.remoteWork',
    defaultMessage: 'Remote work just got an upgrade.',
  },
  experience: {
    id: 'app.home.experience',
    defaultMessage: 'Experience secure collaboration',
  },
  join: {
    id: 'app.home.join',
    defaultMessage: 'Join',
  },
  signIn: {
    id: 'app.home.signIn',
    defaultMessage: 'Sign In',
  },
};

const drawerMessages = { ...messages, ...layoutMessages };

const Home = (props) => {
  const classes = useStyles();

  const {
    intl: { formatMessage },
  } = props;

  return (
    <Layout {...props}>
      <div className={classes.root}>
        <div className={classes.messageWrapper}>
          <div className={classes.introducing}>
            {formatMessage(messages.introducing)}
          </div>
          <LogoAmeraShare width="60%" />
          <div className={classes.remoteWorkLabel}>
            {formatMessage(messages.remoteWork)}
          </div>
          <div className={classes.collaborationLabel}>
            {formatMessage(messages.experience)}
          </div>
          <div className={classes.collaborationLabel}>with AmeraShare™.</div>
          <div className={classes.buttonGroup}>
            <Link to={`${process.env.PUBLIC_URL}/registration`}>
              <Button variant="contained" color="primary">
                {formatMessage(messages.join)}
              </Button>
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/login`}>
              <Button variant="outlined" color="primary">
                {formatMessage(messages.signIn)}
              </Button>
            </Link>
          </div>
        </div>
        <div className={classes.imageWrapper} />
      </div>
    </Layout>
  );
};

export default injectIntl(withLanguageDrawer(Home, drawerMessages));
