import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { LOCATION_TYPES } from '../../../utils/calendar';

const LocationModeSelector = ({ value, handleChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="label-locmodetype">Location</InputLabel>
      <Select
        labelId="label-locmodetype"
        id="event-location-type-select"
        value={value}
        onChange={handleChange}
        label="Location option"
      >
        <MenuItem value={LOCATION_TYPES.own}>Saved Locations</MenuItem>
        <MenuItem value={LOCATION_TYPES.lookup}>Type an Address</MenuItem>
        <MenuItem value={LOCATION_TYPES.find}>Find in Map</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LocationModeSelector;
