import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';
import MainBinSwitch from './MainBinSwitch';
import GroupBinTree from './GroupBinTree';
import GroupMainTree from './GroupMainTree';
// import FileShareSpinner from './FileShareSpinner';
import { getGroupFiles } from '../../redux/actions/fileshare';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  groupFile: {
    height: 'calc(100% - 48px)',
  },
  groupFileWithoutTab: {
    height: '100%',
  },
}));

const GroupFiles = (props) => {
  const { dispatch, groupId, groupTrees, isLeader } = props;
  const styles = useStyles();

  let mainTree, binTree;
  if (
    groupTrees.length > 0 &&
    !!groupTrees.find((gt) => gt.groupId === groupId)
  ) {
    mainTree = groupTrees.find((gt) => gt.groupId === groupId).main_tree;
    binTree = groupTrees.find((gt) => gt.groupId === groupId).bin_tree;
    console.log('Group trees exist and will be used', mainTree, binTree);
  } else {
    console.log('Group trees didnt exist', mainTree, binTree);
  }

  const [mode, setMode] = useState('main');
  // const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //   async function fetchData() {
  //     setLoading(true);
  //     await dispatch(getGroupFiles(groupId));
  //     setLoading(false);
  //   }
  //   fetchData();
  // }, [dispatch, groupId]);

  useEffect(() => {
    dispatch(getGroupFiles(groupId));
  }, [dispatch, groupId]);

  const handleClick = (e, v) => {
    if (v) {
      setMode(v);
    }
  };

  console.log('Groupd', groupId);
  console.log('Main group tree', mainTree);
  console.log('Bin group tree', binTree);
  return (
    <Grid container className={styles.container}>
      <Grid item xs={12}>
        {isLeader && (
          <MainBinSwitch mode={mode} handleClick={handleClick} isGroup />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        className={isLeader ? styles.groupFile : styles.groupFileWithoutTab}
      >
        {mode === 'bin' ? (
          !!binTree ? (
            <GroupBinTree tree={binTree} groupId={groupId} />
          ) : null
        ) : !!mainTree ? (
          <GroupMainTree
            tree={mainTree}
            groupId={groupId}
            isLeader={isLeader}
          />
        ) : null}
        {/* <FileShareSpinner isLoading={isLoading} /> */}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.fileshare.loading,
  isDownloadLoading: state.fileshare.downloadLoading,
  groupTrees: state.fileshare.groupTrees,
});

export default connect(mapStateToProps)(GroupFiles);
