import * as api from '../../config/api';
import {
  SCHEDULE_EVENT_CREATE_START,
  SCHEDULE_EVENT_API_CALL_START,
  SCHEDULE_EVENT_API_CALL_FAIL,
  SCHEDULE_EVENT_API_CALL_GET_SUCCESS,
  SCHEDULE_EVENT_API_CALL_ADD_SUCCESS,
  // Modify
  SCHEDULE_EVENT_MODIFY_START,
  SCHEDULE_EVENT_MODIFY_SUCCESS,
  SCHEDULE_EVENT_MODIFY_FAIL,
  // End Modify
  SCHEDULE_EVENT_HIDE_ERROR,
  SCHEDULE_EVENT_GET_COLORS_START,
  SCHEDULE_EVENT_GET_COLORS_SUCCESS,
  SCHEDULE_EVENT_GET_COLORS_FAILED,
  // Cancel
  SCHEDULE_EVENT_CANCEL_START,
  SCHEDULE_EVENT_CANCEL_SUCCESS,
  SCHEDULE_EVENT_CANCEL_FAIL,
  // EVENT_ATTACH_START,
  // EVENT_ATTACH_PROGRESS,
  // EVENT_ATTACH_SUCCESS,
  // EVENT_ATTACH_FAILED,
} from '../actionTypes/schedule.js';
import axios from 'axios';
import { getUpcomingEvents } from './event';

const apiUrl = api.apiUrl;

export const getScheduleEvent = (params) => (dispatch) => {
  const queryParams = `search_time_start=${encodeURIComponent(
    params.start
  )}&search_time_end=${encodeURIComponent(params.end)}`;
  const url = `${apiUrl}/member/schedule/event?${queryParams}`;
  dispatch({ type: SCHEDULE_EVENT_API_CALL_START });
  api
    .GET(url)
    .then((res) => {
      if (res.success) {
        dispatch({
          type: SCHEDULE_EVENT_API_CALL_GET_SUCCESS,
          payload: res.data[0],
        });
      } else {
        dispatch({
          type: SCHEDULE_EVENT_API_CALL_FAIL,
          payload: res.description,
        });
      }
    })
    .catch((err) => {
      if (err) {
        dispatch({
          type: SCHEDULE_EVENT_API_CALL_FAIL,
          payload: err,
        });
      }
    });
};

export const addScheduleEvent = (formData) => (dispatch) => {
  const url = `${apiUrl}/member/schedule/event`;

  dispatch({ type: SCHEDULE_EVENT_CREATE_START });
  api
    .POST(url, formData, {}, false)
    .then((res) => {
      // console.log(res.success, res.data);
      if (res.success) {
        // console.log(res.data[0]);
        dispatch({
          type: SCHEDULE_EVENT_API_CALL_ADD_SUCCESS,
          payload: res.data[0],
        });

        // refresh upcoming events
        dispatch(getUpcomingEvents());
      } else {
        dispatch({
          type: SCHEDULE_EVENT_API_CALL_FAIL,
          payload: res.description,
        });
      }
    })
    .catch((err) => {
      if (err) {
        dispatch({
          type: SCHEDULE_EVENT_API_CALL_FAIL,
          payload: err,
        });
      }
    });
};

export const deleteCancelEvent = (data) => (dispatch) => {
  const url = `${apiUrl}/member/schedule/event`;
  dispatch({ type: SCHEDULE_EVENT_CANCEL_START });
  api
    .DELETE(url, JSON.stringify(data), {
      'Content-Type': 'application/json',
    })
    .then((res) => {
      console.log('Response after cancelling event', res);
      if (res.success) {
        dispatch({
          type: SCHEDULE_EVENT_CANCEL_SUCCESS,
          payload: { touchedSequence: res.sequence_id, eventData: res.data[0] },
        });

        // refresh upcoming events
        dispatch(getUpcomingEvents());
      } else {
        dispatch({
          type: SCHEDULE_EVENT_CANCEL_FAIL,
          payload: res.description,
        });
      }
    })
    .catch((err) => {
      if (err) {
        dispatch({
          type: SCHEDULE_EVENT_CANCEL_FAIL,
          payload: err,
        });
      }
    });
};

export const scheduleEventHideError = () => ({
  type: SCHEDULE_EVENT_HIDE_ERROR,
});

export const putModifyEvent = (formData) => (dispatch) => {
  const url = `${apiUrl}/member/schedule/event`;
  dispatch({ type: SCHEDULE_EVENT_MODIFY_START });
  api
    .PUT(url, formData, {}, false)
    .then((res) => {
      // console.log(res.success, res.data);
      if (res.success) {
        console.log('put modify event reply', res.data);

        dispatch({
          type: SCHEDULE_EVENT_MODIFY_SUCCESS,
          payload: res.data,
        });

        // refresh upcoming events
        dispatch(getUpcomingEvents());
      } else {
        dispatch({
          type: SCHEDULE_EVENT_MODIFY_FAIL,
          payload: res.description,
        });
      }
    })
    .catch((err) => {
      if (err) {
        dispatch({
          type: SCHEDULE_EVENT_MODIFY_FAIL,
          payload: err,
        });
      }
    });
};

export const getCalendarColors = () => {
  const url = `${apiUrl}/member/schedule/colors`;
  return function (dispatch) {
    dispatch({ type: SCHEDULE_EVENT_GET_COLORS_START });
    console.log('Requesting calendar colors');
    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          console.log('Got colors', res.colors);
          dispatch({
            type: SCHEDULE_EVENT_GET_COLORS_SUCCESS,
            payload: res.colors,
          });
        } else {
          console.error('Colors fetch failed', res.description);
          dispatch({
            type: SCHEDULE_EVENT_GET_COLORS_FAILED,
            payload: res.description,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// Will upload a file, transmit progress and return attached files list in the end
export const postEventAttachment = (formData, setProgress) => {
  const url = `${apiUrl}/member/schedule/attach`;

  return function (dispatch) {
    console.log('Attaching file to event');
    // dispatch({ type: EVENT_ATTACH_START });
    const config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
        // dispatch({ type: EVENT_ATTACH_PROGRESS, payload: percentCompleted });
      },
    };

    return axios
      .post(url, formData, config)
      .then((res) => {
        // console.log('axios res', res);
        if (res.data.success) {
          console.log('Attaching success');
          // dispatch({ type: EVENT_ATTACH_SUCCESS, payload: res.data.data });
          return res.data.data;
        } else {
          console.error('Attaching failed');
          // dispatch({ type: EVENT_ATTACH_FAILED, payload: res.description });
          return null;
        }
      })
      .catch((error) => {
        // dispatch({ type: EVENT_ATTACH_FAILED, payload: error.message });
        return null;
      })
      .finally(() => setProgress(0));
  };
};
