import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import Counter from '../../../components/Counter';
import SVG from '../../../components/Icons/SVG';
import { redirectToLogin } from '../../../config/api';
import { useSelector } from 'react-redux';

const Navigator = (props) => {
  const {
    intl: { formatMessage },
  } = props;

  const mailCount = useSelector((state) => state.mail.mailBadgeCount);

  return (
    <ul className="navigator">
      {props.navItems.map((item, index) => (
        <li key={`nav_${item.id}`}>
          <NavLink
            to={`${process.env.PUBLIC_URL}${item.to}`}
            style={{ textDecoration: 'none' }}
            onClick={(e) => {
              item.id === 'logout'
                ? redirectToLogin()
                : props.setActiveNav(index);
            }}
          >
            <div
              className={classNames(
                'nav-item',
                'sidebar-icon',
                'with-counter',
                {
                  active: index === props.activeNav,
                }
              )}
            >
              <SVG name={item.icon} />
              <Counter
                count={item.id === 'mail' ? mailCount : item.notification}
              />
              <div className="description">{formatMessage(item.label)}</div>
            </div>
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

Navigator.propTypes = {
  activeNav: PropTypes.number.isRequired,
  navItems: PropTypes.array.isRequired,
  setActiveNav: PropTypes.func,
};

export default injectIntl(Navigator);
