import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import CountryInput from '../../pages/Registration/InputElements/CountryInput';

import Modal from './modal';

import { validateInviteMemberForm } from '../../utils/validator/Group';

import {
  sendGroupMemberInvite,
  setReduxGroupAlert,
  removeGroupAlert,
} from '../../redux/actions/group';

const defaultCountryCode = 'us';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  formControl: {
    minWidth: '100%',
  },
  submitBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
  alert: {
    margin: theme.spacing(1),
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
  memberInviteFormFields: {
    border: 0,
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const MemberInviteModal = (props) => {
  const {
    dispatch,
    open,
    onClose,
    roles,
    group_id,
    isLoadingMemberInvite,
    groupAlert,
    countryList,
  } = props;
  // const [role, setRole] = React.useState('');

  // const handleChangeRole = (event) => {
  //   setRole(event.target.value);
  // };

  const [values, setValues] = useState({
    country: 840,
    role: '',
    confirmPhoneRequired: false,
  });
  const [errors, setErrors] = useState({});
  const [isError, setIsError] = useState(false);
  const title = 'Send Invite';
  const classes = useStyles();

  const closeModal = () => {
    groupAlert.show = false;
    removeGroupAlert();
    setIsError(false);
    setErrors({});
    // setRole('');
    onClose();
  };

  useEffect(() => {
    if (groupAlert.show) {
      setTimeout(() => {
        const alertOption = {
          show: false,
          variant: groupAlert.variant ? groupAlert.variant : 'success',
          message: groupAlert.message ? groupAlert.message : '',
        };
        dispatch(setReduxGroupAlert(alertOption));
      }, 5000);
    }
  }, [dispatch, groupAlert]);

  useEffect(() => {
    isError && setErrors(validateInviteMemberForm(values));
  }, [isError, values]);

  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePhoneNumber = (value, country, e, formattedValue) => {
    setValues({
      ...values,
      phoneNumber: value,
    });
  };

  const setFormData = (values) => {
    console.log(values, ' setting form data');
    let formData = new FormData();
    Object.keys(values).map((key) => {
      let value = values[key];
      if (key === 'phoneNumber') {
        const dialCode = countryList.find((clo) => clo.id === values.country)
          .phone;
        value = values.phoneNumber.replace(dialCode, '');
        console.log(value);
      }
      return formData.set(key, value);
    });
    return formData;
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    const cellRegExp = countryList.find((cl) => cl.id === values.country)
      .cell_regexp;
    console.log('values', values);
    const formErrors = validateInviteMemberForm(values, cellRegExp);
    if (values && Object.keys(formErrors).length === 0) {
      setIsError(false);
      const formData = setFormData(values);
      if (group_id) {
        formData.set('groupId', group_id);
      }
      dispatch(sendGroupMemberInvite(formData));
    } else {
      setIsError(true);
      setErrors(formErrors);
      return false;
    }
  };

  const renderRoleOptions = () => {
    if (roles.length > 0) {
      return roles.map((role) => (
        <MenuItem key={role.id} value={role.id}>
          {role.name}
        </MenuItem>
      ));
    } else {
      return null;
    }
  };

  const getPhoneCountryValue = (country) => {
    const countryFind = countryList.find((clo) => clo.id === country);
    console.debug(`Country Found: ${countryFind}`, countryFind);
    if (countryFind) {
      return countryFind.alpha2.toLowerCase();
    }
    return defaultCountryCode;
  };

  const ModalContent = () => (
    <form className={classes.root} noValidate autoComplete="off">
      <fieldset
        disabled={isLoadingMemberInvite}
        className={classes.memberInviteFormFields}
      >
        <FormControl
          className="modal-header-dropdown"
          error={errors.role ? true : false}
        >
          <InputLabel required id="invite-select-role-label">
            Select Type
          </InputLabel>
          <Select
            disableUnderline={true}
            labelId="invite-select-role-label"
            id="invite-select-role"
            required
            name="role"
            value={values.role}
            onChange={handleChange}
          >
            {renderRoleOptions()}
          </Select>
          {errors.role && <FormHelperText>{errors.role}</FormHelperText>}
        </FormControl>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="outlined-first_name"
              label="First Name"
              placeholder="John"
              required
              type="text"
              variant="outlined"
              name="firstName"
              value={values.firstName || ''}
              onChange={(e) => handleChange(e)}
              error={errors.firstName ? true : false}
              helperText={errors.firstName ? errors.firstName : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-last_name"
              label="Last Name"
              placeholder="Doe"
              required
              type="text"
              variant="outlined"
              name="lastName"
              value={values.lastName || ''}
              onChange={(e) => handleChange(e)}
              error={errors.lastName ? true : false}
              helperText={errors.lastName ? errors.lastName : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-email"
              label="Email Address"
              type="email"
              required
              variant="outlined"
              name="groupMemberEmail"
              placeholder="email@example.com"
              onChange={(e) => handleChange(e)}
              value={values.groupMemberEmail || ''}
              error={errors.groupMemberEmail ? true : false}
              helperText={
                errors.groupMemberEmail ? errors.groupMemberEmail : ''
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CountryInput
              country={values.country}
              handleChangeCountry={handleChange}
              errors={errors.country}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneInput
              country={getPhoneCountryValue(values.country)}
              value={values.phoneNumber}
              specialLabel={values.confirmPhoneRequired ? 'Phone *' : 'Phone'}
              onChange={handleChangePhoneNumber}
              inputClass={`phone-number-input ${
                errors.phoneNumber ? 'phone-number-error' : ''
              }`}
              containerClass="phone-number-input-field"
              disableDropdown={true}
              countryCodeEditable={false}
              inputProps={{
                required: values.confirmPhoneRequired,
              }}
            />
            {errors.phoneNumber && (
              <p className="input-error-custom-style">{errors.phoneNumber}</p>
            )}
          </Grid>
          <Grid item xs={12}>
            <GreenCheckbox
              checked={values.confirmPhoneRequired}
              onChange={() =>
                setValues((prev) => ({
                  ...prev,
                  confirmPhoneRequired: !prev.confirmPhoneRequired,
                }))
              }
            />
            Require phone verification
          </Grid>
          <Grid item xs={12}>
            <Collapse in={groupAlert.show}>
              <Alert variant="filled" severity={groupAlert.variant}>
                {groupAlert.message}
              </Alert>
            </Collapse>
          </Grid>
        </Grid>
      </fieldset>
    </form>
  );

  const ModalAction = () => {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          className={classes.submitBtn}
          onClick={() => closeModal()}
          disabled={isLoadingMemberInvite}
        >
          Cancel
        </Button>
        {isLoadingMemberInvite ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disableRipple
            disabled
          >
            Sending ...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disableRipple
            onClick={(e) => handleSubmit(e)}
          >
            Send Invite
          </Button>
        )}
      </>
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      disabled={isLoadingMemberInvite}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="member-invite-modal"
    ></Modal>
  );
};

MemberInviteModal.propTypes = {
  dispatch: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  group_id: PropTypes.number,
  roles: PropTypes.arrayOf(PropTypes.object),
  isLoadingMemberInvite: PropTypes.bool,
  groupAlert: PropTypes.object,
};

MemberInviteModal.defaultProps = {
  dispatch: null,
  open: false,
  onClose: null,
  roles: [],
  group_id: null,
  isLoadingMemberInvite: false,
  groupAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

const mapStateToProps = (state) => ({
  roles: state.member.roles,
  countryList: state.member.countryList,
  isLoadingMemberInvite: state.group.isLoadingMemberInvite,
  groupAlert: state.group.groupAlert,
});

export default connect(mapStateToProps)(MemberInviteModal);
