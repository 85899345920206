import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

function ChatForm({ handleSendMessage, disabled }) {
  const [messageText, setMessageText] = React.useState('');

  const onKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey && messageText.trim() !== '') {
      handleSendMessage(messageText);
      setMessageText('');
      e.preventDefault();
    }
  };
  return (
    <div className="chat-form">
      <div className="chat-form-input-wrapper">
        <TextareaAutosize
          rowsMax={9}
          disabled={disabled}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onKeyDown={onKeyDown}
          placeholder="Type message here..."
          className="chat-form-input"
        />
      </div>
      <div className="chat-input-action-icons">
        <Tooltip title="Add Photos" placement="top">
          <IconButton
            disabled={disabled}
            aria-label="photo-library"
            className="chat-input-action-icon"
            onClick={() => {}}
          >
            <PhotoLibraryIcon fontSize="default" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Videos" placement="top">
          <IconButton
            disabled={disabled}
            aria-label="photo-library"
            className="chat-input-action-icon"
            onClick={() => {}}
          >
            <VideoCallIcon fontSize="large" />
          </IconButton>
        </Tooltip>
        <Tooltip title="More" placement="top">
          <IconButton
            disabled={disabled}
            aria-label="photo-library"
            className="chat-input-action-icon"
            onClick={() => {}}
          >
            <MoreHoriz fontSize="large" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

ChatForm.defaultProps = {
  disabled: false,
};

export default ChatForm;
