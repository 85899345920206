import React from 'react';
import { Grid, Typography, makeStyles, Card } from '@material-ui/core';

import dateFormat from '../../utils/dateFormat';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    padding: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const GroupDetail = (props) => {
  const { isLeader } = props;
  const styles = useStyles();

  const { groupData } = props;

  const groupDetail = isLeader
    ? [
        {
          key: 'Name',
          value: groupData.group_name,
        },
        {
          key: 'Date Created',
          value: dateFormat(groupData.create_date),
        },
        {
          key: 'Total Members',
          value: groupData.total_member + 1,
        },
      ]
    : [
        {
          key: 'Name',
          value: groupData.group_name,
        },
        {
          key: 'Leader',
          value: groupData.group_leader_name,
        },
        {
          key: 'Date Created',
          value: dateFormat(groupData.group_create_date),
        },
        {
          key: 'Date Joined',
          value: dateFormat(groupData.create_date),
        },
        {
          key: 'Total Members',
          value: groupData.total_member + 1,
        },
      ];

  return (
    <Grid container>
      <Card className={styles.card}>
        <Grid item xs={12} className={styles.title}>
          <Typography variant="h6">Group Detail</Typography>
        </Grid>
        <Grid item xs={12}>
          {groupDetail &&
            groupDetail.map((row, index) => {
              return (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <b>{row.key}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{row.value}</Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Card>
    </Grid>
  );
};

export default GroupDetail;
