import React from 'react';
import List from '@material-ui/core/List';
import LocationListItem from './LocationListItem/LocationListItem';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';

const LocationsList = (props) => {
  const {
    errors,
    locationItems,
    handleChange,
    handleDelete,
    handleAdd,
    handleAutocomplete,
    otherTypeOnly,
    billingOnly,
  } = props;
  return (
    <div className="location-list-container">
      {!billingOnly && !otherTypeOnly && (
        <div className="list-header">Locations list</div>
      )}
      <List className="location-list">
        {locationItems && locationItems.length > 0
          ? locationItems.map((li) => (
              <LocationListItem
                errors={errors ? errors[li.id] : null}
                key={li.id}
                locationData={li}
                handleChange={handleChange}
                handleDelete={handleDelete}
                handleAutocomplete={handleAutocomplete}
                addressCount={locationItems.length}
                otherTypeOnly={otherTypeOnly}
                billingOnly={billingOnly}
              />
            )) // When no record - show fallback message
          : !billingOnly &&
            !otherTypeOnly && (
              <Typography variant="body2">Please create a record</Typography>
            )}
      </List>
      {
        <Button
          startIcon={<AddCircleIcon />}
          onClick={handleAdd}
          disabled={
            locationItems &&
            ((!billingOnly &&
              locationItems.length ===
                parseInt(process.env.REACT_APP_MAX_LOCATIONS)) ||
              (billingOnly && locationItems.length === 1))
          }
        >
          Add
        </Button>
      }
    </div>
  );
};

export default LocationsList;
