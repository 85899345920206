const red = '#EE3158';
const light = '#dce1ec'; // light
const dark = '#8ea4c6'; // dark
const green = '#94c03d';
const gray = '#7E7E7E';
const pink = '#dd1e42';
const primary = '#f4faff';
const error = 'rgb(255 0 0)';
const borderColor = '#E5E8F7';
const textDark = '#232231';
const textPurple = '#848ac1';
const textMedium = '#4d4d4d';
const textLight = '#848AC1';
const textNewGray = '#8DA3C5';
const textGrey = '#B1B1B1';
const textBlue = '#182A63';
const borderDark = '#707070';

export {
  red,
  light,
  dark,
  green,
  gray,
  pink,
  primary,
  error,
  borderColor,
  textDark,
  textMedium,
  textLight,
  textGrey,
  textBlue,
  borderDark,
  textPurple,
  textNewGray,
};
