import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { putAvatar } from '../../../../redux/actions/member';
import ImageCrop from '../../../../components/Modal/ImageCrop';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MaterialAvatar from '../../../../components/MaterialAvatar';
import ShowMoreText from 'react-show-more-text';
import Tooltip from '@material-ui/core/Tooltip';

import ContactItemList from './ContactItemList/ContactItemList';
import AddressItemList from './AddressItemList/AddressItemList';
import GroupItemList from './GroupItemList/GroupItemList';
import AchievementItemList from './AchievementItemList/AchievementItemList';
import { displayableFullName } from '../../../../utils/contact';
import {
  openContactVideoCall,
  // sendCallSignal,
} from '../../../../utils/general';

const ProfileAvatar = (props) => {
  const {
    src,
    firstName,
    lastName,
    isContactProfile,
    // memberInfo,
    handleAvatarChange,
  } = props;
  if (isContactProfile) {
    return (
      <div className="profile-avatar">
        <MaterialAvatar
          classes="profile"
          firstName={firstName}
          lastName={lastName}
          src={src}
        />
        <div className="stats-icon">
          <IconButton
            // aria-label="event"
            // className="contact-card-action-icon"
            onClick={props.onSchedule}
          >
            <DateRangeIcon />
          </IconButton>
        </div>
      </div>
    );
  } else {
    return (
      <div className="profile-avatar">
        <label htmlFor="change-avatar">
          <input
            style={{ display: 'none' }}
            id="change-avatar"
            name="change-avatar"
            type="file"
            accept="image/*"
            onChange={handleAvatarChange}
          />
          <MaterialAvatar
            badgeType="edit"
            classes="profile my"
            src={src}
            firstName={firstName}
            lastName={lastName}
          />
        </label>
      </div>
    );
  }
};
ProfileAvatar.propTypes = {
  isContactProfile: PropTypes.bool.isRequired,
  src: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};
ProfileAvatar.defaultProps = {
  src: '',
  firstName: '',
  lastName: '',
};

const ProfileBio = ({ biography }) => {
  const executeOnClick = (isExpanded) => {
    console.log(isExpanded);
  };
  return (
    <div className="text-container">
      <p className="title">Bio:</p>
      <div className="blank-space"></div>
      <ShowMoreText
        lines={2}
        more="More"
        less="Less"
        anchorClass="show-more-link"
        width={500}
        onClick={executeOnClick}
        // expanded={false}
      >
        <p className="text-content">{biography}</p>
      </ShowMoreText>
    </div>
  );
};
ProfileBio.propTypes = {
  biography: PropTypes.string,
};
ProfileBio.defaultProps = {
  biography: '',
};

const ProfileStats = (props) => {
  const {
    memberId,
    country_code,
    contactData,
    locationData,
    groupMemberships,
    achievementsData,
  } = props;
  return (
    <>
      <div className="text-container">
        <Grid container spacing={2}>
          <Grid item xs={6} className="items-box-left">
            <ContactItemList
              country_code={country_code}
              contactData={contactData}
            />
          </Grid>
          <Grid item xs={6} className="items-box-right">
            <AddressItemList locationData={locationData} />
          </Grid>
        </Grid>
      </div>
      <div className="separate-horizontal-line"></div>
      <div className="text-container">
        <Grid container spacing={2}>
          <Grid item xs={6} className="items-box-left">
            <GroupItemList
              groupMemberships={groupMemberships}
              memberId={memberId}
            />
          </Grid>
          <Grid item xs={6} className="items-box-right">
            <AchievementItemList achievementData={achievementsData} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
ProfileStats.propTypes = {
  memberId: PropTypes.number.isRequired,
  country_code: PropTypes.arrayOf(PropTypes.object),
  contactData: PropTypes.arrayOf(PropTypes.object),
  locationData: PropTypes.arrayOf(PropTypes.object),
  groupMemberships: PropTypes.arrayOf(PropTypes.object),
  achievementsData: PropTypes.arrayOf(PropTypes.object),
};
ProfileStats.defaultProps = {
  isContactProfile: false,
  memberId: '',
  country_code: {},
  contactData: [],
  locationData: [],
  groupMemberships: [],
  achievementsData: [],
};

const ProfileInfo = (props) => {
  const [isShowCrop, setShowCrop] = useState(false);

  const {
    isContactProfile,
    title,
    role,
    address,
    putAvatar,
    memberInfo,
    member,
    groupMemberships,
  } = props;
  const {
      first_name,
      last_name,
      biography,
      amera_avatar_url,
      country_code,
      contact_information,
      location_information,
      achievement_information,
    } = memberInfo,
    memberId = isContactProfile
      ? memberInfo.contact_member_id
      : memberInfo.member_id,
    companyName = isContactProfile
      ? memberInfo.company
      : memberInfo.company_name;
  // biography = isContactProfile
  //   ? memberInfo.company_bio
  //   : memberInfo.biography;

  const [src, setSrc] = useState(amera_avatar_url);

  useEffect(() => {
    setSrc(amera_avatar_url);
  }, [amera_avatar_url]);
  const handleAvatarChange = (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setSrc(reader.result);
      };
      setShowCrop(true);
    }
  };

  const handleCropConfirm = (file) => {
    // console.log('Blob at file', file);
    const fileForm = new FormData();
    fileForm.append('avatar', file);
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSrc(reader.result);
      };
    }
    putAvatar(fileForm);
  };

  return (
    <>
      <ImageCrop
        show={isShowCrop}
        close={() => setShowCrop(false)}
        src={src}
        handleCropConfirm={handleCropConfirm}
      />
      <Grid container justify="center" className="profile_avatar-container">
        <Grid item xs={6} className="profile-avatar-container">
          <ProfileAvatar
            firstName={first_name}
            lastName={last_name}
            memberInfo={memberInfo}
            src={src}
            isContactProfile={isContactProfile}
            handleAvatarChange={handleAvatarChange}
            onSchedule={props.onSchedule}
          />
        </Grid>
        <Grid item xs={6}>
          <div className="profile_title-container">
            <p className="profile-name">{displayableFullName(memberInfo)}</p>
            <p className="profile-company">{companyName}</p>
            <p className="profile-role">{title}</p>
            <p className="profile-role">{role}</p>
            <p className="profile-location">{address}</p>
          </div>
        </Grid>
        {isContactProfile && (
          <Grid item xs={12}>
            <div className="action-icons">
              <Tooltip title="Chat" placement="top" arrow>
                <IconButton aria-label="message" className="action-icon">
                  <CommentOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Send email" placement="top" arrow>
                <IconButton
                  aria-label="email"
                  className="action-icon"
                  onClick={props.setCompose}
                >
                  <EmailOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Video call" placement="top" arrow>
                <IconButton
                  aria-label="video"
                  className="action-icon"
                  onClick={props.videoCall}
                >
                  <DesktopWindowsOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Call" placement="top" arrow>
                <IconButton
                  aria-label="video"
                  className="action-icon"
                  onClick={() => openContactVideoCall(member, memberInfo)}
                >
                  <PhoneOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        )}
      </Grid>
      <ProfileBio biography={biography} />
      <div className="separate-horizontal-line"></div>
      <ProfileStats
        memberId={memberId}
        country_code={country_code}
        contactData={contact_information}
        locationData={location_information}
        achievementsData={achievement_information}
        groupMemberships={groupMemberships}
      />
      <div className="separate-horizontal-line"></div>
    </>
  );
};

ProfileInfo.propTypes = {
  isContactProfile: PropTypes.bool.isRequired,
  title: PropTypes.string,
  role: PropTypes.string,
  address: PropTypes.string,
};

ProfileInfo.defaultProps = {
  title: '',
  role: '',
  address: '',
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putAvatar,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ProfileInfo);
