import React from 'react';
import { format, parseISO } from 'date-fns';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TimerIcon from '@material-ui/icons/Timer';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ReplayIcon from '@material-ui/icons/Replay';
import SubjectIcon from '@material-ui/icons/Subject';
import GroupIcon from '@material-ui/icons/Group';
import { getUnstyledHtmlBody } from '../../utils/email';
import {
  calcEventDuration,
  eventDisplayName,
  generateEventInviteListText,
  getInvitedListFilterLoggedInMember,
  recurrenceText,
  RECURRENCE_FREQ,
} from '../../utils/calendar';

const UpcomingEventCard = ({ data, member, showDetail }) => {
  const title = 'Upcoming Event';

  const handleDetail = () => {
    showDetail(data);
  };

  let event_name = eventDisplayName(data, member.member_id);
  let description = getUnstyledHtmlBody(
    data.event_description ? data.event_description : ''
  );

  const duration = calcEventDuration(parseISO(data.start), parseISO(data.end));
  const contact = generateEventInviteListText(
    getInvitedListFilterLoggedInMember(data, member.member_id)
  );

  console.debug(`Duration: ${duration}`);

  return (
    <Card className={'message-card'}>
      <CardHeader className="card-title" title={title} />
      <CardHeader className="event-name" title={event_name} />
      <CardContent>
        <List dense={false}>
          <ListItem className="event-date-time" dense={false}>
            <List>
              <ListItem className="event-date">
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <ListItemText primary={format(new Date(data.start), 'P')} />
              </ListItem>
              <ListItem className="event-time">
                <ListItemIcon>
                  <TimerIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${format(new Date(data.start), 'p')} - ${format(
                    new Date(data.end),
                    'p'
                  )}`}
                />
              </ListItem>
            </List>
          </ListItem>
          {data.event_recurrence_freq !== RECURRENCE_FREQ.none && (
            <ListItem className="event-recurrence">
              <ListItemIcon>
                <ReplayIcon />
              </ListItemIcon>
              <ListItemText
                primary={recurrenceText(
                  data.event_recurrence_freq,
                  data.end_condition,
                  new Date(data.end_date_datetime),
                  data.repeat_times,
                  data.repeat_weekdays
                )}
              />
            </ListItem>
          )}
          <ListItem className="event-location">
            {data.location_address && (
              <>
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary={data.location_address} />
              </>
            )}
          </ListItem>
          <ListItem className="event-contacts">
            {contact && (
              <>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary={contact} />
              </>
            )}
          </ListItem>
          <ListItem className="event-description">
            {description && (
              <>
                <ListItemIcon>
                  <SubjectIcon />
                </ListItemIcon>
                <ListItemText primary={description} />
              </>
            )}
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          aria-label="detail"
          className="card_btn detail_btn"
          onClick={handleDetail}
        >
          Show Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default UpcomingEventCard;
