import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Alert from '@material-ui/lab/Alert';
// import IconButton from '@material-ui/core/IconButton';
// import Collapse from '@material-ui/core/Collapse';
// import CloseIcon from '@material-ui/icons/Close';
import MaterialAvatar from '../../../components/MaterialAvatar';
import ImageCrop from '../../../components/Modal/ImageCrop';
// Inputs
// TODO: Move these to components
import FullNameInput from '../../Registration/InputElements/FullNameInput';
import CompanyNameInput from '../../Registration/InputElements/CompanyNameInput';
import JobTitleInput from '../../Registration/InputElements/JobTitleInput';
import DepartmentListInput from '../../Registration/InputElements/DepartmentList';
import PhoneList from './PhoneList/PhoneList';
import EmailList from './EmailList/EmailList';
import LocationsList from './LocationsList/LocationsList';
import AchievementsList from './AchievementsList/AchievementsList';
import { putMemberInfo, putAvatar } from '../../../redux/actions/member';

import { orderBy } from 'lodash';

import Modal from '../../../components/Modal/modal';
import { validateProfileForm } from '../../../utils/validator/Profile';
const shortid = require('shortid');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  profilePicTitle: {
    fontWeight: '500',
  },
  formControl: {
    minWidth: '100%',
  },
  alert: {
    margin: theme.spacing(1),
  },
  field: {
    margin: '10px',
  },
  countryList: {
    ...theme.typography.body1,
  },
  sectionTitle: {
    marginTop: '20px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.75em',
    marginLeft: '-0.75em',
  },
}));

const ProfileEdit = (props) => {
  const title = 'Edit profile data';
  const classes = useStyles();
  const { open, onClose, isLoading, memberInfo, countryList, dispatch } = props;

  const [values, setValues] = useState({});

  const [src, setSrc] = useState(memberInfo.amera_avatar_url);
  const [isShowCrop, setShowCrop] = useState(false);
  // const [isSubmitting, setSubmitting] = useState(false);
  // const [alert, setAlert] = useState({
  //   open: false,
  //   severity: 'success',
  //   message: '',
  // });

  // Utils
  const isPhone = (device_type) =>
    device_type === 'cell' ||
    device_type === 'landline' ||
    device_type === 'TDD';

  // Parse existing info into state
  useEffect(() => {
    const {
      company_name,
      country_code,
      first_name,
      middle_name,
      last_name,
      department_id,
      job_title_id,
      location_information,
      contact_information,
      achievement_information,
      biography,
    } = memberInfo;

    setValues({
      country: country_code && country_code[0] ? country_code[0].id : 840,
      first_name: first_name,
      middle_name: middle_name,
      last_name: last_name,
      job_title_id: job_title_id,
      department_id: department_id,
      company_name: company_name,
      biography: biography,
      primaryPhoneId:
        contact_information && contact_information[0]
          ? (
              contact_information.find(
                (cio) =>
                  cio.primary_contact === true && isPhone(cio.device_type)
              ) || {}
            ).id
          : null, // pick the first occurence
      primaryEmailId:
        contact_information && contact_information[0]
          ? (
              contact_information.find(
                (cio) =>
                  cio.primary_contact === true && !isPhone(cio.device_type)
              ) || {}
            ).id
          : null, // pick the first occurence
      phoneData:
        contact_information && contact_information[0]
          ? orderBy(
              contact_information
                .filter((o) => isPhone(o.device_type))
                .map((cio) => ({
                  id: cio.id,
                  description: cio.description,
                  phoneType: cio.device_type,
                  display_order: cio.display_order,
                  phoneCountry: countryList
                    .find((clo) => clo.id === cio.device_country)
                    .alpha2.toLowerCase(),
                  phoneNumber: `${
                    countryList.find((clo) => clo.id === cio.device_country)
                      .phone
                  }${cio.device}`,
                  method: cio.method_type,
                  isPhoneVerified: Boolean(cio.device_confirm_date),
                })),
              ['display_order']
            )
          : null,
      emailData:
        contact_information && contact_information[0]
          ? orderBy(
              contact_information
                .filter((o) => !isPhone(o.device_type))
                .map((cio) => ({
                  id: cio.id,
                  description: cio.description,
                  display_order: cio.display_order,
                  email: cio.device,
                  method: cio.method_type,
                })),
              ['display_order']
            )
          : null,
      locationData:
        location_information && location_information[0]
          ? orderBy(
              location_information.map((lio, idx) => ({
                id: lio.id,
                address_1: lio.address_1,
                address_2: lio.address_2,
                location_type: lio.location_type,
                postal: lio.postal,
                province: lio.province ? lio.province : '',
                state: lio.state ? lio.state : '',
                city: lio.city,
                country: lio.country_code_id,
                display_order: idx, //TODO: replace this display_order from database when we have one
              })),
              ['display_order']
            )
          : null,
      achievementData:
        achievement_information && achievement_information[0]
          ? orderBy(
              achievement_information.map((mao) => ({
                id: mao.id,
                entity: mao.entity,
                description: mao.description,
                display_order: mao.display_order,
              })),
              ['display_order']
            )
          : null,
    });
  }, [memberInfo, countryList]);

  const [errors, setErrors] = useState({});

  const closeModal = () => {
    setErrors({});
    // setAlert({ open: false, severity: 'success', message: '' });
    onClose();
  };

  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  // List handlers
  const handleListItemDelete = (itemId, listType, sorter) => {
    const newState = orderBy(
      values[listType].filter((lo) => lo.id !== itemId),
      sorter
    );

    // If we are deleting phones/emails we want to make the last one primary (that will not let to delete it as well)
    let field,
      value = null;
    if (listType === 'phoneData' && newState.length === 1) {
      field = 'primaryPhoneId';
      value = newState[0].id;
    } else if (listType === 'emailData' && newState.length === 1) {
      field = 'primaryEmailId';
      value = newState[0].id;
    }

    setValues({ ...values, [listType]: newState, [field]: value });
  };

  // Phone
  const handlePhoneListChanges = (change) => {
    const { id, name, value } = change;

    if (name === 'primaryPhoneId') {
      setValues({ ...values, primaryPhoneId: value ? id : null });
    } else {
      const currentPhoneData = values.phoneData.find((pdo) => pdo.id === id);
      const restPhoneData = values.phoneData.filter((pdo) => pdo.id !== id);
      const newPhoneState = orderBy(
        restPhoneData.concat([{ ...currentPhoneData, [name]: value }]),
        ['display_order']
      );

      setValues({ ...values, phoneData: newPhoneState });
    }
  };

  // Phone input returns two values => number and country
  const handlePhoneInputChanges = (change) => {
    const { id, phoneNumber, phoneCountry } = change;
    const currentPhoneData = values.phoneData.find((pdo) => pdo.id === id);
    const restPhoneData = values.phoneData.filter((pdo) => pdo.id !== id);
    const newPhoneState = orderBy(
      restPhoneData.concat([
        {
          ...currentPhoneData,
          phoneNumber: phoneNumber,
          phoneCountry: phoneCountry,
        },
      ]),
      ['display_order']
    );

    setValues({ ...values, phoneData: newPhoneState });
  };

  const handlePhoneItemAddClick = () => {
    if (!values.phoneData) {
      setValues({
        ...values,
        phoneData: [
          {
            id: shortid.generate(),
            description: '',
            phoneType: 'cell',
            phoneNumber: '',
            method: 'sms',
            display_order: 1,
            isPhoneVerified: false,
          },
        ],
      });
    } else {
      // Mind the maxiumum ammount of phones
      if (values.phoneData.length + 1 <= process.env.REACT_APP_MAX_PHONES) {
        // Put it last in the  list
        const displayOrder =
          Math.max(...values.phoneData.map((pdo) => pdo.display_order)) + 1;

        setValues({
          ...values,
          phoneData: [
            ...values.phoneData,
            {
              id: shortid.generate(),
              description: '',
              phoneType: 'cell',
              phoneNumber: '',
              method: 'sms',
              display_order: displayOrder,
              isPhoneVerified: false,
            },
          ],
        });
      }
    }
  };

  // Email
  const handleEmailListChanges = (change) => {
    const { id, name, value } = change;

    if (name === 'primaryEmailId') {
      setValues({ ...values, primaryEmailId: value ? id : null });
    } else {
      const currentEmailData = values.emailData.find((edo) => edo.id === id);
      const restEmailData = values.emailData.filter((edo) => edo.id !== id);
      const newEmailState = orderBy(
        restEmailData.concat([{ ...currentEmailData, [name]: value }]),
        ['display_order']
      );

      setValues({ ...values, emailData: newEmailState });
    }
  };

  const handleEmailListAddClick = () => {
    if (!values.emailData) {
      setValues({
        ...values,
        emailData: [
          {
            id: shortid.generate(),
            description: '',
            email: '',
            method: 'html',
            display_order: 1,
          },
        ],
      });
    } else {
      // Mind the maxiumum ammount of emails
      if (values.emailData.length + 1 <= process.env.REACT_APP_MAX_EMAILS) {
        // Put it last in the  list
        const displayOrder =
          Math.max(...values.emailData.map((edo) => edo.display_order)) + 1;

        setValues({
          ...values,
          emailData: [
            ...values.emailData,
            {
              id: shortid.generate(),
              description: '',
              email: '',
              method: 'html',
              display_order: displayOrder,
            },
          ],
        });
      }
    }
  };

  // Locations

  const handleLocationChanges = (change) => {
    const { id, name, value } = change;
    const currentLocationData = values.locationData.find(
      (ldo) => ldo.id === id
    );
    const restLocationData = values.locationData.filter((ldo) => ldo.id !== id);
    const newLocationState = orderBy(
      restLocationData.concat([{ ...currentLocationData, [name]: value }]),
      ['display_order']
    );

    setValues({ ...values, locationData: newLocationState });
  };

  const handleAutoCompleteChange = (change) => {
    const { id, city, state, province, street, postal } = change;
    // console.log('autocomplete data', id, city, state, province, street, postal);
    const currentLocationData = values.locationData.find(
      (ldo) => ldo.id === id
    );
    const restLocationData = values.locationData.filter((ldo) => ldo.id !== id);
    const newLocationState = orderBy(
      restLocationData.concat([
        {
          ...currentLocationData,
          city: city,
          state: state,
          province: province,
          postal: postal,
          street: street,
          address_1: street,
        },
      ]),
      ['display_order']
    );

    setValues({ ...values, locationData: newLocationState });
  };

  const handleLocationAddlick = () => {
    if (!values.locationData) {
      setValues({
        ...values,
        locationData: [
          {
            id: shortid.generate(),
            address_1: '',
            address_2: '',
            location_type: 'other',
            postal: '',
            province: '',
            state: '',
            city: '',
            display_order: 1,
            country: 840,
          },
        ],
      });
    } else {
      // Mind the maxiumum ammount of emails
      if (
        values.locationData.length + 1 <=
        process.env.REACT_APP_MAX_LOCATIONS
      ) {
        // Put it last in the  list
        const displayOrder =
          Math.max(...values.locationData.map((ldo) => ldo.display_order)) + 1;
        setValues({
          ...values,
          locationData: [
            ...values.locationData,
            {
              id: shortid.generate(),
              address_1: '',
              address_2: '',
              location_type: 'other',
              postal: '',
              province: '',
              state: '',
              city: '',
              display_order: displayOrder,
              country: 840,
            },
          ],
        });
      }
    }
  };

  // Achievements

  const handleAchievementsChange = (change) => {
    const { id, name, value } = change;
    const currentAchievementDaya = values.achievementData.find(
      (ado) => ado.id === id
    );
    const restAchievementData = values.achievementData.filter(
      (ado) => ado.id !== id
    );
    const newAchievementState = orderBy(
      restAchievementData.concat([
        { ...currentAchievementDaya, [name]: value },
      ]),
      ['display_order']
    );

    setValues({ ...values, achievementData: newAchievementState });
  };

  const handleAchievementAdd = () => {
    if (!values.achievementData) {
      // This means we have no records yet
      setValues({
        ...values,
        achievementData: [
          {
            id: shortid.generate(),
            entity: '',
            description: '',
            display_order: 1,
          },
        ],
      });
    } else {
      if (
        // Mind the maxiumum ammount of achieves
        values.achievementData.length + 1 <=
        process.env.REACT_APP_MAX_ACHIEVEMENTS
      ) {
        const displayOrder =
          Math.max(...values.achievementData.map((ado) => ado.display_order)) +
          1;
        setValues({
          ...values,
          achievementData: [
            ...values.achievementData,
            {
              id: shortid.generate(),
              entity: '',
              description: '',
              display_order: displayOrder,
            },
          ],
        });
      }
    }
  };

  // Avatar

  const handleAvatarChange = (e) => {
    const selectedFile = e.target.files[0];
    // console.log(selectedFile);
    const reader = new FileReader();

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setSrc(reader.result);
      };
      setShowCrop(true);
    }
  };

  const handleCropConfirm = (file) => {
    // console.log('Blob at file', file);
    const fileForm = new FormData();
    fileForm.append('avatar', file);
    setValues({ ...values, profilePicture: file });
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSrc(reader.result);
      };
    }
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    console.log('values', values);
    const formErrors = validateProfileForm(values);
    // console.log(formErrors);

    if (values && Object.keys(formErrors).length === 0) {
      setErrors({});
      // setSubmitting(true);
      // Values formatted and groupoed by table for easier insert
      const formattedForm = {
        member: {
          first_name: values.first_name,
          middle_name: values.middle_name,
          last_name: values.last_name,
          company_name: values.company_name,
          job_title_id: values.job_title_id,
          department_id: values.department_id,
        },
        member_profile: { biography: values.biography },
        member_achievement: values.achievementData
          ? values.achievementData.map((ad) => ({
              id: ad.id,
              entity: ad.entity,
              description: ad.description,
              display_order: ad.display_order
                ? ad.display_order
                : Math.max(
                    values.achievementData.map((ad) => ad.display_order)
                  ) + 1,
            }))
          : null,
        member_contact_2:
          values.phoneData || values.emailData
            ? values.phoneData.concat(values.emailData).map((cd) => ({
                id: cd.id,
                description: cd.description,
                device_type: cd.phoneType ? cd.phoneType : 'email',
                // phoneCountry is lowercase Alpha2, we need country_code_id
                device_country: cd.phoneCountry
                  ? countryList.find(
                      (clo) => clo.alpha2 === cd.phoneCountry.toUpperCase()
                    ).id
                  : values.country,
                // we need phone number without the phone code digits
                device: cd.phoneNumber
                  ? cd.phoneNumber.replace(
                      countryList.find(
                        (clo) => clo.alpha2 === cd.phoneCountry.toUpperCase()
                      ).phone,
                      ''
                    )
                  : cd.email,
                method_type: cd.method,
                display_order: cd.display_order
                  ? cd.display_order
                  : Math.max(
                      values.phoneData
                        .concat(values.emailData)
                        .map((cd) => cd.display_order)
                    ) + 1,
                primary_contact: cd.phoneCountry
                  ? values.primaryPhoneId === cd.id
                  : values.primaryEmailId === cd.id,
              }))
            : // .filter((mc) => mc.primary_contact === false) //We don't want to send primary contacts, we're just displaying them
              null,

        member_location: values.locationData
          ? values.locationData.map((ldo) => ({
              id: ldo.id,
              city: ldo.city,
              state: ldo.state,
              province: ldo.province,
              postal: ldo.postal,
              address_1: ldo.address_1,
              address_2: ldo.address_2,
              street: ldo.address_1,
              // It can be done in subquery, also can be done here
              country: countryList.find((clo) => clo.id === ldo.country).name,
              country_code_id: ldo.country,
              location_type: ldo.location_type,
            }))
          : null,
      };

      console.log('normalized', formattedForm);
      // setSubmitting(true);
      dispatch(putMemberInfo(formattedForm));
      // .then((res) => {
      //   setSubmitting(false);
      //   if (res) {
      //     setAlert({
      //       open: true,
      //       severity: 'success',
      //       message: 'Profile information updated successfully',
      //     });
      //   } else {
      //     setAlert({
      //       open: true,
      //       severity: 'error',
      //       message: 'Something went wrong',
      //     });
      //   }
      // });

      if (values.profilePicture) {
        // Avatar endpoint
        const fileForm = new FormData();
        fileForm.append('avatar', values.profilePicture);
        dispatch(putAvatar(fileForm));
      }
    } else {
      setErrors(formErrors);
      return false;
    }
  };

  // const AlertMessage = () => {
  //   return (
  //     <Collapse in={alert.open}>
  //       <Alert
  //         variant="filled"
  //         severity={alert.severity}
  //         action={
  //           <IconButton
  //             aria-label="close"
  //             color="inherit"
  //             size="small"
  //             onClick={() => {
  //               setAlert({ open: false });
  //             }}
  //           >
  //             <CloseIcon fontSize="inherit" />
  //           </IconButton>
  //         }
  //       >
  //         {alert.message}
  //       </Alert>
  //     </Collapse>
  //   );
  // };

  const ModalContent = () => (
    <>
      {/* <AlertMessage /> */}
      <ImageCrop
        show={isShowCrop}
        close={() => setShowCrop(false)}
        src={src}
        handleCropConfirm={handleCropConfirm}
      />
      <div className="profile_page-body">
        <form className={classes.root} noValidate autoComplete="off">
          <div className="profile-avatar">
            <label htmlFor="change-avatar-profile-edit">
              <input
                style={{ display: 'none' }}
                id="change-avatar-profile-edit"
                name="change-avatar-mu"
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
              />
              <MaterialAvatar
                badgeType="edit"
                classes="profile my"
                src={src}
                firstName={memberInfo.first_name}
                lastName={memberInfo.last_name}
              />
            </label>
          </div>
          <Grid container spacing={2}>
            <FullNameInput
              onInputChange={handleChange}
              values={values}
              errors={errors}
              include={['first_name', 'middle_name', 'last_name']}
              direction="row"
              textFieldClass="profile-edit-namefield"
            />
            <Grid
              container
              direction="row"
              item
              className="job-details-container"
            >
              <Grid item className="job-details-item">
                <CompanyNameInput
                  companyName={values.company_name}
                  onChange={handleChange}
                  errors={errors.company_name}
                />
              </Grid>
              <Grid item className="job-details-item">
                <DepartmentListInput
                  department={values.department_id}
                  onChange={handleChange}
                  // errors={errors.department_id}
                />
              </Grid>
              <Grid item className="job-details-item">
                <JobTitleInput
                  jobTitle={values.job_title_id}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            {/* Bio */}
            <Grid container>
              <TextField
                name="biography"
                variant="outlined"
                value={values.biography ? values.biography : ''}
                label="Biography"
                placeholder="Tell us about yourself"
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>
            <PhoneList
              errors={errors.phoneList}
              userCountry={
                values.country
                  ? countryList
                      .find((clo) => clo.id === values.country)
                      .alpha2.toLowerCase()
                  : 'us'
              }
              primaryPhoneId={values.primaryPhoneId}
              phoneItems={values.phoneData}
              handleChange={handlePhoneListChanges}
              handlePhoneInputChanges={handlePhoneInputChanges}
              handleDelete={handleListItemDelete}
              handleAdd={handlePhoneItemAddClick}
            />
            <EmailList
              errors={errors.emailList}
              emailItems={values.emailData}
              primaryEmailId={values.primaryEmailId}
              handleChange={handleEmailListChanges}
              handleDelete={handleListItemDelete}
              handleAdd={handleEmailListAddClick}
            />

            <LocationsList
              errors={errors.locationList}
              locationItems={values.locationData}
              handleAdd={handleLocationAddlick}
              handleDelete={handleListItemDelete}
              handleChange={handleLocationChanges}
              handleAutocomplete={handleAutoCompleteChange}
            />
            <AchievementsList
              errors={errors.achievementList}
              achievementItems={values.achievementData}
              handleChange={handleAchievementsChange}
              handleDelete={handleListItemDelete}
              handleAdd={handleAchievementAdd}
            />
          </Grid>
        </form>
      </div>
    </>
  );

  const ModalAction = () => {
    return (
      <div className="actions-wrapper">
        <Button
          variant="contained"
          color="secondary"
          className="action-button"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="action-button"
          disableRipple
          onClick={(e) => handleSubmit(e)}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress
              size={'1.5em'}
              className={classes.buttonProgress}
            />
          ) : null}
          {isLoading ? 'Updating' : 'Update'}
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        className="profile-edit-modal"
      ></Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.member.isLoading,
  app: state.app,
  memberInfo: state.member.memberInfo,
  countryList: state.member.countryList,
});

export default connect(mapStateToProps)(ProfileEdit);
