import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import GroupMembersTable from './GroupMembersTable';

import { validateNewGroupForm } from '../../utils/validator/Group';

import {
  initialGroupMembers,
  createExpandedGroup,
} from '../../redux/actions/group';
import {
  loadContacts,
  loadContactCompanies,
  loadContactCountries,
  loadContactRoles,
  setReduxContactAlert,
} from '../../redux/actions/contact';

import { setContactListFilterSelectOptions } from '../../utils/general';

import {
  ExchangeInput,
  PicturePin,
} from '../../components/Forms/PicturePinForm';

const useStyles = makeStyles((theme) => ({
  groupMemberTool: {
    marginTop: '20px',
  },
  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginTop: '20px',
    marginRight: theme.spacing(2),
    width: '100%',
    boxShadow:
      '0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 7px 0px rgba(0, 0, 0, 0.12)',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sortGroupMembers: {
    float: 'right',
    paddingRight: '20px',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    width: '92%',
  },
}));

const FILTER_SELECT_KEYS = [
  {
    id: 'company',
    text: 'Company',
  },
  {
    id: 'country',
    text: 'Country',
  },
  {
    id: 'role',
    text: 'Role',
  },
];

const NewGroup = (props) => {
  // hook functions
  const classes = useStyles();
  const history = useHistory();

  // props & states
  const {
    contactsCount,
    contactAlert,
    companies,
    countries,
    roles,
    loadContacts,
    loadContactCompanies,
    loadContactCountries,
    loadContactRoles,
    initialGroupMembers,
    createExpandedGroup,
  } = props;

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState('first_name');
  const [order, setOrder] = useState('asc');
  const [filterBy, setFilterBy] = useState({});
  const [allFilterOptions, setAllFilterOptions] = useState([]);
  const [optionValue, setOptionValue] = useState('MOST_SECURE');
  const [file, setFile] = useState('');
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selected, setSelected] = useState([]);

  const [contactsList, setContactsList] = useState([]);

  const hideAlert = () => {
    props.setReduxContactAlert({
      show: false,
      variant: contactAlert.variant,
      message: '',
    });
  };

  const loadInitialFilterValues = useCallback(() => {
    loadContactCompanies();
    loadContactCountries();
    loadContactRoles();
  }, [loadContactCompanies, loadContactCountries, loadContactRoles]);

  useEffect(() => {
    setAllFilterOptions(setContactListFilterSelectOptions(props.contacts));
    setContactsList(props.contacts);
  }, [props.contacts]);

  // effects
  useEffect(() => {
    loadContacts({ searchKey, pageNumber, pageSize }, false, []);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadContacts]);

  useEffect(() => {
    if (props.location.selectedMembers) {
      initialGroupMembers(props.location.selectedMembers);
    } else {
      initialGroupMembers([]);
    }
  }, [props.location, initialGroupMembers]);

  useEffect(() => {
    if (isSubmitting) {
      setErrors(validateNewGroupForm(values, optionValue));
    }
  }, [values, isSubmitting, optionValue]);

  useEffect(() => {
    loadInitialFilterValues();
  }, [loadInitialFilterValues]);

  const handleSetValue = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCreateNewGroup = (event) => {
    if (event) event.preventDefault();
    let formErrors = validateNewGroupForm(values, optionValue);
    setIsSubmitting(true);

    console.log('formErrors', formErrors);
    if (values && Object.keys(formErrors).length === 0) {
      var formData = new FormData();
      formData.set('name', values.group_name);
      formData.set('picture', optionValue !== 'NO_ENCRYPTION' ? file : '');
      formData.set('pin', optionValue !== 'NO_ENCRYPTION' ? values.pin : '');
      formData.set('members', JSON.stringify(selected));
      formData.set('exchangeOption', optionValue);
      formData.set('type', 'create-group');
      createExpandedGroup(formData);
      setTimeout(() => {
        history.push('my-groups');
      }, 1000);
    }
    setErrors(formErrors);
    return false;
  };

  const handleOptionChange = (ev) => {
    setOptionValue(ev.target.value);
  };

  const handlePinChange = (data) => {
    if (data.isValidated) setValues({ ...values, pin: data.pin });
    if (data.picture) setFile(data.picture);
  };

  const loadSortedContacts = (
    property,
    value,
    filterByItems,
    search_key,
    page_number,
    page_size
  ) => {
    let sort = '';
    if (!property) {
      property = orderBy;
    }
    if (!value) {
      value = order;
    }

    if (property === 'member_name') {
      sort =
        value === 'desc' ? `-first_name,-last_name` : `first_name,last_name`;
    } else {
      sort = value === 'desc' ? `-${property}` : `${property}`;
    }
    let params = {};
    filterByItems = filterByItems ? filterByItems : filterBy;
    if (Object.keys(filterByItems).length > 0) {
      //consider user recent filter options as well if exist
      params = { sort, filter: new URLSearchParams(filterByItems) };
    } else {
      params = { sort };
    }
    params.searchKey = search_key ? search_key : searchKey;
    params.pageNumber = page_number ? page_number : pageNumber;
    params.pageSize = page_size ? page_size : pageSize;

    loadContacts(params, true, []);
    setOrderBy(property);
    setFilterBy(filterByItems);
  };
  const loadFilteredContacts = ({ target: { value, name } }) => {
    let filterByItems;
    if (!value) {
      filterByItems = { ...filterBy };
      delete filterByItems[name];
    } else {
      filterByItems = {
        ...filterBy,
        [name]: value,
      };
    }
    loadSortedContacts(null, null, filterByItems);
  };

  const setSelectOptions = (key, data) => {
    let optionValues = [];
    let foundFilterOptions = null;
    switch (key) {
      case 'company':
        optionValues = companies.map((company) => {
          return {
            value: company.company_name,
            name: company.company_name,
          };
        });
        break;
      case 'country_code_id':
        optionValues = countries.map((country) => {
          return {
            value: country.id,
            name: country.name,
          };
        });
        break;
      case 'role':
        // Roles come with Identifiers and name
        optionValues = roles.map((role) => {
          return {
            value: role.name,
            name: role.name,
          };
        });
        break;
      default:
        // By default we must create the expected object
        foundFilterOptions = data.find((d) => d.label === key);
        if (foundFilterOptions) {
          optionValues = foundFilterOptions.values.map((filter) => {
            return {
              value: filter,
              name: filter,
            };
          });
        }
        break;
    }
    return (
      <>
        <option aria-label="None" value="" />
        {optionValues.length > 0 &&
          optionValues.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
      </>
    );
  };

  const search = ({ target: { value } }) => {
    setSearchKey(value);
    loadSortedContacts(null, null, null, value);
  };

  return (
    <React.Fragment>
      {contactAlert.show && (
        <Snackbar
          open={contactAlert.show}
          autoHideDuration={5000}
          onClose={hideAlert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={contactAlert.variant}>{contactAlert.message}</Alert>
        </Snackbar>
      )}
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        className="new-group-form"
      >
        <Grid item md={12} className="new-group-name-container">
          <TextField
            id="outlined-group_name"
            label="Group Name"
            placeholder="Group name"
            type="text"
            required
            fullWidth
            variant="outlined"
            name="group_name"
            value={values.group_name || ''}
            onChange={handleSetValue}
            error={errors.group_name ? true : false}
            helperText={errors.group_name ? errors.group_name : ''}
          />
        </Grid>
        <hr />
        <Grid
          item
          md={optionValue === 'NO_ENCRYPTION' ? 12 : 5}
          xs={12}
          className="exchange-option"
        >
          <ExchangeInput selected={optionValue} onChange={handleOptionChange} />
        </Grid>
        {optionValue !== 'NO_ENCRYPTION' && (
          <Grid item md={6} xs={12}>
            <PicturePin isExchange={false} onChange={handlePinChange} />
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.groupMemberTool}>
        <Grid item sm={8} xs={12}>
          <div className={classes.search}>
            <div aria-label="search-icon" className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={search}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        </Grid>
        <Grid item sm={4} xs={12}>
          <div className={classes.sortGroupMembers}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="sort-group-member-native">
                Sort By
              </InputLabel>
              <Select
                native
                value={orderBy}
                onChange={(e) => loadSortedContacts(e.target.value, 'asc')}
                inputProps={{
                  name: 'sortGroupMembers',
                  id: 'sort-group-member-native',
                }}
              >
                <option aria-label="None" value="" />
                <option value="first_name">First Name</option>
                <option value="last_name">Last Name</option>
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <div className="multi-filter-option">
        <Grid container spacing={2}>
          {FILTER_SELECT_KEYS.map((key) => (
            <Grid item xs={4} key={key.id} className="filter-select-item">
              <FormControl variant="filled">
                <InputLabel htmlFor="filled-age-native-simple">
                  {key.text}
                </InputLabel>
                <Select
                  native
                  value={filterBy && filterBy[key.id] ? filterBy[key.id] : ''}
                  onChange={(e) => loadFilteredContacts(e)}
                  inputProps={{
                    name: key.id,
                    id: key.id,
                  }}
                >
                  {setSelectOptions(key.id, allFilterOptions, key.key)}
                </Select>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="new-group-button">
        <Button
          variant="contained"
          color="secondary"
          className="new-group-action-button"
          onClick={() => history.push('my-groups')}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="new-group-action-button"
          onClick={handleCreateNewGroup}
        >
          Create New Group
        </Button>
      </div>
      <GroupMembersTable
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        selected={selected}
        setSelected={setSelected}
        contactsCount={contactsCount}
        contactsList={contactsList}
        pageSize={pageSize}
        pageNumber={pageNumber}
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
        loadSortedContacts={loadSortedContacts}
      />
      <div className="new-group-button">
        <Button
          variant="contained"
          color="secondary"
          className="new-group-action-button"
          onClick={() => history.push('my-groups')}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="new-group-action-button"
          onClick={handleCreateNewGroup}
        >
          Create New Group
        </Button>
      </div>
    </React.Fragment>
  );
};

NewGroup.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object),
  companies: PropTypes.arrayOf(PropTypes.object),
  countries: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.object),
  addedMembersToGroup: PropTypes.arrayOf(PropTypes.object),
  contactAlert: PropTypes.object,
};

NewGroup.defaultProps = {
  contacts: [],
  companies: [],
  countries: [],
  roles: [],
  addedMembersToGroup: [],
  contactAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

const mapStateToProps = (state) => ({
  contacts: state.contact.contacts,
  contactsCount: state.contact.totalCount,
  companies: state.contact.companies,
  countries: state.contact.countries,
  roles: state.contact.roles,
  member: state.member.member,
  contactAlert: state.contact.contactAlert,
  addedMembersToGroup: state.group.addedMembersToGroup,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        loadContactCompanies,
        loadContactCountries,
        loadContactRoles,
        loadContacts,
        initialGroupMembers,
        createExpandedGroup,
        setReduxContactAlert,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewGroup);
