import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Modal from './modal';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    marginBottom: 10,
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
}));

const SortBySettingsModal = (props) => {
  const { type, open, onClose, orderBy, setOrderBy, loading, onClick } = props;
  const classes = useStyles();

  const options_contact = [
    {
      id: 0,
      name: 'First Name',
      value: 'first_name',
    },
    {
      id: 1,
      name: 'Last Name',
      value: 'last_name',
    },
  ];
  const options_group = [
    {
      id: 0,
      name: 'Group Name',
      value: 'group_name',
    },
    {
      id: 1,
      name: 'Members Count',
      value: 'total_member',
    },
    {
      id: 2,
      name: 'Files Count',
      value: 'total_files',
    },
    {
      id: 3,
      name: 'Create Date',
      value: 'group_create_date',
    },
  ];
  const options_group_memberships = [
    {
      id: 0,
      name: 'Group Name',
      value: 'group_name',
    },
    {
      id: 1,
      name: 'Group Leader First Name',
      value: 'group_leader_first_name',
    },
    {
      id: 2,
      name: 'Group Leader Last Name',
      value: 'group_leader_last_name',
    },
    {
      id: 3,
      name: 'Members Count',
      value: 'total_member',
    },
    {
      id: 4,
      name: 'Files Count',
      value: 'total_files',
    },
    {
      id: 5,
      name: 'Create Date',
      value: 'group_create_date',
    },
  ];

  const renderOptions = () => {
    const options =
      type === 'contacts'
        ? options_contact
        : type === 'my_groups'
        ? options_group
        : options_group_memberships;
    return options.map((item) => (
      <option key={item.id} value={item.value}>
        {item.name}
      </option>
    ));
  };

  const handleChange = (e) => {
    setOrderBy(e.target.value);
  };

  const closeModal = () => {
    onClose();
  };

  const handleSubmit = () => {
    onClick(orderBy, 'asc');
    closeModal();
  };

  const ModalContent = () => (
    <div className={classes.inputContainer}>
      <FormControl fullWidth>
        <InputLabel htmlFor="name-native-simple">Sort By</InputLabel>
        <Select
          native
          value={orderBy}
          onChange={handleChange}
          inputProps={{
            name: 'name-select',
            id: 'name-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          {renderOptions()}
        </Select>
      </FormControl>
    </div>
  );

  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.actionBtn}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            disabled
          >
            ...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            onClick={handleSubmit}
          >
            Ok
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        disabled={loading}
        title={type === 'contacts' ? 'Sort Contacts' : 'Sort Groups'}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      />
    </>
  );
};

SortBySettingsModal.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool,
  orderBy: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  setOrderBy: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

SortBySettingsModal.defaultProps = {
  type: '',
  open: false,
  loading: false,
};

export default SortBySettingsModal;
