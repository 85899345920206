import React from 'react';

import MyProfile from '../../shared/MyProfile';
import TabsPanel from './TabsPanel';

function ChatsPanel() {
  return (
    <>
      <MyProfile />
      <TabsPanel />
    </>
  );
}

export default ChatsPanel;
