import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {
  green,
  light,
  red,
  textGrey,
  textNewGray,
} from '../../../../jss/colors';

// export const containerOverflowedHeight = 'calc(100vh - 340px)';

export const StyledToggleButton = withStyles({
  root: {
    '&$selected': {
      backgroundColor: green,
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: green,
        color: '#FFFFFF',
      },
    },
  },
  selected: {},
})(ToggleButton);

export const useEmailPageStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: `${theme.spacing(2)}px 0 0 0`,
    width: '100%',
    // height: 'calc(100vh - 214px)',
  },
  child: {
    position: 'absolute',
    bottom: 0,
    right: 30,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(8),

    width: '100%',
    height: 50,

    borderBottom: '1px solid #8DA3C5',
  },
  newMessageButton: {
    color: 'white',
    borderRadius: 25,
  },
  refreshButton: {
    marginLeft: 20,
  },
  toggleView: {
    float: 'right',
    paddingRight: 15,
  },
  toggleButton: {
    color: '#12fa32',
    textTransform: 'none',
    marginBottom: 'unset',
    border: 'none',
  },
  headerRightHolder: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '0',
    justifyContent: 'space-between',
  },
  search: {
    borderRadius: 5,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    boxShadow:
      '0 1px 0 -1px rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12)',
  },
  searchIcon: {
    float: 'right',
    paddingTop: 3,
    paddingRight: 5,
    pointerEvents: 'none',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: 10,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  mailSection: {
    paddingTop: 20,
  },
  mailListSection: {
    height: '100%',
    overflow: 'hidden',
    '& .infinite-scroll-component__outerdiv': {
      width: 'inherit',
    },
  },
  mailDetailSection: {
    height: '100%',
  },
  tableMailSection: {
    paddingTop: 0,
    paddingRight: 10,
  },
  tableMailListContainer: {
    boxShadow:
      '0 1px 0 -1px rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12)',
    padding: 0,
    borderRadius: 6,
    backgroundColor: '#FFFFFF',
    maxHeight: 360,
    overflowY: 'scroll',
  },
  tableMailDetailContainer: {
    boxShadow:
      '0 1px 0 -1px rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12)',
    padding: 0,
    marginTop: 15,
    borderRadius: 6,
    backgroundColor: '#FFFFFF',
  },
  scroll: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
      'background-color': light,
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      color: 'white',
      'background-color': 'white',
    },
    '&::-webkit-scrollbar-thumb': {
      'background-color': textGrey,
    },
  },
  groupWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
  foldersLayout: {
    // height: containerOverflowedHeight,
    overflow: 'auto',
    width: '100%',
    padding: '0 5px',
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    color: '#2E2A60',
  },
  ddControl: {
    width: '100%',
    minWidth: 120,
  },
  filter: {
    borderBottom: '1px solid #8DA3C5',
  },
  deleteButton: {
    color: 'white',
    backgroundColor: red,
  },
  darkButton: {
    color: textNewGray,
    maxHeight: 35,
    minHeight: 35,
    maxWidth: 35,
    minWidth: 35,
    marginRight: 10,
  },
  greenButton: {
    color: green,
    maxHeight: 35,
    minHeight: 35,
    maxWidth: 35,
    minWidth: 35,
    marginRight: 10,
  },
  attachment: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 15,
    fontFamily: 'medium',
    justifyContent: 'center',
    textDecoration: 'none',
    color: 'inherit',
  },
  iconWrapper: {
    width: 58,
    height: 58,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
}));
