import {
  FETCH_CONVERSATIONS_START,
  FETCH_CONVERSATIONS_SUCCESS,
  FETCH_CONVERSATIONS_FAILED,
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILED,
  SEND_MESSAGE_START,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILED,
  ADD_MESSAGE,
} from '../actionTypes/chat';
import { fetchTodos } from '../../config/apiService/Fakeapi';

export const loadConversations = () => (dispatch) => {
  console.log('loaidng conversations');
  const url = 'conversations';
  dispatch(fetchConversationsStart());
  fetchTodos(url)
    .then((conversations) => {
      console.log('chat conversations in response ', conversations);
      dispatch(fetchConversationsSuccess(conversations));
    })
    .catch((error) => {
      console.log('conversations', error);
      dispatch(fetchConversationsFailed(error));
    });
};

export const loadMessages = (conversationId) => (dispatch) => {
  const url = 'messages';
  dispatch(fetchMessagesStart({ conversationId }));
  fetchTodos(url, conversationId)
    .then((messages) => {
      console.log('messages in response ', messages);
      dispatch(fetchMessagesSuccess({ messages }));
    })
    .catch((error) => {
      dispatch(fetchMessagesFailed(error));
    });
};

export const sendMessage = (conversationId, messageObj, newMessageId) => (
  dispatch
) => {
  console.log('sending message', messageObj);
  const url = 'message/send';
  dispatch(sendMessageStart(messageObj));
  return new Promise((resolve) => {
    fetchTodos(url, conversationId)
      .then((replyMessageObj) => {
        let newMessageObj = { ...replyMessageObj };
        newMessageObj.id = newMessageId;
        newMessageObj.createdAt = new Date().toUTCString();
        console.log('message reply --->', newMessageObj, replyMessageObj);
        dispatch(sendMessageSuccess(newMessageObj));
        resolve(true);
      })
      .catch((error) => {
        console.log(error);
        dispatch(sendMessageFailed(error));
      });
  });
};

export const addMessage = (message) => (dispatch) => {
  dispatch(addMessageAction(message));
};

export const fetchConversationsStart = () => ({
  type: FETCH_CONVERSATIONS_START,
});
export const fetchConversationsSuccess = (payload) => ({
  type: FETCH_CONVERSATIONS_SUCCESS,
  payload,
});
export const fetchConversationsFailed = (payload) => ({
  type: FETCH_CONVERSATIONS_FAILED,
  payload,
});

export const fetchMessagesStart = (payload) => ({
  type: FETCH_MESSAGES_START,
  payload,
});
export const fetchMessagesSuccess = (payload) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload,
});
export const fetchMessagesFailed = (payload) => ({
  type: FETCH_MESSAGES_FAILED,
  payload,
});

export const sendMessageStart = (payload) => ({
  type: SEND_MESSAGE_START,
  payload,
});
export const sendMessageSuccess = (payload) => ({
  type: SEND_MESSAGE_SUCCESS,
  payload,
});
export const sendMessageFailed = (payload) => ({
  type: SEND_MESSAGE_FAILED,
  payload,
});

const addMessageAction = (payload) => ({
  type: ADD_MESSAGE,
  payload,
});
