import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ProfileEdit from '../ProfileEdit/ProfileEdit';

import Modal from '../../../components/Modal/modal';
import ProfileInfo from './ProfileInfo';

const MyProfile = (props) => {
  const { open, onClose, memberState, groupMemberships, memberAlert } = props;
  let { memberInfo, jobTitleList } = memberState;
  const title = 'Profile Information';

  if (memberInfo === null) {
    memberInfo = {};
  }

  const { job_title_id, job_title, location_information } = memberInfo;

  // const [newProfileImage, setNewProfileImage] = useState(null);
  const [isEdit, setEdit] = useState(false);

  const handleEditClick = () => {
    setEdit(true);
  };

  useEffect(() => {
    if (memberAlert.show) {
      setEdit(false);
      setTimeout(() => {
        props.handleSnackbarClose();
      }, 5000);
    }
  }, [memberAlert]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    onClose();
    props.handleSnackbarClose();
  };

  // Home, fallback for first if no home address
  let city = 'N/A';
  let state = 'N/A';
  let province = 'N/A';
  let postal = 'N/A';
  let country_code_id = 849;

  if (
    location_information &&
    location_information.length &&
    location_information[0]
  ) {
    const homeAddress = location_information.find(
      (lio) => lio.location_type === 'home'
    );
    if (homeAddress) {
      city = homeAddress.city;
      state = homeAddress.state;
      province = homeAddress.province;
      postal = homeAddress.postal;
      country_code_id = homeAddress.country_code_id;
    } else {
      city = location_information[0].city;
      state = location_information[0].state;
      province = location_information[0].province;
      postal = location_information[0].postal;
      country_code_id = location_information[0].country_code_id;
    }
  }

  // Prepare job title
  let memberJobTitle = '';
  if (job_title) {
    memberJobTitle = job_title;
  } else if (job_title_id && jobTitleList) {
    memberJobTitle = jobTitleList.find(
      (jto) => jto.job_title_id === job_title_id
    );
    if (memberJobTitle) {
      memberJobTitle = memberJobTitle.job_title || '';
    }
  }
  //Prepare member address
  const memberAddress = `${city ? city : null}, ${
    country_code_id === 840 ? state : province
  }, ${postal} `;

  const ModalContent = () => (
    <>
      <Button onClick={handleEditClick} className="modal-header-button">
        Edit Profile
      </Button>
      <ProfileEdit open={isEdit} onClose={() => setEdit(false)} />
      <div className="profile_page-body">
        <ProfileInfo
          isContactProfile={false}
          memberInfo={memberInfo}
          title={memberJobTitle}
          role={memberState.role}
          address={memberAddress}
          groupMemberships={groupMemberships}
        />
      </div>
    </>
  );

  const ModalAction = () => (
    <Grid container justify="flex-end">
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseModal}
        className="modal-action-btn"
      >
        cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleEditClick}
        className="modal-action-btn"
      >
        edit profile
      </Button>
    </Grid>
  );
  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        className="profile-modal"
      ></Modal>
    </>
  );
};

MyProfile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

MyProfile.defaultProps = {
  open: false,
  onClose: null,
};

const mapStateToProps = (state) => ({
  memberState: state.member,
  groupMemberships: state.group.groupList,
  memberAlert: state.member.memberAlert,
});

export default connect(mapStateToProps)(MyProfile);
