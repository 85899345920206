import {
  FETCH_CONVERSATIONS_START,
  FETCH_CONVERSATIONS_SUCCESS,
  FETCH_CONVERSATIONS_FAILED,
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILED,
  SEND_MESSAGE_START,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILED,
  ADD_MESSAGE,
} from '../actionTypes/chat';

// The initial state of the chats
const initialState = {
  conversations: [],
  selectedConversationId: null,
  messages: [],
  loadingConversations: false,
  loadingMessages: false,
  error: null,
};

const chat = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONVERSATIONS_START:
      return {
        ...state,
        error: null,
        loadingConversations: true,
      };
    case FETCH_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        conversations: action.payload,
        loadingConversations: false,
      };
    case FETCH_CONVERSATIONS_FAILED:
      return {
        ...state,
        error: action.payload,
        loadingConversations: false,
      };

    case FETCH_MESSAGES_START:
      return {
        ...state,
        error: null,
        selectedConversationId: action.payload.conversationId,
        messages: [],
        loadingMessages: true,
      };
    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload.messages,
        loadingMessages: false,
      };
    case FETCH_MESSAGES_FAILED:
      return {
        ...state,
        error: action.payload,
        loadingMessages: false,
      };

    case SEND_MESSAGE_START:
      return {
        ...state,
        messages: [action.payload, ...state.messages],
      };
    case SEND_MESSAGE_SUCCESS:
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [action.payload, ...state.messages],
      };
    case SEND_MESSAGE_FAILED:
      return {
        ...state, // there should array of errors for all failed messages or set message status in messages
      };
    default:
      return state;
  }
};

export default chat;
