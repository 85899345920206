import loginImg from '../../assets/img/login-image.png';

import grey from '@material-ui/core/colors/grey';
import { textDark, borderDark, textGrey } from '../../jss/colors';

const loginStyle = (theme) => ({
  loginPage: {
    width: '100%',
    height: '100vh',
    display: 'flex',
  },
  welcomeSection: {
    width: '53%',
    backgroundImage: `url("${loginImg}")`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  messageContainer: {
    width: '100%',
    height: '100%',
    opacity: '90%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '26% 0% 0% 16%',
  },
  largeMessage: {
    fontSize: '44px',
    fontFamily: 'Roboto-Medium',
  },
  mediumMessage: {
    fontSize: '32px',
    fontFamily: 'Helvetica Neue',
    marginTop: '2em',
  },
  formSection: {
    padding: '4% 4% 5% 7%',
    backgroundColor: grey[100],
    color: textGrey,
    width: '47%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiFormLabel-root': {
      fontSize: '2em',
      color: textGrey,
    },
    '& .MuiInputBase-input': {
      height: '2em',
    },
    '& .MuiInputLabel-formControl': {
      top: '-0.2em',
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${borderDark}`,
    },
    '& .MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },
  },
  goHome: {
    textAlign: 'right',
    '& a': {
      color: textDark,
    },
  },
  logoImg: {
    width: '40%',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  emailInput: {
    marginTop: '4em',
  },
  passwordInput: {
    marginTop: '5em',
    marginBottom: '0.3em',
  },
  forgotPasswordLabel: {
    '& label': {
      fontSize: '1.3em',
      whiteSpace: 'nowrap',

      '& a': {
        textDecoration: 'none',
        color: textGrey,
        '&:hover, &:active': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  signInWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4em',
    padding: '0% 25% 0% 25%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '2px',
    '& .MuiButton-label': {
      fontSize: '1.3em',
    },
    '& label': {
      fontSize: '1.3em',
      whiteSpace: 'nowrap',

      '& a': {
        textDecoration: 'none',
        color: textGrey,
        '&:hover, &:active': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
});

export default loginStyle;
