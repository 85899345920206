import React from 'react';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import AchievementListItem from './AchievementListItem/AchievementListItem';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';

const AchievementsList = (props) => {
  const {
    errors,
    achievementItems,
    handleChange,
    handleDelete,
    handleAdd,
  } = props;

  return (
    <div className="achievement-list-container">
      <List
        className="achievement-list"
        subheader={
          <ListSubheader disableSticky className="list-header">
            Achievements
          </ListSubheader>
        }
      >
        {achievementItems && achievementItems.length > 0 ? (
          achievementItems.map((ai) => (
            <AchievementListItem
              errors={errors ? errors[ai.id] : null}
              key={ai.id}
              achievementData={ai}
              handleChange={handleChange}
              handleDelete={handleDelete}
            />
          ))
        ) : (
          // When no record - show fallback message
          <Typography variant="body2">Please create a record</Typography>
        )}
      </List>
      <Button
        startIcon={<AddCircleIcon />}
        onClick={handleAdd}
        // disabled={true}
        disabled={
          achievementItems &&
          achievementItems.length ===
            parseInt(process.env.REACT_APP_MAX_ACHIEVEMENTS)
        }
      >
        Add
      </Button>
    </div>
  );
};

export default AchievementsList;
