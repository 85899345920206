import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';

export const RecurrenceCheckbox = (props) => {
  const { checked, onChange } = props;
  return (
    <FormControlLabel
      value="end"
      control={
        <Checkbox
          color="primary"
          checked={checked}
          onChange={onChange}
          name="recurrence"
        />
      }
      label="Recurrence"
      labelPlacement="end"
    />
  );
};

export const RecurrenceDetail = (props) => {
  const { onClick, recurrenceText } = props;
  return (
    <Grid container wrap="nowrap" justify="flex-end" alignItems="center">
      <Typography variant="body2">{recurrenceText}</Typography>
      <IconButton onClick={onClick}>
        <EditIcon />
      </IconButton>
    </Grid>
  );
};
