import { APP_START } from '../actionTypes/app';
import {
  CREATE_GROUP,
  IS_LOADING_CREATE_GROUP,
  GET_GROUP_LIST,
  IS_LOADING_GET_GROUP_LIST,
  SET_AMERA_GROUP_SECURITY,
  GET_GROUP_DETAIL,
  REMOVE_GROUP_MEMBER,
  ADD_GROUP_MEMBER,
  SET_GROUP_ALERT,
  REMOVE_GROUP_ALERT,
  SET_ADD_MEMBER_FROM,
  SET_SHOW_GROUP_DETAIL_MODAL,
  FETCH_GROUP_MEMBERSHIPS_START,
  FETCH_GROUP_MEMBERSHIPS_SUCCESS,
  FETCH_GROUP_MEMBERSHIPS_FAILED,
  GET_CONTACT_GROUP_MEMBERSHIP,
  IS_LOADING_MEMBER_INVITE,
  IS_DELETING_GROUP,
  DELETED_GROUP,
  FAIL_DELETED_GROUP,
  LOAD_GROUP_MEMBERS,
  ADD_MEMBER_TO_NEW_GROUP,
  INITIAL_GROUP_MEMBERS,
  SET_GROUP_SECURITY,
  SET_GROUP_LEADER_DETAIL,
  SET_GROUP_CALENDAR_ACTIVITY,
  SET_GROUP_DRIVE_ACTIVITY,
} from '../actionTypes/group';

// The initial state of the App
export const initialState = {
  createGroupLoading: false,
  getGroupListLoading: false,
  groupList: [],
  groupData: {},
  groupMembers: [],
  addedMembersToGroup: [],
  ameraGroupSecurity: [],
  groupAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
  memberForm: true,
  showGroupDetailModal: false,
  isDrive: false,
  groupMembershipList: [],
  contactGroupMemberships: [],
  isLoadingMemberInvite: false,
  deletingGroup: false,
  security: null,
  group_id: 0,
  loadingGroups: false,
  loadingGroupMemberships: false,
  groupLeaderData: null,
  groupDriveActivity: null,
  groupCalendarActivity: null,
};

const group = (state = initialState, action) => {
  switch (action.type) {
    case APP_START:
      return {
        ...state,
        ...{
          createGroupLoading: false,
          getGroupListLoading: false,
          groupAlert: {
            show: false,
            variant: 'success',
            message: '',
          },
          memberForm: true,
          showGroupDetailModal: false,
          isLoadingMemberInvite: false,
          deletingGroup: false,
        },
      };
    case LOAD_GROUP_MEMBERS:
      return {
        ...state,
        groupMembers: action.payload,
      };
    case ADD_MEMBER_TO_NEW_GROUP:
      return {
        ...state,
        groupMembers: reGroupMembers(state.groupMembers, action.payload),
        addedMembersToGroup: action.payload,
      };
    case INITIAL_GROUP_MEMBERS:
      return {
        ...state,
        addedMembersToGroup: action.payload,
      };
    case IS_LOADING_CREATE_GROUP:
      return {
        ...state,
        createGroupLoading: true,
      };
    case IS_LOADING_GET_GROUP_LIST:
      return {
        ...state,
        getGroupListLoading: true,
      };
    case IS_LOADING_MEMBER_INVITE:
      return {
        ...state,
        isLoadingMemberInvite: true,
      };
    case CREATE_GROUP:
      return {
        ...state,
        createGroupLoading: false,
        groupList: action.payload.concat(state.groupList),
      };
    case GET_GROUP_LIST:
      return {
        ...state,
        getGroupListLoading: false,
        groupList: [...action.payload],
      };
    case SET_AMERA_GROUP_SECURITY:
      return {
        ...state,
        ameraGroupSecurity: [...state.ameraGroupSecurity, action.payload],
      };
    case GET_GROUP_DETAIL:
      return {
        ...state,
        groupData: action.payload,
      };
    case REMOVE_GROUP_MEMBER:
      return {
        ...state,
        groupData: action.payload,
        groupList: reTotalGroupMemberCount(
          state.groupList,
          action.payload,
          'remove'
        ),
      };
    case ADD_GROUP_MEMBER:
      return {
        ...state,
        groupData: action.payload,
        groupList: reTotalGroupMemberCount(
          state.groupList,
          action.payload,
          'add'
        ),
      };
    case SET_GROUP_ALERT:
      return {
        ...state,
        isLoadingMemberInvite: false,
        groupAlert: action.payload,
      };
    case REMOVE_GROUP_ALERT:
      return {
        ...state,
        groupAlert: {
          show: false,
          variant: 'success',
          message: '',
        },
        isLoadingMemberInvite: false,
      };
    case SET_ADD_MEMBER_FROM:
      return {
        ...state,
        memberForm: action.payload,
      };
    case SET_SHOW_GROUP_DETAIL_MODAL:
      return {
        ...state,
        showGroupDetailModal: action.payload.showGroupDetailModal,
        isDrive: action.payload.isDrive,
      };
    case FETCH_GROUP_MEMBERSHIPS_START:
      return {
        ...state,
        loadingGroupMemberships: true,
      };
    case FETCH_GROUP_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        loadingGroupMemberships: false,
        groupMembershipList: action.payload,
      };
    case FETCH_GROUP_MEMBERSHIPS_FAILED:
      return {
        ...state,
        loadingGroupMemberships: false,
      };
    case GET_CONTACT_GROUP_MEMBERSHIP:
      return {
        ...state,
        contactGroupMemberships: action.payload,
      };
    case DELETED_GROUP:
      return {
        ...state,
        groupList: removeGroups(state.groupList, action.payload),
        deletingGroup: false,
      };
    case FAIL_DELETED_GROUP:
      return {
        ...state,
        deletingGroup: false,
      };
    case IS_DELETING_GROUP:
      return {
        ...state,
        deletingGroup: true,
      };
    case SET_GROUP_SECURITY:
      return {
        ...state,
        security: action.payload,
      };
    case SET_GROUP_LEADER_DETAIL:
      return {
        ...state,
        groupLeaderData: action.payload,
      };
    case SET_GROUP_DRIVE_ACTIVITY:
      return {
        ...state,
        groupDriveActivity: action.payload,
      };
    case SET_GROUP_CALENDAR_ACTIVITY:
      return {
        ...state,
        groupCalendarActivity: action.payload,
      };
    default:
      return state;
  }
};

function reTotalGroupMemberCount(groupList, group, type) {
  for (let i = 0; i < groupList.length; i++) {
    if (groupList[i]['group_id'] === group.group_id)
      if (type === 'remove') {
        groupList[i].total_member--;
      } else if (type === 'add') {
        groupList[i].total_member++;
      }
  }
  return groupList;
}

function reGroupMembers(groupMembers, selectedMemberIdList) {
  let members = groupMembers;
  for (let i = 0; i < selectedMemberIdList.length; i++) {
    members = members.filter(
      (member) => member.member_id !== selectedMemberIdList[i]
    );
  }
  return members;
}

function removeGroups(groupList, deletedGroups) {
  let newGroupsList = [...groupList];
  Object.keys(deletedGroups).map((group_id) => {
    if (deletedGroups[group_id] === true) {
      newGroupsList = [
        ...newGroupsList.filter(
          (x) => x.group_id.toString() !== group_id.toString()
        ),
      ];
    } else {
      console.log('Failed to delete group', group_id);
    }
    return group_id;
  });
  return newGroupsList;
}

export default group;
