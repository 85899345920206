import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import MaterialAvatar from '../../components/MaterialAvatar';
import SortBySettingsModal from '../../components/Modal/SortBySettingsModal';
import UpdateContactRoleModal from '../../components/Modal/UpdateContactRoleModal';
import {
  setCallPartner,
  setCallPartnerMemberId,
} from '../../redux/actions/one2onevcall';
import { displayableFullName, getContactDevice } from '../../utils/contact';
import { openContactVideoCall, sendCallSignal } from '../../utils/general';
import ContactProfile from '../Profile/Profiles/ContactProfile';
// import SVG from '../../components/Icons/SVG';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import SecurityDetailModal from './SecurityDetailModal';
import AmeraPagination from '../../components/AmeraPagination';
import CompanyDetailModal from '../../components/Company/CompanyDetailModal';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 12,
    right: 40,
    width: 100,
    background: 'transparent',
    border: '1px solid #aaaaaa',
    height: 20,
    borderRadius: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  bar: {
    // borderRadius: 8,
    backgroundColor: '#94c03d',
    // margin: 1,
  },
}))(LinearProgress);

// const useStyles = makeStyles((theme) => ({
//   root: {
//     position: 'relative',
//     width: '100%',
//     height: '100%',
//   },
//   child: {
//     // position: 'absolute',
//     // // bottom: 0,
//     // right: 30,
//     zIndex: 999,
//     position: 'fixed',
//     right: 30,
//     bottom: 20,
//     // top: '50%',
//     // left: ' 50%',
//     /* bring your own prefixes */
//     // transform: translate(-50%, -50%);
//     // transform: `translate(-50%, -50%)`,
//   },
// }));

const ContactStatus = (props) => {
  let { status } = props;
  if (status === 'online') {
    return (
      <Tooltip title="Online" arrow>
        <IconButton aria-label="Online" className="online-icon">
          <DesktopWindowsIcon />
        </IconButton>
      </Tooltip>
    );
  } else if (status === 'offline') {
    return (
      <Tooltip title="Offline" arrow>
        <IconButton aria-label="Offline" className="offline-icon">
          <DesktopAccessDisabledIcon />
        </IconButton>
      </Tooltip>
    );
  } else {
    return <></>;
  }
};
ContactStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
ContactStatus.defaultPage = {
  status: '',
};

const CardContainer = (props) => {
  const {
    member,
    loading,
    totalCount,
    contactData,
    loadSortedContacts,
    showContactsSetting,
    setShowContactsSetting,
    orderBy,
    setOrderBy,
    pageSize,
    pageNumber,
    handleChangePage,
    handleChangeRowsPerPage,
    loadContactCompanies,
    loadContactCountries,
    loadContactRoles,
    openDetailsFor,
    onSchedule,
  } = props;
  const [isShowSecurityModal, setShowSecurityModal] = useState(false);
  const [selectedContacts, setSlectedContacts] = useState([]);
  const [contactDetailData, setContactDetailData] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showContactRoleModal, setShowContactRoleModal] = useState(false);
  const [showCompanyDetailModal, setShowCompanyDetailModal] = useState(false);

  const [companyId, setCompanyId] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  // const classes = useStyles();

  // useEffect(() => {
  //   setNoOfPages(Math.ceil(contactData.length / itemsPerPage));
  // }, [itemsPerPage, contactData]);

  useEffect(() => {
    if (!!openDetailsFor) {
      showContactDetail(
        contactData.find((cdo) => cdo.contact_member_id === openDetailsFor)
      );
    }
  }, [contactData, openDetailsFor]);

  const showContactSecurity = (contact) => {
    setContactDetailData(contact);
    setShowSecurityModal(true);
  };

  const showContactDetail = (contact) => {
    setContactDetailData(contact);
    setShowDetailModal(true);
  };

  const showContactRoles = (contact) => {
    setContactDetailData(contact);
    setShowContactRoleModal(true);
  };

  const closeAndReloadContacts = () => {
    loadSortedContacts();
    loadContactCompanies();
    loadContactCountries();
    loadContactRoles();
    setShowContactRoleModal(false);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = contactData.map((n) => n.id);
  //     setSlectedContacts(newSelecteds);
  //     return;
  //   }
  //   setSlectedContacts([]);
  // };

  const handleSelect = (event, contact_member_id) => {
    let item = contactData.find(
      (el) => el.contact_member_id === contact_member_id
    );
    let findItem = selectedContacts.findIndex(
      (el) => el.contact_member_id === contact_member_id
    );
    if (findItem === -1) {
      setSlectedContacts([...selectedContacts, item]);
    } else {
      let newSelected = [...selectedContacts];
      newSelected.splice(findItem, 1);
      setSlectedContacts([...newSelected]);
    }
  };

  const handleCloseSecurityModal = () => {
    loadSortedContacts();
    setShowSecurityModal(false);
  };

  const goOne2OneVideoCall = (partner, notificationData) => {
    props.setCallPartner(partner);
    dispatch(setCallPartnerMemberId(partner.member_id));
    sendCallSignal(notificationData.member, notificationData.contact);
    history.push(
      `${process.env.PUBLIC_URL}/contacts/call/video/${partner.member_id}`
    );
  };

  const openCompanyDetailModal = (company_id) => {
    setShowCompanyDetailModal(true);
    setCompanyId(company_id);
  };

  return (
    <>
      <ContactProfile
        open={showDetailModal}
        onClose={() => {
          setShowDetailModal(false);
          // This clears the state causing the Profile modal to show up (if any), when routed from Fileshare
          history.push(`${process.env.PUBLIC_URL}/contacts/`);
        }}
        contact={contactDetailData}
        member={member}
        setCompose={() => {
          props.setCompose({
            open: true,
            initialState: {
              receivers: [
                {
                  email: contactDetailData.email,
                  member: contactDetailData.member_name,
                  first_name: contactDetailData.first_name,
                  last_name: contactDetailData.last_name,
                  contact_member_id: contactDetailData.contact_member_id,
                },
              ],
            },
          });
        }}
        videoCall={() => {
          goOne2OneVideoCall(
            {
              email: contactDetailData.email,
              member_id: contactDetailData.contact_member_id,
              first_name: contactDetailData.first_name,
              last_name: contactDetailData.last_name,
            },
            { member: member, contact: contactDetailData }
          );
        }}
        onSchedule={() => onSchedule([contactDetailData])}
      />
      <SecurityDetailModal
        open={isShowSecurityModal}
        onClose={() => handleCloseSecurityModal()}
        contact={contactDetailData}
      />
      <SortBySettingsModal
        type="contacts"
        loading={loading}
        open={showContactsSetting}
        orderBy={orderBy}
        onClose={() => setShowContactsSetting(false)}
        setOrderBy={setOrderBy}
        onClick={loadSortedContacts}
      />
      <UpdateContactRoleModal
        contact={contactDetailData}
        open={showContactRoleModal}
        onClose={() => closeAndReloadContacts()}
      />
      {/* {contactData.length > 0 && (
        <div className="card-pagination contact-cards-pagination">
          <div className="item-per-page">
            <FormControl>
              <Select
                labelId="item-count-per-page-select-label"
                id="item-count-per-page-select"
                value={itemsPerPage}
                onChange={(e) => setItemPerPage(e.target.value)}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={75}>75</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <p className="item-count-text">Cards Per Page</p>
          </div>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={noOfPages}
            page={page}
            onChange={handlePageChange}
            defaultPage={1}
            color="primary"
            showFirstButton
            showLastButton
          />
        </div>
      )} */}
      <Collapse in={selectedContacts.length > 0}>
        <EnhancedTableToolbar
          title=""
          selectedContacts={selectedContacts}
          handleCreateGroup={props.goToCreateGroup}
          handleScheduleEvent={onSchedule}
          handleDeleteContacts={(selectedContacts) => {
            props.deleteContactsFunc(selectedContacts);
            setSlectedContacts([]);
          }}
          setCompose={props.setCompose}
        />
      </Collapse>
      <Grid container className="contact-container">
        {contactData.map((contact) => {
          // *** remove it later when contact status property available
          return (
            <Grid
              item
              md={6}
              sm={12}
              lg={4}
              key={contact.id}
              className="contact-card"
            >
              <Card>
                <div className="custom-card_header">
                  <MaterialAvatar
                    classes="contact"
                    member_id={contact.contact_member_id}
                    src={contact.amera_avatar_url}
                    onClick={() => showContactDetail(contact)}
                    firstName={contact.first_name}
                    lastName={contact.last_name}
                  />
                  <div className="primary-title">
                    <div className="title">{displayableFullName(contact)}</div>
                    <div
                      className={classNames('primary-header', {
                        clickable: !!contact.company_id,
                      })}
                      onClick={() => {
                        if (contact.company_id) {
                          openCompanyDetailModal(contact.company_id);
                        }
                      }}
                    >
                      {contact.company_id
                        ? contact.member_company_name
                        : contact.company}
                    </div>
                    <div className="second-header">{contact.title}</div>
                    <div className="second-header">
                      {contact.create_date &&
                        format(new Date(contact.create_date), 'P')}
                    </div>
                  </div>
                  <BorderLinearProgress
                    variant="determinate"
                    value={contact.security_exchange_option}
                    onClick={() => showContactSecurity(contact)}
                  />
                  <p className="bar-value">
                    {contact.security_exchange_option + '%'}
                  </p>
                  <p
                    className="bar-title"
                    onClick={() => showContactSecurity(contact)}
                  >
                    Security Level
                  </p>
                  <div className="select-checkbox">
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      checked={selectedContacts.some(
                        (selectedContact) =>
                          selectedContact.contact_member_id ===
                          contact.contact_member_id
                      )}
                      onClick={(event) =>
                        handleSelect(event, contact.contact_member_id)
                      }
                    />
                  </div>
                  <div className="contact-actions">
                    <div className="text-container contact-role">
                      <div className="content_list">
                        <p className="content_key">Type</p>
                        <Link
                          component="button"
                          variant="body2"
                          className="content_value"
                          onClick={() => {
                            showContactRoles(contact);
                          }}
                        >
                          {contact.role || 'Not Available'}
                        </Link>
                      </div>
                    </div>
                    <div className="online-status-button">
                      {
                        <ContactStatus
                          status={
                            contact.online_status === 'online'
                              ? 'online'
                              : 'offline'
                          }
                          contact={contact}
                        />
                      }
                    </div>
                  </div>
                </div>
                <CardActions disableSpacing className="contact-card-action">
                  <Tooltip title="Chat" placement="top" arrow>
                    <IconButton
                      aria-label="message"
                      className="contact-card-action-icon"
                    >
                      <CommentOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Shared Files" placement="top" arrow>
                    <IconButton
                      aria-label="File share"
                      className="contact-card-action-icon"
                    >
                      {/* <SVG name="fileShareSmall" style={{backgroundColor: 'red', width: 40, height: 40}} /> */}
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Send email" placement="top" arrow>
                    <IconButton
                      aria-label="email"
                      className="contact-card-action-icon"
                      onClick={() => {
                        props.setCompose({
                          open: true,
                          initialState: {
                            receivers: [
                              {
                                email: contact.email,
                                member: contact.member_name,
                                first_name: contact.first_name,
                                last_name: contact.last_name,
                                contact_member_id: contact.contact_member_id,
                              },
                            ],
                          },
                        });
                      }}
                    >
                      <EmailOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Video call" placement="top" arrow>
                    <IconButton
                      aria-label="conference"
                      className="contact-card-action-icon"
                      onClick={() => {
                        goOne2OneVideoCall(
                          {
                            email: contact.email,
                            member_id: contact.contact_member_id,
                            first_name: contact.first_name,
                            last_name: contact.last_name,
                          },
                          { member, contact }
                        );
                      }}
                    >
                      <DesktopWindowsOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Call" placement="top" arrow>
                    <IconButton
                      onClick={() => openContactVideoCall(member, contact)}
                      aria-label="call"
                      className="contact-card-action-icon"
                    >
                      <PhoneOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Schedule" placement="top" arrow>
                    <IconButton
                      aria-label="event"
                      className="contact-card-action-icon"
                      onClick={() => onSchedule([contact])}
                    >
                      <DateRangeIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
                <CardContent className="contact-card_content">
                  <div className="content_list">
                    <p className="content_key">Cell number</p>
                    <p className="content_value">
                      {getContactDevice(contact, 'Cell phone') ||
                        'Not Available'}
                    </p>
                  </div>
                  <div className="content_list">
                    <p className="content_key">Office number</p>
                    <p className="content_value">
                      {getContactDevice(contact, 'Office phone') ||
                        'Not Available'}
                    </p>
                  </div>
                  {contact.email && contact.personal_email ? (
                    <FormControl className="contact-email-list">
                      <Select
                        value={contact.email}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value={contact.email}>
                          {contact.email}
                        </MenuItem>
                        <MenuItem value={contact.personal_email}>
                          {contact.personal_email}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <div className="content_list">
                      <p className="content_key">email</p>
                      <p className="content_value">{contact.email}</p>
                    </div>
                  )}
                </CardContent>
                {/* <CardActions
                    disableSpacing
                    className="contact-card-action-button"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => showContactDetail(contact)}
                    >
                      view profile
                    </Button>
                  </CardActions> */}
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <AmeraPagination
        rowsPerPage={pageSize}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={totalCount}
        page={pageNumber}
        handleChangePage={handleChangePage}
      />
      <CompanyDetailModal
        open={showCompanyDetailModal}
        isEdit={false}
        onClose={() => {
          setShowCompanyDetailModal(false);
        }}
        companyId={companyId}
        switchMode={() => {}}
      />
    </>
  );
};

CardContainer.propTypes = {
  member: PropTypes.object,
  contactData: PropTypes.arrayOf(PropTypes.object),
  filterValues: PropTypes.arrayOf(PropTypes.object),
  setCallPartner: PropTypes.func,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

CardContainer.defaultProps = {
  member: {},
  contactData: [],
  filterValues: [],
  pageNumber: 0,
  pageSize: 25,
};

const mapStateToProps = (state) => ({
  member: state.member.member,
  loading: state.contact.loading,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setCallPartner }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);
