import { apiUrl } from './api';

export const apiPaths = {
  mail: {
    //get all emails
    getInbox: `${apiUrl}/mail/inbox`,
    //get email details
    getInboxEmailDetails: (email_id) => `${apiUrl}/mail/${email_id}`,
    //post new email
    postEmail: `${apiUrl}/mail/draft/send`,

    //get sent emails
    getSent: `${apiUrl}/mail/sent/list`,
    //get send email details
    getSendEmailDetails: (email_id) => `${apiUrl}/mail/sent/${email_id}`,

    //get starred emails
    getStarred: `${apiUrl}/mail/star/list`,
    //post email starred
    postEmailStar: `${apiUrl}/mail/star`,
    //get starrded email details
    getStarredEmailDetails: (email_id) => `${apiUrl}/mail/star/${email_id}`,
    //get trash emails
    getTrash: `${apiUrl}/mail/trash/list`,
    //get trash email details
    getTrashEmailDetails: (email_id) => `${apiUrl}/mail/trash/${email_id}`,

    //post email from trash to origin
    postMoveTrashToOrigin: `${apiUrl}/mail/trash/mv/origin`,
    //post email to trash
    postEmailToTrash: `${apiUrl}/mail/trash`,
    //post email from trash to archive
    postEmailFromTrashToArchive: `${apiUrl}/mail/trash/mv/archive`,
    //delete trash email
    deleteTrashEmail: (email_id) => `${apiUrl}/mail/trash/${email_id}`,

    //get archive emails
    getArchive: `${apiUrl}/mail/archive/list`,
    //get archive email details
    getArchiveEmailDetails: (email_id) => `${apiUrl}/mail/archive/${email_id}`,
    //post email to archive
    postEmailToArchive: `${apiUrl}/mail/archive`,

    //post email from archive to trash
    postEmailFromArchiveToTrash: `${apiUrl}/mail/archive/mv/trash`,
    //post email from archive to origin
    postMoveArchiveToOrigin: `${apiUrl}/mail/archive/mv/origin`,

    //post forward email
    postForwardEmail: `${apiUrl}/mail/forward`,

    //get all drafts emails
    getDraftEmails: `${apiUrl}/mail/draft/list`,
    //get draft email details
    getDraftEmailDetails: (email_id) => `${apiUrl}/mail/draft/get/${email_id}`,
    //post draft email
    postDraft: `${apiUrl}/mail/draft`,
    //delete drafted email
    deleteDraftedEmail: (email_id) => `${apiUrl}/mail/draft/${email_id}`,

    //attach files to email
    postAttach: `${apiUrl}/mail/attach`,
    //delete attached files
    deleteAttach: (email_id, attach_id) =>
      `${apiUrl}/mail/attach/${email_id}/${attach_id}`,

    //delete trash email PERMAMENTLY!
    deleteEmail: (email_id) => `${apiUrl}/mail/trash/${email_id}`,

    //get all settings for email
    getSettings: `${apiUrl}/mail/settings`,
    //post (update) settings
    updateSettings: `${apiUrl}/mail/settings`,

    //get signatures
    signatureList: `${apiUrl}/mail/sign/list`,
    //post signatures
    signatureUpdate: `${apiUrl}/mail/sign`,
    //delete signature
    signatureDelete: `${apiUrl}/mail/sign`,

    //post new folder
    postFolder: `${apiUrl}/mail/folders`,

    //get all folders
    getFolders: `${apiUrl}/mail/folders`,

    //post to change email folder
    postEmailToFolder: `${apiUrl}/mail/folders`,

    //delete folder
    deleteFolder: `${apiUrl}/mail/folders`,

    //move email to folder
    moveEmailToFolder: `${apiUrl}/mail/folders/mv`,

    getSelectableMembersInbox: '/mail/inbox/members',
    getSelectableMembersStarred: '/mail/star/members',
    getSelectableMembersDraft: '/mail/draft/members',
    getSelectableMembersSent: '/mail/sent/members',
    getSelectableMembersTrash: '/mail/trash/members',
    getSelectableMembersArchive: '/mail/archive/members',
  },
};
