import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InviteLine from '../../../../components/InviteLine';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

const InvitedMembers = ({
  groupInfo,
  invitations
}) => {

  return (
    <Grid item container alignItems="center" alignContent="center">
      <Grid item container xs={2} justify="center" alignItems="center">
        <Grid item>
          <PeopleAltIcon />
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid item>
          <Typography>
            {!!groupInfo
              ? `${groupInfo.group_name} invited members`
              : 'Invited contacts'}
          </Typography>
        </Grid>
        <Grid item>
          {invitations.map((invite) => (
            <InviteLine
              key={invite.invite_member_id}
              contactData={invite}
              inviteData={invite}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  groupList: state.group.groupList,
});

export default connect(mapStateToProps)(InvitedMembers);
