import * as api from '../../config/api';
import {
  // REMOVE_COMPANY_MEMBER,
  // ADD_COMPANY_MEMBER,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  LOAD_COMPANY,
  LOAD_COMPANIES,
  LOAD_UNREGISTERED_COMPANIES,
  DELETE_UNREGISTERED_COMPANY,
  UPDATE_UNREGISTERED_COMPANY,
  CREATE_COMPANY_FROM_NAME,
} from '../actionTypes/company';

const apiUrl = api.apiUrl;

export const getUnregisteredCompanies = () => {
  return function (dispatch) {
    const url = `${apiUrl}/company/unregistered`;
    return api
      .GET(url)
      .then((res) => {
        return dispatch({
          type: LOAD_UNREGISTERED_COMPANIES,
          payload: res.data,
        });
      })
      .catch((error) => {});
  };
};

export const getCompanyDetail = (companyId) => {
  return function (dispatch) {
    const url = `${apiUrl}/company/${companyId}`;
    return api.GET(url).then((res) => {
      return dispatch({
        type: LOAD_COMPANY,
        payload: res.data,
      });
    });
  };
};

export const createCompany = (formData) => {
  return function (dispatch) {
    const url = `${apiUrl}/company`;
    api
      .POST(url, formData, {})
      .then((res) => {
        return dispatch({
          type: CREATE_COMPANY,
          payload: res.data,
        });
      })
      .catch((error) => {});
  };
};

export const deleteCompanies = (data) => (dispatch) => {
  const url = `${apiUrl}/company`;
  return api
    .DELETE(url, data, {})
    .then((res) => {
      if (res.data) {
        return dispatch({
          type: DELETE_COMPANY,
          payload: res.data,
        });
      }
    })
    .catch((error) => {});
};

export const updateCompany = (companyId, formData) => {
  return function (dispatch) {
    const url = `${apiUrl}/company/${companyId}`;
    return api
      .PUT(url, formData, {})
      .then((res) => {
        return dispatch({
          type: UPDATE_COMPANY,
          payload: res.data,
        });
      })
      .catch((error) => {});
  };
};

// // remove member from company
export const removeCompanyMember = (formData) => {
  const url = `${apiUrl}/company/member`;
  return function (dispatch) {
    return api
      .DELETE(url, formData, {})
      .then((res) => {
        return dispatch({
          type: UPDATE_COMPANY,
          payload: res.data,
        });
      })
      .catch((error) => {});
  };
};

// add member to company
export const addCompanyMember = (formData) => async (dispatch) => {
  const url = `${apiUrl}/company/member`;
  try {
    const res = await api.POST(url, formData, {});
    if (res.success) {
      return dispatch({ type: UPDATE_COMPANY, payload: res.data });
    }
  } catch (error) {}
};

export const loadCompanies = () => async (dispatch) => {
  const url = `${apiUrl}/company`;
  try {
    const res = await api.GET(url);
    if (res.success) {
      return dispatch({ type: LOAD_COMPANIES, payload: res.data });
    }
  } catch (error) {}
};

export const deleteUnregisteredCompany = (companyName) => async (dispatch) => {
  const url = `${apiUrl}/company/unregistered`;
  const formData = new FormData();
  formData.append('company_name', companyName);
  try {
    const res = await api.DELETE(url, formData, {});
    if (res.success) {
      return dispatch({
        type: DELETE_UNREGISTERED_COMPANY,
        payload: companyName,
      });
    }
  } catch (error) {}
};

export const updateUnregisteredCompany = (
  companyName,
  newCompanyName
) => async (dispatch) => {
  const url = `${apiUrl}/company/unregistered`;
  const formData = new FormData();
  formData.append('company_name', companyName);
  formData.append('new_company_name', newCompanyName);
  try {
    const res = await api.PUT(url, formData, {});
    if (res.success) {
      return dispatch({
        type: UPDATE_UNREGISTERED_COMPANY,
        payload: {
          companyName,
          newCompanyName,
        },
      });
    }
  } catch (error) {}
};

export const createCompanyFromName = (companyName) => async (dispatch) => {
  const url = `${apiUrl}/company/unregistered`;
  const formData = new FormData();
  formData.append('company_name', companyName);
  try {
    const res = await api.POST(url, formData, {});
    if (res.success) {
      return dispatch({
        type: CREATE_COMPANY_FROM_NAME,
        payload: {
          company: res.data,
          companyName,
        },
      });
    }
  } catch (error) {}
};
