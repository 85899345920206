import { SET_SNACKBAR } from '../actionTypes/snackbar';

export const initialState = {
  snackbarData: {
    open: false,
    message: '',
    type: '',
  },
};

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      return {
        ...state,
        snackbarData: { ...action.payload },
      };
    default:
      return state;
  }
};

export default snackbar;
