import 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import Card from '@material-ui/core/Card';
import { MuiPickersUtilsProvider, Calendar } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { Button, CardActions, CardContent } from '@material-ui/core';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { getScheduleEvent } from '../../redux/actions/schedule_event';
import { getGroupList } from '../../redux/actions/group';
import Popover from '@material-ui/core/Popover';
import {
  CALENDAR_VIEWS,
  getMonthLimits,
  getParams,
  eventDisplayName,
  parseFromStr,
} from '../../utils/calendar';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import EventCreateModal from '../../components/Modal/EventModals/EventCreateModal';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { loadContacts } from '../../redux/actions/contact';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EventTitle from '../Calendar/EventTitle';

const useStyles = makeStyles((theme) => ({
  eventDay: {
    '& .MuiPickersDay-day': {
      backgroundColor: '#94c03d80',
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: '#94c03d',
    },
  },
  calendar: {
    '& .MuiPickersCalendar-week': {
      padding: 5,
    },
  },
  dayCalendar: {
    height: '350px !important',
    width: '400px !important',
    '& .rbc-event': {
      height: 'auto !important',
    },
    '& .rbc-event-content > div': {
      alignItems: 'center',
    },
  },
  dayTitle: {
    alignItems: 'center',
    display: 'inline-flex',
    margin: '15px 15px 0px 15px',
    fontSize: 16,
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
  },
  calendarButton: {
    marginLeft: 'auto',
    background: 'white',
    boxShadow: 'none',
    color: '#94c03d',
    border: `1px solid #94c03d`,
    '&:hover': {
      color: 'white',
      background: '#94c03d',
      boxShadow: 'none',
    },
  },
  createEvent: {
    margin: '15px 15px 0px 0px',
  },
  noEvent: {
    padding: '10px 15px 15px 15px',
  },
  eventRow: {
    padding: '0 5px',
    margin: '5px 0',
    '&:hover': {
      backgroundColor: '#f2f2f2',
      cursor: 'pointer',
    },
  },
  [theme.breakpoints.down(1550)]: {
    calendarButton: {
      marginTop: 16,
    },
  },
}));

const defaultCreateModalState = {
  isShow: false,
  startDateTime: null,
  endDateTime: null,
};

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
const DnDCalendar = withDragAndDrop(BigCalendar);

const CalendarWidget = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { events, dispatch, handleSelectEvent, memberId } = props;

  const [date, changeDate] = useState(new Date());
  const [anchorEl, setAnchorEl] = React.useState({
    target: null,
    value: null,
    date: null,
  });
  const [createModalOptions, setCreateModalOptions] = useState(
    defaultCreateModalState
  );

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const oneDayToMillisec = 86400000 - 1;

  const onOpenPicker = () => {};

  useEffect(() => {
    const params = getParams(CALENDAR_VIEWS.month, getMonthLimits(date));
    dispatch(getScheduleEvent(params));
  }, [date, dispatch]);

  useEffect(() => {
    dispatch(loadContacts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGroupList(true));
  }, [dispatch]);

  const hndlOpenDaySchedule = (date, event, data) => {
    event.preventDefault();
    setAnchorEl({
      target: event.currentTarget,
      value: data ? data.toString() : null,
      date: date,
    });
  };

  const hndlCloseDaySchedule = (event) => {
    event.preventDefault();
    setAnchorEl({ target: null, value: null, date: null });
  };

  const hndlCreateEvent = () => {
    const current = new Date();
    const start_datetime = new Date(
      anchorEl.date.getFullYear(),
      anchorEl.date.getMonth(),
      anchorEl.date.getDate(),
      current.getHours() + 1,
      0,
      0
    );
    const end_dateTime = new Date(
      anchorEl.date.getFullYear(),
      anchorEl.date.getMonth(),
      anchorEl.date.getDate(),
      current.getHours() + 1,
      30,
      0
    );

    setCreateModalOptions({
      isShow: true,
      startDateTime: start_datetime,
      endDateTime: end_dateTime,
    });
  };
  const renderDateElem = (date, event_name, dayComponent) => {
    if (!event_name) {
      return (
        <div
          className={classes.noEventDay}
          onClick={(event) => hndlOpenDaySchedule(date, event, event_name)}
        >
          {dayComponent}
        </div>
      );
    }
    return (
      <div
        className={classes.eventDay}
        onClick={(event) => hndlOpenDaySchedule(date, event, event_name)}
      >
        {dayComponent}
      </div>
    );
  };
  const renderDayInPicker = (
    date,
    selectedDate,
    dayInCurrentMonth,
    dayComponent
  ) => {
    for (const sch_event of events) {
      const event_start_datetime = Date.parse(
        utcToZonedTime(sch_event.start, tz)
      );
      const event_end_datetime = Date.parse(utcToZonedTime(sch_event.end, tz));
      const current__start_datetime = Date.parse(date);
      const current__end_datetime = current__start_datetime + oneDayToMillisec;

      if (
        event_end_datetime < current__start_datetime ||
        current__end_datetime < event_start_datetime
      )
        continue;
      return renderDateElem(
        date,
        eventDisplayName(sch_event, memberId),
        dayComponent
      );
    }
    return renderDateElem(date, null, dayComponent);
  };

  const handleChangeDate = (day) => {
    changeDate(new Date(day));
  };

  const renderPopover = (id, open) => {
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl.target}
        onClose={hndlCloseDaySchedule}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container justify="space-between">
          <span className={classes.dayTitle}>
            {anchorEl.date ? format(anchorEl.date, 'PPP') : ''}
          </span>
          <Button
            className={classes.createEvent}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddCircleOutlineIcon />}
            onClick={hndlCreateEvent}
          >
            create event
          </Button>
        </Grid>
        <div className="big_calendar">
          <DnDCalendar
            startAccessor={(e) => e && parseFromStr(e.start, tz)}
            endAccessor={(e) => e && parseFromStr(e.end, tz)}
            titleAccessor={(e) => <EventTitle event={e} memberId={memberId} />}
            defaultView={CALENDAR_VIEWS.day}
            localizer={localizer}
            popup={false}
            events={events}
            date={anchorEl.date}
            onNavigate={(e) => anchorEl.date}
            onSelectEvent={handleSelectEvent}
            selectable
            className={classes.dayCalendar}
            toolbar={false}
            scrollToTime={new Date()}
          />
        </div>
      </Popover>
    );
  };

  const open = Boolean(anchorEl.target);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Card>
      <CardContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={classes.calendar}>
            <Calendar
              date={date}
              onMonthChange={handleChangeDate}
              onChange={handleChangeDate}
              renderDay={renderDayInPicker}
              onOpen={onOpenPicker}
              allowKeyboardControl={open ? false : true}
              {...props}
            />
          </div>
        </MuiPickersUtilsProvider>
        {createModalOptions.isShow && (
          <EventCreateModal
            show={createModalOptions.isShow}
            onClose={() => setCreateModalOptions(defaultCreateModalState)}
            inputs={createModalOptions}
            displayTz={tz}
          />
        )}
        {open && renderPopover(id, open)}
      </CardContent>
      <CardActions>
        <Button
          className={classes.calendarButton}
          variant="contained"
          color="primary"
          startIcon={<EventAvailableIcon />}
          onClick={() => history.push(`${process.env.PUBLIC_URL}/calendar`)}
        >
          Go To Calendar
        </Button>
      </CardActions>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  events: state.scheduleEvents.data,
  memberId: state.member.member.member_id,
  groupList: state.group.groupList,
});

export default connect(mapStateToProps)(CalendarWidget);
