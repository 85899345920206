export const SET_CALL_PARTNER = 'SET_CALL_PARTNER';
export const SET_REMOTE_STREAM = 'SET_REMOTE_STREAM';
export const SET_LOCAL_STREAM = 'SET_LOCAL_STREAM';
export const SET_LOCAL_DEVICES = 'SET_LOCAL_DEVICES';
export const SET_REMOTE_DEVICES = 'SET_REMOTE_DEVICES';
export const SET_CURRENT_KEY = 'SET_CURRENT_KEY';
export const SET_ENCRYPT_ENABLED = 'SET_ENCRYPT_ENABLED';
export const SET_LOCAL_SELECTED = 'SET_LOCAL_SELECTED';
export const SET_CALL_STARTED = 'SET_CALL_STARTED';
export const SET_HAS_ECHO_CANCELLATION = 'SET_HAS_ECHO_CANCELLATION';
export const SET_LOCAL_AUDIO_ENABLED = 'SET_LOCAL_AUDIO_ENABLED';
export const SET_LOCAL_VIDEO_ENABLED = 'SET_LOCAL_VIDEO_ENABLED';
export const SET_REMOTE_ENCRYPT_ENABLED = 'SET_REMOTE_ENCRYPT_ENABLED';
export const SET_USER_LIST = 'SET_USER_LIST';
export const SIGNALING_SERVER_LOGIN = 'SIGNALING_SERVER_LOGIN';
export const SET_CALL_MEMBER_ID = 'SET_CALL_MEMBER_ID';
export const SET_MEMBER_INFO = 'SET_MEMBER_INFO';
export const CLEAR_CALL = 'CLEAR_CALL';
export const SET_CALL_ENDED_BY_REMOTE = 'SET_CALL_ENDED_BY_REMOTE';
export const SET_ENCRYPTION_AVAILABLE = 'SET_ENCRYPTION_AVAILABLE';
