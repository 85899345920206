import React from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PlaceInfo from './PlaceInfo';
import redPin from '../../../assets/img/red-dot.png';
import celeryPin from '../../../assets/img/celery-dot.png';

const LocationMapMarker = (props) => {
  const {
    place,
    onMarkerClick,
    onInfoClose,
    isInfoOpen,
    isSelected,
    onSelectClick,
  } = props;
  const {
    geometry,
    // icon,
    // name,
    // photos,
    // place_id,
    // price_level,
    // rating,
    // vicinity,
    // user_ratings_total,
  } = place;
  const { location } = geometry;

  return (
    <Marker
      icon={
        // FIXME: Change this later to show different marker
        isSelected
          ? { url: celeryPin, scaledSize: new window.google.maps.Size(35, 35) }
          : { url: redPin, scaledSize: new window.google.maps.Size(35, 35) }
      }
      onClick={onMarkerClick}
      position={location}
    >
      {isInfoOpen && (
        <InfoWindow onCloseClick={onInfoClose}>
          <Grid container direction="column" style={{ width: '15rem' }}>
            <PlaceInfo place={place} isSelected={isSelected} />
            <Grid item container justify="flex-end" xs={12}>
              {!!onSelectClick && (
                <Button
                  onClick={() => {
                    onSelectClick(place);
                    onInfoClose();
                  }}
                >
                  Select
                </Button>
              )}
              <Button onClick={onInfoClose}>Close</Button>
            </Grid>
          </Grid>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default LocationMapMarker;
