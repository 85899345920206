import React, { useState } from 'react';
import ImageDialog from '../../../ImageDialog';
import Grid from '@material-ui/core/Grid';

const EventCover = ({ imageUrl }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      onClick={() => {
        setOpen(true);
      }}
      wrap="nowrap"
      style={{
        height: '14rem',
        overflow: 'hidden',
        marginBottom: '0.8rem',
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <img
        src={imageUrl}
        alt=""
        style={{
          width: '100%',
          height: 'fit-content',
          position: 'relative',
          bottom: '4rem',
        }}
      />
      {isOpen && (
        <ImageDialog
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          imageUrl={imageUrl}
        />
      )}
    </Grid>
  );
};

export default EventCover;
