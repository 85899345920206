import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import GroupPreview from './GroupPreview/GroupPreview';
import { getGroupList } from '../redux/actions/group';

const GroupAutocomplete = (props) => {
  const {
    dispatch,
    groupOptions,
    isShowOnlyLeader = false,
    handleChange,
    label,
    disablerFunction,
    variant = 'standard',
    disabled = false,
    multiple = true,
    values,
  } = props;

  useEffect(() => {
    // Depending on the flag we fetch either all groups (where leader and where member) OR only the groups where we are leader
    dispatch(getGroupList(!isShowOnlyLeader));
  }, [dispatch, isShowOnlyLeader]);

  return (
    <Autocomplete
      multiple={multiple}
      id="groups"
      disabled={disabled}
      options={groupOptions}
      getOptionSelected={(o, v) => o.group_id === v.group_id}
      getOptionLabel={(o) => (!!o ? o.group_name : null)}
      getOptionDisabled={(o) => (!!disablerFunction ? disablerFunction(o) : o)} //Disables groups that already have one of the selected files shared with em
      renderOption={(o) => <GroupPreview groupData={o} />}
      onChange={handleChange}
      value={values}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder="AMERA Groups"
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  groupOptions: state.group.groupList,
  // member_id: state.member.member.member_id,
});

export default connect(mapStateToProps)(GroupAutocomplete);
