import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import NotApplicableCheck from './NotApplicableCheck';

const CompanyNameInput = ({
  companyName,
  errors,
  onChange,
  onToggle,
  isApplicable,
}) => {
  return (
    <TextField
      id="company-name"
      label="Company Name"
      type="text"
      variant="outlined"
      required
      name="company_name"
      disabled={!!onToggle && !isApplicable}
      placeholder="Please enter your company name here"
      className="register_form_input"
      onChange={onChange}
      value={companyName || ''}
      error={errors ? true : false}
      InputProps={{
        endAdornment: !!onToggle && (
          <InputAdornment postion="end">
            <NotApplicableCheck isChecked={!isApplicable} onChange={onToggle} />
          </InputAdornment>
        ),
      }}
      helperText={errors ? errors : ''}
    />
  );
};

export default CompanyNameInput;
