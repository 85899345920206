import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter } from 'connected-react-router';
import history from '../../utils/history';

import app from './app.js';
import global from './globalReducer';
import fileshare from './fileshare';
import member from './member';
import group from './group';
import contact from './contact';
import chat from './chat';
import event from './event';
import snackbar from './snackbar';
import mail from './mail';
import schedulerSetting from './scheduler_setting';
import scheduleEvents from './schedule_event';
import one2onevcall from './one2onevcall';
import VideoCallRedux from './VideoCallRedux';
import reportBugs from './reportBugs';
import company from './company';

const appReducer = combineReducers({
  app,
  global,
  fileshare,
  member: persistReducer(
    {
      key: 'member',
      storage,
      blacklist: [
        'isLoading',
        'isFetching',
        'isUpdated',
        'isSuccess',
        'error',
        'isSubmittingRegisterForm',
        'isLoadingVerification',
        'notificationsSetting',
      ],
    },
    member
  ),
  group,
  contact,
  chat: persistReducer(
    {
      key: 'chat',
      storage,
      blacklist: ['loadingConversations', 'loadingMessages', 'error'],
    },
    chat
  ),
  one2onevcall,
  event,
  schedulerSetting,
  scheduleEvents,
  snackbar,
  mail,
  VideoCallRedux,
  reportBugs,
  company,
  router: connectRouter(history),
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'APP_RESET') {
    localStorage.removeItem('persist:root');
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
