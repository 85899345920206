import React from 'react';
import TextField from '@material-ui/core/TextField';

const PostalInput = (props) => {
  const { onPostalChange, postalCode, errors, state, province, city } = props;

  let helperText;

  const subLocality = state ? state : province;

  if (errors) {
    helperText = errors;
  } else if (!subLocality && !city) {
    helperText = null;
  } else if (subLocality && !city) {
    helperText = subLocality;
  } else if (!subLocality && city) {
    helperText = city;
  } else {
    helperText = `${city}, ${subLocality}`;
  }

  return (
    <TextField
      id="outlined-postal_code"
      label={'Postal Code'}
      type="text"
      required
      variant="outlined"
      name="postal_code"
      placeholder="Postal / ZIP code"
      className="register_form_input"
      onChange={onPostalChange}
      autoComplete="off"
      value={postalCode || ''}
      error={errors ? true : false}
      helperText={helperText}
    />
  );
};

export default PostalInput;
