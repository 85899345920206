import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Modal from '../../components/Modal/modal';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
}));

const Payments = (props) => {
  const { open, onClose } = props;
  const title = 'Payment Settings';
  const classes = useStyles();

  const ModalContent = () => <div className="profile_page-body"></div>;
  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          disableRipple
          className={classes.actionBtn}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      ></Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
