import { SET_BAGE_COUNT } from '../actionTypes/mail';

// The initial state of the App
export const initialState = {
  mailBadgeCount: 0,
};

const mail = (state = initialState, action) => {
  switch (action.type) {
    case SET_BAGE_COUNT:
      return {
        ...state,
        mailBadgeCount: action.payload,
      };
    default:
      return state;
  }
};

export default mail;
