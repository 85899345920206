import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Button from '@material-ui/core/Button';
import UI from './components/Conference/modules/UI/UI';
// import { loadReCaptcha } from 'react-recaptcha-v3';
import { vacall } from './components/Conference/global';
import keyboardshortcut from './components/Conference/modules/keyboardshortcut/keyboardshortcut';
import history from './utils/history';
import MainRouter from './MainRouter';
import './styles/index.scss';

import { nonBrowserFeatureState } from './utils/non-browser-state';
import Debug from './components/Modal/Debug';
import { getVersionFromMain, isSecuritySetInMain } from './utils/electron';
import SaveData from './components/Modal/SaveData';
import { SanackBarMessage } from './components/Modal/CustomSnackBar';
let version;
if (nonBrowserFeatureState) {
  getVersionFromMain()
    .then(function (v) {
      version = v;
    })
    .catch((e) => {
      console.log(e);
    });
}
window.APP = {
  UI,
  vacall,
  keyboardshortcut,
};

const App = () => {
  // const callback = () => {
  //   console.log('Captcha loaded');
  // };

  // useEffect(() => {
  //   loadReCaptcha('6LfFIb0ZAAAAAJMPqY5GzAWyjWOrvWg9v1a0754b', callback);
  // }, []);
  const [showDebugModal, setShowDebugModal] = useState(false);
  const [showSaveDataModal, setShowSaveDataModal] = useState(false);

  useEffect(() => {
    console.log('Checking pathname and browserFeatureState');
    if (
      !showSaveDataModal &&
      nonBrowserFeatureState &&
      window.location &&
      window.location.pathname.indexOf('/login') === -1
    ) {
      console.log('Checking security');
      isSecuritySetInMain().then((res) => {
        if (res === true) {
          console.log(`Security is missing: ${res}`);
          setShowSaveDataModal(true);
        }
      });
    }
  });

  return (
    <>
      <SanackBarMessage />
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={MainRouter} />
        </Switch>
      </ConnectedRouter>
      {nonBrowserFeatureState && (
        <div className="debug-container" style={{ zIndex: 1200 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => setShowDebugModal(true)}
          >
            Debug {version && ` - Version: ${version}`}
          </Button>
          <Debug
            show={showDebugModal}
            close={() => setShowDebugModal(false)}
          ></Debug>
          <SaveData
            show={showSaveDataModal}
            close={() => setShowSaveDataModal(false)}
            type={'member'}
          ></SaveData>
        </div>
      )}
    </>
  );
};

export default App;
