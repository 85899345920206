import * as api from '../../config/api';
import { store } from '../../utils/store';
import { fetchTodos } from '../../config/apiService/Fakeapi';
import { openCallWindow } from '../../utils/general';
import { formatInTimeZone } from '../../utils/calendar';
import { push } from 'connected-react-router';

import {
  GET_MEMBER_EVENTS,
  FETCH_ACTIVITIES_START,
  UPDATE_ACTIVITIES,
  FETCH_ACTIVITIES_FAILED,
  GET_COMPOSE,
  ANSWER_CALL,
  INCOMING_CALL,
  CALL_REPLY,
  GROUP_CALL_REPLY,
  SET_REPLY_MESSAGE,
  SET_CALL_GROUP_ID,
  GET_UPCOMING_EVENTS_SUCCEEDED,
  GET_UPCOMING_EVENTS_FAILED,
  GET_EVENT_INVITATIONS_SUCCEEDED,
  GET_EVENT_INVITATIONS_FAILED,
} from '../actionTypes/event';

import { setCallPartnerMemberId } from './one2onevcall';

const apiUrl = api.apiUrl;
const notificationsUrl = `${apiUrl}/web-notifications`;

/*
  START SSE API
*/

let es = null; // Need reference to EventSource to remove eventListeners on logout
const pingHandler = async (msg) => {
  console.debug(`Recieved PING ${msg.data}`);
};
const notifyHandler = async (msg) => {
  console.debug(`Recieved NOTIFY`);

  const data = JSON.parse(msg.data);
  const state = await store.getState();
  const member_id = state.member.member.member_id;
  if (data.reply_type) {
    if (data.type === 'group' && data.caller_id === member_id) {
      const groupCallReplies = state.event.groupCallReplies;
      const newData = [...groupCallReplies, data];
      store.dispatch({ type: GROUP_CALL_REPLY, payload: newData });
    } else store.dispatch({ type: CALL_REPLY, payload: data });
  } else {
    store.dispatch({ type: INCOMING_CALL, payload: data });
  }
};

// Got activity

export const sendNotification = (data) => {
  console.debug('notify', data);
  const url = `${notificationsUrl}/notify`;
  // const url = `${apiUrl}/notify`;
  api
    .POST(url, JSON.stringify(data), { 'Content-Type': 'application/json' })
    .then((res) =>
      res.status === '200'
        ? console.debug('Notification sent')
        : console.debug('Something went wrong when sending notifications')
    )
    .catch((err) => console.error(err));
};

export const subscribeToSSE = (memberId) => {
  es = new EventSource(`${notificationsUrl}/subscribe/${memberId}`, {
    withCredentials: true,
  });
  es.onopen = () => {
    // console.debug('The ready state is', es.readyState);
    // console.debug(es);
    es.addEventListener('PING', pingHandler);
    es.addEventListener('NOTIFY', notifyHandler);
  };

  es.onerror = (err) => {
    es.removeEventListener('PING', pingHandler);
    es.removeEventListener('NOTIFY', notifyHandler);
    console.debug(err);
  };
};

export const unsubscribeSSE = () => {
  console.debug('Unsubscribing from SSE');
  es.removeEventListener('PING', pingHandler);
  es.removeEventListener('NOTIFY', notifyHandler);
  es.close();
};

/*
  END SSE API
*/

export const answerCall = (callData) => {
  return function (dispatch) {
    console.debug('Firing ANSWER_CALL', callData.call_url);

    openCallWindow(callData.call_url);
    dispatch({ type: ANSWER_CALL, payload: callData });
  };
};

export const answerOne2oneCall = (callData, answerData) => {
  return function (dispatch) {
    console.debug('Firing ANSWER_CALL', callData.call_url);
    dispatch({ type: ANSWER_CALL, payload: callData });
    if (answerData.reply_type === 'decline') {
      sendNotification({
        ...callData,
        reply_type: answerData.reply_type,
        callee_id: answerData.member_id,
      });
    } else {
      dispatch({
        type: SET_REPLY_MESSAGE,
        payload: {
          ...callData,
          reply_type: answerData.reply_type,
          callee_id: answerData.member_id,
        },
      });
      dispatch(setCallPartnerMemberId(callData.caller_id));
      dispatch(
        push(
          `${process.env.PUBLIC_URL}/contacts/call/video/${callData.caller_id}`
        )
      );
    }
  };
};

export const answerGroupCall = (callData, answerData) => {
  return async function (dispatch) {
    console.log('Firing ANSWER_CALL', callData.call_url);
    await dispatch({ type: ANSWER_CALL, payload: callData });
    if (answerData.reply_type === 'decline') {
      sendNotification({
        ...callData,
        reply_type: answerData.reply_type,
        callee_id: answerData.member_id,
      });
    } else {
      await dispatch({
        type: SET_REPLY_MESSAGE,
        payload: {
          ...callData,
          reply_type: answerData.reply_type,
          callee_id: answerData.member_id,
        },
      });

      await dispatch({ type: SET_CALL_GROUP_ID, payload: callData.group_id });
      await dispatch(openCallWindow(callData.call_url));
    }
  };
};

export const answerGroupVideoCall = (callData, answerData) => {
  return async function (dispatch) {
    console.log('Firing ANSWER_CALL', callData.call_url);
    await dispatch({ type: ANSWER_CALL, payload: callData });
    if (answerData.reply_type === 'decline') {
      sendNotification({
        ...callData,
        reply_type: answerData.reply_type,
        callee_id: answerData.member_id,
      });
    } else {
      await dispatch({
        type: SET_REPLY_MESSAGE,
        payload: {
          ...callData,
          reply_type: answerData.reply_type,
          callee_id: answerData.member_id,
        },
      });
      await dispatch({ type: SET_CALL_GROUP_ID, payload: callData.group_id });
      await dispatch(push(`${process.env.PUBLIC_URL}${callData.call_url}`));
    }
  };
};

// FIXME: Here we'll hadnle voicemail
export const callToVoiceMail = (callData) => {
  return function (dispatch) {
    console.debug(
      `Will initiate recording call ${callData.call_id} to voice mail`
    );
    dispatch({ type: ANSWER_CALL, payload: callData });
  };
};

export const getMemberEvents = () => {
  const url = 'member-event';
  return function (dispatch) {
    fetchTodos(url).then((res) => {
      dispatch({
        type: GET_MEMBER_EVENTS,
        payload: res,
      });
    });
  };
};

export const getNotifications = () => (dispatch) => {
  const url = `${apiUrl}/member/activity`;
  dispatch(fetchActivitiesRequestStart());
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.activities) {
        if (res.activities.invitations && res.activities.invitations.data) {
          dispatch(
            updateActivities({
              type: 'invitations',
              data: res.activities.invitations.data,
            })
          );
        }
        if (res.activities.mails && res.activities.mails.data) {
          dispatch(
            updateActivities({
              type: 'mails',
              data: res.activities.mails.data,
            })
          );
        }
      } else {
        dispatch(fetchActivitiesRequestFailed(null));
      }
    })
    .catch((error) => {
      console.debug(error);
      dispatch(fetchActivitiesRequestFailed(error));
    });
};

export const getSessions = () => (dispatch) => {
  let isCanSeeAllSessions = false;
  let uri = `?get_all=${isCanSeeAllSessions}&page_number=0&page_size=10&sort=-create_date`;
  const url = `${api.apiUrl}/system/activity/session${uri}`;
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.activities) {
        let data = {
          type: 'sessions',
          data: res.activities,
        };
        dispatch(updateActivities(data));
      } else {
        dispatch(fetchActivitiesRequestFailed(null));
      }
    })
    .catch((error) => {
      console.debug(error);
    });
};

export const getThreats = () => (dispatch) => {
  let isCanSeeAllSessions = false;
  let uri = `?get_all=${isCanSeeAllSessions}&page_number=0&page_size=10&sort=-create_date`;
  const url = `${api.apiUrl}/system/activity/threat${uri}`;
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.activities) {
        let data = {
          type: 'alerts',
          data: res.activities,
        };
        dispatch(updateActivities(data));
      } else {
        dispatch(fetchActivitiesRequestFailed(null));
      }
    })
    .catch((error) => {
      console.debug(error);
    });
};

export const getCompose = (compose) => {
  return function (dispatch) {
    dispatch({
      type: GET_COMPOSE,
      payload: compose,
    });
  };
};

export const clearReplyMessage = () => ({
  type: SET_REPLY_MESSAGE,
  payload: null,
});

export const fetchActivitiesRequestStart = () => ({
  type: FETCH_ACTIVITIES_START,
});
export const updateActivities = (payload) => ({
  type: UPDATE_ACTIVITIES,
  payload,
});
export const fetchActivitiesRequestFailed = (payload) => ({
  type: FETCH_ACTIVITIES_FAILED,
  payload,
});

export const acceptInvitation = (contact_member_id, status) => {
  const url = `${apiUrl}/member/contact/request/${contact_member_id}`;
  const data = JSON.stringify({ status });
  return (dispatch) => {
    return api
      .PUT(url, data, { 'Content-Type': 'application/json' })
      .then((res) => {
        if (res.success) {
          dispatch(getNotifications());
        }
        return res.success;
      })
      .catch(() => {
        return false;
      });
  };
};

export const setGroupCallReplies = (groupCallReplies) => ({
  type: GROUP_CALL_REPLY,
  payload: groupCallReplies,
});

export const getUpcomingEvents = () => {
  console.debug('Upcoming events');
  const current = formatInTimeZone(new Date(), 'UTC');
  const limit = 10;

  return (dispatch) => {
    const url = `${apiUrl}/member/event/upcoming?current=${current}&limit=${limit}`;

    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          dispatch({ type: GET_UPCOMING_EVENTS_SUCCEEDED, payload: res.data });
        } else {
          dispatch({
            type: GET_UPCOMING_EVENTS_FAILED,
            payload: res.description,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_UPCOMING_EVENTS_FAILED, payload: error });
      });
  };
};

export const getEventInvitations = () => {
  console.debug('Invite events');

  return (dispatch) => {
    const url = `${apiUrl}/member/event/invite`;

    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          dispatch({
            type: GET_EVENT_INVITATIONS_SUCCEEDED,
            payload: res.data,
          });
        } else {
          dispatch({
            type: GET_EVENT_INVITATIONS_FAILED,
            payload: res.description,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_EVENT_INVITATIONS_FAILED, payload: error });
      });
  };
};

export const setEventInviteStatus = (event_invite_id, status, comment) => {
  console.debug('set event invitation status');

  return (dispatch) => {
    const url = `${apiUrl}/member/event/invite/${event_invite_id}`;
    const data = JSON.stringify({ status, comment });

    return api
      .PUT(url, data, { 'Content-Type': 'application/json' })
      .then((res) => {
        if (res.success) {
          dispatch(getUpcomingEvents());
          dispatch(getEventInvitations());
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
};

export const acceptGroupInvitation = (groupId, status) => {
  const url = `${apiUrl}/member/group/membership/request/${groupId}`;
  const data = JSON.stringify({ status });
  return (dispatch) => {
    return api
      .PUT(url, data, { 'Content-Type': 'application/json' })
      .then(() => {
        return dispatch(getNotifications());
      })
      .catch(() => {});
  };
};

export const setCallGroupId = (call_group_id) => ({
  type: SET_CALL_GROUP_ID,
  payload: call_group_id,
});
