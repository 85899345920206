import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

const ErrorFallback = (props) => {
  // We can add resetErrorBoundary prop as well to trigger some action that would reset state or smth like that
  const { error } = props;
  const [isShow, setShow] = useState(false);

  let content;
  if (isShow) {
    content = (
      <>
        <code id="message">{error.message}</code>
        <code>{error.stack}</code>
      </>
    );
  } else {
    content = null;
  }

  return (
    <div className="error-fallback" role="alert">
      <h5>
        Something went wrong. Please contact AMERA Share support and provide
        them the following information.
      </h5>
      <Button
        className="hide-btn"
        variant="standard"
        color="primary"
        onClick={() => setShow((ps) => !ps)}
      >
        {isShow ? 'Hide details' : 'Show details'}
      </Button>
      {content}
      {/* <button onClick={resetErrorBoundary}>Try again</button> */}
    </div>
  );
};

export default ErrorFallback;
