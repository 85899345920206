import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import InputBase from '@material-ui/core/InputBase';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

import MaterialAvatar from '../../../components/MaterialAvatar';
import { textCapitalized } from '../../../utils/general';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    settingsButton: {
      padding: theme.spacing(0, 1, 0, 1),
      marginTop: theme.spacing(-1),
    },
    searchContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    search: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      backgroundColor: fade(theme.palette.common.white, 1),
      borderRadius: 5,
    },
    inputSearch: {
      marginLeft: theme.spacing(1),
      flex: 1,
      fontFamily: 'Roboto',
    },
    searchIcon: {
      padding: theme.spacing(1.2, 1.2, 0.5, 1.2),
    },
  })
);

function ProfileTitle(props) {
  const { memberInfo, memberTitle, memberAddress } = props;
  return (
    <div className="profile_title-container">
      <p className="profile-name">{`${textCapitalized(
        memberInfo.first_name
      )} ${textCapitalized(memberInfo.last_name)}`}</p>
      <p className="profile-company">{memberInfo.company_name}</p>
      <p className="profile-role">{memberTitle}</p>
      {/* <p className="profile-role">{memberInfo.role}</p> */}
      <p className="profile-location">{memberAddress}</p>
    </div>
  );
}

function MyProfile(props) {
  const classes = useStyles();
  const { memberState } = props;
  let { memberInfo } = memberState;
  const { jobTitleList } = memberState;

  if (memberInfo === null) {
    memberInfo = {};
  }

  const {
    first_name,
    last_name,
    amera_avatar_url,
    job_title_id,
    job_title,
    location_information,
  } = memberInfo;

  // Home, fallback for first if no home address TODO: there should general function to get location info for Myprofile Modal, contact Modal and here as well
  let city = 'N/A';
  let state = 'N/A';
  let province = 'N/A';
  let postal = 'N/A';
  let country_code_id = 849;

  if (location_information[0]) {
    const homeAddress = location_information.find(
      (lio) => lio.location_type === 'home'
    );
    if (homeAddress) {
      city = homeAddress.city;
      state = homeAddress.state;
      province = homeAddress.province;
      postal = homeAddress.postal;
      country_code_id = homeAddress.country_code_id;
    } else {
      city = location_information[0].city;
      state = location_information[0].state;
      province = location_information[0].province;
      postal = location_information[0].postal;
      country_code_id = location_information[0].country_code_id;
    }
  }

  // Prepare job title
  let memberJobTitle = '';
  if (job_title) {
    memberJobTitle = job_title;
  } else if (job_title_id && jobTitleList) {
    memberJobTitle = jobTitleList.find(
      (jto) => jto.job_title_id === job_title_id
    );
    if (memberJobTitle) {
      memberJobTitle = memberJobTitle.job_title || '';
    }
  }

  //Prepare member address
  const memberAddress = `${city ? city : null}, ${
    country_code_id === 840 ? state : province
  }, ${postal} `;

  const onSearch = (e) => {
    const searchKey = e.target.value;
    console.log('searching user ==> ', searchKey);
  };

  return (
    <Card className="chat-my-profile">
      <CardHeader
        className="chat-profile-card-header"
        avatar={
          <MaterialAvatar
            badgeType="none"
            classes="chat-my-profile-avatar"
            src={amera_avatar_url}
            firstName={first_name}
            lastName={last_name}
          />
        }
        action={
          <Tooltip title="More">
            <IconButton
              aria-label="settings"
              className={classes.settingsButton}
            >
              <MoreHoriz className="chat-settings-icon" />
            </IconButton>
          </Tooltip>
        }
        title={
          <ProfileTitle
            memberInfo={memberInfo}
            memberTitle={memberJobTitle}
            memberAddress={memberAddress}
          />
        }
      />
      <CardContent className="chat-profile-card-content">
        <div className={classes.searchContainer}>
          <div component="form" className={classes.search}>
            <InputBase
              className={classes.inputSearch}
              placeholder="Search"
              onChange={onSearch}
              inputProps={{ 'aria-label': 'search user chats' }}
            />
            <div aria-label="search-icon" className={classes.searchIcon}>
              <SearchIcon />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  memberState: state.member,
  jobTitleList: state.member.jobTitleList,
});

export default connect(mapStateToProps)(MyProfile);
