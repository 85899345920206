/* global APP */

import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { IconFullScreen } from '../icons';
import UIEvents from '../service/UI/UIEvents';
import { injectIntl } from 'react-intl';
/**
 * FullScreen
 */

export class FullScreen extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      FullScreen: false,
    };
  }
  componentDidMount() {
    APP.UI.addListener(UIEvents.FULL_SCREEN, () => {
     this._setFullScreen();
    });
  }

  _setFullScreen = () => {
    var full = this.state.FullScreen;
    if (typeof APP === 'object') {
      if (!full) {
        const documentElement = document.documentElement || {};

        if (typeof documentElement.requestFullscreen === 'function') {
          documentElement.requestFullscreen();
        } else if (typeof documentElement.mozRequestFullScreen === 'function') {
          documentElement.mozRequestFullScreen();
        } else if (
          typeof documentElement.webkitRequestFullscreen === 'function'
        ) {
          documentElement.webkitRequestFullscreen();
        }
        this.setState({ FullScreen: true });
      } else {
        /* eslint-disable no-lonely-if */

        // $FlowFixMe
        if (typeof document.exitFullscreen === 'function') {
          document.exitFullscreen();

          // $FlowFixMe
        } else if (typeof document.mozCancelFullScreen === 'function') {
          document.mozCancelFullScreen();

          // $FlowFixMe
        } else if (typeof document.webkitExitFullscreen === 'function') {
          document.webkitExitFullscreen();
        }
        this.setState({ FullScreen: false });
      }
    }
  };
  render() {
    console.log(APP);
    const {intl} = this.props;
    const messages = {
      fullscr: {
        id: 'app.home.fullscr',
        defaultMessage: 'View full screen',
      },
      exitfullscr: {
        id: 'app.home.exitfullscr',
        defaultMessage: 'Exit full screen',
      }      
    };
    return (
      <Dropdown.Item
        text={this.props.text}
        eventKey={this.props.eveneKey}
        onClick={this._setFullScreen}
      >
        <div className="amera-icon">
          <IconFullScreen />
        </div>
        <span>
          {this.state.FullScreen ? intl.formatMessage(messages.exitfullscr) : intl.formatMessage(messages.fullscr)}
        </span>
      </Dropdown.Item>
    );
  }
}
//export default FullScreen;
export default injectIntl(FullScreen);
