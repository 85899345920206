import React from 'react';
import List from '@material-ui/core/List';
import PhoneListItem from './PhoneListItem/PhoneListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';

const PhoneList = (props) => {
  const {
    errors,
    primaryPhoneId,
    phoneItems,
    userCountry,
    handleChange,
    handlePhoneInputChanges,
    handleDelete,
    handleAdd,
  } = props;
  return (
    <div className="phone-list-container">
      <List
        className="phone-list"
        subheader={
          <ListSubheader disableSticky className="list-header">
            Phone list
          </ListSubheader>
        }
      >
        {phoneItems && phoneItems.length > 0 ? (
          phoneItems.map((pi) => (
            <PhoneListItem
              errors={errors ? errors[pi.id] : null}
              key={pi.id}
              handlePhoneInputChanges={handlePhoneInputChanges}
              isPrimary={pi.id === primaryPhoneId}
              phoneData={pi}
              phoneCount={phoneItems.length}
              userCountry={userCountry}
              handleChange={handleChange}
              handleDelete={handleDelete}
            />
          )) // When no record - show fallback message
        ) : (
          <Typography variant="body2">Please create a record</Typography>
        )}
      </List>
      <Button
        startIcon={<AddCircleIcon />}
        onClick={handleAdd}
        disabled={
          phoneItems &&
          phoneItems.length === parseInt(process.env.REACT_APP_MAX_PHONES)
        }
      >
        Add
      </Button>
    </div>
  );
};

export default PhoneList;
