import React from 'react';
import { PropTypes } from 'prop-types';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import ReplyIcon from '@material-ui/icons/Reply';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import { displayableFullName } from '../../../utils/contact';

import MaterialAvatar from '../../../components/MaterialAvatar';
import {
  acceptInvitation,
  acceptGroupInvitation,
} from '../../../redux/actions/event';
import { setSnackbarData } from '../../../redux/actions/snackbar';
import { useHistory } from 'react-router-dom';

const MeetingCard = ({ notification, type, invitation_type, setHeight }) => {
  let history = useHistory();
  let message = notification.message;
  let subject;
  let title = 'New Notification';
  if (type === 'mails') {
    subject = notification.request_data.subject.replace(/<[^>]+>/g, '');
    message = notification.request_data.body.replace(/<[^>]+>/g, '');
    title = 'New Email';
  }

  let name = !!notification.first_name
    ? `${displayableFullName(notification)}`
    : null;
  if (type === 'invitations') {
    if (invitation_type === 'add-contact') {
      subject = `${name} added you to their contact list.`;
      title = 'Contact Invite';
    } else if (invitation_type === 'create-group') {
      subject = `${name} added you to a new group "${notification.request_params.name}".`;
      title = 'New Group Invite';
    } else if (invitation_type === 'add-group-member') {
      subject = `${name} added you to the "${notification.request_params.name}" group.`;
      title = 'Group Invite';
    }
  }

  const dispatch = useDispatch();

  const handleResponse = async (status) => {
    if (type !== 'invitations' && type !== 'mails') {
      throw Error('Invalid action event type');
    }

    const ACTION_EVENT_TYPE = type === 'mails' ? type : invitation_type;
    let identifier, func;
    switch (ACTION_EVENT_TYPE) {
      case 'add-contact':
        identifier = notification.member_id;
        func = acceptInvitation;
        break;
      case 'add-group-member':
        identifier = notification.request_params.groupId;
        func = acceptGroupInvitation;
        break;
      case 'create-group':
        identifier = notification.response.data[0].group_id;
        func = acceptGroupInvitation;
        break;
      case 'mails':
        identifier =
          (notification &&
            notification.request_data &&
            notification.request_data.mail_id) ||
          null;
        if (identifier) {
          history.push({
            pathname: 'mail/inbox',
            search: `?mail_id=${identifier}`,
          });
        }

        return;
      default:
        console.error('THIS IS NOT DEFINED IN MeetingCard.js');
        break;
    }
    const result = await dispatch(func(identifier, status));
    const accept_decline = status === 'active' ? 'accepted' : 'declined';
    if (result) {
      dispatch(
        setSnackbarData({
          open: true,
          message: `Successfully ${accept_decline} request`,
          type: 'success',
        })
      );
    } else {
      dispatch(
        setSnackbarData({
          open: true,
          message: `Request failed to be ${accept_decline}`,
          type: 'error',
        })
      );
    }
  };

  return (
    <Card className="meeting-card">
      <CardHeader
        className="card-title"
        title={title}
        action={
          <span>{`${format(new Date(notification.create_date), 'p')} ${format(
            new Date(notification.create_date),
            'PPP'
          )} `}</span>
        }
      />
      <CardHeader
        className="card-profile-header"
        avatar={
          <MaterialAvatar
            firstName={notification.first_name}
            lastName={notification.last_name}
            src={notification.amera_avatar_url}
            isBasicAvatar={false}
          />
        }
        title={
          <span>
            <b>{'From:'}</b> {name}
          </span>
        }
        subheader={subject}
      />
      <CardContent>
        <List dense={true}>
          {type === 'mails' && (
            <ListItem className="event-description">
              <ListItemText primary={message} />
            </ListItem>
          )}
        </List>
      </CardContent>
      <CardActions disableSpacing>
        <div className="btn-container">
          {type !== 'mails' && (
            <Button
              variant="contained"
              color="secondary"
              aria-label="Cancel"
              className="card_btn cancel_btn"
              startIcon={<CancelIcon />}
              onClick={(e) => handleResponse('declined')}
            >
              {type === 'invitations' ? 'Decline' : 'Cancel'}
            </Button>
          )}
        </div>
        <div className="btn-container">
          <Button
            variant="contained"
            color="secondary"
            aria-label="Confirm"
            className="card_btn confirm_btn"
            startIcon={type === 'mails' ? <ReplyIcon /> : <CheckBoxIcon />}
            onClick={(e) => handleResponse('active')}
          >
            {type === 'mails' ? 'Open Email' : 'Accept'}
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};

MeetingCard.propTypes = {
  notification: PropTypes.object,
};

MeetingCard.defaultProps = {
  notification: {},
};

export default MeetingCard;
