import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosSharp from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';

// const CustomArrowButtons = ({ onClick }) => (
//   <IconButton
//     disableRipple
//     aria-label="right-arrow"
//     onClick={onClick}
//     className="arrow left"
//   >
//     <ArrowBackIosSharp />
//   </IconButton>
// );

const CustomArrowButtons = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-arrow-buttons">
      <IconButton
        disableRipple
        aria-label="right-arrow"
        disabled={currentSlide === 0}
        className="arrow"
        onClick={() => previous()}
      >
        <ArrowBackIosSharp />
      </IconButton>
      <IconButton
        disableRipple
        aria-label="right-arrow"
        className="arrow right"
        onClick={() => next()}
      >
        <ArrowForwardIosSharpIcon />
      </IconButton>
    </div>
  );
};

export default CustomArrowButtons;
// export const CustomRightArrow = ({ onClick }) => (
//
// );
