import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import {
  // isAfter,
  isBefore,
  isEqual,
  add,
  sub,
  getDay,
  isSameDay,
  parseISO,
  startOfDay,
  differenceInMinutes,
  isValid,
  endOfDay,
} from 'date-fns';

import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Modal from '../modal';

import MemberAutocomplete from '../../MemberAutocomplete';
import GroupAutocomplete from '../../GroupAutocomplete';
import GroupMembersChips from './GroupMembersChips';
import AllDayCheckbox from './AllDayCheckbox';
import MultiDayCheckbox from './MultiDayCheckbox';
import DateTimeLine from './DateTimeLine';
import EventName from './EventName';
import EventTypeSelect from './EventTypeSelect';
import LocationLine from './LocationLine';
import ColorSelect from './ColorSelect';
import AttachButton from './AttachButton';
import { ProgressBar } from '../../ProgressBar/index';
import AttachmentList from './AttachmentList';
import CustomComposeEditor from '../../../pages/Email/SharedComponents/Compose/CustomComposeEditor/';
// import DurationSelect from './DurationSelect';
import EventModalActions from './EventModalActions';
import EventModalSnack from './EventModalSnack';
import EventURLInput from './EventURLInput';
import usePrevious from '../../../hooks/usePrevious';

import {
  LOCATION_TYPES,
  EVENT_TYPES,
  ADDRESS_TYPES,
  // changeTz,
  prepareChangesForms,
  // makeUtcDate,
  eventTypeText,
  SNACK_TIMEOUT,
  INVITE_MODE,
  parseGroupToState,
} from '../../../utils/calendar';
import {
  putModifyEvent,
  postEventAttachment,
} from '../../../redux/actions/schedule_event';

import { validateScheduleEvent } from '../../../utils/validator/ScheduleEvent';
import { includes } from 'lodash';
const shortid = require('shortid');

const defaultSnack = { isShow: false, variant: 'success', message: '' };
const CLOSE_TIMEOUT = SNACK_TIMEOUT + 500;
const MOVE_HOURS = 1;

const EventEditModal = (props) => {
  const {
    show,
    onClose,
    selectedEvent,
    isModifying,
    colors,
    error,
    locationObjects,
    contactOptions,
    groupList,
    dispatch,
  } = props;
  const prevModyfing = usePrevious(isModifying);
  const [snackState, setSnackState] = useState(defaultSnack);
  const [eventState, setEventState] = useState({});
  const [errors, setErrors] = useState({});
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressShow, setUploadProgressShow] = useState(false);

  // Fill the state based on selected event's props
  useEffect(() => {
    console.log('event info incoming', selectedEvent);
    setEventState((ps) => ({
      event_id: selectedEvent.event_id,
      name: selectedEvent.event_name,
      type: selectedEvent.event_type,
      colorId: !!selectedEvent.event_color_id
        ? colors.find((co) => co.id === selectedEvent.event_color_id).id
        : 'no-color',
      description: selectedEvent.event_description,
      invitedGroup: !!selectedEvent.group_info
        ? parseGroupToState(
            groupList.find(
              (glo) => glo.group_id === selectedEvent.group_info.group_id
            ),
            false,
            selectedEvent.invitations
          )
        : {},
      inviteMode: !!selectedEvent.group_info
        ? INVITE_MODE.group
        : INVITE_MODE.contacts,
      invitations: selectedEvent.invitations,
      eventTimeZone: selectedEvent.event_tz,
      start: parseISO(selectedEvent.start),
      end: parseISO(selectedEvent.end),
      isFullDay: selectedEvent.is_full_day,
      locationMode: selectedEvent.location_mode,
      coverAttachmentId: selectedEvent.cover_attachment_id,
      locationData:
        selectedEvent.location_mode === LOCATION_TYPES.own
          ? selectedEvent.location_id
          : selectedEvent.location_address,
    }));

    if (
      !!selectedEvent.attachments &&
      selectedEvent.attachments.length > 0 &&
      !!selectedEvent.attachments[0]
    ) {
      setAttachedFiles((ps) => selectedEvent.attachments);
    }
  }, [colors, contactOptions, groupList, selectedEvent]);

  const invitationsToMembersHelper = () =>
    !!eventState.invitations &&
    eventState.invitations.length > 0 &&
    !!eventState.invitations[0]
      ? eventState.invitations
      : // .map((io) => io.invite_member_id)
        // .map((contact_member_id) =>
        //   contactOptions.find(
        //     (coo) => coo.contact_member_id === contact_member_id
        //   )
        // )
        [];

  const handleMemberChange = (e, v) => {
    console.log('Members', v);

    setEventState((ps) => {
      const selectedContactIds = v.map((co) => co.contact_member_id);
      const alreadyInvitedIds =
        !!eventState.invitations && eventState.invitations.length > 0
          ? eventState.invitations.map((io) => io.invite_member_id)
          : [];

      const newInvitations = selectedContactIds.map((cid) =>
        includes(alreadyInvitedIds, cid)
          ? ps.invitations.find((io) => io.invite_member_id === cid)
          : { invite_member_id: cid, invite_id: shortid.generate() }
      );

      return { ...ps, invitations: newInvitations };
    });
  };

  // Cleanup upon modal closing
  const handleClose = useCallback(() => {
    // setEventState({});
    setAttachedFiles([]);
    setUploadProgressShow(false);
    setUploadProgress(0);
    setErrors({});
    onClose();
  }, [onClose]);

  // Handle API calls results
  useEffect(() => {
    if (prevModyfing === true && isModifying === false) {
      if (!error) {
        setSnackState({
          isShow: true,
          variant: 'success',
          message: 'Event updated successfully',
        });
        setTimeout(() => handleClose(), [CLOSE_TIMEOUT]);
      } else {
        console.log('error from redux', error);
        setSnackState({
          isShow: true,
          variant: 'error',
          message: error,
        });
      }
    }
  }, [handleClose, error, prevModyfing, isModifying]);

  const handleSubmitClick = () => {
    //TODO: validate event name here
    const formErrors = validateScheduleEvent(eventState);
    console.log('form errors', formErrors);

    if (Object.keys(formErrors).length === 0) {
      setErrors({});
      let form = prepareChangesForms(eventState, attachedFiles);
      form.set('mode', 'full');
      dispatch(putModifyEvent(form));
    } else {
      setErrors(formErrors);
    }
  };

  const handleFullDayClick = (e) => {
    e.persist();
    if (e.target.checked) {
      // Is full day
      setEventState((ps) => ({
        ...ps,
        isFullDay: true,
        start: startOfDay(ps.start),
        end: endOfDay(ps.end),
      }));
    } else {
      setEventState((ps) => ({
        ...ps,
        isFullDay: false,
        // end: add(ps.start, { hours: MOVE_HOURS }),
      }));
    }
  };

  const handleLocationModeChange = (e) => {
    const mode = e.target.value;

    setEventState((ps) => {
      let locData = '';

      if (mode === LOCATION_TYPES.own) {
        // First of the location ids, currently- just the first one

        locData =
          !!locationObjects && locationObjects.length > 0
            ? locationObjects[0].id
            : null;
      } else if (
        mode === LOCATION_TYPES.find ||
        mode === LOCATION_TYPES.lookup
      ) {
        // If switching between find an lookup => maintain the address line
        locData =
          ps.locationMode === LOCATION_TYPES.find ||
          ps.locationMode === LOCATION_TYPES.lookup
            ? ps.locationData
            : '';
      }

      return { ...ps, locationMode: mode, locationData: locData };
    });
  };

  const handleEventTypeChange = (e) => {
    const eventType = e.target.value;

    let locationData = '';
    let locationMode;

    if (
      eventType === EVENT_TYPES.meeting ||
      eventType === EVENT_TYPES.personal
    ) {
      locationData =
        !!locationObjects && locationObjects.length > 0
          ? locationObjects[0].id
          : null;
      locationMode = LOCATION_TYPES.own;
    } else if (
      eventType === EVENT_TYPES.video ||
      eventType === EVENT_TYPES.webinar ||
      eventType === EVENT_TYPES.chat ||
      eventType === EVENT_TYPES.audio
    ) {
      locationData = '';
      locationMode = LOCATION_TYPES.url;
    }
    setEventState((ps) => ({
      ...ps,
      type: eventType,
      locationData,
      locationMode,
    }));
  };

  const handleDateInput = (date, type) => {
    // console.log('date input', type, date);
    if (isValid(date)) {
      setEventState((ps) => {
        // Moving start date ALWAYS maintains the initial duration (by means of moving the end date)
        if (type === 'start') {
          const prevDifferenceMinutes = differenceInMinutes(ps.end, ps.start);
          console.log('The hours difference was', prevDifferenceMinutes);
          return {
            ...ps,
            start: date,
            end: add(date, { minutes: prevDifferenceMinutes }),
            weekDays: [getDay(date)],
          };
        } else if (
          type === 'end' &&
          (isBefore(date, ps.start) || isEqual(date, ps.start))
        ) {
          // We need to move the start date
          const movedStart = sub(date, { hours: MOVE_HOURS });
          return {
            ...ps,
            start: movedStart,
            end: date,
            weekDays: [getDay(movedStart)],
          };
        } else {
          return {
            ...ps,
            [type]: date,
            weekDays: [getDay(ps.start)],
          };
        }
      });
    }
  };

  const handleClearClick = (fileObject) => {
    console.log(fileObject);
    //TODO: Delete the uploaded file to don't leave orphans
    // Clear from state
    setAttachedFiles((ps) =>
      ps.filter((afo) => afo.file_id !== fileObject.fileId)
    );

    // If we delete what is meant to be cover
    if (eventState.coverAttachmentId === fileObject.member_file_id) {
      setEventState((ps) => ({ ...ps, coverAttachmentId: null }));
    }
  };

  const handleSelectFileToAttach = async (event) => {
    // event.persist();
    // One file at a time for now
    const fileListArr = Array.from(event.target.files);

    console.log(fileListArr[0]);
    let formData = new FormData();
    formData.set('file', fileListArr[0]);
    formData.set('fileName', fileListArr[0].name);
    formData.set('size', fileListArr[0].size);
    setUploadProgressShow(true);
    const fileData = await dispatch(
      postEventAttachment(formData, setUploadProgress)
    );
    setUploadProgressShow(false);
    // console.log('fileData', fileData);
    setAttachedFiles((ps) => [
      ...ps,
      { ...fileData, attachment_id: shortid.generate() },
    ]);
  };

  const handleDurationSelect = (v) => {
    setEventState((ps) => ({
      ...ps,
      end: v,
      isFullDay: false,
    }));
  };

  const handleMultiDayClick = (e) => {
    e.persist();
    if (e.target.checked) {
      setEventState((ps) => ({ ...ps, end: add(ps.end, { days: 1 }) }));
    } else {
      setEventState((ps) => {
        if (ps.isFullDay) {
          return {
            ...ps,
            start: startOfDay(ps.start),
            end: endOfDay(ps.start),
          };
        } else {
          return {
            ...ps,
            end: add(ps.start, { hours: MOVE_HOURS }),
          };
        }
      });
    }
  };

  const handleGroupMemberClick = (contactMemberId) => {
    console.log('chip clicked', contactMemberId);
    setEventState((ps) => {
      return {
        ...ps,
        invitedGroup: {
          ...ps.invitedGroup,
          members: ps.invitedGroup.members.map((mo) =>
            mo.contact_member_id === contactMemberId
              ? { ...mo, isShouldInvite: !mo.isShouldInvite }
              : mo
          ),
        },
      };
    });
  };

  const ModalContent = () => (
    <>
      <EventModalSnack
        snackState={snackState}
        timeout={SNACK_TIMEOUT}
        onClose={() => setSnackState((ps) => ({ ...ps, isShow: false }))}
      />
      <div className="create-event-modal-content">
        <Grid container spacing={2} justify="space-between">
          {/* Recurrence and toggles */}
          <Grid container item xs={6} alignItems="center"></Grid>
          <Grid item xs={3}>
            <AllDayCheckbox
              checked={eventState.isFullDay}
              onChange={handleFullDayClick}
            />
          </Grid>
          <Grid item xs={3}>
            <MultiDayCheckbox
              checked={!isSameDay(eventState.start, eventState.end)}
              onChange={handleMultiDayClick}
            />
          </Grid>
          {/* END Recurrence and toggles */}

          {/* Event Name and type*/}
          <Grid item xs={1}>
            <ColorSelect
              colorId={eventState.colorId}
              onChange={(e) =>
                setEventState((ps) => ({ ...ps, colorId: e.target.value }))
              }
            />
          </Grid>
          <Grid item md={7} xs={12}>
            <EventName
              value={eventState.name}
              onChange={(input) =>
                setEventState((ps) => ({ ...ps, name: input }))
              }
              error={errors.name}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <EventTypeSelect
              value={eventState.type}
              handleChange={handleEventTypeChange}
              disabled
            />
          </Grid>
          {/* END Event Name and type*/}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Invited members */}
          {eventState.type !== EVENT_TYPES.personal && (
            <>
              {/* Invited members */}
              {eventState.inviteMode === INVITE_MODE.contacts && (
                <Grid item xs={12}>
                  <MemberAutocomplete
                    handleChange={handleMemberChange}
                    label="Invite contacts to event"
                    values={invitationsToMembersHelper()}
                  />
                </Grid>
              )}
              {eventState.inviteMode === INVITE_MODE.group && (
                <Grid item container xs={12}>
                  <Grid item xs={4}>
                    <GroupAutocomplete
                      label="Invite Group members"
                      //   isShowOnlyLeader
                      disabled
                      multiple={false}
                      values={eventState.invitedGroup}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <GroupMembersChips
                      invitedGroup={eventState.invitedGroup}
                      handleChipClick={handleGroupMemberClick}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {/* END Invited members */}
          {/* Date time */}
          <Grid item xs={12}>
            <DateTimeLine
              start={eventState.start}
              end={eventState.end}
              isFullDay={eventState.isFullDay}
              onDateChange={handleDateInput}
              onDurationChange={handleDurationSelect}
              error={errors.datetime}
            />
          </Grid>
          {/* END Date time*/}

          {(eventState.type === EVENT_TYPES.meeting ||
            eventState.type === EVENT_TYPES.personal) && (
            <Grid item xs={12}>
              <LocationLine
                start={eventState.start}
                end={eventState.end}
                meetingType={eventState.type}
                locationMode={eventState.locationMode}
                locationData={eventState.locationData}
                onModeChange={handleLocationModeChange}
                onInputChange={(v) =>
                  setEventState((ps) => ({ ...ps, locationData: v }))
                }
                error={errors.location}
              />
            </Grid>
          )}

          {(eventState.type === EVENT_TYPES.video ||
            eventState.type === EVENT_TYPES.webinar) && (
            <Grid item xs={12}>
              <EventURLInput
                meetingType={eventState.type}
                locationData={eventState.locationData}
                onInputChange={(v) =>
                  setEventState((ps) => ({ ...ps, locationData: v }))
                }
                error={errors.location}
              />
            </Grid>
          )}
          {/*END Location*/}

          <Grid item xs={12}>
            <CustomComposeEditor
              multiline
              rowsMax={20}
              value={eventState.description}
              handleChangebd={(v) => {
                setEventState((ps) => ({
                  ...ps,
                  description: v,
                }));
              }}
              placeholder={'Your event details...'}
            />
          </Grid>

          {/* Files */}
          {uploadProgressShow && (
            <Grid item xs={12}>
              <ProgressBar value={uploadProgress} />
            </Grid>
          )}
          {attachedFiles.length > 0 && (
            <Grid item xs={12} md={8}>
              <AttachmentList
                attachedFiles={attachedFiles}
                onClearClick={handleClearClick}
                cover={eventState.coverAttachmentId}
                onSelectCover={(v) =>
                  setEventState((ps) => ({
                    ...ps,
                    coverAttachmentId: ps.coverAttachmentId === v ? null : v,
                  }))
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <AttachButton onChange={handleSelectFileToAttach} />
          </Grid>
          {/* Files end*/}
        </Grid>
      </div>
    </>
  );

  console.debug('Edit form state', eventState, attachedFiles);

  return (
    <Modal
      open={show}
      onClose={onClose}
      title={`Update ${eventTypeText(selectedEvent.event_type)}`}
      ModalContent={ModalContent}
      ModalAction={() => (
        <EventModalActions
          closeLabel="Cancel"
          onClose={handleClose}
          isLoading={isModifying}
          loadingLabel="Updating.."
          actionLabel="Update"
          onAction={handleSubmitClick}
        />
      )}
      className="create-event-modal"
    />
  );
};

EventEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  locationObjects: PropTypes.array,
  isModifying: PropTypes.bool.isRequired,
  selectedEvent: PropTypes.object,
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  contactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  colors: state.scheduleEvents.colors,
  isModifying: state.scheduleEvents.isModifying,
  error: state.scheduleEvents.error,
  locationObjects: state.member.memberInfo.location_information.filter(
    (lo) => lo.location_type !== ADDRESS_TYPES.billing
  ),
  contactOptions: state.contact.contacts,
  groupList: state.group.groupList,
});

export default connect(mapStateToProps)(EventEditModal);
