import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
  // DELETE_COMPANY,
  // ADD_COMPANY_MEMBER,
  // REMOVE_COMPANY_MEMBER,
  LOAD_COMPANIES,
  LOAD_COMPANY,
  LOAD_UNREGISTERED_COMPANIES,
  UPDATE_UNREGISTERED_COMPANY,
  DELETE_UNREGISTERED_COMPANY,
  CREATE_COMPANY_FROM_NAME,
} from '../actionTypes/company';

// The initial state of the App
export const initialState = {
  companyList: [],
  companyData: null,
  unregisteredCompanies: [],
};

let newUnregisteredCompanies;

const company = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COMPANIES:
      return {
        ...state,
        companyList: action.payload,
      };
    case CREATE_COMPANY:
      return {
        ...state,
        companyList: [action.payload, ...state.companyList],
      };
    case LOAD_COMPANY:
      return {
        ...state,
        companyData: action.payload,
      };
    case UPDATE_COMPANY:
      const newCompanyList = state.companyList.map((company) => {
        if (company.id === action.payload.id) {
          return action.payload;
        } else return company;
      });
      let newCompanyData = {};
      if (state.companyData && state.companyData.id === action.payload.id) {
        newCompanyData = { companyData: action.payload };
      }

      return {
        ...state,
        companyList: newCompanyList,
        ...newCompanyData,
      };
    case LOAD_UNREGISTERED_COMPANIES:
      return {
        ...state,
        unregisteredCompanies: action.payload,
      };
    case UPDATE_UNREGISTERED_COMPANY:
      newUnregisteredCompanies = state.unregisteredCompanies.map((company) => {
        if (company.company_name === action.payload.companyName) {
          return {
            company_name: action.payload.newCompanyName,
            total_members: company.total_members,
          };
        }
        return company;
      });

      return {
        ...state,
        unregisteredCompanies: newUnregisteredCompanies,
      };
    case DELETE_UNREGISTERED_COMPANY:
      newUnregisteredCompanies = state.unregisteredCompanies.filter(
        (company) => company.company_name !== action.payload
      );
      return {
        ...state,
        unregisteredCompanies: newUnregisteredCompanies,
      };
    case CREATE_COMPANY_FROM_NAME:
      newUnregisteredCompanies = state.unregisteredCompanies.filter(
        (company) => company.company_name !== action.payload.companyName
      );

      return {
        ...state,
        unregisteredCompanies: newUnregisteredCompanies,
        companyList: [action.payload.company, ...state.companyList],
      };

    default:
      return state;
  }
};

export default company;
