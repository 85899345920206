import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Typography,
  makeStyles,
  Card,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ClearIcon from '@material-ui/icons/Clear';

import { removeCompanyMember } from '../../redux/actions/company';
import ConfirmDialog from '../ConfirmDialog';
import AddMemberModal from './AddMemberModal';
import ContactPreview from '../ContactPreview';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    padding: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  addMember: {
    textAlign: 'right',
  },
}));

const CompanyMembers = (props) => {
  const { isEdit, company } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const maxTableRowCount = 3;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(maxTableRowCount);

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [deleteMemberId, setDeleteMemberId] = useState(0);
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);

  const handleDeleteConfirmDialogClose = () => {
    setDeleteConfirmDialogOpen(false);
  };

  const handleDeleteConfirmDialogOpen = (memberId) => {
    setDeleteConfirmDialogOpen(true);
    setDeleteMemberId(memberId);
  };

  const handleAddMemberModalClose = () => {
    setAddMemberModalOpen(false);
  };

  const handleAddMemberModalOpen = () => {
    setAddMemberModalOpen(true);
  };

  const removeMemberFromCompany = () => {
    let formData = new FormData();
    formData.set('member_id', deleteMemberId);
    formData.set('company_id', company.id);
    dispatch(removeCompanyMember(formData));
    handleDeleteConfirmDialogClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Grid container>
      <Card className={styles.card}>
        <Grid container alignItems="center">
          <Grid item xs={8} className={styles.title}>
            <Typography variant="h6">Company Members</Typography>
          </Grid>
          {isEdit && (
            <Grid item xs={4} className={styles.addMember}>
              <Tooltip title="Add Member" placement="top" arrow>
                <IconButton
                  aria-label="Add Member"
                  className={styles.addMemberIcon}
                  color="primary"
                  onClick={handleAddMemberModalOpen}
                >
                  <PersonAddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <TableContainer className={styles.tableContainer}>
            <Table
              className={styles.table}
              aria-label="customized table"
              stickyHeader
            >
              <TableBody>
                {company &&
                  company.members &&
                  company.members.length > 0 &&
                  company.members
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell id="name_email">
                          {
                            <ContactPreview
                              contactData={{ ...row, member_id: row.id }}
                              isShowAvatar={true}
                            />
                          }
                        </TableCell>
                        <TableCell>
                          <b>{row.role_type}</b>
                        </TableCell>
                        {isEdit && (
                          <TableCell id="delete" align="center">
                            <IconButton
                              aria-label="Delete Member"
                              className={styles.deleteMemberIcon}
                              onClick={() =>
                                handleDeleteConfirmDialogOpen(row.id)
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[maxTableRowCount]}
            component="div"
            count={company && company.members ? company.members.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Card>
      <AddMemberModal
        title="Add Member"
        open={addMemberModalOpen}
        companyId={company.id}
        company={company}
        onClose={handleAddMemberModalClose}
      />
      <ConfirmDialog
        open={deleteConfirmDialogOpen}
        title="Confirm Delete"
        onOk={removeMemberFromCompany}
        onCancel={handleDeleteConfirmDialogClose}
      />
    </Grid>
  );
};

export default CompanyMembers;
