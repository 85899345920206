const colors = {
  blue: '#4284df',
  darkGreen: '#28a745',
  darkRed: '#c82333',
  foggyRed: '#dc3545',
  grey: '#6c757d',
  yellow: '#ffc107',
  teal: '#17a2b8',
  orange: '#ff7f50',
  textDark: '#6c757d',
};

const firstScreenModeStyle = (theme) => ({
  root: {
    height: '100%',
    color: theme.palette.common.white,
    '& .MuiGrid-item': {
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
    '& .MuiButton-label': {
      color: theme.palette.common.white,
    },
    '& .MuiFormControlLabel-label': {
      color: theme.palette.common.black,
    },
  },
  callBtnWrapper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  fileInput: {
    display: 'none',
  },
  videoPlayer: {
    minHeight: '270px',
    maxHeight: '270px',
    width: '100%',
    height: '270px',
    pointerEvents: 'none',
  },
  callBtn: {
    backgroundColor: colors.blue,
    color: theme.palette.common.white,
  },
  closeCallBtn: {
    backgroundColor: colors.darkRed,
    color: theme.palette.common.white,
    marginTop: theme.spacing(3),
    '& .MuiSvgIcon-root': {
      color: colors.orange,
    },
    '& .MuiButton-label:hover': {
      color: colors.foggyRed,
    },
  },
  callControlWrapper: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
  },
  shareDisplay: {
    background: colors.teal,
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
  },
  sendFile: {
    background: colors.blue,
  },
  startRecording: {
    background: colors.foggyRed,
  },
  play: {
    background: colors.grey,
  },
  download: {
    background: colors.yellow,
  },
  playerControlWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  localPlayerControl: {
    width: '33.3%',
    height: '2em',
    whiteSpace: 'nowrap',
    '& .MuiButton-label': {
      color: colors.textDark,
    },
    background: theme.palette.common.white,
    '& .MuiButtonBase-root:active': {
      background: colors.darkGreen,
      '& .MuiButton-label': {
        color: theme.palette.common.white,
      },
    },
  },
  remotePlayerControl: {
    width: '50%',
    height: '2em',
    whiteSpace: 'nowrap',
    '& .MuiButton-label': {
      color: colors.textDark,
    },
    background: theme.palette.common.white,
    '& .MuiButtonBase-root:active': {
      background: colors.darkGreen,
      '& .MuiButton-label': {
        color: theme.palette.common.white,
      },
    },
  },
  playerControlGroup: {
    paddingTop: 0,
    width: '100%',
    '& .MuiButton-root': {
      textTransform: 'none',
    },
  },
  select: {
    display: 'block',
    width: '100%',
    height: '2.3em',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    backgroundColor: theme.palette.common.white,
  },
  selectWrapper: {
    marginTop: theme.spacing(2),
    '& label': {
      fontWeight: 'bold',
    },
  },
  encryptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  encryptionControlWrapper: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  encryptionCard: {
    height: '100%',
    '& .MuiCardContent-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  encryptionImage: {
    minWidth: '100%',
    maxWidth: '100%',
    width: '100%',
    height: '270px',
    minHeight: '270px',
    maxHeight: '270px',
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  encryptionImageKey: {
    '& label': {
      fontWeight: 'bold',
    },
  },
  controlGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& label': {
      fontWeight: 'bold',
    },
    paddingBottom: theme.spacing(1),
  },
  fileInputWrapper: {
    '& .MuiButton-root': {
      background: colors.blue,
    },
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
    },
    '& label': {
      display: 'block',
    },
  },
  userPinWrapper: {
    '& input': {
      height: '2.2em',
      width: '100%',
      border: '1px solid #ced4da',
      borderRadius: '0.23em',
      background: theme.palette.common.white,
      paddingLeft: theme.spacing(1),
    },
    marginLeft: theme.spacing(2),
  },
  binaryEncryptionKey: {
    width: '100%',
    '& label': {
      fontWeight: 'bold',
      color: theme.palette.common.black,
    },
    '& textarea': {
      width: '100%',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#495057',
      backgroundColor: '#e9ecef',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      height: '150px',
    },
  },
  localWebcam: {
    '& .MuiCardContent-root:first-child': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  remoteWebcam: {
    '& .MuiCardContent-root:first-child': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  reactSelectWrapper: {
    color: theme.palette.common.black,
  },
  modalContentWrapper: {
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '1.2em',
    textAlign: 'center',
  },
  contactPreviewWrapper: {
    color: theme.palette.secondary.main,
    width: '350px',
    '& .MuiGrid-item': {
      padding: 0,
    },
  },
  viewModeWrapper: {
    textAlign: 'right',
  },
});

export default firstScreenModeStyle;
