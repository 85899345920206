import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// import CheckIcon from '@material-ui/icons/Check';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { makeStyles } from '@material-ui/core/styles';

import PhoneInput from 'react-phone-input-2';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { formatISO, format } from 'date-fns';
import CountryInput from './InputElements/CountryInput';
import FullNameInput from './InputElements/FullNameInput';
import CompanyNameInput from './InputElements/CompanyNameInput2';
import DepartmentListInput from './InputElements/DepartmentList';
import JobTitleInput from './InputElements/JobTitleInput';
import PictureAndPINInput from './InputElements/PictureAndPINInput';
import PINInput from './InputElements/PINInput';
import EmailInput from './InputElements/EmailInput';
import PasswordInput from './InputElements/PasswordInput';
import PostalInput from './InputElements/PostalInput';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import TOTPModal from './TOTPModal';
import PromoModal from './PromoModal';
import TermsAndConditonsCheckBox from './InputElements/TermsAndConditonsCheckBox';
import ImageCrop from '../../components/Modal/ImageCrop';

import * as api from '../../config/api';
import {
  postRegisterMemeber,
  getTermsAndConditions,
} from '../../redux/actions/member';

import { validateRegisterForm } from '../../utils/validator/Register';
import { getAddress, geoApi } from '../../utils/country';

import defaultProfileImage from '../../assets/img/profile-picture-cover.png';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.75em',
    marginLeft: '-0.75em',
  },
  promocodeBtn: {
    margin: '-10px',
  },
}));

const RegisterForm = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    invite,
    isSubmittingRegisterForm,
    isShouldShowPromo,
    countryList,
    dispatch,
  } = props;
  const [isShowPromoModal, setShowPromoModal] = useState(false);
  // const { dispatch, member, invite, terms } = props;
  const [isShowCrop, setShowCrop] = useState(false);
  const [clientIpAddress, setClientIpAddress] = useState('');
  // TEST SET
  // const [values, setValues] = useState({
  //   country: 392,
  //   job_title_id: 5,
  //   department_id: 7,
  //   state: null,
  //   city: null,
  //   company_name: 'Los Pollo Hermanos',
  //   first_name: 'Jessie',
  //   last_name: 'Pinkman',
  //   email: 'andrey.vanyakin@gmail.com',
  //   password: 'meth',
  //   confirm_password: 'meth',
  //   postal_code: '22162',
  //   pin: 78787878,
  //   confirmPin: 78787878,
  //   dob: '1986-11-24',
  //   isApplicable: true,
  //   isAcceptedTerms: true,
  //   isOpenedTerms: true,
  //   isCellConfirmed: true,
  //   cellConfirmationTS: formatISO(new Date()),
  //   isEmailConfirmed: true,
  //   emailConfirmationTS: formatISO(new Date()),
  //   cell: '79035031963',
  //   activatedPromoCode: null,
  // });
  const [zipRule, setZipRule] = useState(null);
  const [values, setValues] = useState({
    country: 840,
    postal_code: '',
    dob: null,
    cell: '',
    isCellConfirmed: false,
    state: null,
    city: null,
    isAcceptedTerms: false,
    isOpenedTerms: false,
    cellConfirmationTS: null,
    isEmailConfirmed: false,
    isApplicable: true,
    job_title_id: '',
    department_id: '',
    // cellConfirmationTS: null,
    emailConfirmationTS: null,
    signed_toc_id: null,
    activatedPromoCode: null,
  });

  const [errors, setErrors] = useState({});
  const [isShowTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [isShowTOTPModal, setShowTOTPModal] = useState(false);
  //
  const [prevProfileImage, setPrevProfileImage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const [promoCode, setPromoCode] = useState('');
  const contract_text = useRef(null);
  const amera_tos_id = useRef(null);

  const countryObj = countryList.find((cl) => cl.id === values.country);

  // const reCaptchaRef = useRef(null);
  // const verifyCallback = (recaptchaToken) => {
  //   // Here you will get the final recaptchaToken!!!
  //   console.log('<= your recaptcha token');
  // };

  useEffect(() => {
    console.log('Invite key is ', isShouldShowPromo);
    if (isShouldShowPromo) {
      setTimeout(() => {
        setShowPromoModal(true);
      }, 2000);
    }
  }, [isShouldShowPromo]);

  // Get the zip code rule for selected country
  useEffect(() => {
    if (countryObj) {
      fetch(`${geoApi}/${countryObj.alpha2}`)
        .then((res) => res.json())
        .then((data) => {
          // console.log('geodata', data);
          setZipRule(data.zip);
        });
    }
  }, [countryObj]);

  // Get the state and city for valis postcode
  useEffect(() => {
    if (values.postal_code && RegExp(zipRule, 'g').test(values.postal_code)) {
      getAddress(
        values.postal_code,
        `${process.env.REACT_APP_GMAPS_API_KEY}`,
        (err, res) => {
          if (err) {
          } else {
            let state, province, city;
            if (res) {
              // Those are  objects. There are short variants
              state = res.state.long;
              province = res.province.long;
              city = res.city.long;
            } else {
              state = null;
              province = null;
              city = null;
            }

            setValues((prevValues) => ({
              ...prevValues,
              state: state,
              province: province,
              city: city,
            }));
          }

          // console.log(state, city);
        }
      );
    }
  }, [values.postal_code, zipRule]);

  useEffect(() =>
    // Load the terms and conditions
    {
      if (countryObj) {
        getTermsAndConditions().then((res) => {
          const terms = res;
          const termData = (terms
            ? terms
            : [
                {
                  //Fallback for demos
                  amera_tos_id: 1,
                  contract_text: 'Contract text',
                  country_code_alpha2: 'US',
                },
              ]
          ).find((tco) => tco.country_code_alpha2 === countryObj.alpha2);
          contract_text.current = termData.contract_text;
          amera_tos_id.current = termData.amera_tos_id;
        });
      }
      // Terms and conditions
    }, [countryObj]);

  useEffect(() => {
    let isIP = false;
    const ipReqUrl = 'https://geolocation-db.com/json/';
    api.GET(ipReqUrl).then((res) => {
      if (!isIP) {
        setClientIpAddress(res.IPv4);
      }
    });
    return () => {
      isIP = true;
    };
  }, []);

  useEffect(() => {
    if (invite) {
      console.log('invite obj', invite);
      let inviteCountry = 840;
      if (invite.country) {
        inviteCountry = invite.country;
      }
      console.log(invite);
      setValues((prevState) => ({
        ...prevState,
        email: invite.email,
        isEmailConfirmed: true,
        emailConfirmationTS: formatISO(new Date()),
        first_name: invite.first_name,
        last_name: invite.last_name,
        country: inviteCountry,
        confirmPhoneRequired: invite.confirm_phone_required,
        cell: invite.phone_number
          ? `${countryList.find((clo) => clo.id === inviteCountry).phone}${
              invite.phone_number
            }`
          : '',
      }));
    }
  }, [invite, countryList]);

  const upperPartRef = useRef();
  useEffect(() => {
    // FIXME: Add other conditions here like which errors ezxactly
    if (isError && upperPartRef.current) {
      upperPartRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [isError]);

  useEffect(() => {
    if (!values.confirm_password) return;
    else if (!values.password)
      setErrors((prev) => ({
        ...prev,
        password: 'Password is required',
        confirm_password: '',
      }));
    else if (values.password !== values.confirm_password)
      setErrors((prev) => ({
        ...prev,
        password: '',
        confirm_password: 'Password is not matched!',
      }));
    else setErrors((prev) => ({ ...prev, password: '', confirm_password: '' }));
  }, [values.password, values.confirm_password]);

  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCompanyId = (company_id) => {
    setValues({
      ...values,
      company_id,
    });
  };

  const handleChangeCompany = (company_name) => {
    setValues({
      ...values,
      company_name,
    });
  };

  const handleChangeCountry = (event) => {
    setValues({
      ...values,
      country: event.target.value,
      isAcceptedTerms: false,
      isOpenedTerms: false,
      signed_toc_id: null,
      postal_code: '',
      state: null,
      city: null,
    });
  };

  const handleChangePostCode = (event) => {
    setValues({
      ...values,
      postal_code: event.target.value,
      state: null,
      city: null,
    });
  };

  const handleOpenTermsAndConditions = (event) => {
    event.preventDefault();
    setValues({
      ...values,
      // User has opened ToC at least once
      isOpenedTerms: true,
    });
    setShowTermsAndConditions(true);
  };

  const handleTermsToggle = (event) => {
    setValues((prevValues) => ({
      ...values,
      isOpenedTerms: true,
      isAcceptedTerms: !prevValues.isAcceptedTerms,
      // If checked - save the id of terms and conditions
      signed_toc_id: !prevValues.isAcceptedTerms ? amera_tos_id : null,
    }));
  };

  const handleVerifyClick = () => {
    // console.log('Verify clicked');
    setShowTOTPModal(true);
  };

  const handlePromoConfirmed = (appliedPromoId) => {
    // console.log('Just verfified promo code', verifiedCode);
    setValues({ ...values, activatedPromoCode: appliedPromoId });
  };
  const handleVerificationSuccess = () => {
    setValues({
      ...values,
      isCellConfirmed: true,
      cellConfirmationTS: formatISO(new Date()),
    });
  };

  const handleEmailVerified = () => {
    setValues({
      ...values,
      isEmailConfirmed: true,
      emailConfirmationTS: formatISO(new Date()),
    });
  };

  const handleChangePhoneNumber = (value, country, e, formattedValue) => {
    setValues({
      ...values,
      cell: value,
      isCellConfirmed: false,
      cellConfirmationTS: null,
    });
  };

  const handleBirthDateChange = (date) => {
    console.log('date', date);
    setValues({
      ...values,
      dob: date ? date : '',
    });
  };

  const handleThreeTimesWrongSMSCode = () => {
    history.push('/home');
  };

  const handleAcceptClick = () => {
    setValues({ ...values, isAcceptedTerms: true });
  };

  const handleApplicableToggle = (e) => {
    // The field is called Not applicable, so that's why we invert here
    const isApplicable = !e.target.checked;
    setValues((prevValues) => ({
      ...values,
      isApplicable,
      company_name: isApplicable ? prevValues.company_name : null,
    }));
  };

  useEffect(() => {
    isError && setErrors(validateRegisterForm(values));
  }, [isError, values]);

  const setFormData = (values) => {
    let formData = new FormData();
    Object.keys(values).map((key) => {
      let value =
        key === 'dob' ? format(values[key], 'yyyy-LL-dd') : values[key];
      return formData.set(key, value);
    });
    return formData;
  };

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    // const token = await reCaptchaRef.current.execute();
    // console.log(token);
    const formErrors = validateRegisterForm(values);
    console.log(formErrors);
    if (values && Object.keys(formErrors).length === 0) {
      setIsError(false);
      const formData = setFormData(values);

      const inviteKey = invite ? invite.invite_key : '';
      // if (invite) {
      //   let { group_id } = invite;
      //   if (group_id) {
      //     formData.set('groupId', group_id);
      //   }
      // }
      const result = await dispatch(postRegisterMemeber(formData, inviteKey));
      if (result) {
        setAlert({
          open: true,
          severity: 'success',
          message: 'Member registered successfully! redirecting to login...',
        });
        setTimeout(() => {
          history.push('/login');
        }, 3000);
      } else if (result instanceof Error) {
        const statusCode = result.res.status;
        if (statusCode === 409) {
          setAlert({
            open: true,
            severity: 'warning',
            message: 'Member already exists!',
          });
        } else if (statusCode === 500) {
          setAlert({
            open: true,
            severity: 'error',
            message: 'Something went wrong, please try again later!',
          });
        }
      }
    } else {
      setIsError(true);
      setErrors(formErrors);
      return false;
    }
  };

  const AlertMessage = () => {
    return (
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setAlert({ open: false });
        }}
      >
        <Alert
          variant="filled"
          severity={alert.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert({ open: false });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {alert.message}
        </Alert>
      </Snackbar>
    );
  };

  // If should show Verify button

  const isCanVerifyCell = useCallback(() => {
    let isPhoneInput;
    if (countryObj && countryObj.cell_regexp) {
      //  We can test
      const regExp = new RegExp(countryObj.cell_regexp);
      isPhoneInput = regExp.test(values.cell);
    } else {
      isPhoneInput = values.cell && values.cell.length !== 0;
    }

    return isPhoneInput && !values.isCellConfirmed;
  }, [countryObj, values.cell, values.isCellConfirmed]);

  const isShowVerify = isCanVerifyCell();

  // Avatar

  const handleAvatarChange = (e) => {
    const selectedFile = e.target.files[0];
    // console.log(selectedFile);
    const reader = new FileReader();

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setPrevProfileImage(reader.result);
      };
      setShowCrop(true);
    }
  };

  const handleCropConfirm = (file) => {
    setValues({ ...values, profilePicture: file });

    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPrevProfileImage(reader.result);
      };
    }
  };

  console.log('state', values);

  return (
    <>
      <PromoModal
        show={isShowPromoModal}
        promoCode={promoCode}
        setPromoCode={setPromoCode}
        close={() => setShowPromoModal(false)}
        onPromoActivation={handlePromoConfirmed}
      />

      <TermsAndConditionsModal
        show={isShowTermsAndConditions}
        close={() => setShowTermsAndConditions(false)}
        onAcceptClick={handleAcceptClick}
        text={contract_text.current}
      />

      {/* Phone */}
      <TOTPModal
        show={isShowTOTPModal}
        close={() => setShowTOTPModal(false)}
        onVerificationSuccess={handleVerificationSuccess}
        onThreeTimesWrong={handleThreeTimesWrongSMSCode}
        phoneNumber={values.cell}
      />

      <form className="registration_form">
        <Grid container>
          {/* Alert */}
          <Grid item sm={12}>
            <AlertMessage />
          </Grid>
          {/* IP check */}
          <Grid item sm={12}>
            <Typography>CHECK IP: {clientIpAddress}</Typography>
          </Grid>
          {/* Left Column */}
          <Grid item sm={6} className="no-padding" ref={upperPartRef}>
            {/* Country */}
            <Grid item>
              <CountryInput
                country={values.country}
                handleChangeCountry={handleChangeCountry}
                errors={errors.country}
              />
            </Grid>
            {/* Fullname */}
            <Grid item className="no-padding">
              <FullNameInput
                onInputChange={handleChange}
                values={values}
                errors={errors}
                include={['first_name', 'last_name']}
                direction="column"
                textFieldClass="register_form_input"
              />
            </Grid>
            {/* Company name */}
            <Grid item>
              <CompanyNameInput
                // companyName={values.company_name}
                companyId={values.company_id}
                onToggle={handleApplicableToggle}
                isApplicable={values.isApplicable}
                handleChangeCompanyId={handleChangeCompanyId}
                handleChangeCompany={handleChangeCompany}
                errors={errors.company_name}
              />
            </Grid>
          </Grid>
          {/* Right column */}
          <Grid item sm={6} className="no-padding">
            {/* Picture and PIN */}
            <ImageCrop
              show={isShowCrop}
              close={() => setShowCrop(false)}
              src={prevProfileImage}
              handleCropConfirm={handleCropConfirm}
            />
            <PictureAndPINInput
              // ref={picPinRef}
              picture={
                prevProfileImage ? prevProfileImage : defaultProfileImage
              }
              handleAvatarChange={handleAvatarChange}
              PIN={values.pin}
              pinErrors={errors.pin}
              picErrors={errors.profilePicture}
              onPINChange={handleChange}
            />
          </Grid>
          {/* Job Title and confirm PIN*/}
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6}>
              <JobTitleInput
                jobTitle={values.job_title_id}
                onChange={handleChange}
                errors={errors.job_title_id}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PINInput
                onPINChange={handleChange}
                PIN={values.confirmPin}
                errors={errors.confirmPin}
                isConfirm
              />
            </Grid>
          </Grid>
          {/* Department */}
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6}>
              <DepartmentListInput
                department={values.department_id}
                onChange={handleChange}
                errors={errors.department_id}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* Blank */}
            </Grid>
          </Grid>

          {/* Email and phone*/}
          <Grid container spacing={0}>
            {/* Email */}
            <Grid item xs={12} sm={6}>
              <EmailInput
                handleEmailChange={handleChange}
                handleEmailVerified={handleEmailVerified}
                handleThreeTimesWrong={handleThreeTimesWrongSMSCode}
                isConfirmed={values.isEmailConfirmed}
                email={values.email}
                errors={errors.email}
              />
            </Grid>
            {/* Phone */}
            <Grid item xs={12} sm={6} className="no-padding">
              <Grid
                container
                alignItems="center"
                direction="row"
                wrap="nowrap"
                className="no-padding"
              >
                <Grid item xs={12} sm={isShowVerify ? 8 : 12}>
                  <PhoneInput
                    country={
                      countryObj ? countryObj.alpha2.toLowerCase() : 'us'
                    }
                    specialLabel="phone *"
                    value={values.cell}
                    onChange={handleChangePhoneNumber}
                    inputClass={`phone-number-input ${
                      errors.cell ? 'phone-number-error' : ''
                    }`}
                    containerClass="phone-number-input-field register"
                    disableDropdown={true}
                    disabled={!invite ? false : !!invite.confirm_phone_required}
                    countryCodeEditable={false}
                  />
                  {errors.cell && (
                    <p className="input-error-custom-style">{errors.cell}</p>
                  )}
                </Grid>
                {/* <Grid item> */}
                {values.isCellConfirmed ? (
                  <VerifiedUserIcon color="primary" />
                ) : null}
                {/* </Grid> */}
                {isShowVerify && (
                  <Grid item sm={2}>
                    <Button color="primary" onClick={handleVerifyClick}>
                      Verify
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* Dob & Postal */}
          <Grid container spacing={0} alignItems="flex-start">
            {/* DOB */}
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  disableFuture
                  className="register_form_input"
                  variant="inline"
                  required
                  error={!!errors.dob}
                  inputVariant="outlined"
                  // onError={(value) => value}
                  label="Date of Birth"
                  format="MM/dd/yyyy"
                  placeholder="MM/DD/YYYY"
                  value={values.dob}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => handleBirthDateChange(date)}
                />
              </MuiPickersUtilsProvider>
              {errors.dob && <p className="error">{errors.dob}</p>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <PostalInput
                state={values.state}
                province={values.province}
                city={values.city}
                onPostalChange={handleChangePostCode}
                postalCode={values.postal_code}
                errors={errors.postal_code}
              />
            </Grid>
          </Grid>
          {/* Password */}
          <Grid container>
            <Grid item xs={12} sm={6}>
              <PasswordInput
                handlePasswordChange={handleChange}
                password={values.password}
                errors={errors.password}
              />
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <PasswordInput
                  handlePasswordChange={handleChange}
                  password={values.confirm_password}
                  errors={errors.confirm_password}
                  isConfirm
                />
              </Grid>
            </Grid>
            {/* T&C */}
            <Grid item xs={12}>
              <IconButton
                id="show-promocode"
                className={classes.promocodeBtn}
                onClick={() => setShowPromoModal(true)}
              >
                <HelpIcon color="primary" />
              </IconButton>
              <label htmlFor="show-promocode">Do you have a promo code?</label>
              <br />
              <TermsAndConditonsCheckBox
                onOpenTermsAndConditons={handleOpenTermsAndConditions}
                isAccepted={values.isAcceptedTerms}
                onCheckBoxToggle={handleTermsToggle}
                errors={errors.toc}
              />
            </Grid>
            {/* Submit */}
            <Grid item xs={12} className="register-form-custom-space">
              {isSubmittingRegisterForm ? (
                <Button
                  variant="contained"
                  color="primary"
                  className="register-form_submit"
                  disableRipple
                  disabled
                  type="submit"
                >
                  <CircularProgress
                    size={'1.5em'}
                    className={classes.buttonProgress}
                  />
                  Submitting..
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className="register-form_submit"
                  disableRipple
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              )}
            </Grid>
            {/* Captcha */}
            {/* <ReCaptcha
              ref={reCaptchaRef}
              sitekey="6LfFIb0ZAAAAAJMPqY5GzAWyjWOrvWg9v1a0754b"
              action="submit"
              verifyCallback={verifyCallback}
            /> */}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  member: state.member.member,
  countryList: state.member.countryList,
  terms: state.member.termsConditions,
  // isSubmittingRegisterForm: state.member.isSubmittingRegisterForm,
});

export default connect(mapStateToProps)(RegisterForm);
