import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import Modal from '../../components/Modal/modal.js';
import ContactPreview from '../../components/ContactPreview';

const CallAction = (props) => {
  const { classes } = props;
  const {
    // callStarted,
    partnerInfo,
    memberInfo,
    callEndedByRemote,
    handleCloseCall,
    handleCloseCallFromModal,
    showCallStatusModal,
  } = props;

  const ModalContent = () => {
    let displayStr = 'Call Ended!';
    if (showCallStatusModal) {
      displayStr = partnerInfo
        ? `${partnerInfo.first_name} ${partnerInfo.last_name} is unavailable`
        : '';
    }

    return <div className={classes.modalContentWrapper}>{displayStr}</div>;
  };

  const ModalAction = () => (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleCloseCallFromModal}
    >
      Close
    </Button>
  );
  return (
    <>
      <Grid item md={4} sm={12}>
        {memberInfo && (
          <div className={classes.contactPreviewWrapper}>
            <ContactPreview contactData={memberInfo} isShowAvatar={true} />
          </div>
        )}
        {partnerInfo ? (
          <Button
            className={classes.closeCallBtn}
            fullWidth={true}
            onClick={handleCloseCall}
          >
            <StopIcon />
            Leave call with
            {` ${partnerInfo.first_name} ${partnerInfo.last_name}`}
          </Button>
        ) : (
          <div>
            <div className={classes.callControlWrapper}></div>
          </div>
        )}
      </Grid>
      <Modal
        open={showCallStatusModal || callEndedByRemote}
        onClose={handleCloseCallFromModal}
        title="Amerashare video call"
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        disableBackdropClick={true}
      ></Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  partner: state.one2onevcall.partner,
  // ameraWebrtcClient: state.one2onevcall.ameraWebrtcClient,
  // callStarted: state.one2onevcall.callStarted,
  partnerInfo: state.one2onevcall.partnerInfo,
  callReply: state.event.callReply,
  member_id: state.one2onevcall.member_id,
  memberInfo: state.one2onevcall.memberInfo,
  callEndedByRemote: state.one2onevcall.callEndedByRemote,
});

export default connect(mapStateToProps)(CallAction);
