import {
  SET_REPORT_BUG_ALERT,
  POST_REPORT_START,
  POST_REPORT_SUCCESS,
  FETCH_REPORTS_START,
  FETCH_REPORTS_SUCCESS,
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
} from '../actionTypes/reportBugs';

import { setReportAlert } from '../../utils/alert';

import * as api from '../../config/api';

const apiUrl = api.apiUrl;

export const createBugReport = (formData) => (dispatch) => {
  const url = `${apiUrl}/member/report/bug`;
  dispatch(createReportStart());
  api
    .POST(url, formData, {})
    .then((res) => {
      if (res.success) {
        dispatch(createReportSuccess());
      }
      const payload = setReportAlert(res, res.success);
      dispatch(setReduxReportAlert(payload));
    })
    .catch((error) => {
      const payload = setReportAlert(error, false);
      dispatch(setReduxReportAlert(payload));
    });
};

export const getBugReports = (params) => (dispatch) => {
  return new Promise((resolve) => {
    let uri = `get_all=${params.isCanSeeAll}&search_key=${params.searchKey}&page_number=${params.pageNumber}&page_size=${params.pageSize}&sort=${params.sortBy}&member_id=${params.member_id}`;
    const url = `${api.apiUrl}/member/report/bug?${uri}`;
    dispatch(fetchReportsStart());
    api
      .GET(url)
      .then((res) => {
        if (res && res.success) {
          dispatch(
            fetchReportsSuccess({ count: res.count, reports: res.data })
          );
          const payload = setReportAlert(res, res.success);
          dispatch(setReduxReportAlert(payload));
          resolve(true);
        } else {
          throw new Error('Something went wrong when fetch bug reports');
        }
      })
      .catch((error) => {
        const payload = setReportAlert(error, false);
        dispatch(setReduxReportAlert(payload));
        resolve(false);
      });
  });
};

export const getUsers = (params) => (dispatch) => {
  const url = `${api.apiUrl}/member/report/bug/users?get_all=${params.isCanSeeAll}`;
  dispatch(fetchUsersStart());
  api
    .GET(url)
    .then((res) => {
      if (res && res.success) {
        dispatch(fetchUsersSuccess(res.data));
      } else {
        throw new Error('Something went wrong when fetch bug report users.');
      }
    })
    .catch((error) => {
      const payload = setReportAlert(error, false);
      dispatch(setReduxReportAlert(payload));
    });
};

export const setReduxReportAlert = (payload) => ({
  type: SET_REPORT_BUG_ALERT,
  payload,
});

const createReportStart = () => ({
  type: POST_REPORT_START,
});
const createReportSuccess = () => ({
  type: POST_REPORT_SUCCESS,
});

const fetchReportsStart = () => ({
  type: FETCH_REPORTS_START,
});
const fetchReportsSuccess = (payload) => ({
  type: FETCH_REPORTS_SUCCESS,
  payload,
});

const fetchUsersStart = () => ({
  type: FETCH_USERS_START,
});
const fetchUsersSuccess = (payload) => ({
  type: FETCH_USERS_SUCCESS,
  payload,
});
