import React from 'react';
import Grid from '@material-ui/core/Grid';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import ForumRoundedIcon from '@material-ui/icons/ForumRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';

import {
  LOCATION_TYPES,
  EVENT_TYPES,
  RECURRENCE_FREQ,
  eventDisplayName,
} from '../../utils/calendar';

const EventTitle = ({ event, memberId }) => {
  //   console.log('event in title', event);
  const {
    event_type,
    event_recurrence_freq,
    // host_member_id,
    location_mode,
    location_address,
    attachments,
  } = event;

  const ICON_FONT_SIZE = 'small';

  const recurrenceIcon =
    event_recurrence_freq !== RECURRENCE_FREQ.none ? (
      <ReplayRoundedIcon fontSize={ICON_FONT_SIZE} />
    ) : null;

  let locationIcon = null;
  if (
    event_type === EVENT_TYPES.meeting &&
    (location_mode === LOCATION_TYPES.own ||
      location_mode === LOCATION_TYPES.lookup) &&
    !!location_address
  ) {
    locationIcon = <RoomRoundedIcon fontSize={ICON_FONT_SIZE} />;
  } else if (location_mode === LOCATION_TYPES.url && !!location_address) {
    locationIcon = <LinkRoundedIcon fontSize={ICON_FONT_SIZE} />;
  }

  const attachmentIcon =
    !!attachments && attachments.length > 0 ? (
      <AttachFileRoundedIcon fontSize={ICON_FONT_SIZE} />
    ) : null;

  let eventTypeIcon;
  if (event_type === EVENT_TYPES.meeting) {
    eventTypeIcon = <PeopleAltRoundedIcon fontSize={ICON_FONT_SIZE} />;
  } else if (event_type === EVENT_TYPES.chat) {
    eventTypeIcon = <ForumRoundedIcon fontSize={ICON_FONT_SIZE} />;
  } else if (event_type === EVENT_TYPES.audio) {
    eventTypeIcon = <CallRoundedIcon fontSize={ICON_FONT_SIZE} />;
  } else if (event_type === EVENT_TYPES.video) {
    eventTypeIcon = <VideocamRoundedIcon fontSize={ICON_FONT_SIZE} />;
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      //   alignContent="center"
      spacing={1}
      wrap="nowrap"
      direction="row"
    >
      <Grid item>{eventTypeIcon}</Grid>
      {!!locationIcon && <Grid item>{locationIcon}</Grid>}
      {!!attachmentIcon && <Grid item>{attachmentIcon}</Grid>}
      <Grid item>{eventDisplayName(event, memberId)}</Grid>
      {!!recurrenceIcon && <Grid item>{recurrenceIcon}</Grid>}
    </Grid>
  );
};

export default EventTitle;
