import React from 'react';
import useStyles from './EventModalStyles';
import Button from '@material-ui/core/Button';

const EventModalActions = (props) => {
  const {
    closeLabel,
    onClose,
    isLoading,
    loadingLabel,
    actionLabel,
    onAction,
  } = props;
  const classes = useStyles();
  return (
    <div className="create-event-footer">
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.actionBtn}
          onClick={onClose}
        >
          {closeLabel}
        </Button>
        {isLoading ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            disabled
          >
            {loadingLabel}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            onClick={onAction}
          >
            {actionLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EventModalActions;
