import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';
import { getMemberFiles } from '../../redux/actions/fileshare';
import MainBinSwitch from './MainBinSwitch';
// import FileShareSpinner from './FileShareSpinner';

import MemberMainTree from './MemberMainTree';
import MemberBinTree from './MemberBinTree';

const useStyles = makeStyles((theme) => ({
  memberFilesSection: {
    height: 'calc(100% - 40px)',
    marginTop: 50,
  },
}));

const MemberFiles = (props) => {
  const styles = useStyles();
  const { dispatch, binTree, fileTree } = props;
  const [mode, setMode] = useState('main');
  // const [isLoading, setLoading] = useState(false);

  const handleClick = (e, v) => {
    if (v) {
      setMode(v);
    }
  };

  useEffect(() => {
    // async function fetchData() {
    // setLoading(true);
    dispatch(getMemberFiles());
    // setLoading(false);
    // }
    // fetchData();
  }, [dispatch]);

  return (
    <Col className={styles.memberFilesSection}>
      <MainBinSwitch mode={mode} handleClick={handleClick} />
      {mode === 'bin' ? (
        !!binTree ? (
          <MemberBinTree binTree={binTree} />
        ) : null
      ) : !!fileTree ? (
        <MemberMainTree fileTree={fileTree} />
      ) : null}
      {/* <FileShareSpinner isLoading={isLoading} /> */}
    </Col>
  );
};

const mapStateToProps = (state) => ({
  binTree: state.fileshare.bin_tree,
  fileTree: state.fileshare.main_tree,
});

export default connect(mapStateToProps)(MemberFiles);
