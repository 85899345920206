import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { Link } from 'react-router-dom';
// import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '../../components/Modal/modal';
import IOSSwitch from '../../components/IOSSwitch';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
}));

const SecurityInformation = (props) => {
  const { open, onClose } = props;
  const title = 'Security Preferences';
  const classes = useStyles();
  const [securityQuestion, setSecurityQuestion] = useState(true);
  const [picPin, setPicPin] = useState(false);
  // const [editPassword, setEditPassword] = useState(false);
  // const [editUsername, setEditUsername] = useState(false);
  const [facialRecognition, setFacialRecognition] = useState(false);
  const [smsMultiFactor, setSmsMultiFactor] = useState(false);
  const [dongle, setDongle] = useState(false);
  const [browser, setBrowser] = useState(false);
  const [regionAndCarrierData, setRegionAndCarrierData] = useState(false);

  const ModalContent = () => (
    <div className="profile_page-body">
      <div className="profile_page-security">
        <div className="profile_page-section">
          <div className="page_section-row page_section_description">
            <div className="section-row_label--sub">
              Here are our options to give your account some additional security
            </div>
          </div>

          {/* <div className="page_section-row">
            <div className="section-row_label">
              Username and Password change
            </div>
          </div>
          <div className="page_section-row">
            <div className="profile_page-update-section">
              <div className="input-label">Password</div>
              <div className="input-field">
                {editPassword ? (
                  <TextField
                    id="outlined-edit-password"
                    type="text"
                    variant="outlined"
                  />
                ) : (
                  <TextField
                    disabled
                    id="outlined-password"
                    type="text"
                    variant="filled"
                  />
                )}
              </div>
              <div className="set-edit">
                <Link
                  to="#"
                  className="page_section-button--text"
                  onClick={() => setEditPassword(!editPassword)}
                >
                  {editPassword ? 'Cancel' : 'Change Password'}
                </Link>
              </div>
            </div>
          </div>
          <div className="page_section-row">
            <div className="profile_page-update-section">
              <div className="input-label">Username</div>
              <div className="input-field">
                {editUsername ? (
                  <TextField
                    id="outlined-edit-username"
                    type="text"
                    variant="outlined"
                  />
                ) : (
                  <TextField
                    disabled
                    id="outlined-username"
                    type="text"
                    variant="filled"
                  />
                )}
              </div>
              <div className="set-edit">
                <Link
                  to="#"
                  className="page_section-button--text"
                  onClick={() => setEditUsername(!editUsername)}
                >
                  {editUsername ? 'Cancel' : 'Add'}
                </Link>
              </div>
            </div>
          </div> */}

          <div className="page_section-row">
            <span className="section-row_label">
              Picture and PIN Device Authentication
            </span>
            <IOSSwitch
              checked={picPin}
              onChange={(event) => setPicPin(event.target.checked)}
            />
          </div>
          <div className="page_section-row flex-column">
            <div className="section-row_label--sub">
              Picture and PIN Device authentication if enabled will require you
              to enter a separate Picture and PIN that will be used on any
              device you want to access AmeraShare witih.
            </div>
          </div>

          <div className="page_section-row">
            <span className="section-row_label">Security Questions</span>
            <IOSSwitch
              checked={securityQuestion}
              onChange={(event) => setSecurityQuestion(event.target.checked)}
            />
          </div>
          <div className="page_section-row flex-column">
            <div className="section-row_label--sub">
              Security questions will be used for Username and Password recovery
              if you forget your username and/or password and cannot access your
              email.
            </div>
          </div>

          <div className="page_section-row flex-column">
            <span className="section-row_label">Facial Recognition</span>
            <IOSSwitch
              checked={facialRecognition}
              onChange={(event) => setFacialRecognition(event.target.checked)}
            />
          </div>

          <div className="page_section-row flex-column">
            <span className="section-row_label">SMS Multi Factor</span>
            <IOSSwitch
              checked={smsMultiFactor}
              onChange={(event) => setSmsMultiFactor(event.target.checked)}
            />
          </div>

          <div className="page_section-row flex-column">
            <span className="section-row_label">Dongle</span>
            <IOSSwitch
              checked={dongle}
              onChange={(event) => setDongle(event.target.checked)}
            />
          </div>

          <div className="page_section-row flex-column">
            <span className="section-row_label">Browser</span>
            <IOSSwitch
              checked={browser}
              onChange={(event) => setBrowser(event.target.checked)}
            />
          </div>

          <div className="page_section-row flex-column">
            <span className="section-row_label">Region and Carrier Data</span>
            <IOSSwitch
              checked={regionAndCarrierData}
              onChange={(event) =>
                setRegionAndCarrierData(event.target.checked)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          disableRipple
          className={classes.actionBtn}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      ></Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityInformation);
