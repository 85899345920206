import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import html2canvas from 'html2canvas';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import Modal from '../../components/Modal/modal';
import { getCanvasBlob } from '../../utils/file';
import { getBrowserDetails } from '../../utils/general';
import { textBlue } from '../../jss/colors';

import useScreenFeedback from '../../hooks/useScreenFeedback';
import {
  createBugReport,
  setReduxReportAlert,
} from '../../redux/actions/reportBugs';

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    padding: '0.5rem',
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
  inputContainer: {
    minWidth: '545px',
  },
  screenshotContainer: {
    position: 'relative',
    width: '320px',
    height: '180px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
  },
  canvasContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0)',
    cursor: 'pointer',
    '&:hover': {
      color: textBlue,
      background: 'rgba(255, 255, 255, 0.7)',
    },
  },
  feedbackSubmitBtn: {
    position: 'fixed',
    top: '20px',
    left: 'calc(50% - 102px)',
    zIndex: 1001,
  },
}));

const ReportBugs = (props) => {
  const title = 'Report Bugs';
  const classes = useStyles();
  const dispatch = useDispatch();
  const { openFeedback, closeFeedback, cancelFeedback } = useScreenFeedback();
  const { reduxState, loading, reportAlert } = useSelector((state) => ({
    reduxState: state,
    loading: state.reportBugs.loading,
    reportAlert: state.reportBugs.reportAlert,
  }));
  const { open, setOpen } = props;

  const [description, setDescription] = useState('');
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [canvas, setCanvas] = useState(null);
  const [enableScreenshot, setEnableScreenshot] = useState(true);

  const onSubmit = async () => {
    const fileName = 'page_screenshot.jpg';
    const formData = new FormData();
    formData.append('redux_state', JSON.stringify(reduxState));
    formData.append('description', description);

    // get user browser, system and network details
    const browserInfo = getBrowserDetails();
    formData.append('browser_info', JSON.stringify(browserInfo));

    // get screenshot image
    let blob, file;
    if (canvas && enableScreenshot) {
      // convert canvas to binary file
      blob = await getCanvasBlob(canvas, fileName);
      file = new File([blob], blob.name, { type: 'image/jpeg' });
      formData.append('file', file);
      formData.append('file_name', blob.name);
      formData.append('file_size', file.size);
      formData.append('file_iv', file.iv);
    } else {
      formData.append('file', '');
    }
    dispatch(createBugReport(formData));
  };

  useEffect(() => {
    // https://stackoverflow.com/a/59162619/7078805
    // fix: show svg icons in screenshot
    let svgElements = document.body.querySelectorAll('svg');
    svgElements.forEach(function (item) {
      item.setAttribute('width', item.getBoundingClientRect().width);
      item.setAttribute('height', item.getBoundingClientRect().height);
      item.style.width = null;
      item.style.height = null;
    });
  }, []);
  useEffect(() => {
    if (open && enableScreenshot) {
      setCanvasElement(canvas);
    }
  }, [enableScreenshot, open, canvas]);

  const setCanvasElement = (el) => {
    try {
      if (el) {
        el.style.width = '100%';
        el.style.height = '100%';
      }
      let parent = document.getElementById('captured-screenshot');
      if (parent) {
        let childCanvas = parent.getElementsByTagName('canvas')[0];
        if (childCanvas) {
          childCanvas.replaceWith(el);
        } else {
          parent.append(el);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const showFeedback = () => {
    openFeedback();
    setIsFeedbackOpen(true);
    setOpen(false);
  };
  const hideFeedback = async () => {
    setOpen(true);
    setIsFeedbackOpen(false);
    try {
      const capturedCanvas = await closeFeedback();
      setCanvasElement(capturedCanvas);
      setCanvas(capturedCanvas);
    } catch (error) {
      console.error('something went wrong', error);
      cancelFeedback();
    }
  };
  const onEnter = async () => {
    if (!canvas) {
      const capturedCanvas = await html2canvas(document.body);
      setCanvasElement(capturedCanvas);
      setCanvas(capturedCanvas);
    }
  };
  const closeModal = useCallback(() => {
    setOpen(false);
    setCanvas(null);
  }, [setOpen, setCanvas]);

  const handleSnackbarClose = useCallback(() => {
    if (reportAlert.show) {
      const alertOption = {
        show: false,
        variant: reportAlert.variant,
        message: '',
      };
      dispatch(setReduxReportAlert(alertOption));
    }
  }, [dispatch, reportAlert]);
  useEffect(() => {
    if (reportAlert.show) {
      setTimeout(() => {
        if (reportAlert.variant === 'success') {
          closeModal();
        }
        handleSnackbarClose();
      }, 5000);
    }
  }, [reportAlert, handleSnackbarClose, closeModal]);

  const ModalContent = () => (
    <>
      <div className={classes.contentRoot}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextField
              id="outlined-multiline-static"
              label="Bug Details"
              fullWidth
              multiline
              rows={3}
              placeholder="Please provide as much information as possible."
              variant="outlined"
              autoFocus
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} fullWidth>
            <Collapse in={reportAlert.show}>
              <Alert
                onClose={handleSnackbarClose}
                severity={reportAlert.variant}
              >
                {reportAlert.message}
              </Alert>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableScreenshot}
                  onChange={(e) => setEnableScreenshot(e.target.checked)}
                  name="enable-screenshot"
                  color="primary"
                />
              }
              label="Include Screenshot"
            />
          </Grid>
          {enableScreenshot && (
            <Grid item xs={12}>
              <div
                id="captured-screenshot"
                className={classes.screenshotContainer}
              >
                <div className={classes.canvasContainer} onClick={showFeedback}>
                  {canvas ? (
                    <Typography>Click to highlight info</Typography>
                  ) : (
                    <CircularProgress size={24} color="secondary" />
                  )}
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          disabled={loading}
          disableRipple
          className={classes.actionBtn}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableRipple
          className={classes.actionBtn}
          onClick={onSubmit}
        >
          {loading ? (
            <CircularProgress size={24} color="secondary" />
          ) : (
            'Submit'
          )}
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        onEnter={onEnter}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      ></Modal>
      {isFeedbackOpen && (
        <Button
          onClick={hideFeedback}
          variant="contained"
          color="primary"
          size="large"
          className={classes.feedbackSubmitBtn}
          data-html2canvas-ignore
          startIcon={<AspectRatioIcon />}
        >
          Take Screenshot
        </Button>
      )}
    </>
  );
};

export default ReportBugs;
