import React from 'react';
import Typography from '@material-ui/core/Typography';

const DrivingDirectionDetails = ({ directions }) => {
  const { distance, duration /*, steps*/ } = directions.routes[0].legs[0];
  return (
    <Typography variant="body1">
      Driving time {duration.text}, distance {distance.text}
    </Typography>
  );
};

export default DrivingDirectionDetails;
