import React from 'react';
import Header, { messages as headerMessages } from '../Header';
import Sidebar, { messages as sidebarMessages } from '../Sidebar';
import Content from '../Content';

export const messages = { ...headerMessages, ...sidebarMessages };

const Layout = (props) => {
  return (
    <div>
      <Header />
      <div className="main">
        <Sidebar {...props} />
        <Content {...props} />
      </div>
    </div>
  );
};

export default Layout;
