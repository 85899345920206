import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import IconButton from '@material-ui/core/IconButton';
// import clockPng from '../../../assets/img/clock.png';
// import goPng from '../../../assets/img/go.png';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Sound from 'react-sound';
import ringtone from '../../../assets/media/alarm_gentle.mp3';
import { sortBy, last } from 'lodash';
import {
  answerOne2oneCall,
  answerGroupCall,
  answerGroupVideoCall,
} from '../../../redux/actions/event';

let callData;

const CallNotification = (props) => {
  const { calls, member_id, dispatch } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [playStatus, setPlayStatus] = useState('PLAYING');

  const isIncomingCall = calls.length > 0;
  callData = last(sortBy(calls, (c) => c.start_time));

  const handleCallAction = (event, member_id) => {
    const { type, call_url } = callData;

    const option = event.target.id;
    const external = call_url !== '' && call_url.indexOf('http') === 0;
    setAnchorEl(null);
    let answerCallFunc = answerOne2oneCall;
    if (type === 'group' && external) {
      answerCallFunc = answerGroupCall;
    } else if (type === 'group' && !external) {
      answerCallFunc = answerGroupVideoCall;
    }

    if (option === 'accept') {
      dispatch(answerCallFunc(callData, { reply_type: 'accept', member_id }));
    } else if (option === 'decline') {
      // dispatch(callToVoiceMail(callData));
      dispatch(answerCallFunc(callData, { reply_type: 'decline', member_id }));
    }
  };

  let label, containerClasses, actionBox;

  if (isIncomingCall) {
    // console.log('Will play');
    // play();
    const { type, caller_name, group_name } = callData;
    if (type === 'person') {
      label = `${caller_name} calling`;
    } else if (type === 'group') {
      label = `${group_name} conference call`;
    }
    containerClasses = 'call-notification upcoming';
    actionBox = (
      <span className="action-box">
        <WatchLaterIcon />
        {/* <img src={clockPng} alt="clock" /> */}
        <span className="time">{format(new Date(), 'KK:mm bb')}</span>
        {/* <img src={goPng} alt="go" /> */}
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <PlayCircleFilledIcon className="go-btn" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          onClick={(event) => {
            handleCallAction(event, member_id);
          }}
        >
          <MenuItem id="accept" key="accept">
            Accept
          </MenuItem>
          <MenuItem id="decline" key="decline">
            Decline
          </MenuItem>
        </Menu>
      </span>
    );
  } else {
    label = 'No notifications';
    containerClasses = 'call-notification no-calls';
    actionBox = null;
  }

  return (
    <div className={containerClasses}>
      {isIncomingCall && (
        <Sound
          url={ringtone}
          playStatus={playStatus}
          volume={75}
          onFinishedPlaying={() => setPlayStatus('STOPPED')}
        />
      )}
      <div className="label">{label}</div>
      {actionBox}
    </div>
  );
};

CallNotification.propTypes = {
  dispatch: PropTypes.func,
  calls: PropTypes.array,
  member_id: PropTypes.any,
};

CallNotification.defaultProps = {
  dispatch: null,
  calls: [],
};

const mapStateToProps = (state) => ({
  calls: state.event.incomingCalls,
  member_id: state.member.member.member_id,
});

export default connect(mapStateToProps)(CallNotification);
