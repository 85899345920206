import React from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-multi-carousel';
import { Col } from 'react-bootstrap';
import UpcomingEventCard from '../../components/Dashboard/UpcomingEventCard';
import EmptyCard from '../../components/Dashboard/EmptyCard';
import CustomArrowButtons from '../../components/Carousel/CustomArrowButtons';

const UpcomingEvent = (props) => {
  const { upcomingEvents, member, showDetail } = props;

  if (upcomingEvents && upcomingEvents.length === 1) {
    upcomingEvents.push({ id: 'empty' });
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1350 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1350, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      swipeable={true}
      infinite={false}
      draggable={false}
      arrows={false}
      responsive={responsive}
      autoPlay={false}
      keyBoardControl={false}
      containerClass="carousel-container"
      className="react-multi-carousel-list"
      sliderClass={
        upcomingEvents && upcomingEvents.length < 1
          ? 'react-multi-carousel-track-w-100'
          : ''
      }
      renderButtonGroupOutside={true}
      customButtonGroup={
        upcomingEvents && upcomingEvents.length > 2 && <CustomArrowButtons />
      }
    >
      {upcomingEvents && upcomingEvents.length > 0
        ? upcomingEvents.map((event, index) => (
            <Col className="meeting-card" key={index}>
              {event.id === 'empty' ? (
                <EmptyCard
                  key={event.id}
                  type="invitation"
                  message="No upcoming event."
                />
              ) : (
                <UpcomingEventCard
                  key={event.id}
                  type="event-upcoming"
                  data={event}
                  member={member}
                  showDetail={showDetail}
                />
              )}
            </Col>
          ))
        : [0, 1].map((index) => (
            <Col className="meeting-card" key={index}>
              <EmptyCard type="invitation" message="No upcoming event." />
            </Col>
          ))}
    </Carousel>
  );
};

const mapStateToProps = (state) => ({
  upcomingEvents: state.event.upcomingEvents,
  member: state.member.member,
});

export default connect(mapStateToProps)(UpcomingEvent);
