import {
  SET_REPORT_BUG_ALERT,
  POST_REPORT_START,
  POST_REPORT_SUCCESS,
  FETCH_REPORTS_START,
  FETCH_REPORTS_SUCCESS,
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
} from '../actionTypes/reportBugs';

const initialState = {
  loading: false,
  reports: [],
  users: [],
  reportAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

const reportBugs = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_BUG_ALERT:
      return {
        ...state,
        reportAlert: action.payload,
        loading: false,
      };
    case POST_REPORT_START:
    case FETCH_REPORTS_START:
    case FETCH_USERS_START:
      return {
        ...state,
        loading: true,
      };
    case POST_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: action.payload.reports,
        count: action.payload.count,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    default:
      return state;
  }
};

export default reportBugs;
