import * as api from '../../config/api';
import {
  IS_LOADING_CREATE_GROUP,
  IS_LOADING_GET_GROUP_LIST,
  CREATE_GROUP,
  GET_GROUP_LIST,
  GET_GROUP_DETAIL,
  REMOVE_GROUP_MEMBER,
  ADD_GROUP_MEMBER,
  SET_GROUP_ALERT,
  REMOVE_GROUP_ALERT,
  SET_ADD_MEMBER_FROM,
  SET_AMERA_GROUP_SECURITY,
  SET_SHOW_GROUP_DETAIL_MODAL,
  FETCH_GROUP_MEMBERSHIPS_START,
  FETCH_GROUP_MEMBERSHIPS_SUCCESS,
  FETCH_GROUP_MEMBERSHIPS_FAILED,
  GET_CONTACT_GROUP_MEMBERSHIP,
  IS_LOADING_MEMBER_INVITE,
  IS_DELETING_GROUP,
  DELETED_GROUP,
  FAIL_DELETED_GROUP,
  ADD_MEMBER_TO_NEW_GROUP,
  INITIAL_GROUP_MEMBERS,
  SET_GROUP_SECURITY,
  SET_GROUP_DRIVE_ACTIVITY,
  SET_GROUP_CALENDAR_ACTIVITY,
  SET_GROUP_LEADER_DETAIL,
} from '../actionTypes/group';

import { setGroupAlert } from '../../utils/alert';

const apiUrl = api.apiUrl;

// create new group
export const createGroup = (formData) => {
  console.log('create group', formData);
  const url = `${apiUrl}/group`;
  return function (dispatch) {
    dispatch({
      type: IS_LOADING_CREATE_GROUP,
    });
    api
      .POST(url, formData, {})
      .then((res) => {
        dispatch({
          type: CREATE_GROUP,
          payload: res.data,
        });
        const payload = setGroupAlert(res, true, 'Group');
        dispatch(setReduxGroupAlert(payload));
      })
      .catch((error) => {
        dispatch({
          type: CREATE_GROUP,
          payload: [],
        });
        const payload = setGroupAlert(error, false, 'Group');
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

// create new group with extended data

export const createExpandedGroup = (formData) => {
  const url = `${apiUrl}/group`;
  return function (dispatch) {
    dispatch({
      type: IS_LOADING_CREATE_GROUP,
    });
    api
      .POST(url, formData, {})
      .then((res) => {
        dispatch({
          type: CREATE_GROUP,
          payload: res.data,
        });
        const payload = setGroupAlert(res, true, 'Group');
        dispatch(setReduxGroupAlert(payload));
      })
      .catch((error) => {
        dispatch({
          type: CREATE_GROUP,
          payload: [],
        });
        const payload = setGroupAlert(error, false, 'Group');
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

export const deleteGroups = (data) => (dispatch) => {
  const url = `${apiUrl}/group`;
  dispatch({ type: IS_DELETING_GROUP });
  api
    .DELETE(url, data, {})
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DELETED_GROUP,
          payload: res.data,
        });
      }
      dispatch(setShowGroupDetailModal(false));
      const payload = setGroupAlert(res, true, 'Group');
      dispatch(setReduxGroupAlert(payload));
    })
    .catch((error) => {
      dispatch({
        type: FAIL_DELETED_GROUP,
      });
      dispatch(setShowGroupDetailModal(false));
      const payload = setGroupAlert(error, false, 'Group');
      dispatch(setReduxGroupAlert(payload));
    });
};

// get group list for logged in member
export const getGroupList = (all, sortBy, searchKey) => {
  const params = {
    get_all: all ? 1 : '',
    sort: sortBy ? sortBy : '',
    search_key: searchKey ? searchKey : '',
  };
  // const uri = `?get_all=${all ? '1' : ''}&sort=${sortBy}&search_key=${searchKey}`;
  const url = `${apiUrl}/groups?${new URLSearchParams(params).toString()}`;
  return function (dispatch) {
    dispatch({
      type: IS_LOADING_GET_GROUP_LIST,
    });
    api
      .GET(url)
      .then((res) => {
        // console.log('Group list request response', res.data);
        dispatch({
          type: GET_GROUP_LIST,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_GROUP_LIST,
          payload: [],
        });
      });
  };
};

// get group detail information
export const getGroupDetail = (groupId) => {
  const url = `${apiUrl}/group/${groupId}`;
  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        dispatch({
          type: GET_GROUP_DETAIL,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// add member to group
export const addGroupMember = (formData) => {
  const url = `${apiUrl}/groups/membership`;
  return function (dispatch) {
    api
      .POST(url, formData, {})
      .then((res) => {
        dispatch({
          type: ADD_GROUP_MEMBER,
          payload: res.data,
        });
        const payload = setGroupAlert(res, true, 'Member');
        dispatch(setReduxGroupAlert(payload));
      })
      .catch((error) => {
        const payload = setGroupAlert(error, false, 'Member');
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

// remove member from group
export const removeGroupMember = (formData) => {
  const url = `${apiUrl}/groups/membership`;
  return function (dispatch) {
    api
      .DELETE(url, formData, {})
      .then((res) => {
        dispatch({
          type: REMOVE_GROUP_MEMBER,
          payload: res.data,
        });
        const payload = setGroupAlert(res, true, 'Member');
        dispatch(setReduxGroupAlert(payload));
      })
      .catch((error) => {
        const payload = setGroupAlert(error, true, 'Member');
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

// add member to group
export const sendGroupMemberInvite = (formData) => async (dispatch) => {
  const url = `${apiUrl}/member/group/invite`;

  dispatch({
    type: IS_LOADING_MEMBER_INVITE,
  });
  try {
    const res = await api.POST(url, formData, {});
    dispatch({
      type: SET_GROUP_ALERT,
      payload: setGroupAlert(res, true, 'Invite'),
    });
  } catch (error) {
    dispatch({
      type: SET_GROUP_ALERT,
      payload: setGroupAlert(error, false, 'Invite'),
    });
  }
};

export const removeGroupAlert = () => {
  return function (dispatch) {
    dispatch({
      type: REMOVE_GROUP_ALERT,
      payload: null,
    });
  };
};

export const switchAddMemberForm = () => {
  return function (dispatch) {
    dispatch({
      type: SET_ADD_MEMBER_FROM,
      payload: true,
    });
  };
};

export const setInitAlert = () => {
  return function (dispatch) {
    const payload = {
      show: false,
      variant: 'success',
      message: 'none',
    };
    dispatch(setReduxGroupAlert(payload));
  };
};

export const setAmeraGroupSecurity = (payload) => {
  return function (dispatch) {
    dispatch({
      type: SET_AMERA_GROUP_SECURITY,
      payload,
    });
  };
};

export const setShowGroupDetailModal = (
  showGroupDetailModal,
  isDrive = false
) => {
  return function (dispatch) {
    dispatch({
      type: SET_SHOW_GROUP_DETAIL_MODAL,
      payload: {
        showGroupDetailModal,
        isDrive,
      },
    });
  };
};

// get group membership with redux
export const getGroupMemberShip = (sortBy, searchKey) => {
  const params = {
    sort: sortBy ? sortBy : '',
    search_key: searchKey ? searchKey : '',
  };
  // const url = `${apiUrl}/groups/membership?sort=${sortBy}&search_key=${searchKey}`;
  const url = `${apiUrl}/groups/membership?${new URLSearchParams(
    params
  ).toString()}`;

  return function (dispatch) {
    dispatch(fetchGroupMembershipsStart());
    api
      .GET(url, {})
      .then((res) => {
        dispatch(fetchGroupMembershipsSuccess(res.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchGroupMembershipsFailed(error));
      });
  };
};

// get contact group membership with redux
export const getContactGroupMemberShip = (params = {}) => {
  const url = `${apiUrl}/groups/membership?${new URLSearchParams(
    params
  ).toString()}`;
  return function (dispatch) {
    api
      .GET(url, {})
      .then((res) => {
        dispatch({
          type: GET_CONTACT_GROUP_MEMBERSHIP,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// get group membership list
// export const getGroupMemberShip = () => {
//   const url = `${apiUrl}/groups/membership`;
//   return api.GET(url, {});
// };

export const setReduxGroupAlert = (payload) => ({
  type: SET_GROUP_ALERT,
  payload,
});

export const addMembersToNewGroup = (payload) => ({
  type: ADD_MEMBER_TO_NEW_GROUP,
  payload,
});

export const initialGroupMembers = (payload) => ({
  type: INITIAL_GROUP_MEMBERS,
  payload,
});

export const updateSecurity = (formData, group_id) => {
  const url = `${apiUrl}/member/group/security/${group_id}`;
  return function (dispatch) {
    api
      .POST(url, formData, {})
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {});
  };
};

export const getGroupSecurity = (group_id) => {
  console.log('++++++++++++++++++++++++++++++', group_id);
  const url = `${apiUrl}/member/group/security/${group_id}`;
  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        dispatch({ type: SET_GROUP_SECURITY, payload: res.data });
      })
      .catch((error) => {});
  };
};

export const fetchGroupMembershipsStart = () => ({
  type: FETCH_GROUP_MEMBERSHIPS_START,
});
export const fetchGroupMembershipsSuccess = (payload) => ({
  type: FETCH_GROUP_MEMBERSHIPS_SUCCESS,
  payload,
});
export const fetchGroupMembershipsFailed = (payload) => ({
  type: FETCH_GROUP_MEMBERSHIPS_FAILED,
  payload,
});

export const getGroupDriveActivity = (group_id) => {
  const url = `${apiUrl}/group/activity/drive/${group_id}`;
  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        dispatch({ type: SET_GROUP_DRIVE_ACTIVITY, payload: res.data });
      })
      .catch((error) => {});
  };
};

export const getGroupCalendarActivity = (group_id) => {
  const url = `${apiUrl}/group/activity/calendar/${group_id}`;
  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        dispatch({ type: SET_GROUP_CALENDAR_ACTIVITY, payload: res.data });
      })
      .catch((error) => {});
  };
};

export const getGroupLeaderDetail = (group_leader_id) => {
  const url = `${apiUrl}/member/info/${group_leader_id}`;
  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        dispatch({
          type: SET_GROUP_LEADER_DETAIL,
          payload: {
            title: res.data.job_title,
            company: res.data.company_name,
            amera_avatar_url: res.data.amera_avatar_url,
            email: res.data.email,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
