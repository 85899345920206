import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import MemberLocationPreview from '../../../components/MemberLocationPreview';
import { ADDRESS_TYPES } from '../../../utils/calendar';
import { connect } from 'react-redux';

const MemberLocationSelect = ({ value, handleChange, locationObjects }) => {
  console.debug(locationObjects, value);

  return !!locationObjects && !!locationObjects[0] ? (
    <FormControl fullWidth>
      <InputLabel id="label-mylocations">My locations</InputLabel>
      <Select
        labelId="label-mylocations"
        id="event-location-type-select"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        label="Location option"
      >
        {locationObjects.map((lo) => (
          <MenuItem
            key={lo.id}
            value={lo.id}
            // style={{ paddingTop: '4px', paddingBottom: '4px' }}
          >
            <MemberLocationPreview data={lo} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <TextField
      value="Your Location List is Empty"
      // disabled
      fullWidth
    />
  );
};

// Don't show Billing addressses
const mapStateToProps = (state) => ({
  locationObjects: state.member.memberInfo.location_information.filter(
    (lo) => lo && lo.location_type !== ADDRESS_TYPES.billing
  ),
});

export default connect(mapStateToProps)(MemberLocationSelect);
