import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import Grid from '@material-ui/core/Grid';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import InputBase from '@material-ui/core/InputBase';
// import SearchIcon from '@material-ui/icons/Search';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import SettingsIcon from '@material-ui/icons/Settings';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import CardContainer from './CardContainer';
import ListContainer from './ListContainer';
import NavigationGroup from './NavigationGroup';
import SortBySettingsModal from '../../components/Modal/SortBySettingsModal';

import { getGroupMemberShip } from '../../redux/actions/group';

const useStyles = makeStyles((theme) => ({
  groupTool: {
    marginTop: '20px',
  },
  sortGroup: {
    float: 'right',
    paddingRight: '20px',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    width: '92%',
  },
  groupSettingBtn: {
    marginLeft: theme.spacing(1.25),
    marginRight: theme.spacing(1.25),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  groupSettingSVG: {
    '&:hover': {
      cursor: 'pointer',
      color: '#868282',
    },
  },
}));

const GroupMembership = (props) => {
  const { loading, dispatch, groupMembershipList, location } = props;
  // console.log('Group membership location', location.state);
  const classes = useStyles();
  const [viewCard, setViewCard] = useState(true);
  const [orderBy, setOrderBy] = useState('group_name');
  const [view, setView] = useState('module');
  const [searchKey, setSearchKey] = useState('');
  const [showGroupSortSetting, setShowGroupSortSetting] = useState(false);

  const toggleCard = (event, nextView) => {
    if (nextView !== null) {
      setViewCard(!viewCard);
      setView(nextView);
    }
  };

  useEffect(() => {
    dispatch(getGroupMemberShip());
  }, [dispatch]);

  const loadSortedGroupMemberShip = (property, order) => {
    let sortBy = '';
    sortBy = order === 'desc' ? `-${property}` : `${property}`;

    dispatch(getGroupMemberShip(sortBy, searchKey));
    setOrderBy(property);
  };

  // const sortBy = (e) => {
  //   e.preventDefault();
  //   const key = e.target.value;
  //   const groupMembers = sortMember(groupDetail.members, key);
  //   setGroupDetail({
  //     ...groupDetail,
  //     members: groupMembers,
  //   });
  // };

  // const sortMember = (members, sortKey) => {
  //   let newMembers = [];
  //   if (sortKey === 'Name') {
  //     newMembers = members.sort(function (a, b) {
  //       if (a.first_name < b.first_name) {
  //         return -1;
  //       }
  //       if (a.first_name > b.first_name) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //   } else if (sortKey === 'Joined Date') {
  //     newMembers = members.sort(function (a, b) {
  //       if (new Date(a.joined_date) < new Date(b.joined_date)) {
  //         return -1;
  //       }
  //       if (new Date(a.joined_date) > new Date(b.joined_date)) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //   }
  //   return newMembers;
  // };

  const handleSearch = (e) => {
    const newSearchKey = e.target.value,
      sortBy = orderBy;
    dispatch(getGroupMemberShip(sortBy, newSearchKey));
    setSearchKey(newSearchKey);
  };

  return (
    <React.Fragment>
      <div className="group-section view-section">
        <div className="toggle-button-group">
          <NavigationGroup
            type="group-memberships"
            searchKey={searchKey}
            handleSearch={handleSearch}
          />
          <ToggleButtonGroup value={view} exclusive onChange={toggleCard}>
            <ToggleButton value="module" aria-label="module">
              <Tooltip title="Group Tile">
                <ViewModuleIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="list" aria-label="list">
              <Tooltip title="Group List">
                <ViewListIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          <div className={classes.groupSettingBtn}>
            <Tooltip title="Settings" arrow>
              <SettingsIcon
                className={classes.groupSettingSVG}
                onClick={() => setShowGroupSortSetting(true)}
              />
            </Tooltip>
          </div>
        </div>
        {/* <Grid container className={classes.groupTool}>
          <Grid item sm={8} xs={12}>
            <div className={classes.search}>
              <div aria-label="search-icon" className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                // onChange={search}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Grid>
        </Grid> */}
        {viewCard ? (
          <CardContainer
            groups={groupMembershipList}
            type="membership"
            openDetailsFor={
              !!location.state ? location.state.openDetailsFor : null
            }
          ></CardContainer>
        ) : (
          <ListContainer
            groups={groupMembershipList}
            type="membership"
            orderBy={orderBy}
            setOrderBy={(property) => setOrderBy(property)}
            handleSortList={loadSortedGroupMemberShip}
            openDetailsFor={
              !!location.state ? location.state.openDetailsFor : null
            }
          ></ListContainer>
        )}
      </div>
      <SortBySettingsModal
        type="group_memberships"
        loading={loading}
        open={showGroupSortSetting}
        orderBy={orderBy}
        onClose={() => setShowGroupSortSetting(false)}
        setOrderBy={setOrderBy}
        onClick={loadSortedGroupMemberShip}
      />
    </React.Fragment>
  );
};

GroupMembership.propTypes = {
  dispatch: PropTypes.func,
  groupMembershipList: PropTypes.arrayOf(PropTypes.object),
};

GroupMembership.defaultProps = {
  dispatch: null,
  groupMembershipList: [],
};

const mapStateToProps = (state) => ({
  loading: state.group.loadingGroupMemberships,
  groupMembershipList: state.group.groupMembershipList,
});

export default connect(mapStateToProps)(GroupMembership);
