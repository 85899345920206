import React, { useState, useEffect } from 'react';

import Navigator from './Navigator';

import navItems, { messages as navMessages } from './navItems';

export const messages = { ...navMessages };

const Sidebar = ({ location: { pathname } }) => {
  const [activeNav, setActiveNav] = useState(0);
  useEffect(() => {
    const navItemIndex = navItems.findIndex((item) =>
      pathname.includes(item.to)
    );
    if (navItemIndex >= 0) {
      setActiveNav(navItemIndex);
    } else {
      setActiveNav(0);
    }
  }, [pathname]);

  return (
    <div className="sidebar">
      <Navigator
        navItems={navItems}
        activeNav={activeNav}
        setActiveNav={setActiveNav}
      />
    </div>
  );
};

export default Sidebar;
