import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import LockIcon from '@material-ui/icons/Lock';
import ShareIcon from '@material-ui/icons/Share';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Divider from '@material-ui/core/Divider';
import { formatDistanceToNow, parseISO, format } from 'date-fns';

import Modal from '../../../../components/Modal/modal';
import SharedWithGroupsList from './SharedWithGroupsList';
import SharedWithMembersList from './SharedWithMembersList';
import MemberCard from './MemberCard';
import {
  deleteShare,
  getGroupFiles,
  getDownloadFile,
} from '../../../../redux/actions/fileshare';

const FileDetail = (props) => {
  const {
    show,
    close,
    groupId,
    isGroupLeader,
    selectedFile,
    memberId,
    onShareAction,
    dispatch,
  } = props;
  const title = 'File Details';

  let history = useHistory();

  const {
    amera_file_url,
    name,
    isEncrypted,
    isDir,
    create_date,
    modDate,
    sharedWithMembers,
    sharedWithGroups,
    sharedWithMe,
  } = selectedFile;

  const handleUnshareClick = async (shareId) => {
    await dispatch(deleteShare(JSON.stringify({ share_id: shareId })));
    if (!!groupId) {
      await dispatch(getGroupFiles(groupId));
    }
  };
  const handleMemberClick = (consumerMemberId) => {
    // Handler to make something on member click
    history.push({
      pathname: `${process.env.PUBLIC_URL}/contacts`,
      state: {
        openDetailsFor: consumerMemberId,
      },
    });
  };
  const handleGroupClick = (consumerGroupId) => {
    // Handler to make something on group click
    const isLeader =
      sharedWithGroups.find((swgo) => swgo.group_id === consumerGroupId)
        .leader_id === memberId;
    const pathName = `${process.env.PUBLIC_URL}${
      isLeader ? '/groups/my-groups' : '/groups/membership'
    }`;
    history.push({
      pathname: pathName,
      state: {
        openDetailsFor: consumerGroupId,
      },
    });
  };

  const handleDenyShareClick = () => {
    handleUnshareClick(sharedWithMe.share_id);
    close();
  };

  const handleShareClick = () => {
    onShareAction([selectedFile]);
    close();
  };

  // Only for own files
  const isFileSharedWithAnybody = !!sharedWithMembers || !!sharedWithGroups;

  // These are used when accessing through group files
  const isMyShareWithGroup =
    !!sharedWithMe && sharedWithMe.sharer_id === memberId;
  // const isImLeaderOfGroup =

  const memberShareData = (
    <>
      {!isFileSharedWithAnybody ? (
        <>
          <ListItem key="sharing_status">
            <ListItemIcon>
              <span>{'Shared status:'}</span>
            </ListItemIcon>
            <ListItemText primary={'File not shared'} />
          </ListItem>
        </>
      ) : null}
      {sharedWithGroups && (
        <>
          <Divider />
          <ListItem key="consumer_groups">
            <ListItemIcon>
              <span>{'Shared with groups:'}</span>
            </ListItemIcon>
            <ListItemText
              primary={
                <SharedWithGroupsList
                  sharedWithGroups={sharedWithGroups}
                  handleUnshareClick={handleUnshareClick}
                  handleGroupClick={handleGroupClick}
                  memberId={memberId}
                />
              }
            />
          </ListItem>
        </>
      )}
      {sharedWithMembers && (
        <>
          <Divider />
          <ListItem key="consumer_members">
            <ListItemIcon>
              <span>{'Shared with members:'}</span>
            </ListItemIcon>
            <ListItemText
              primary={
                <SharedWithMembersList
                  sharedWithMembers={sharedWithMembers}
                  handleUnshareClick={handleUnshareClick}
                  handleMemberClick={handleMemberClick}
                />
              }
            />
          </ListItem>
          <Divider />
        </>
      )}
      {sharedWithMe && (
        <div className="gutters">
          <ListItem key="shared_with_me" className="member_item">
            <ListItemIcon>
              <span>Shared by:</span>
            </ListItemIcon>
            <ListItemText
              primary={
                <MemberCard
                  member_id={sharedWithMe.sharer_id}
                  first_name={sharedWithMe.sharer_first_name}
                  last_name={sharedWithMe.sharer_last_name}
                  shared_date={sharedWithMe.shared_date}
                  job_title={sharedWithMe.job_title}
                  department={sharedWithMe.department}
                  company_name={sharedWithMe.sharer_company_name}
                  handleMemberClick={handleMemberClick}
                />
              }
            />
            <ListItemSecondaryAction>
              <Button onClick={handleDenyShareClick}>deny</Button>
            </ListItemSecondaryAction>
          </ListItem>
        </div>
      )}
    </>
  );

  // Here sharedWithMe represents the share made with the group

  const groupShareData = sharedWithMe && (
    <div className="gutters">
      <ListItem key="shared_with_this_group" className="member_item">
        <ListItemIcon>
          <span>Shared by:</span>
        </ListItemIcon>
        <ListItemText
          primary={
            <MemberCard
              member_id={sharedWithMe.sharer_id}
              first_name={
                isMyShareWithGroup ? 'me' : sharedWithMe.sharer_first_name
              }
              last_name={
                isMyShareWithGroup ? '' : sharedWithMe.sharer_last_name
              }
              shared_date={sharedWithMe.shared_date}
              job_title={sharedWithMe.job_title}
              department={sharedWithMe.department}
              company_name={sharedWithMe.sharer_company_name}
              handleMemberClick={isMyShareWithGroup ? null : handleMemberClick} // We dont want
            />
          }
        />
        {isMyShareWithGroup || isGroupLeader ? (
          <ListItemSecondaryAction>
            <Button onClick={handleDenyShareClick}>
              {isMyShareWithGroup ? 'Unshare' : 'Deny'}
            </Button>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    </div>
  );

  const ModalContent = () => (
    <List component="nav" aria-label="secondary mailbox folders">
      <ListItem key="name">
        <ListItemIcon>
          <span> {isDir ? `Folder name:  ` : `File name: `}</span>
        </ListItemIcon>
        <ListItemText
          primary={
            // <Button
            //   startIcon={isDir ? null : <CloudDownloadIcon />}
            //   onClick={isDir ? null : handleDownloadClick}
            // >
            name
            // </Button>
          }
        />
      </ListItem>
      <ListItem key="encrypted">
        <ListItemIcon>
          <span>{'Encryption: '}</span>
        </ListItemIcon>
        <ListItemText
          primary={
            isEncrypted ? (
              <span>
                <LockIcon color="primary" fontSize="inherit" /> AMERA Encrypted
              </span>
            ) : (
              <span>
                <NoEncryptionIcon fontSize="inherit" /> No encryption
              </span>
            )
          }
        />
      </ListItem>

      {!!groupId ? groupShareData : memberShareData}

      <ListItem key="update-date">
        <ListItemIcon>
          <span>{'Last Updated: '}</span>
        </ListItemIcon>
        <ListItemText
          primary={formatDistanceToNow(parseISO(modDate), { addSuffix: true })}
        />
      </ListItem>
      <ListItem key="create-date">
        <ListItemIcon>
          <span>{'Date Created: '}</span>
        </ListItemIcon>
        <ListItemText primary={format(parseISO(create_date), 'MM/dd/yyyy')} />
      </ListItem>
    </List>
  );

  const ModalAction = () => (
    <>
      {!!onShareAction && (
        <Button
          startIcon={<ShareIcon />}
          variant="outlined"
          onClick={handleShareClick}
        >
          Share
        </Button>
      )}
      {!isDir && (
        <Button
          startIcon={<CloudDownloadIcon />}
          onClick={() => getDownloadFile(amera_file_url, name)}
          variant="outlined"
        >
          Download
        </Button>
      )}
    </>
  );

  return (
    <>
      <Modal
        open={show}
        onClose={close}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        className={`file-view-detail-modal ${
          sharedWithMe || sharedWithMembers || sharedWithGroups ? 'shared' : ''
        }`}
      ></Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  memberId: state.member.member.member_id,
});

export default connect(mapStateToProps)(FileDetail);
