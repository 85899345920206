import React, { useState } from 'react';
import Modal from '../../modal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TitleAndDatetime from './TitleAndDatetime';
import EventLocation from './EventLocation';
import InvitedMembers from './InvitedMembers';
import EventDetails from './EventDetails';
import Attachments from './Attachments';
import EventLink from './EventLink';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import HostInfo from './HostInfo';
import EventCover from './EventCover';
import { eventTypeText, EVENT_TYPES } from '../../../../utils/calendar';
import { apiUrl } from '../../../../config/api';

const EventInfoModal = (props) => {
  const {
    show,
    onClose,
    selectedEvent,
    onEditClick,
    onCancelClick,
    isEditable,
  } = props;

  const [placePhoto, setPlacePhoto] = useState(null);

  console.log('selectedEvent', selectedEvent);
  const {
    event_type,
    location_id,
    location_address,
    host_member_info,
    invitations,
    attachments,
    event_description,
    group_info,
    cover_attachment_id,
  } = selectedEvent;

  const isHasLocation =
    (event_type === EVENT_TYPES.meeting ||
      event_type === EVENT_TYPES.personal) &&
    (!!location_id || !!location_address);
  const isHasLink =
    (event_type === EVENT_TYPES.chat ||
      event_type === EVENT_TYPES.video ||
      event_type === EVENT_TYPES.webinar) &&
    !!location_address &&
    location_address !== '';
  const isHasMembers =
    !!invitations && invitations.length > 0 && !!invitations[0];
  const isHasAttachment =
    !!attachments && attachments.length > 0 && !!attachments[0];

  // Cover image is either provided when scheduled OR restraunt / bar image
  let coverImage = null;
  if (!!cover_attachment_id && attachments?.length > 0) {
    const coverAttachmentObj = attachments.find(
      (afo) => afo.member_file_id === cover_attachment_id
    );
    coverImage = `${apiUrl}/${coverAttachmentObj?.file_url}`;
  } else if (!!placePhoto) {
    coverImage = placePhoto;
  }

  const handlePlaceData = async (place) => {
    const picUrl = await place.photos[0].getUrl({ maxWidth: 600 });
    setPlacePhoto(picUrl);
  };

  const ModalContent = () => (
    <Grid container spacing={2}>
      {!!coverImage && <EventCover imageUrl={coverImage} />}
      <TitleAndDatetime event={selectedEvent} />
      {isHasLocation && (
        <EventLocation event={selectedEvent} onPlaceData={handlePlaceData} />
      )}
      {isHasLink && <EventLink link={location_address} />}
      {isHasMembers && (
        <InvitedMembers groupInfo={group_info} invitations={invitations} />
      )}
      {!!event_description && <EventDetails details={event_description} />}
      {isHasAttachment && <Attachments attachments={attachments} />}
      <HostInfo hostInfo={host_member_info} />
    </Grid>
  );
  const ModalAction = () => (
    <Grid
      container
      spacing={2}
      style={{ padding: '0.5rem 1.5rem' }}
      justify="space-between"
    >
      <Grid item>
        {isEditable && (
          <>
            <Button startIcon={<EditIcon />} onClick={onEditClick}>
              Edit
            </Button>
            <Button startIcon={<ClearIcon />} onClick={onCancelClick}>
              Cancel {eventTypeText(event_type)}
            </Button>
          </>
        )}
      </Grid>
      <Grid item>
        <Button onClick={onClose}>Close</Button>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={show}
      onClose={onClose}
      title={`${eventTypeText(event_type)} details`}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      // maxWidth="sm"
      className="event-info-modal"
    ></Modal>
  );
};

export default EventInfoModal;
