import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Grid,
} from '@material-ui/core';

import { startDisplay } from '../../utils/webcam/webrtc';

let captureStream, mediaRecorder, recordedBlobs;

const AdditionalFeature = (props) => {
  const [record, setRecord] = useState(false);
  const [play, setPlay] = useState(false);
  const [playAvailable, setPlayAvailable] = useState(false);
  const [downloadAvailable, setDownloadAvailable] = useState(false);
  const { classes } = props;
  const { callStarted, remoteVideoRef, remoteStream } = props;

  const handleDownload = () => {
    const blob = new Blob(recordedBlobs, { type: 'video/webm' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // later - max, get input file type
    let nowtime = new Date().toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    nowtime = nowtime.replace(/\//g, '');

    a.download = `Amera-webcam-${nowtime}.webm`;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  };

  const startRecording = () => {
    recordedBlobs = [];
    let options = { mimeType: 'video/webm;codecs=vp9,opus' };
    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      console.error(`${options.mimeType} is not supported`);
      options = { mimeType: 'video/webm;codecs=vp8,opus' };
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.error(`${options.mimeType} is not supported`);
        options = { mimeType: 'video/webm' };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.error(`${options.mimeType} is not supported`);
          options = { mimeType: '' };
        }
      }
    }
    try {
      mediaRecorder = new MediaRecorder(captureStream, options);
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e);
      return;
    }

    mediaRecorder.onstop = (event) => {
      console.log('Recorder stopped: ', event);
      console.log('Recorded Blobs: ', recordedBlobs);
    };
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start();
    setDownloadAvailable(false);
    setPlayAvailable(false);
  };

  const stopRecording = () => {
    if (!mediaRecorder) return;
    mediaRecorder.stop();
    const promptRecordingSource = false; //document.querySelector('#promptRecordingSource').checked;
    if (promptRecordingSource) {
      let videoTrack = captureStream.getVideoTracks()[0];
      videoTrack.stop();
    }
    captureStream = null;
    remoteVideoRef.current.srcObject = remoteStream;
    setDownloadAvailable(true);
    setPlayAvailable(true);
  };

  const handleDataAvailable = (event) => {
    if (event.data && event.data.size > 0) {
      console.log('im pushing im pushing', recordedBlobs);
      recordedBlobs.push(event.data);
    }
  };

  useEffect(() => {
    const handleRecord = async () => {
      if (record) {
        captureStream = remoteStream;

        // see if we want to capture display instead of remote...
        const promptRecordingSource = false; // document.querySelector('#promptRecordingSource').checked;
        if (promptRecordingSource) {
          let displayMediaOptions = {
            video: { cursor: 'always' },
            audio: true,
          };
          // could add constraints here...
          //let supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
          captureStream = await startDisplay(displayMediaOptions);

          remoteVideoRef.current.srcObject = captureStream; // so we can see what is being captured
        }
        // later - max, do we need to mix audo so its all captured??
        startRecording();
      } else {
        stopRecording();
      }
    };
    handleRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    if (play) {
      const superBuffer = new Blob(recordedBlobs, { type: 'video/webm' });
      if(remoteVideoRef && remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = null;
        remoteVideoRef.current.src = window.URL.createObjectURL(superBuffer);
        remoteVideoRef.current.controls = true;
        remoteVideoRef.current.play();
      }
    } else {
      
      if(remoteVideoRef) remoteVideoRef.current.srcObject = remoteStream;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [play]);

  return (
    <Grid item md={4} sm={12}>
      <ButtonGroup
        size="large"
        variant="contained"
        aria-label="capture controls"
        disabled={!callStarted}
      >
        <Button
          className={classes.startRecording}
          onClick={() => {
            setRecord((prev) => !prev);
          }}
        >
          {record ? 'Stop Recording' : 'Start Recording'}
        </Button>
        <Button
          className={classes.play}
          onClick={() => {
            setPlay((prev) => !prev);
          }}
          disabled={!playAvailable}
        >
          {play ? 'Monitor Webcam' : 'Play'}
        </Button>
        <Button
          className={classes.download}
          disabled={!downloadAvailable}
          onClick={handleDownload}
        >
          Download
        </Button>
      </ButtonGroup>
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={true}
            onChange={() => {}}
            name="checkedB"
            color="primary"
          />
        }
        label="Prompt recording source:"
        labelPlacement="start"
      /> */}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  remoteStream: state.one2onevcall.remoteStream,
  callStarted: state.one2onevcall.callStarted,
});

export default connect(mapStateToProps)(AdditionalFeature);
