import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';

import FileShareSpinner from '../FileShare/FileShareSpinner';
import LeftbgContainer from './LeftbgContainer';
import RegisterForm from './RegisterForm';
import Footer from '../Sections/Footer/GuestFooter';
import ErrorBoundary from '../../components/ErrorBoundary';

import { ValidInvite } from '../../redux/actions/member';
import { loadCompanies } from '../../redux/actions/company';

const Registration = (props) => {
  const { isSubmittingRegisterForm, dispatch } = props;
  const [invite, setInvite] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const { inviteKey } = useParams();

  useEffect(() => {
    dispatch(loadCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inviteKey) {
      ValidInvite(inviteKey)
        .then((res) => {
          setInvite(res);
        })
        .catch((error) => {
          console.error(error);
          setAlert({
            open: true,
            severity: 'error',
            message: 'Invite is no longer a valid, please request a new invite',
          });
        });
    }
  }, [inviteKey]);

  const AlertMessage = () => {
    return (
      <Collapse in={alert.open} className="invalid-invite">
        <Alert
          variant="filled"
          severity={alert.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert({ open: false });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {alert.message}
        </Alert>
      </Collapse>
    );
  };

  return (
    <>
      <div className="registration_page">
        <Grid container spacing={0}>
          <Hidden only={['xs', 'sm']}>
            <Grid item xs={4}>
              <LeftbgContainer />
            </Grid>
          </Hidden>
          <Grid item sm={12} md={8}>
            <AlertMessage />
            <ErrorBoundary>
              <RegisterForm
                invite={invite}
                isShouldShowPromo={inviteKey ? false : true}
                isSubmittingRegisterForm={isSubmittingRegisterForm}
              />
              <FileShareSpinner isLoading={isSubmittingRegisterForm} />
            </ErrorBoundary>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  invite: state.member.invite,
  isSubmittingRegisterForm: state.member.isSubmittingRegisterForm,
});

export default connect(mapStateToProps)(Registration);
