import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { GPLACES_TYPES, googlePlaceToReadable } from '../../../utils/calendar';

const GooglePlaceTypeAutocomplete = (props) => {
  const { placeType, onSelectPlaceType, variant = 'standard' } = props;

  //   console.log('GPLACES_TYPES', Object.values(GPLACES_TYPES));
  return (
    <Autocomplete
      multiple={false}
      fullWidth
      id="google-place-types"
      options={Object.values(GPLACES_TYPES).sort()}
      disableClearable
      getOptionSelected={(o, v) => o === v}
      getOptionLabel={(o) => googlePlaceToReadable(o)}
      renderOption={(o) => googlePlaceToReadable(o)}
      onChange={onSelectPlaceType}
      value={placeType}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label="Place type"
          placeholder="Place type"
        />
      )}
    />
  );
};

export default GooglePlaceTypeAutocomplete;
