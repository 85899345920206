import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardContent, Grid, makeStyles } from '@material-ui/core';

import { apiUrl } from '../../config/api';

import { ab2str } from '../../utils/webcam/webrtc';

const colors = {
  blue: '#4284df',
  darkGreen: '#28a745',
  darkRed: '#c82333',
  foggyRed: '#dc3545',
  grey: '#6c757d',
  yellow: '#ffc107',
  teal: '#17a2b8',
  orange: '#ff7f50',
  textDark: '#6c757d',
};

const useStyles = makeStyles((theme) => ({
  encryptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  encryptionControlWrapper: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  encryptionCard: {
    height: '100%',
    '& .MuiCardContent-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  encryptionImage: {
    minWidth: '100%',
    maxWidth: '100%',
    width: '100%',
    height: '270px',
    minHeight: '270px',
    maxHeight: '270px',
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  encryptionImageKey: {
    '& label': {
      fontWeight: 'bold',
    },
  },
  controlGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& label': {
      fontWeight: 'bold',
    },
    paddingBottom: theme.spacing(1),
  },
  fileInputWrapper: {
    '& .MuiButton-root': {
      background: colors.blue,
    },
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
    },
    '& label': {
      display: 'block',
    },
  },
  fileInput: {
    display: 'none',
  },
  userPinWrapper: {
    '& input': {
      height: '2.2em',
      width: '100%',
      border: '1px solid #ced4da',
      borderRadius: '0.23em',
      background: theme.palette.common.white,
      paddingLeft: theme.spacing(1),
    },
    marginLeft: theme.spacing(2),
  },
  binaryEncryptionKey: {
    width: '100%',
    '& label': {
      fontWeight: 'bold',
      color: theme.palette.common.black,
    },
    '& textarea': {
      width: '100%',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#495057',
      backgroundColor: '#e9ecef',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      height: '150px',
    },
  },
}));

const EncryptionController = (props) => {
  const [picture, setPicture] = useState(null);
  const [pin, setPin] = useState('08221991');
  const [pictureStyle, setPictureStyle] = useState({});
  const classes = useStyles();
  let pictureRef = useRef();
  const {
    signalingClient,
    security,
    e2E,
    currentKey,
    toggleEncy,
    isMyGroup,
  } = props;

  // const dispatch = useDispatch();
  const _arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  useEffect(() => {
    if (security) {
      let sp = pin;
      if (security.pin) sp = security.pin;
      if (security.security_picture) {
        let url = `${apiUrl}${security.security_picture}`;
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'arraybuffer';
        xhr.onload = function (e) {
          const b64 = _arrayBufferToBase64(this.response);
          // var raw = String.fromCharCode.apply(null, arr);
          // var b64 = btoa(raw);
          const dataURL = 'data:image/png;base64,' + b64;
          let size = parseInt(xhr.getResponseHeader('Content-Length'));
          let chunksize = 50000;
          let nextslice, gotmore;
          let realjuju = dataURL.split(',')[1];
          for (let i = 0; i < realjuju.length; i += chunksize) {
            // continue;
            nextslice = realjuju.slice(i, i + chunksize);
            gotmore = nextslice.length === chunksize;
            if (gotmore) {
              const msg = {
                type: 'getkey',
                length: size,
                index: i,
                more: true,
                rawbytes: nextslice,
              };
              signalingClient.sendMessage(msg);
            } else {
              const msg = {
                type: 'getkey',
                length: size,
                pin: sp,
                twofa: '05181955', // twofactor.value,
                keysize: 256,
                index: i,
                more: false,
                verbose: 1,
                rawbytes: nextslice,
              };
              signalingClient.sendMessage(msg);
            }
          }
        };
        xhr.send();
      }
      if (!isMyGroup && security.exchange_option === 'MOST_SECURE') {
        setPin('');
      } else {
        setPin(sp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [security]);

  const handlePicPinFiles = (files) => {
    if (!signalingClient) return;
    files = files.target.files;

    for (let j = 0; j < files.length; j++) {
      const file = files[j];

      if (!file.type.startsWith('image/')) {
        continue;
      }
      let size = file.size;
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setPicture(reader.result);
      };
      reader.onload = function () {
        let chunksize = 50000;
        let nextslice, gotmore;
        let realjuju = reader.result.split(',')[1];
        //twofactor.value = "05181955"; // online for now
        for (let i = 0; i < realjuju.length; i += chunksize) {
          // continue;
          nextslice = realjuju.slice(i, i + chunksize);
          gotmore = nextslice.length === chunksize;
          if (gotmore) {
            const msg = {
              type: 'getkey',
              length: size,
              index: i,
              more: true,
              rawbytes: nextslice,
            };

            signalingClient.sendMessage(msg);
          } else {
            const msg = {
              type: 'getkey',
              length: size,
              pin,
              twofa: '05181955', // twofactor.value,
              keysize: 256,
              index: i,
              more: false,
              verbose: 1,
              rawbytes: nextslice,
            };

            signalingClient.sendMessage(msg);
          }
        }
      };
      reader.onerror = function () {
        console.log(reader.error);
      };
    }
  };

  const handleEncrypt = async () => {
    if (!signalingClient) return;
    toggleEncy();
  };

  useEffect(() => {
    if (pictureRef.current) {
      let ps =
        pictureRef.current.offsetHeight > pictureRef.offsetWidth
          ? { width: '100%', height: 'auto' }
          : { width: 'auto', height: '100%' };
      setPictureStyle(ps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureRef.current]);

  const stringCurrentKey = useMemo(() => ab2str(currentKey), [currentKey]);

  return (
    <Grid item md={12} sm={12}>
      <Card className={classes.encryptionCard} variant="outlined">
        <CardContent className={classes.encryptionWrapper}>
          <div className={classes.encryptionImage}>
            {picture && (
              <img
                ref={pictureRef}
                src={picture}
                style={pictureStyle}
                alt="Preview"
              />
            )}
            {!picture &&
              security &&
              security.security_picture &&
              (isMyGroup ||
                (security.exchange_option !== 'MOST_SECURE' &&
                  security.exchange_option !== 'VERY_SECURE' &&
                  security.exchange_option !== 'SECURE')) && (
                <img
                  ref={pictureRef}
                  src={`${apiUrl}${security.security_picture}`}
                  style={pictureStyle}
                  alt="Preview"
                />
              )}
          </div>
          <div className={classes.encryptionControlWrapper}>
            <div className={classes.encryptionImageKey}>
              <label htmlFor="aeskey">Key</label>
              <br />
              <input
                type="text"
                className="form-control form-control-sm"
                id="aeskey"
                value={stringCurrentKey}
                placeholder="current key"
                disabled
              />
              <hr />
            </div>
            <h5>Encryption Controls</h5>
            <div className={classes.controlGroup}>
              <div className={classes.fileInputWrapper}>
                <label>Picture</label>
                <input
                  className={classes.fileInput}
                  id="picfile"
                  type="file"
                  accept=".png, .jpg, .ppm"
                  onChange={handlePicPinFiles}
                />
                <label htmlFor="picfile">
                  <Button
                    variant="contained"
                    size="small"
                    component="span"
                    disabled={e2E}
                  >
                    Choose Picture
                  </Button>
                </label>
              </div>
              <div className={classes.userPinWrapper}>
                <label htmlFor="userpin">PIN</label>
                <input
                  type="text"
                  placeholder="enter pin"
                  value={pin}
                  onChange={(e) => {
                    setPin(e.target.value);
                  }}
                />
              </div>
            </div>
            <Button
              fullWidth={true}
              variant="contained"
              color="primary"
              onClick={handleEncrypt}
            >
              {!e2E ? 'Create key and encrypt' : 'Remove encryption'}
            </Button>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  currentKey: state.VideoCallRedux.currentKey,
  security: state.group.security,
});

export default connect(mapStateToProps)(EncryptionController);
