import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { differenceInMinutes, differenceInSeconds, compareAsc } from 'date-fns';
import { getNewExpiration, autoLogout } from '../redux/actions/member';
import { redirectToLogin } from '../config/api';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let interval = null;

const Countdown = (props) => {
  const { show, end } = props;
  const [now, setNow] = useState(new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    interval = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  const handleClose = (event, reason) => {
    dispatch(getNewExpiration());
    // setOpen(false);
  };

  useEffect(() => {
    if (compareAsc(now, end) >= 0 && interval) {
      clearInterval(interval);
      dispatch(autoLogout());
      redirectToLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [now, end]);

  const diffInMins = differenceInMinutes(end, now);
  const diffInSecs = differenceInSeconds(end, now) % 60;

  return (
    <Snackbar
      open={show}
      onClose={() => {}}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} onClick={handleClose} severity="warning">
        You will be logged out in {diffInMins} minutes and {diffInSecs}{' '}
        {diffInSecs === 0 || diffInSecs > 1 ? 'seconds ' : 'second '}
        later. Please click here to stay logged in.
      </Alert>
    </Snackbar>
  );
};

export default Countdown;
