import React from 'react';
import { ListItem, ListItemAvatar } from '@material-ui/core';
import { unescape } from 'lodash';
import parse from 'html-react-parser';
import classNames from 'classnames';
import moment from 'moment';

import MaterialAvatar from '../../../../components/MaterialAvatar';

function MessageContent({ messageText, createdAt }) {
  return (
    <div className="message-content">
      <div className="message-time">
        {createdAt && moment(createdAt).format('MMM DD, hh:mm A')}
      </div>
      <div className="message-text">{parse(unescape(messageText))}</div>
    </div>
  );
}

function ChatMessages({
  id,
  member_id,
  isMyMessage,
  firstName,
  lastName,
  createdAt,
  avatarUrl,
  messageText,
}) {
  const messageClass = classNames('chat-message', {
    'my-message': isMyMessage,
    'other-message': !isMyMessage,
  });
  return (
    <ListItem key={id} className={messageClass}>
      <ListItemAvatar className="chat-message-avatar-div">
        <MaterialAvatar
          classes="chat-message-avatar"
          src={avatarUrl}
          member_id={member_id}
          firstName={firstName}
          lastName={lastName}
        />
      </ListItemAvatar>
      <MessageContent messageText={messageText} createdAt={createdAt} />
    </ListItem>
  );
}

export default ChatMessages;
