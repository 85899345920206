let alert = {
  show: true,
  variant: '',
  message: '',
};

export const setMemberAlert = (res, success, message = '') => {
  if (success) {
    alert['variant'] = 'success';
    alert['message'] = message ? message : res.description;
  } else {
    alert['variant'] = 'error';
    alert['message'] = message ? message : 'Something went wrong!';
  }
  return alert;
};

export const setGroupAlert = (res, success, type, message) => {
  if (success) {
    alert['variant'] = 'success';
    alert['message'] = message ? message : res.description;
  } else {
    // Looks like res.res. was wrong
    if (res.status === 409) {
      alert['variant'] = 'warning';
      alert['message'] = `${type} already exist!`;
    } else {
      alert['variant'] = 'error';
      alert['message'] = 'Something went wrong, please try again later';
    }
  }
  return alert;
};

export const setFileShareAlert = (res, success) => {
  if (success) {
    alert['status'] = 'success';
    alert['message'] = res.description;
  } else {
    // Looks like res.res. was wrong
    if (res.status === 409) {
      alert['status'] = 'warning';
      alert['message'] = 'File shared already!';
    } else {
      alert['status'] = 'error';
      alert['message'] = 'Something went wrong, please try again later';
    }
  }
  return alert;
};

export const setContactAlert = (res, success, message = null) => {
  if (success) {
    alert['variant'] = 'success';
    alert['message'] = message ? message : 'Contact created successfully!';
  } else {
    if (res && res.res && res.res.status) {
      if (res.res.status === 404) {
        alert['variant'] = 'warning';
        alert['message'] = 'Member does not exist!';
      } else if (res.res.status === 405) {
        alert['variant'] = 'error';
        alert['message'] = 'Method not supported!';
      } else if (res.res.status === 409) {
        alert['variant'] = 'warning';
        alert['message'] = 'Contact exist already!';
      } else {
        alert['variant'] = 'error';
        alert['message'] = 'Something went wrong, please try again later';
      }
    } else {
      alert['variant'] = 'error';
      alert['message'] = message;
    }
  }
  return alert;
};

export const setReportAlert = (res, success) => {
  if (success) {
    alert['variant'] = 'success';
    alert['message'] = res.message ? res.message : 'Bug Reported Successfully.';
  } else {
    alert['variant'] = 'error';
    alert['message'] = 'Something went wrong!';
  }
  return alert;
};
