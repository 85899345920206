import React, { useState, useMemo } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import { apiUrl } from '../config/api';
import { withStyles } from '@material-ui/core/styles';

import { green, gray } from '../jss/colors';

const MaterialAvatar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  //   Initals used as fallback only
  const {
    member_id,
    src,
    classes,
    firstName,
    lastName,
    onClick,
    isHoverName,
    badgeType,
    badgeVariant,
    isBasicAvatar,
    style,
    ...otherProps
  } = props;

  const handlePopoverOpen = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  let badgeIcon, bgColor, baseRem, color;
  switch (badgeType) {
    case 'settings':
      baseRem = 1.5;
      badgeIcon = <SettingsIcon style={{ fontSize: `${baseRem * 0.75}rem` }} />;
      bgColor = 'black';
      color = 'white';
      break;
    case 'edit':
      baseRem = 2;
      badgeIcon = (
        <Tooltip title="Edit Picture" arrow>
          <EditIcon style={{ fontSize: `${baseRem * 0.75}rem` }} />
        </Tooltip>
      );
      bgColor = '#8ea4c6';
      color = 'white';
      break;
    case 'online':
      baseRem = 0.5;
      badgeIcon = null;
      bgColor = green;
      color = 'white';
      break;
    case 'offline':
      baseRem = 0.5;
      badgeIcon = null;
      bgColor = gray;
      color = 'white';
      break;
    default:
      badgeIcon = null;
  }

  const IconBadge = withStyles((theme) => ({
    badge: {
      zIndex: 999,
      cursor: 'pointer',
      backgroundColor: bgColor,
      color: color,
      height: `${baseRem}rem`,
      width: `${baseRem}rem`,
      borderRadius: `${baseRem / 2}rem`,
      boxShadow:
        badgeType === 'online' || badgeType === 'offline'
          ? `0 0 0 2px ${theme.palette.background.paper}`
          : '',
    },
  }))(Badge);

  const format = (name) => {
    if (name) {
      return name.charAt(0).toUpperCase();
    } else {
      return '';
    }
  };

  const source = (srcProp, member_id = null) => {
    if (srcProp || member_id) {
      const isDataURL = srcProp && srcProp.startsWith('data:');
      const isStaticURL = srcProp && srcProp.startsWith('/static');
      // const isDataURL = RegExp(
      //   /^\s*data:([a-z]+\/[a-z0-9\-\+]+(;[a-z\-]+\=[a-z0-9\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i
      // ).test(srcProp);
      if (isDataURL || isStaticURL) {
        return srcProp;
      } else {
        if (member_id) {
          return `${apiUrl}/member/${member_id}/avatar`;
        }
        return `${apiUrl}${srcProp}`;
      }
    } else {
      return null;
    }
  };

  const body = useMemo(() => {
    if (isBasicAvatar) {
      return (
        <Avatar
          src={source(src)}
          alt={`${firstName} ${lastName}`}
          {...otherProps}
        />
      );
    } else {
      return (
        <IconBadge
          badgeContent={badgeIcon}
          overlap="circle"
          color="secondary"
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          variant={badgeVariant}
        >
          <Avatar
            src={source(src, member_id)}
            alt={`${firstName} ${lastName}`}
            className={`material-avatar ${classes}`}
            style={style}
            onMouseEnter={isHoverName ? (e) => handlePopoverOpen(e) : null}
            onMouseLeave={isHoverName ? handlePopoverClose : null}
            variant="rounded"
          >
            {`${format(firstName)}${format(lastName)}`}
          </Avatar>
          {isHoverName ? (
            <Popover
              id={`${classes}_${firstName}_${lastName}`}
              open={Boolean(anchorEl)}
              onClose={handlePopoverClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              disableRestoreFocus
            >
              <Typography>{`${firstName} ${lastName}`}</Typography>
            </Popover>
          ) : null}
        </IconBadge>
      );
    }
  }, [
    isBasicAvatar,
    anchorEl,
    badgeIcon,
    badgeVariant,
    classes,
    firstName,
    isHoverName,
    lastName,
    src,
    member_id,
    style,
    otherProps,
  ]);

  return onClick !== undefined && onClick !== null ? (
    isBasicAvatar ? (
      body
    ) : (
      <IconButton onClick={onClick} style={{ cursor: 'pointer' }}>
        {body}
      </IconButton>
    )
  ) : (
    <>{body}</>
  );
};

MaterialAvatar.defaultProps = {
  badgeVariant: 'standard',
  isBasicAvatar: false,
};

export default MaterialAvatar;
