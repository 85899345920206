import { set, includes } from 'lodash';

export const validateProfileForm = (values) => {
  let errors = {};

  if (!values.first_name) {
    set(errors, 'first_name', 'First name is required');
  }

  if (!values.last_name) {
    set(errors, 'last_name', 'Last name is required');
  }

  if (!values.company_name) {
    set(errors, 'company_name', 'Company name is required');
  }

  if (values.achievementData) {
    values.achievementData.forEach((ado) => {
      const { id, entity, description } = ado;

      if (!entity) {
        set(errors, `achievementList[${id}].entity`, 'Entity is required');
      }

      if (!description) {
        set(
          errors,
          `achievementList[${id}].description`,
          'Description is required'
        );
      }
    });
  }

  if (values.emailData) {
    values.emailData.forEach((edo) => {
      const { email, description, id } = edo;
      if (values.primaryEmailId !== id) {
        // We don't check primaries
        if (!email) {
          set(errors, `emailList[${id}].email`, 'Email address is required');
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          set(errors, `emailList[${id}].email`, 'Email address is invalid');
        }

        if (!description) {
          set(
            errors,
            `emailList[${id}].description`,
            'Description is required'
          );
        }
      }
    });
  }

  if (values.phoneData) {
    values.phoneData.forEach((pdo) => {
      const { description, phoneType, phoneNumber, isPhoneVerified, id } = pdo;
      if (values.primaryPhoneId !== id) {
        //we don't check primaries
        // Needs description
        if (!description) {
          set(
            errors,
            `phoneList[${id}].description`,
            'Description is required'
          );
        }

        if (includes(['cell', 'TDD'], phoneType)) {
          // Has to be verified by TOTP
          if (!isPhoneVerified) {
            set(errors, `phoneList[${id}].phoneInput`, 'Phone not verified');
          }
        } else if (phoneType === 'landline') {
          // At least 10 digits
          if (phoneNumber.length < 10) {
            set(errors, `phoneList[${id}].phoneInput`, 'Number too short');
          }
        }
      }
    });
  }

  if (values.locationData) {
    values.locationData.forEach((ldo) => {
      // Must have postal
      const { id, postal } = ldo;
      if (!postal) {
        set(errors, `locationList[${id}].postal`, 'Postal code is required');
      }
    });
  }

  return errors;
};

export const validateProfileSettingForm = (values) => {
  let errors = {};

  if (values.locationData) {
    values.locationData.forEach((ldo) => {
      // Must have postal
      const { id, postal } = ldo;
      if (!postal) {
        set(errors, `locationList[${id}].postal`, 'Postal code is required');
      }
    });
  }

  return errors;
};
