import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const MultiDayCheckbox = (props) => {
  const { checked, onChange } = props;
  return (
    <FormControlLabel
      value="end"
      control={
        <Checkbox
          color="primary"
          checked={checked}
          onChange={onChange}
          name="multiDayEvent"
        />
      }
      label="Multi Day Event"
      labelPlacement="end"
    />
  );
};

export default MultiDayCheckbox;
