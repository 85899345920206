import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChatsPanel from './ChatsPanel';
import ChatMessages from '../shared/ChatMessages/index';
import ChatForm from '../shared/ChatForm';

import { sendMessage } from '../../../redux/actions/chat';
import { uuidv4 } from '../../../utils/chat';

function One2OneChat(props) {
  const { selectedConversationId, sendMessage } = props;
  const messagesEnfRef = useRef();

  const scrollToBottom = () => {
    if (messagesEnfRef && messagesEnfRef.current) {
      messagesEnfRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  };
  const handleSendMessage = (messageText) => {
    let messageObj = {};
    messageObj = {
      id: uuidv4(),
      imageAlt: null,
      messageText,
      createdAt: new Date().toUTCString(),
      isMyMessage: true,
    };
    const newMessageid = uuidv4();
    console.log(newMessageid, 'newMessageId');
    sendMessage(selectedConversationId, messageObj, newMessageid).then(() => {
      setTimeout(() => scrollToBottom(), 100); //need to remove timeout when backend attached for new message
    });
  };

  return (
    <div className="chat-section">
      <div className="messages-container">
        <ChatMessages messagesEnfRef={messagesEnfRef} />
        <ChatForm handleSendMessage={handleSendMessage} />
      </div>
      <div className="chats-panel">
        <ChatsPanel />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  selectedConversationId: state.chat.selectedConversationId,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ sendMessage }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(One2OneChat);
