import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ContactPreview from '../../components/ContactPreview';

import Toolbar from './Toolbar';

// import styles from '../../jss/views/firstScreenModeStyles';

const styles = (theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  videoRect: {
    flex: '1',
    backgroundColor: theme.palette.common.black,
    justifyContent: 'center',
    display: 'flex',
    overflow: 'hidden',
    borderBottomLeftRadius: theme.spacing(2),
  },
  remoteVideoWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  remoteVideoPlayer: {
    width: '100%',
    maxHeight: '100%',
  },
  localVideoWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    marginLeft: theme.spacing(1),
  },
  localVideoPlayer: {
    width: '100%',
    maxHeight: '100%',
  },
  footer: {
    backgroundColor: '#333333',
    height: '64px',
    width: '100%',
    borderBottomLeftRadius: theme.spacing(2),
  },
  relative: {
    position: 'relative',
    width: '100%',
    maxHeight: '100%',
  },
  contactPreviewWrapper: {
    background: '#000000c2',
    padding: '10px',
    borderRadius: '10px',
    position: 'absolute',
    top: '0px',
    left: '18px',
    color: 'white',
    width: '350px',
    '& .MuiGrid-item': {
      padding: 0,
    },
  },
});

const useStyles = makeStyles(styles);

const ThirdScreenMode = (props) => {
  const classes = useStyles();

  const { localVideoRef, remoteVideoRef } = props;

  const remoteMemberInfo = useSelector(
    (state) => state.one2onevcall.memberInfo
  );
  const memberInfo = useSelector((state) => state.member.member);

  return (
    <div className={classes.root}>
      <Toolbar {...props} />
      <div className={classes.videoRect}>
        <div className={classes.remoteVideoWrapper}>
          <div className={classes.relative}>
            <video
              autoPlay
              playsInline
              ref={remoteVideoRef}
              className={classes.remoteVideoPlayer}
              me="true"
            />
            {remoteMemberInfo && (
              <div className={classes.contactPreviewWrapper}>
                <ContactPreview
                  contactData={remoteMemberInfo}
                  isShowAvatar={true}
                />
              </div>
            )}
          </div>
        </div>
        <div className={classes.localVideoWrapper}>
          <div className={classes.relative}>
            <video
              autoPlay
              playsInline
              ref={localVideoRef}
              className={classes.localVideoPlayer}
              me="true"
              muted="muted"
            />
            {memberInfo && (
              <div className={classes.contactPreviewWrapper}>
                <ContactPreview contactData={memberInfo} isShowAvatar={true} />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className={classes.footer}></div> */}
    </div>
  );
};

export default ThirdScreenMode;
