import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ADDRESS_TYPES } from '../../../../../utils/calendar';

const LocationTypeSelector = (props) => {
  const { location_type, handleChange } = props;
  return (
    <FormControl className="location-type-selector" fullWidth>
      <InputLabel id="location-type-input-label">Location type</InputLabel>
      <Select
        labelId="location-type"
        name="location_type"
        value={location_type}
        onChange={handleChange}
      >
        <MenuItem key={ADDRESS_TYPES.home} value={ADDRESS_TYPES.home}>
          Home Address
        </MenuItem>
        <MenuItem key={ADDRESS_TYPES.work} value={ADDRESS_TYPES.work}>
          Company Address
        </MenuItem>
        <MenuItem key={ADDRESS_TYPES.billing} value={ADDRESS_TYPES.billing}>
          Billing Address
        </MenuItem>
        <MenuItem key={ADDRESS_TYPES.other} value={ADDRESS_TYPES.other}>
          Other Address
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LocationTypeSelector;
