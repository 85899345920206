import React, { useEffect, useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  TableRow,
  TableCell,
  Link,
  FormControl,
  Select,
  MenuItem,
  InputBase,
  InputLabel,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from '@material-ui/icons/Search';
import Alert from '@material-ui/lab/Alert';
import { format } from 'date-fns';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import JSONViewModal from '../../components/Modal/JSONViewModal';
import {
  getUsers,
  getBugReports,
  setReduxReportAlert,
} from '../../redux/actions/reportBugs';

import styles from '../../jss/views/activityTableStyle';
import * as api from '../../config/api';

const headCells = [
  {
    id: 'user',
    label: 'User',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'assignee',
    label: 'Assignee',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'description',
    label: 'Description',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'current_url',
    label: 'Current URL',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'referer_url',
    label: 'Referer URL',
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'error_message',
    label: 'Error Message',
    minWidth: 170,
    sortable: false,
  },
  {
    id: 'advanced_details',
    label: 'Advanced Details',
    minWidth: 100,
    sortable: false,
  },
  {
    id: 'create_date',
    label: 'Create Date and Time',
    minWidth: 140,
    sortable: true,
  },
  {
    id: 'bug_screenshot',
    label: 'Screenshot',
    minWidth: 100,
    sortable: false,
  },
];

const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.minWidth }}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(styles);

const GlobalBugReports = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { count, reports, users, reportAlert } = useSelector((state) => ({
    count: state.reportBugs.count,
    reports: state.reportBugs.reports,
    users: state.reportBugs.users,
    reportAlert: state.reportBugs.reportAlert,
  }));

  const { isCanSeeAll } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState('');

  const [searchKey, setSearchKey] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [zoomObj, setZoomObj] = useState({ id: '', shouldZoom: false });
  const [jsonArray, setJsonArray] = useState([]);
  const [open, setOpen] = useState(false);

  const onSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      search();
    }
  };
  const search = () => {
    getList({
      sortBy: orderBy,
      searchKey,
      pageNumber: page,
      pageSize: rowsPerPage,
      member_id: selectedUser,
    });
  };

  const filterByUser = async (member_id) => {
    const status = await getList({
      member_id,
      sortBy: orderBy,
      searchKey: '',
      pageNumber: 0,
      pageSize: rowsPerPage,
    });
    if (status) {
      setSelectedUser(member_id);
      setPage(0);
      setSearchKey('');
    } else {
      console.error('unable to get list for specific user.');
    }
  };

  const getList = useCallback(
    (params) => {
      return dispatch(getBugReports({ isCanSeeAll, ...params }));
    },
    [dispatch, isCanSeeAll]
  );

  useEffect(() => {
    dispatch(getUsers({ isCanSeeAll }));
  }, [dispatch, isCanSeeAll]);

  useEffect(() => {
    getList({
      sortBy: '',
      searchKey: '',
      pageNumber: 0,
      pageSize: 10,
      member_id: '',
    });
  }, [getList]);

  const loadSortedBugReports = (property, order, pageNumber, pageSize) => {
    let sortBy = property;
    if (property === 'user') {
      sortBy = 'first_name';
    }
    sortBy = order === 'desc' ? `-${sortBy}` : `${sortBy}`;
    getList({
      sortBy,
      searchKey,
      pageNumber: pageNumber >= 0 ? pageNumber : page,
      pageSize: pageSize ? pageSize : rowsPerPage,
      member_id: selectedUser,
    });
    setOrderBy(property);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedBugReports(property, newOrder);
  };

  const handleChangePage = (newPage) => {
    if (newPage !== page) {
      loadSortedBugReports(orderBy, order, newPage, null);
      setPage(newPage);
      console.log(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10);
    const pageNumber = 0;
    loadSortedBugReports(orderBy, order, pageNumber, pageSize);
    setRowsPerPage(pageSize);
    setPage(pageNumber);
  };

  const handleZoomChange = useCallback((shouldZoom, id) => {
    setZoomObj({ id, shouldZoom });
  }, []);

  const showJSONViewModal = (error, browser_info, redux_state) => {
    let newJsonArray = [];
    if (error) {
      newJsonArray.push({
        title: 'Error Info',
        data: error,
        collapseLevel: redux_state ? 0 : 1,
      });
    }
    if (browser_info) {
      newJsonArray.push({
        title: 'Browser Details',
        data: browser_info,
        collapseLevel: 1,
      });
    }
    if (redux_state) {
      newJsonArray.push({
        title: 'Redux State',
        data: redux_state,
        collapseLevel: 1,
      });
    }

    setJsonArray(newJsonArray);
    setOpen(true);
  };

  const handleSnackbarClose = useCallback(() => {
    if (reportAlert.show) {
      const alertOption = {
        show: false,
        variant: reportAlert.variant,
        message: '',
      };
      dispatch(setReduxReportAlert(alertOption));
    }
  }, [dispatch, reportAlert]);

  useEffect(() => {
    if (reportAlert.show) {
      setTimeout(() => {
        handleSnackbarClose();
      }, 4000);
    }
  }, [reportAlert, handleSnackbarClose]);

  return (
    <Paper className={classes.paper}>
      <Toolbar variant="dense" className={classes.searchRoot}>
        <div className={classes.search}>
          <div aria-label="search-icon" className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            onKeyDown={(e) => onSearchKeyPress(e)}
          />
        </div>
        <FormControl variant="outlined" className={classes.filerDropDown}>
          <InputLabel id="demo-simple-select-outlined-label">
            Filter By
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={users.length > 0 ? selectedUser : ''}
            onChange={(e) => filterByUser(e.target.value)}
            label="Filter By"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {users.map((user) => (
              <MenuItem key={user.member_id} value={user.member_id}>
                {user.first_name} {user.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Toolbar>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="bugsReportTable"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {reports.map((row, index) => {
              const fullName = `${row.first_name || ''} ${row.last_name || ''}`,
                selected = parseInt(selectedUser) === row.member_id,
                error =
                  (row.redux_state &&
                    row.redux_state.app &&
                    row.redux_state.app.error) ||
                  {};
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`user_bug_reports_${index}`}
                >
                  <TableCell className={classes.tableCellUser}>
                    {selected ? (
                      fullName
                    ) : fullName.trim() ? (
                      <Tooltip
                        title={`Filter list by ${fullName}`}
                        placement="top"
                      >
                        <Link
                          className={classes.tableCellLink}
                          component="button"
                          onClick={() => filterByUser(row.member_id)}
                        >
                          {fullName}
                        </Link>
                      </Tooltip>
                    ) : (
                      'Unkonwn User'
                    )}
                  </TableCell>
                  <TableCell>{row.assignee ? row.assignee : ''}</TableCell>
                  <TableCell>{row.status ? row.status : ''}</TableCell>
                  <TableCell>
                    {row.description ? row.description : ''}
                  </TableCell>
                  <TableCell>
                    {row.current_url ? row.current_url : ''}
                  </TableCell>
                  <TableCell>
                    {row.referer_url ? row.referer_url : ''}
                  </TableCell>
                  <TableCell>
                    {error.message
                      ? error.message.substring(0, 20) + '...'
                      : ''}
                    {error.stack && (
                      <Tooltip title={`Show Details`} placement="bottom">
                        <Link
                          className={classes.tableCellLink}
                          component="button"
                          onClick={() => showJSONViewModal(error, null, null)}
                        >
                          More
                        </Link>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.browser_info ? (
                      <Tooltip
                        title={`Show Advanced details`}
                        placement="bottom"
                      >
                        <Link
                          className={classes.tableCellLink}
                          component="button"
                          onClick={() =>
                            showJSONViewModal(
                              null,
                              row.browser_info,
                              row.redux_state
                            )
                          }
                        >
                          Show
                        </Link>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCellDate}>
                    {row.create_date && format(new Date(row.create_date), 'Pp')}
                  </TableCell>
                  <TableCell className={classes.tableCellScreenshot}>
                    {row.s3_screenshot_url ? (
                      <ControlledZoom
                        key={row.id}
                        isZoomed={zoomObj.id === row.id && zoomObj.shouldZoom}
                        onZoomChange={(shouldZoom) =>
                          handleZoomChange(shouldZoom, row.id)
                        }
                      >
                        <img
                          alt="Bug screenshot"
                          src={api.apiUrl + row.s3_screenshot_url}
                          width="62"
                          height="34.88"
                        />
                      </ControlledZoom>
                    ) : (
                      '- -'
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {count > 10 && (
        <div className="card-pagination contact-cards-pagination">
          <div className="item-per-page">
            <FormControl>
              <Select
                labelId="item-count-per-page-select-label"
                id="item-count-per-page-select"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={60}>60</MenuItem>
                <MenuItem value={70}>70</MenuItem>
              </Select>
            </FormControl>
            <p className="item-count-text">Cards Per Page</p>
          </div>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={parseInt(Math.ceil(count / rowsPerPage))}
            page={page + 1}
            onChange={(e, newPage) => handleChangePage(newPage - 1)}
            color="primary"
            showFirstButton
            showLastButton
          />
        </div>
      )}
      <JSONViewModal
        open={open}
        onClose={() => setOpen(false)}
        title="Advanced details"
        jsonArray={jsonArray}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={reportAlert.show}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={reportAlert.variant}>
          {reportAlert.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default GlobalBugReports;
