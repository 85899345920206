import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { EVENT_TYPES, eventTypeText } from '../../../utils/calendar';

const EventTypeSelect = ({ value, handleChange, disabled = false }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="label-eventtype">Event Type</InputLabel>
      <Select
        labelId="label-eventtype"
        id="eventtype-select"
        value={value}
        onChange={handleChange}
        label="Event Type"
        disabled={disabled}
      >
        {Object.values(EVENT_TYPES).map((et) => (
          <MenuItem key={et} value={et}>
            {eventTypeText(et)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EventTypeSelect;
