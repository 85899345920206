import React from 'react';
import { useDispatch } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

const ErrorBoundaryWrapper = (props) => {
  const dispatch = useDispatch();
  const onError = async (error) => {
    // It seems window.apiUrl not loading untill window object is ready that's why added async/await to load actions file
    const { appError } = await import('../../redux/actions/app');
    dispatch(appError({ message: error.message, stack: error.stack }));
  };
  return (
    <ErrorBoundary onError={onError} FallbackComponent={ErrorFallback}>
      {props.children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
