import 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Col } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import MeetingCard from './MeetingCard';
import CustomArrowButtons from '../../../components/Carousel/CustomArrowButtons';
import EmptyCard from '../../../components/Dashboard/EmptyCard';
import EventCard from '../../../components/Dashboard/EventCard';

const ScheduledMeetings = (props) => {
  let allNotifications = props.notifications;
  if (props.event_invites && props.event_invites.length > 0) {
    allNotifications = allNotifications.concat({
      id: 0,
      type: 'event',
      data: props.event_invites,
    });
  }
  allNotifications = allNotifications
    .map((data) => {
      if (
        data.type === 'alerts' ||
        data.type === 'activities' ||
        data.type === 'sessions'
      ) {
        //currently disabling it
        return [];
      } else if (data.type === 'event') {
        let notification = data.data;
        // notification.type = data.type;
        return notification.map((item) => {
          item.type = data.type;
          return item;
        });
      } else {
        let notification = data.data;
        // notification.type = data.type;
        return notification.map((item) => {
          item.type = data.type;
          return item;
        });
      }
    })
    .flat();
  // Omit read emails
  allNotifications = allNotifications.filter((item) => {
    if (item.type === 'mails') {
      return !item.read;
    }
    return true;
  });

  if (allNotifications) {
    if (allNotifications.length === 1) {
      allNotifications.push({ id: 'empty0', type: 'empty' });
      allNotifications.push({ id: 'empty1', type: 'empty' });
    } else if (allNotifications.length === 2) {
      allNotifications.push({ id: 'empty0', type: 'empty' });
    }
  }

  console.log('allNotifications', allNotifications);

  const responsive = {
    device7: {
      breakpoint: { max: 4000, min: 3100 },
      items: 6,
    },
    device6: {
      breakpoint: { max: 3100, min: 2600 },
      items: 5,
    },
    device5: {
      breakpoint: { max: 2600, min: 2158 },
      items: 4,
    },
    device4: {
      breakpoint: { max: 2158, min: 1600 },
      items: 3,
    },
    device3: {
      breakpoint: { max: 1599, min: 600 },
      items: 2,
    },
    // device2: {
    //   breakpoint: { max: 1023, min: 958 },
    //   items: 1,
    // },
    // device1: {
    //   breakpoint: { max: 1023, min: 768 },
    //   items: 2,
    // },
    device0: {
      breakpoint: { max: 599, min: 0 },
      items: 1,
    },
  };
  return (
    <Card>
      <CardContent>
        <Carousel
          swipeable={false}
          draggable={false}
          arrows={false}
          responsive={responsive}
          infinite={false}
          autoPlay={false}
          keyBoardControl={true}
          containerClass="carousel-container"
          className="react-multi-carousel-list"
          sliderClass={
            allNotifications && allNotifications.length < 1
              ? 'react-multi-carousel-track-w-100'
              : ''
          }
          renderButtonGroupOutside={true}
          customButtonGroup={
            allNotifications &&
            allNotifications.length > 2 && <CustomArrowButtons />
          }
        >
          {allNotifications.length > 0 ? (
            allNotifications.map((notification, index) => (
              <Col className="meeting-card" key={index}>
                {notification.type === 'empty' ? (
                  <EmptyCard
                    key={notification.id}
                    type="market"
                    message="No Marketing Data"
                  />
                ) : notification.type === 'event' ? (
                  <EventCard
                    key={notification.id}
                    type="event-invite"
                    data={notification}
                  />
                ) : (
                  <MeetingCard
                    key={notification.id}
                    type={notification.type}
                    notification={notification}
                    invitation_type={notification.invitation_type}
                  />
                )}
              </Col>
            ))
          ) : (
            <EmptyCard type="invitation" message="No Data." />
          )}
        </Carousel>
      </CardContent>
    </Card>
  );
};

ScheduledMeetings.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  event_invites: PropTypes.arrayOf(PropTypes.object),
};

ScheduledMeetings.defaultProps = {
  notifications: [],
  event_invites: [],
};

const mapStateToProps = (state) => ({
  notifications: state.event.notifications,
  event_invites: state.event.invitations,
});

export default connect(mapStateToProps)(ScheduledMeetings);
