import {
  IS_LOADING_CONTACT_LIST,
  IS_FINISHED_CONTACT_LIST_LOADING,
  NEW_CONTACT,
  NEW_CONTACTS,
  SET_CONTACT_ALERT,
  GET_CONTACT_MEMBERS,
  UPDATE_CONTACT_MEMBERS,
  REMOVE_CONTACT_MEMBER,
  REMOVE_CONTACT_MEMBERS,
  DELETE_CONTACT_START,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILED,
  UPDATE_CONTACT_REQUEST_START,
  UPDATE_CONTACT_REQUEST_SUCCESS,
  UPDATE_CONTACT_REQUEST_FAILED,
  FETCH_CONTACT_COMPANIES_START,
  FETCH_CONTACT_COMPANIES_SUCCESS,
  FETCH_CONTACT_COMPANIES_FAILED,
  FETCH_CONTACT_COUNTRIES_START,
  FETCH_CONTACT_COUNTRIES_SUCCESS,
  FETCH_CONTACT_COUNTRIES_FAILED,
  FETCH_CONTACT_ROLES_START,
  FETCH_CONTACT_ROLES_SUCCESS,
  FETCH_CONTACT_ROLES_FAILED,
  CONTACTS_RECIVERS,
  SET_SECURITY,
} from '../actionTypes/contact.js';

// The initial state of the App
export const initialState = {
  loading: false,
  isUpdated: false,
  contacts: [],
  totalCount: null,
  recivers: [],
  companies: [],
  countries: [],
  roles: [],
  contactMembers: [],
  isDeleting: false,
  contactAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
  security: null,
};

const contact = (state = initialState, action) => {
  if (!state.contactAlert) {
    state.contactAlert = initialState.contactAlert;
  }
  switch (action.type) {
    case IS_LOADING_CONTACT_LIST:
      return {
        ...state,
        loading: true,
      };
    case IS_FINISHED_CONTACT_LIST_LOADING:
      /*
      const newContacts = action.payload.map(item => {
        const newItem = {...item, checked: false};
        return newItem;
      })

      return {
        ...state,
        contacts: newContacts,
        loading: false,
      };

      */
      return {
        ...state,
        contacts: action.payload.contacts,
        totalCount: action.payload.count,
        loading: false,
      };
    case GET_CONTACT_MEMBERS:
      return {
        ...state,
        contactMembers: action.payload,
        loading: false,
      };
    case UPDATE_CONTACT_MEMBERS:
      return {
        ...state,
        contactMembers: action.payload,
      };
    case NEW_CONTACTS:
      return {
        ...state,
        contacts: state.contacts.concat(action.payload),
        loading: false,
      };
    case CONTACTS_RECIVERS:
      return {
        ...state,
        recivers: action.payload,
      };
    case NEW_CONTACT:
      return {
        ...state,
        contacts: state.contacts.concat(action.payload),
        loading: false,
      };
    case UPDATE_CONTACT_REQUEST_START:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };
    case UPDATE_CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        contacts: updateContact(state.contacts, action.payload),
      };
    case UPDATE_CONTACT_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        error: action.payload,
      };
    case FETCH_CONTACT_COMPANIES_START:
    case FETCH_CONTACT_COUNTRIES_START:
    case FETCH_CONTACT_ROLES_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTACT_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };
    case FETCH_CONTACT_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        loading: false,
      };
    case FETCH_CONTACT_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        loading: false,
      };
    case FETCH_CONTACT_COMPANIES_FAILED:
    case FETCH_CONTACT_COUNTRIES_FAILED:
    case FETCH_CONTACT_ROLES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_CONTACT_ALERT:
      return {
        ...state,
        contactAlert: action.payload,
        loading: false,
      };
    case REMOVE_CONTACT_MEMBER:
      return {
        ...state,
        contactMembers: state.contactMembers.filter(
          (member) => member.id !== action.payload
        ),
      };
    case REMOVE_CONTACT_MEMBERS:
      return {
        ...state,
        contactMembers: removeAddedMember(state.contactMembers, action.payload),
      };
    case DELETE_CONTACT_START:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_CONTACT_SUCCESS: {
      return {
        ...state,
        isDeleting: false,
        contacts: removeContacts(state.contacts, action.payload),
      };
    }
    case DELETE_CONTACT_FAILED: {
      return {
        ...state,
        isDeleting: false,
      };
    }
    case SET_SECURITY: {
      return {
        ...state,
        security: action.payload,
      };
    }
    default:
      return state;
  }
};

export const removeAddedMember = (members, contacts) => {
  let newMembers = members;
  for (let i = 0; i < contacts.length; i++) {
    newMembers = newMembers.filter(
      (member) => member.id !== contacts[i].contact_member_id
    );
  }
  return newMembers;
};

function removeContacts(contacts, contactsToRemove) {
  let newContacts = [...contacts];
  Object.keys(contactsToRemove).map((id) => {
    if (contactsToRemove[id] === true) {
      newContacts = [
        ...newContacts.filter((x) => x.id.toString() !== id.toString()),
      ];
    } else {
      console.log('Failed to delete group', id);
    }
    return id;
  });
  return newContacts;
}

export default contact;

const updateContact = (contacts, updatedContact) => {
  return contacts.map((contact) => {
    if (updatedContact.id !== contact.id) {
      return contact; // we wont car un-matched items
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...contact,
      ...updatedContact,
    };
  });
};
