import React, { useEffect, useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import format from 'date-fns/format';

import styles from '../../jss/views/activityTableStyle';
import * as api from '../../config/api';

const headCells = [
  {
    id: 'first_name',
    label: 'Name',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'company_name',
    label: 'Company',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'device',
    label: 'Device',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'user_os',
    label: 'OS',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'user_browser',
    label: 'Browser',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'organization_name',
    label: 'Carrier',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'region',
    label: 'Region',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'ip_address',
    label: 'IP Address',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'remote_postal_code',
    label: 'Postal Code',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'remote_timezone_name',
    label: 'Timezone',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'create_date',
    label: 'Session',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort, isCanSeeAllSessions } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells
          .filter(
            (headCell) =>
              (!isCanSeeAllSessions &&
                headCell.label !== 'Name' &&
                headCell.label !== 'Company' &&
                headCell.label !== 'Status') ||
              (isCanSeeAllSessions &&
                headCell.label !== 'Postal Code' &&
                headCell.label !== 'OS')
          )
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(styles);

const SessionActivity = ({ isCanSeeAllSessions = false }) => {
  const classes = useStyles();
  let history = useHistory();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    isCanSeeAllSessions ? 500 : 25
  );
  const [data, setData] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState('create_date');
  const [order, setOrder] = useState('desc');

  const search = (event) => {
    setSearchKey(event.target.value);
  };

  const getList = useCallback(
    (params = {}) => {
      let uri = `?search_key=${searchKey}&get_all=${isCanSeeAllSessions}`;
      if (params && params.type === 'sort') {
        uri = uri + '&sort=' + params.sortItems;
      }
      // const url = `${api.apiUrl}/system/activity/session?search_key=${searchKey}&page_number=${page}&page_size=${rowsPerPage}`;
      const url = `${api.apiUrl}/system/activity/session${uri}`;
      api
        .GET(url)
        .then((res) => {
          setData(res.activities);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [searchKey, isCanSeeAllSessions]
  );

  useEffect(() => {
    let sortItems = '',
      params = {};
    sortItems = order === 'desc' ? `-${orderBy}` : `${orderBy}`;

    params = { type: 'sort', sortItems };
    getList(params);
  }, [getList, order, orderBy]);

  const loadSortedSession = (property, order) => {
    let sortItems = '',
      params = {};
    sortItems = order === 'desc' ? `-${property}` : `${property}`;

    params = { type: 'sort', sortItems };
    getList(params);
    setOrder(order);
    setOrderBy(property);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedSession(property, newOrder);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const redirectToActivities = (member_id) => {
    history.push(
      `${process.env.PUBLIC_URL}/statistic?tab_index=4&member_id=${member_id}`
    );
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar variant="dense" className={classes.searchRoot}>
        <div className={classes.search}>
          <div aria-label="search-icon" className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={searchKey}
            onChange={search}
          />
        </div>
        <FormControl variant="outlined" className={classes.filerDropDown}>
          <InputLabel id="demo-simple-select-outlined-label">
            Sort By
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={orderBy}
            onChange={(e) => {
              let order = 'asc';
              if (e.target.value === 'create_date') {
                order = 'desc';
              }
              loadSortedSession(e.target.value, order);
              setOrderBy(e.target.value);
              setOrder(order);
            }}
            label="Sort By"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {headCells
              .filter(
                (headCell) =>
                  (!isCanSeeAllSessions &&
                    headCell.label !== 'Name' &&
                    headCell.label !== 'Company' &&
                    headCell.label !== 'Status') ||
                  (isCanSeeAllSessions &&
                    headCell.label !== 'Postal Code' &&
                    headCell.label !== 'OS')
              )
              .map((headCell) => (
                <MenuItem key={headCell.id} value={headCell.id}>
                  {headCell.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Toolbar>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="activityTable"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            isCanSeeAllSessions={isCanSeeAllSessions}
          />
          <TableBody>
            {data
              .slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((row, index) => {
                const fullName = `${row.first_name || ''} ${
                  row.last_name || ''
                }`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`invite_activity_${index}`}
                  >
                    {isCanSeeAllSessions && (
                      <>
                        <TableCell className={classes.tableCellUser}>
                          <Tooltip
                            title={`Show ${fullName} activities`}
                            placement="top"
                          >
                            <Link
                              className={classes.tableCellLink}
                              component="button"
                              onClick={() =>
                                redirectToActivities(row.member_id)
                              }
                            >
                              {fullName}
                            </Link>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{row.company_name}</TableCell>
                      </>
                    )}
                    <TableCell>{row.device ? row.device : ''}</TableCell>
                    {!isCanSeeAllSessions && (
                      <TableCell>{row.user_os ? row.user_os : ''}</TableCell>
                    )}
                    <TableCell>
                      {row.user_browser
                        ? row.user_browser +
                          (row.user_browser_version
                            ? ' ' + row.user_browser_version
                            : '')
                        : ''}
                    </TableCell>
                    <TableCell>
                      {row.organization_name
                        ? row.organization_name +
                          (row.organization_type
                            ? ' (' + row.organization_type + ')'
                            : '')
                        : ''}
                    </TableCell>
                    <TableCell>{row.city ? row.city : ''}</TableCell>
                    <TableCell>{row.region ? row.region : ''}</TableCell>
                    <TableCell>{row.country ? row.country : ''}</TableCell>
                    <TableCell>
                      {row.ip_address ? row.ip_address : ''}
                    </TableCell>
                    {!isCanSeeAllSessions && (
                      <TableCell>
                        {row.remote_postal_code ? row.remote_postal_code : ''}
                      </TableCell>
                    )}
                    <TableCell>
                      {row.remote_timezone_name ? row.remote_timezone_name : ''}
                    </TableCell>
                    <TableCell>
                      {format(new Date(row.create_date), 'MM/dd/yyyy hh:mm a')}
                    </TableCell>
                    {isCanSeeAllSessions && <TableCell>{row.status}</TableCell>}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[25, 50, 75, 100, 500]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
      {
        <div className="card-pagination contact-cards-pagination">
          <div className="item-per-page">
            <FormControl>
              <Select
                labelId="item-count-per-page-select-label"
                id="item-count-per-page-select"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={75}>75</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={100}>500</MenuItem>
              </Select>
            </FormControl>
            <p className="item-count-text">Cards Per Page</p>
          </div>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={parseInt(Math.ceil(data.length / rowsPerPage))}
            page={page}
            onChange={handleChangePage}
            defaultPage={1}
            color="primary"
            showFirstButton
            showLastButton
          />
        </div>
      }
    </Paper>
  );
};

SessionActivity.propTypes = {
  isCanSeeAllSessions: PropTypes.bool,
};

export default SessionActivity;
