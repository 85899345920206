import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import Grid from '@material-ui/core/Grid';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import InputBase from '@material-ui/core/InputBase';
// import SearchIcon from '@material-ui/icons/Search';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import SettingsIcon from '@material-ui/icons/Settings';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { fade, makeStyles } from '@material-ui/core/styles';

import CardContainer from './CardContainer';
import ListContainer from './ListContainer';
import NavigationGroup from './NavigationGroup';
import SortBySettingsModal from '../../components/Modal/SortBySettingsModal';

import { getGroupList } from '../../redux/actions/group';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  groupTool: {
    marginTop: '20px',
  },
  // sortGroup: {
  //   float: 'right',
  //   paddingRight: '20px',
  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginTop: '20px',
    marginRight: theme.spacing(2),
    width: '100%',
    boxShadow:
      '0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 7px 0px rgba(0, 0, 0, 0.12)',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // sortGroup: {
  //   float: 'right',
  //   paddingRight: '20px',
  // },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    width: '92%',
  },
  groupSettingBtn: {
    marginLeft: theme.spacing(1.25),
    marginRight: theme.spacing(1.25),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  groupSettingSVG: {
    '&:hover': {
      cursor: 'pointer',
      color: '#868282',
    },
  },
}));

const MyGroup = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const { loading, dispatch, groupList, location } = props;
  const [viewCard, setViewCard] = useState(true);
  const [orderBy, setOrderBy] = useState('group_name');
  const [view, setView] = useState('module');
  const [searchKey, setSearchKey] = useState('');
  const [showGroupSortSetting, setShowGroupSortSetting] = useState(false);
  const toggleCard = (event, nextView) => {
    if (nextView !== null) {
      setViewCard(!viewCard);
      setView(nextView);
    }
  };

  useEffect(() => {
    dispatch(getGroupList());
  }, [dispatch]);

  const loadSortedGroups = (property, order) => {
    let sortBy = '';
    sortBy = order === 'desc' ? `-${property}` : `${property}`;

    dispatch(getGroupList(false, sortBy, searchKey));
    setOrderBy(property);
  };

  const handleScheduleClick = (group) => {
    if (!!group && typeof group === 'object') {
      console.log('Group to be pushed', group);
      history.push({
        pathname: `${process.env.PUBLIC_URL}/calendar`,
        search: `?group_id=${group.group_id}`,
      });
    }
  };

  const handleSearch = (e) => {
    const newSearchKey = e.target.value,
      sortBy = orderBy;
    dispatch(getGroupList(false, sortBy, newSearchKey));
    setSearchKey(newSearchKey);
  };

  return (
    <React.Fragment>
      <div className="group-section view-section">
        <div className="toggle-button-group">
          <NavigationGroup
            type="my-groups"
            searchKey={searchKey}
            handleSearch={handleSearch}
          />
          <ToggleButtonGroup value={view} exclusive onChange={toggleCard}>
            <ToggleButton value="module" aria-label="module">
              <Tooltip title="Group Tile">
                <ViewModuleIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="list" aria-label="list">
              <Tooltip title="Group List">
                <ViewListIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          <div className={classes.groupSettingBtn}>
            <Tooltip title="Settings" arrow>
              <SettingsIcon
                className={classes.groupSettingSVG}
                onClick={() => setShowGroupSortSetting(true)}
              />
            </Tooltip>
          </div>
        </div>
        {/* <Grid container className={classes.groupTool}>
          <Grid item sm={8} xs={12}>
            <div className={classes.search}>
              <div aria-label="search-icon" className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                // onChange={search}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Grid>
          <Grid item sm={4} xs={12}>
            <div className={classes.sortGroup}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="sort-group-native">Sort By</InputLabel>
                <Select
                  native
                  value={orderBy}
                  onChange={(e) => {
                    loadSortedGroups(e.target.value, 'asc');
                    setOrderBy(e.target.value);
                  }}
                  inputProps={{
                    name: 'sortGroup',
                    id: 'sort-group-native',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value="group_leader_first_name">First Name</option>
                  <option value="group_leader_last_name">Last Name</option>
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid> */}
        {viewCard ? (
          <CardContainer
            groups={groupList}
            type="group"
            onScheduleClick={handleScheduleClick}
            openDetailsFor={
              !!location.state ? location.state.openDetailsFor : null
            }
          ></CardContainer>
        ) : (
          <ListContainer
            orderBy={orderBy}
            setOrderBy={(property) => setOrderBy(property)}
            handleSortList={loadSortedGroups}
            groups={groupList}
            type="group"
            openDetailsFor={
              !!location.state ? location.state.openDetailsFor : null
            }
          ></ListContainer>
        )}
        <SortBySettingsModal
          type="my_groups"
          loading={loading}
          open={showGroupSortSetting}
          orderBy={orderBy}
          onClose={() => setShowGroupSortSetting(false)}
          setOrderBy={setOrderBy}
          onClick={loadSortedGroups}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  groupList: state.group.groupList,
  loading: state.group.getGroupListLoading,
});

export default connect(mapStateToProps)(MyGroup);
