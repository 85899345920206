import React, { useState } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Card,
  TextField,
} from '@material-ui/core';

import dateFormat from '../../utils/dateFormat';
import LocationCountrySelector from '../LocationCountrySelector';
import MaterialAvatar from '../MaterialAvatar';
import ImageCrop from '../Modal/ImageCrop';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    padding: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  margin8: {
    margin: theme.spacing(1),
  },
}));

const CompanyDetail = (props) => {
  const styles = useStyles();

  const { company, state, isEdit, src, setSrc, onStateChange } = props;
  const [isShowCrop, setShowCrop] = useState(false);

  let data = [
    {
      key: 'name',
      label: 'Name',
      required: true,
      value: isEdit ? (state.name ? state.name : company.name) : company.name,
    },
    {
      key: 'primary_url',
      value: isEdit
        ? state.primary_url
          ? state.primary_url
          : company.primary_url
        : company.primary_url,
      label: 'Company URL',
    },
    {
      key: 'main_phone',
      value: isEdit
        ? state.main_phone
          ? state.main_phone
          : company.main_phone
        : company.main_phone,
      label: 'Phone',
    },
    {
      key: 'address_1',
      label: 'Address 1',
      value: isEdit
        ? state.address_1
          ? state.address_1
          : company.address_1
        : company.address_1,
    },
    {
      key: 'address_2',
      label: 'Address 2',
      value: isEdit
        ? state.address_2
          ? state.address_2
          : company.address_2
        : company.address_2,
    },
    {
      key: 'city',
      label: 'City',
      value: isEdit ? (state.city ? state.city : company.city) : company.city,
    },
    {
      key: 'state',
      label: 'State',
      value: isEdit
        ? state.state
          ? state.state
          : company.state
        : company.state,
    },
    {
      key: isEdit ? 'country_code_id' : 'country',
      value: isEdit
        ? state.country_code_id
          ? state.country_code_id
          : company.country_code_id
        : company.country,
      label: 'Country',
    },
  ];

  if (!isEdit) {
    data = [
      ...data,
      {
        key: 'create_date',
        label: 'Create Date',
        value: dateFormat(company.create_date),
      },
      {
        key: 'total_members',
        label: 'Total Members',
        value: company && company.members ? company.members.length : 0,
      },
    ];
  }

  const handleCropConfirm = (file) => {
    onStateChange({ logo: file });
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSrc(reader.result);
      };
    }
  };

  const handleAvatarChange = (e) => {
    if (!isEdit) return;
    const selectedFile = e.target.files[0];
    const reader = new FileReader();

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setSrc(reader.result);
      };
      setShowCrop(true);
    }
  };

  const handleChange = (event) => {
    event.persist();
    onStateChange({
      [event.target.name]: event.target.value,
    });
  };

  const handleCountryChange = (event) => {
    event.persist();
    onStateChange({
      country_code_id: event.target.value.toString(),
    });
  };

  return (
    <Grid container>
      <Card className={styles.card}>
        <div className="profile-avatar d-flex justify-content-center">
          <ImageCrop
            show={isShowCrop}
            close={() => setShowCrop(false)}
            src={src}
            handleCropConfirm={handleCropConfirm}
          />
          <label htmlFor="change-avatar-profile-edit">
            <input
              style={{ display: 'none' }}
              id="change-avatar-profile-edit"
              name="change-avatar-mu"
              type="file"
              accept="image/*"
              disabled={!isEdit}
              onChange={handleAvatarChange}
            />
            <MaterialAvatar
              badgeType={isEdit ? 'edit' : null}
              classes="profile my"
              src={src ? src : company.s3_logo_url}
            />
          </label>
        </div>
        <Grid item xs={12} className={styles.title}>
          <Typography variant="h6">Detail</Typography>
        </Grid>
        <Grid item xs={12}>
          {data &&
            data.map((row, index) => {
              return (
                <Grid container spacing={2} key={index}>
                  {isEdit ? (
                    row.key !== 'country_code_id' ? (
                      <TextField
                        label={row.label}
                        fullWidth={true}
                        required={row.required ? true : false}
                        color="primary"
                        value={row.value === null ? '' : row.value}
                        className={styles.margin8}
                        name={row.key}
                        onChange={handleChange}
                      />
                    ) : (
                      <LocationCountrySelector
                        location_type
                        className={styles.margin8}
                        country={row.value}
                        handleChange={handleCountryChange}
                      />
                    )
                  ) : (
                    <>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <b>{row.label}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">{row.value}</Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}
        </Grid>
      </Card>
    </Grid>
  );
};

export default CompanyDetail;
