import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import ListDeleteButton from '../../ListDeleteButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

const AchievementListItem = (props) => {
  const { errors, achievementData, handleChange, handleDelete } = props;
  const { id, entity, description } = achievementData;

  const handleTextInput = (event) => {
    handleChange({
      id: id,
      name: event.target.name,
      value: event.target.value,
    });
  };

  let entityError,
    descriptionError = false;
  if (errors) {
    entityError = errors.entity ? errors.entity : null;
    descriptionError = errors.description ? errors.description : null;
  }

  return (
    <ListItem id={id} key={id} className="item">
      <div className="item-text">
        <TextField
          error={Boolean(entityError)}
          label="Entity"
          type="text"
          name="entity"
          placeholder="University"
          onChange={handleTextInput}
          value={entity}
          helperText={entityError}
        />
        <TextField
          error={Boolean(descriptionError)}
          label="Description"
          type="text"
          name="description"
          placeholder="Description"
          onChange={handleTextInput}
          value={description}
          helperText={descriptionError}
        />
      </div>
      <ListItemSecondaryAction>
        <ListDeleteButton
          id={id}
          handleDelete={handleDelete}
          listContext="achievement"
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default AchievementListItem;
