import {
  IconButton,
  ListItem,
  makeStyles,
  Paper,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';
import SVG from '../../../../../components/Icons/SVG';
import { gray, green, textNewGray } from '../../../../../jss/colors';

const useStyles = makeStyles((theme) => ({
  darkButton: {
    color: textNewGray,
    maxHeight: 35,
    minHeight: 35,
    maxWidth: 35,
    minWidth: 35,
    marginRight: 10,
  },
  folder: {
    color: textNewGray,
    fontWeight: 'bold',
    maxWidth: 200,
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'auto',
  },
  simpleText: {
    backgroundColor: green,
    color: 'white',
    padding: 10,
  },
  noFolders: {
    color: gray,
    padding: 10,
  },
}));

export const MailMoveToFolderElement = ({
  icon,
  tooltip,
  handleSubmit,
  folders,
  selectedFolder,
  section,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton onClick={handleClick} className={classes.darkButton}>
          <SVG name={icon} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper>
          <Typography className={classes.simpleText}>Move To :</Typography>
          {folders.length === 0 ? (
            <Typography className={classes.noFolders}>No Folders</Typography>
          ) : (
            <>
              {selectedFolder && (
                <ListItem
                  className={classes.folder}
                  button
                  onClick={() => {
                    handleSubmit(-1);
                    handleClose();
                  }}
                >
                  {section}
                </ListItem>
              )}
              {folders.map(
                (el) =>
                  el.id !== selectedFolder && (
                    <ListItem
                      key={el.id}
                      className={classes.folder}
                      button
                      onClick={() => {
                        handleSubmit(el.id);
                        handleClose();
                      }}
                    >
                      {el.name}
                    </ListItem>
                  )
              )}
            </>
          )}
        </Paper>
      </Popover>
    </>
  );
};
