import React from 'react';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import MaterialAvatar from '../../../../components/MaterialAvatar';

function ConversationEmpty(props) {
  console.log('contactAvatarURL', props.contactAvatarURL);
  return (
    <div id="no-coversation-layout">
      <div id="no-conversation-content">
        <h2>No Conversations</h2>
        <AvatarGroup max={3} id="no-conversation-avatar-group">
          <MaterialAvatar
            src={props.memberAvatarURL}
            firstName={props.memberFirstName}
            lastName={props.memberLastName}
            isBasicAvatar={true}
          />
          <MaterialAvatar
            src={props.contactAvatarURL}
            firstName={props.contactFirstName}
            lastName={props.contactLastName}
            isBasicAvatar={true}
          />
          {props.isGroupChat && (
            <MaterialAvatar
              src=""
              firstName=""
              lastName=""
              isBasicAvatar={true}
            />
          )}
        </AvatarGroup>
        <p>{props.emptyMessage}</p>
      </div>
    </div>
  );
}

export default ConversationEmpty;
