// import React, { Component } from 'react';
import VideoConference from './function';
export const VI = new VideoConference();
export const vacall = {
  connection: {},
  isJoined: false,
  room: null,
  localTracks: [],
  remoteTracks: {},
  isVideo: null,
  remoteVideos: {},
  popup: false,
  ToggleView: false,
  screenshareEnabled: false,
  blurBackground:false,
  isLocalId: Function,
  userCount: 1,
  VI:VI,
  isMicMuted: Function,
  isVideoMuted: Function,
  youTubeJOined: {
    conference: null,
    id: null,
    isFakeParticipant: null,
    name: null,
    from: null,
  },
  localParticipant : {
    _displayName:null,
    _isVideo:null,
    _isAudio:null,
    _avatar_url:null,
    _member_id:null
    }
};
// window.APP = {
//   vacall,
// }
export let rePopo = false;
export const config = {
  hosts: {
    domain: 'conference.ameraiot.com',
    muc: 'conference.conference.ameraiot.com', // FIXME: use XEP-0030
    focus: 'focus.conference.ameraiot.com',
  },

  serviceUrl: 'wss://conference.ameraiot.com/xmpp-websocket',
  clientNode: 'https://conference.ameraiot.com',
  startWithAudioMuted: true,
  resolution: 720,
  disableSimulcast: true, // false,
  constraints: {
    video: {
      aspectRatio: 16 / 9,
      height: {
        ideal: 1280,
        max: 1280,
        min: 240,
      },
    },
  },
  useStunTurn: true,
};
export const initOptions = {
  disableAudioLevels: true,
};

export const conference = {
  // enableWebsocketResume: true,
  useStunTurn: true,
  openBridgeChannel: true,
  startWithAudioMuted: true,
  // Enable / disable simulcast support.
  disableSimulcast: true, // false,
  resolution: 720,
  constraints: {
    video: {
      aspectRatio: 16 / 9,
      height: {
        ideal: 1280,
        max: 1280,
        min: 240,
      },
    },
  },
  //   e2eping: {
  //     pingInterval: -1
  // },
  testing: {
    // Enables experimental simulcast support on Firefox.
    //disableE2EE: false,
    enableFirefoxSimulcast: false,

    // P2P test mode disables automatic switching to P2P when there are 2
    // participants in the conference.
    p2pTestMode: false,

    // Enables the test specific features consumed by jitsi-meet-torture
    // testMode: false
    octo: {
      probability: 1,
    },
    // Disables the auto-play behavior of all newly created video element.
    // This is useful when the client runs on a host with limited resources.
    // noAutoPlayVideo: false
  },
};
