import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DenseTabs from '../components/DenseTabs/DenseTabs';
import DenseTab from '../components/DenseTabs/DenseTab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { CALENDAR_VIEWS } from '../utils/calendar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Tooltip from '@material-ui/core/Tooltip';

const useStyles = (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  flex: {
    display: 'flex',
  },
  filter: {
    marginLeft: 20,
    width: 150,
  },
});

const FunctionalToolbar = (props) => {
  const {
    view,
    label,
    // localizer,
    onNavigate,
    onView,
    classes,
  } = props;

  const handleViewClick = (e, v) => {
    onView(v);
  };

  return (
    <Grid container alignItems="center" justify="space-between" spacing={0}>
      <Grid
        item
        container
        wrap="nowrap"
        justify="space-between"
        xs={12}
        className={classes.root}
        spacing={0}
      >
        <Grid item xs={2}></Grid>

        <Grid
          item
          container
          justify="center"
          alignItems="center"
          direction="row"
          wrap="nowrap"
          xs={3}
        >
          <Tooltip title="Show previous">
            <IconButton onClick={() => onNavigate('PREV')}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>

          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <Tooltip title="Show current">
                <Button
                  onClick={() => onNavigate('TODAY')}
                  style={{ textTransform: 'none' }}
                >
                  <Typography variant="h5">{label}</Typography>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>

          <Tooltip title="Show next">
            <IconButton onClick={() => onNavigate('NEXT')}>
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={2}>
          <DenseTabs
            value={view}
            onChange={handleViewClick}
            indicatorColor="primary"
          >
            <DenseTab value={CALENDAR_VIEWS.month} label="Month" />
            <DenseTab value={CALENDAR_VIEWS.week} label="Week" />
            <DenseTab value={CALENDAR_VIEWS.day} label="Day" />
          </DenseTabs>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(useStyles)(FunctionalToolbar);
