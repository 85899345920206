import React from 'react';
import {
  // DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const DateInput = (props) => {
  const { value, label, onChange, disabled = false, minDate = null } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        // disablePast
        // inputVariant="outlined"
        fullWidth
        minDate={minDate}
        label={label}
        format="MM/dd/yyyy"
        value={value}
        // InputAdornmentProps={{ position: 'start' }}
        // onMonthChange={(date) => handleMonthChange(date)}
        onChange={onChange}
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateInput;
