import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import Modal from './modal';

import {
  updateContact,
  setReduxContactAlert,
} from '../../redux/actions/contact';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
}));

const UpdateContactRoleModal = (props) => {
  const {
    open,
    onClose,
    contact,
    dispatch,
    loading,
    roles,
    contactAlert,
  } = props;
  const [selectedRoleId, setSelectedRoleId] = useState('');

  const handleListItemClick = (event, id) => {
    setSelectedRoleId(id);
  };

  const title = 'Update Contact Role';
  const classes = useStyles();

  //for now fixing with eslint b/c it cause rerender which results infinite API calls
  // To fix it properly shift contactAlert code in contact root component just like we did in contact setting modal.
  // In addition we should reload contacts in updateContact action callback.
  const closeModal = useCallback(() => {
    // contactAlert.show = false;
    setSelectedRoleId(null);
    onClose();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSnackbarClose = () => {
    if (contactAlert.show) {
      const alertOption = {
        show: false,
        variant: contactAlert.variant,
        message: '',
      };
      dispatch(setReduxContactAlert(alertOption));
    }
  };

  useEffect(() => {
    const setDefaultRole = () => {
      const role = roles.filter((role) => role.name === contact.role)[0];
      if (role && role.id) {
        setSelectedRoleId(role.id);
      }
    };
    setDefaultRole();
  }, [contact, roles]);

  useEffect(() => {
    if (open && contactAlert.show) {
      closeModal();
      setTimeout(() => {
        const alertOption = {
          show: false,
          variant: contactAlert.variant ? contactAlert.variant : 'success',
          message: '',
        };
        dispatch(setReduxContactAlert(alertOption));
      }, 5000);
    }
  }, [open, contactAlert, dispatch, closeModal]);

  const updateContactRole = () => {
    let updatedContact = { ...contact };
    //for now updating contact role by name but we should update by role_id
    let role = roles.filter((role) => role.id === selectedRoleId)[0];
    updatedContact['role_id'] = role.id;
    updatedContact['role'] = role.name;
    dispatch(updateContact(updatedContact));
  };
  const renderListItems = () => {
    return roles.map((role) => (
      <ListItem
        button
        key={role.id}
        value={role.id}
        selected={role.id === selectedRoleId}
        alignItems="center"
        onClick={(event) => handleListItemClick(event, role.id)}
      >
        <ListItemText>{role.name}</ListItemText>
      </ListItem>
    ));
  };

  const ModalContent = () => (
    <div>
      <List component="nav">{renderListItems()}</List>
    </div>
  );

  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.actionBtn}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            disabled
          >
            Updating ...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            onClick={(e) => updateContactRole(e)}
          >
            Update
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        disabled={loading}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={contactAlert.show}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={contactAlert.variant}>{contactAlert.message}</Alert>
      </Snackbar>
    </>
  );
};

UpdateContactRoleModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  contact: PropTypes.object,
  loading: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.object),
  contactAlert: PropTypes.object,
};

UpdateContactRoleModal.defaultProps = {
  open: false,
  onClose: null,
  contact: {},
  loading: false,
  roles: [],
  contactAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

const mapStateToProps = (state) => ({
  loading: state.contact.loading,
  roles: state.member.roles,
  contactAlert: state.contact.contactAlert,
});

export default connect(mapStateToProps)(UpdateContactRoleModal);
