import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import ChatsPanel from './ChatsPanel';
import ChatMessages from '../shared/ChatMessages';
import ChatForm from '../shared/ChatForm';

import useStrophe from '../../../hooks/useStrophe';
import { addMessage, loadMessages } from '../../../redux/actions/chat';

const ROOM_SERVER = 'conference.nomnom.im',
  ROOM = 'anonymous@' + ROOM_SERVER,
  JID = 'nomnom.im',
  PASSWORD = 'pass123';

const Alert = (props) => <MuiAlert elevation={6} {...props} />;

function Notifications({ stropheAlert, hideAlert }) {
  return (
    <Snackbar
      open={stropheAlert.show}
      autoHideDuration={4000}
      onClose={hideAlert}
    >
      <Alert
        variant="outlined"
        onClose={hideAlert}
        severity={stropheAlert.severity}
      >
        {stropheAlert.message}
      </Alert>
    </Snackbar>
  );
}

function GroupChat(props) {
  const { memberState, messages, addMessage, loadMessages } = props;
  const { first_name, last_name } = memberState.memberInfo;
  const nickName = first_name + ' ' + last_name;

  const scrollToBottom = () => {
    if (messagesEnfRef && messagesEnfRef.current) {
      messagesEnfRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  };

  const {
    stropheAlert,
    hideAlert,
    authenticated,
    rosters,
    sendMessage,
  } = useStrophe({
    ROOM,
    ROOM_SERVER,
    JID,
    PASSWORD,
    nickName,
    debuggingMode: true,
    addMessage,
    scrollToBottom,
  });

  const messagesEnfRef = useRef();

  useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  const handleSendMessage = (messageText) => {
    const room_name = ROOM,
      msg = messageText;
    sendMessage(room_name, msg, 'groupchat');
  };

  return (
    <>
      <div className="chat-section">
        <div className="messages-container">
          <ChatMessages
            isGroupChat={true}
            emptyMessage="Please wait while Group Chat is loading..."
            roomMessages={messages}
            messagesEnfRef={messagesEnfRef}
          />
          <ChatForm
            disabled={!authenticated}
            handleSendMessage={handleSendMessage}
          />
        </div>
        <div className="chats-panel">
          <ChatsPanel rosters={rosters} />
        </div>
      </div>
      <Notifications stropheAlert={stropheAlert} hideAlert={hideAlert} />
    </>
  );
}

const mapStateToProps = (state) => ({
  memberState: state.member,
  messages: state.chat.messages,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ addMessage, loadMessages }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupChat);
