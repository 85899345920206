export const SCHEDULER_SETTING_API_CALL_START =
  'SCHEDULER_SETTING_API_CALL_START';
export const SCHEDULER_SETTING_API_CALL_SUCCESS =
  'SCHEDULER_SETTING_API_CALL_SUCCESS';
export const SCHEDULER_SETTING_API_CALL_FAIL =
  'SCHEDULER_SETTING_API_CALL_FAIL';

export const SCHEDULE_EVENT_API_CALL_START = 'SCHEDULE_EVENT_API_CALL_START';
export const SCHEDULE_EVENT_CREATE_START = 'SCHEDULE_EVENT_CREATE_START';
export const SCHEDULE_EVENT_API_CALL_FAIL = 'SCHEDULE_EVENT_API_CALL_FAIL';
export const SCHEDULE_EVENT_API_CALL_GET_SUCCESS =
  'SCHEDULE_EVENT_API_CALL_GET_SUCCESS';
export const SCHEDULE_EVENT_API_CALL_ADD_SUCCESS =
  'SCHEDULE_EVENT_API_CALL_ADD_SUCCESS';

export const SCHEDULE_EVENT_MODIFY_START = 'SCHEDULE_EVENT_MODIFY_START';
export const SCHEDULE_EVENT_MODIFY_SUCCESS = 'SCHEDULE_EVENT_MODIFY_SUCCESS';
export const SCHEDULE_EVENT_MODIFY_FAIL = 'SCHEDULE_EVENT_MODIFY_FAIL';

export const SCHEDULE_EVENT_CANCEL_START = 'SCHEDULE_EVENT_CANCEL_START';
export const SCHEDULE_EVENT_CANCEL_SUCCESS = 'SCHEDULE_EVENT_CANCEL_SUCCESS';
export const SCHEDULE_EVENT_CANCEL_FAIL = 'SCHEDULE_EVENT_CANCEL_FAIL';

export const SCHEDULE_EVENT_INVITE_API_CALL_START =
  'SCHEDULE_EVENT_API_CALL_START';
export const SCHEDULE_EVENT_INVITE_API_CALL_FAIL =
  'SCHEDULE_EVENT_API_CALL_FAIL';
export const SCHEDULE_EVENT_INVITE_API_CALL_GET_SUCCESS =
  'SCHEDULE_EVENT_INVITE_API_CALL_GET_SUCCESS';
export const SCHEDULE_EVENT_INVITE_API_CALL_ADD_SUCCESS =
  'SCHEDULE_EVENT_INVITE_API_CALL_ADD_SUCCESS';

export const SCHEDULE_EVENT_HIDE_ERROR = 'SCHEDULE_EVENT_HIDE_ERROR';

// Calendar Colors
export const SCHEDULE_EVENT_GET_COLORS_START =
  'SCHEDULE_EVENT_GET_COLORS_START';
export const SCHEDULE_EVENT_GET_COLORS_SUCCESS =
  'SCHEDULE_EVENT_GET_COLORS_SUCCESS';
export const SCHEDULE_EVENT_GET_COLORS_FAILED =
  'SCHEDULE_EVENT_GET_COLORS_FAILED';

// Calendar event file uploads
export const EVENT_ATTACH_START = 'EVENT_ATTACH_START';
export const EVENT_ATTACH_PROGRESS = 'EVENT_ATTACH_PROGREESS';
export const EVENT_ATTACH_SUCCESS = 'EVENT_ATTACH_SUCCESS';
export const EVENT_ATTACH_FAILED = 'EVENT_ATTACH_FAILED';
